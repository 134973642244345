import { rem } from '@/theme'
import styled from 'styled-components'

const InfoStyle = styled.div`

	// PC
	@media screen and (min-width: 540px){
		margin-bottom: 50px;
		div.crumbs-bar {
			${({ theme }) => theme.align};
			width: fit-content;
			margin-bottom: 20px;
			cursor: pointer;
			svg {
				width: 16px;
				height: 16px;
				margin-right: 9px;
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		div.crumbs-bar {
			${({ theme }) => theme.align};
			padding: ${rem(20)} 0;
			& > svg {
				width: ${rem(20)};
				height: ${rem(20)};
				margin-right: ${rem(12)};
				path {
					stroke: black;
				}
			}
		}
  }
`

export {
	InfoStyle
}




