import React from 'react'
import { FooterLogoSvg } from '@/utils/icoStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import { FooterStyle } from './styled'
import Text from '@/components/Text'
import Contact from './Contact'

const Footer:React.FC<RouterProps> = ({ t })=> {

  const { linkList } = useCommon()

  // 操作 - 跳转
  const jumpHandle = (sign:string) => {
    window.open(linkList[sign])
  }

  // 渲染 - 导航
  const footerLinkRender = () => {
    const list:any = []
    const footerLinkList = [
      { name: 'Cookies', url: 'Cookies' },
      { name: 'Documentation', url: 'Documentation' },
      { name: 'Service', url: 'Service' },
      { name: 'Policy', url: 'Policy' },
      { name: 'Faqs', url: 'Faqs' },
    ]
    footerLinkList.forEach((item:any, index:number) => {
      const { name, url } = item
      list.push(<Text key={`footerLink_${index}`} color='gray500' hovercolor='text_main' callback={() => jumpHandle(url)}>{t(`Link.${name}`)}</Text>)
    })
    return list
  }

  return (
    <FooterStyle>
      <FooterLogoSvg />
      <div>
        <div className='link-bar'>{ footerLinkRender() }</div>
        <Contact />
      </div>
    </FooterStyle>
  )
}

export default withRouter(Footer)
