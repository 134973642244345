import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import { ScTabStyle } from './styled'
import Text from '@/components/Text'

interface ChildProps extends RouterProps {
  currentKey: string
  callback: any
  kind?:string
  data:any
}

const ScTab:React.FC<ChildProps> = ({ t, data, callback, currentKey, kind='1' })=> {

  const { isPC } = useContext(ConfigContext)

  const contentRender = (item:any, index:number) => {
    const { key, text, imgUrl } = item
    const isActive = currentKey === key
    if(kind === '1') {
      return (
        <Text 
          color={isActive ? 'text_main' : 'tab_default'}
          clsName={`one-normal ${isActive ? 'one-active' : ''}`}
          callback={() => callback(key)}
          size={isPC ? '18' : '16'}
          key={`${text}_${index}`} 
          hovercolor='text_main'
          type='specialEnglish'
          kind='Bold'>
          {t(`Tab.${text}`)}
        </Text>
      )
    }
    if(kind === '2') {
      return (
        <div className={`two-normal ${isActive ? 'two-active' : ''}`} key={`${text}_${index}`} >
          { imgUrl && <img src={imgUrl} alt='' /> }
          <Text 
            color={isActive ? 'primary500' : 'text_main'}
            kind='Bold'
            callback={() => callback(key)}
            size={isPC ? '16' : '14'}>
            {t(`Tab.${text}`)}
          </Text>
        </div>
      )
    }
  }

  const tabRender = () => {
    const list:any = []
    data.forEach((item:any, index:number) => {
      list.push(contentRender(item, index))
    })
    return list
  }

  return (
    <ScTabStyle>{tabRender()}</ScTabStyle>
  )
}

export default withRouter(ScTab)

