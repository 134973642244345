import qs from 'qs'
import axios from 'axios'
const TIME_OUT = 20000 // 超时时间20秒

// 返回数据拦截处理
axios.interceptors.response.use(response => {
  return response.data
}, error => Promise.reject(error.response))

// 封装请求方法
const $_request = (method:string, url:string, data:object, headers?:any) => {
  const configData:any = {
    withCredentials: false, // 跨域带cookie
    url, // 请求的地址
    timeout: TIME_OUT, // 超时时间, 单位毫秒
    headers: headers
  }
  
  const Data = { ...data } 

  if (method === 'get') {
    configData.method = 'get'
    configData.params = Data // get 请求的数据
  } else if (method === 'postForm') {
    configData.method = 'post'
    configData.headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }
    configData.data = qs.stringify(Data)
  } else if (method === 'postJson') {
    configData.method = 'post'
    configData.headers = Object.assign(headers, { 'Content-Type': 'application/json; charset=UTF-8' })
    configData.data = Data
  } else if (method === 'patch')  {
    configData.method = 'patch'
    configData.headers = { 'Content-Type': 'application/json; charset=UTF-8' }
    configData.data = Data
  }

  return axios(configData)
}

class Ajax {

  get = (url:string, data = {}, headers = {}) => {
    return $_request('get', url, data, headers)
  }

  postForm = (url:string, data = {}, headers = {}) => {
    return $_request('postForm', url, data, headers)
  }

  postJson = (url:string, data = {}, headers = {}) => {
    return $_request('postJson', url, data, headers)
  }

  patch = (url:string, data = {}, headers = {}) => {
    return $_request('patch', url, data, headers)
  }

}

const ajax = new Ajax()

export default ajax
