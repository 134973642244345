const swapManageState:any = {
  // 当前池子 - 信息
  poolInfo: {},
  // LP - 资产
  lpBalance: '0',
  // TokenA - 资产
  tokenBalanceA: '0',
  // TokenB - 资产
  tokenBalanceB: '0',
  // 加载状态
  loadingStatus: true
}

const swapManageReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  swapManageReducer,
  swapManageState
}