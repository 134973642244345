const bridgeManageState:any = {
  // 当前池子 - 信息
  poolInfo: {},
  // LP - 资产
  lpBalance: '0',
  // Token - 资产
  tokenBalance: '0',
  // 加载状态
  loadingStatus: true
}

const bridgeManageReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  bridgeManageReducer,
  bridgeManageState
}