import { useEffect, useContext } from 'react'
import { ConfigContext, PoolCreateContext } from '@/utils/contextStatic'
import useContractSwapV2 from '@/hooks/useContract/useContractSwapV2'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import BigNumber from 'bignumber.js'

/**
 * 设置按钮状态 1:授權A 2:加載中 3:确认 5:钱包未链接 6: 链不匹配 7:授权B
 * @param {String} inputValA         // 输入值A
 * @param {String} inputValB         // 输入值B
 * @param {String} handler           // 方法
 */

export default function useBtnPoolCreate (inputValA: string, inputValB:string, handler: any) {

  const { _inPutFormat } = usePublicEthers()

  const { _getApproveAmount } = usePublicErc20()

  const { swapOperateContract } = useContractSwapV2()
  
  const { account, chainId } = useContext(ConfigContext)

  const { createFormParam } = useContext(PoolCreateContext)

  const { tokenAddressA, tokenAddressB, selectNetwork, tokenDecimalA, tokenDecimalB } = createFormParam
  
  // 钱包 -> 授权
  useEffect(() => {
    const initFun = async() => {
      if(account) {
        if(+chainId === selectNetwork) {
          let directionA:any = 0
          let directionB:any = 0
          if(+inputValA > 0 || +inputValB > 0) {
            const approveAmountA = await _getApproveAmount(tokenAddressA, swapOperateContract)
            const approveAmountB = await _getApproveAmount(tokenAddressB, swapOperateContract)
            directionA = new BigNumber(approveAmountA).minus(_inPutFormat(inputValA ? inputValA : '0', tokenDecimalA)).toFixed(0)
            directionB = new BigNumber(approveAmountB).minus(_inPutFormat(inputValB ? inputValB : '0', tokenDecimalB)).toFixed(0)
          }
          if(+directionA >= 0 && +directionB >= 0) {
            handler(3)
          } else {
            if(directionA < 0) {
              handler(1)
            } else {
              if(directionB < 0) handler(7)
            }
          }
        } else {
          handler(6)
        }
      } else {
        handler(5)
      }
    }
    initFun()
  },[inputValA, inputValB, account, chainId]) 
}