import { rem } from '@/theme'
import styled from 'styled-components'

const PopSelectWalletStyle = styled.div`

  @media screen and (min-width: 540px) { 
    & > ul {
      padding-top: 20px;
      li {
        margin-top: 16px;
        ${({ theme }) => theme.align_between};
        height: 64px;
        padding: 0 16px;
        border: 1px solid ${({ theme }) => theme.gray100};
        border-radius: 12px;
        cursor: pointer;
        svg {
          width: 36px;
          height: 36px;
        }
      }
      li:hover, li.active {
        border-color: ${({ theme }) => theme.primary500};
        background: ${({ theme }) => theme.wallet_bg};
      }
    }
  }

`

const H5SelectWalletStyle = styled.ul`
  position: fixed;
	top: ${rem(56)};
	left: 0;
	width: 100%;
	height: calc(100% - ${rem(56)});
	padding: ${rem(16)} ${rem(16)} 0;
  background: ${({ theme }) => theme.white};
  z-index: 998;
  overflow-y: auto;
  li {
    ${({ theme }) => theme.align_between};
    height: ${rem(64)};
		padding: 0 ${rem(16)};
    margin-bottom: ${rem(16)};
    border: 1px solid ${({ theme }) => theme.gray100};
    border-radius: ${rem(12)};
    svg {
      width: ${rem(36)};
      height: ${rem(36)};
    }
	}
`

export {
  PopSelectWalletStyle,
  H5SelectWalletStyle
}


