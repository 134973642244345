import { rem } from '@/theme'
import styled from 'styled-components'

const PopStyle = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.4);
	z-index: 998;
	display: flex;
	align-items: center;
	.pop {
		background: ${({ theme }) => theme.white};
		z-index: 998;
		overflow: hidden;
		margin: 0 auto;
		.title-bar  {
			${({ theme }) => theme.align_center};
			position: relative;
			svg {
				position: absolute;
				cursor: pointer;
				right: 0;
			}
		}
	}
	
	@media screen and (min-width: 540px) { 
		.pop {
			padding: 24px 30px 30px;
			width: 464px;
			border-radius: 24px;
			.title-bar {
				svg {
					right: 0px;
					width: 16px;
					height: 16px;
				}
			}
		}
	}

	@media screen and (max-width: 540px) { 
		.pop {
			padding: ${rem(20)} ${rem(20)} ${rem(30)};
			width: 90%;
			border-radius: ${rem(16)};
			.title-bar {
				svg {
					width: ${rem(16)};
					height: ${rem(16)};
				}
			}
		}
	}
`

export {
  PopStyle
}


