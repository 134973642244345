import { useEffect, useContext } from 'react'
import { ConfigContext, SwapManageContext } from '@/utils/contextStatic'
import useContractSwapV2 from '@/hooks/useContract/useContractSwapV2'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import BigNumber from 'bignumber.js'

/**
 * 设置按钮状态 1:授權A 2:加載中 3:确认 5:钱包未链接 6: 链不匹配
 * @param {String} inputVal         // 输入值
 * @param {String} handler          // 方法
 */

export default function useBtnSwapRemoveLiq (inputVal: string, handler: any) {

  const { _inPutFormat } = usePublicEthers()

  const { _getApproveAmount } = usePublicErc20()

  const { poolInfo } = useContext(SwapManageContext)
  
  const { swapOperateContract } = useContractSwapV2()
  
  const { account, chainId } = useContext(ConfigContext)

  const { chain_id, pair } = poolInfo
  
  // 钱包 -> 授权
  useEffect(() => {
    const initFun = async() => {
      if(account) {
        if(+chainId === chain_id) {
          const approveAmount = await _getApproveAmount(pair, swapOperateContract)
          const direction = new BigNumber(approveAmount).minus(_inPutFormat(inputVal ? inputVal : '0')).toFixed(0)
          if(+direction >= 0) {
            handler(3)
          } else {
            handler(1)
          }
        } else {
          handler(6)
        }
      } else {
        handler(5)
      }
    }
    initFun()
  },[inputVal, poolInfo, account, chainId]) 
}