import { rem } from '@/theme'
import styled from 'styled-components'

const PopBridgeChainStyle = styled.div`
  
  div.chain-bar {
    overflow: auto;
    & > div{
      ${({ theme }) => theme.align_between};
    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  // PC
  @media screen and (min-width: 540px) { 
    div.chain-bar {
      max-height: 490px;
      margin-top: 10px;
      & > div {
        padding: 12px 0;
        cursor: pointer;
        & > span { 
          font-size: 18px;
        }
      }
      & > div:hover {
        background: ${({ theme }) => theme.gray50};
      }
    }
  }

  // H5
  @media screen and (max-width: 540px) { 
		div.chain-bar {
      max-height: ${rem(430)};
      margin-top: ${rem(10)};
      & > div {
        padding: ${rem(12)} 0;
        & > span { 
          font-size: ${rem(16)};
        }
      }
    }
  }
`

export {
  PopBridgeChainStyle
}


