import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import { LeftSvg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { InfoStyle } from './styled'


const Info:React.FC<RouterProps> = ({ t, navigate, params }) => {

  const { linkList } = useCommon()

  const { token_symbol } = params
  
  const { isPC } = useContext(ConfigContext)

  const jumpHandle = () => {
    navigate(linkList['Pool'])
  }

  // 渲染 - 面包屑
  const crumbsRender = () => {
    const pcCon = (
      <>
        <div className='crumbs-bar' onClick={jumpHandle}>
          <LeftSvg />
          <Text color='primary500'>{t('Link.Pool')}</Text>
        </div>
        <ScCoin kind='1' content={`${token_symbol} Pool`} name={token_symbol} />
      </>
    )
    const h5Con = (
      <div className='crumbs-bar' onClick={jumpHandle}>
        <LeftSvg />
        <ScCoin kind='7' content={`${token_symbol} Pool`} name={token_symbol}  />
      </div>
    )
    return isPC ? pcCon : h5Con
  }

  return (
    <InfoStyle>{ crumbsRender() }</InfoStyle>
  )
}

export default withRouter(Info)
