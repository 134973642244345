import React, { useContext, useState, useEffect } from 'react'
import { ConfigDispatchContext, BridgeContext, BridgeDispatchContext, ConfigContext } from '@/utils/contextStatic'
import useBusinessBridge from '@/hooks/useBusiness/useBusinessBridge'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import { Down2Svg, DownSvg, Wallet2Svg } from '@/utils/icoStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import useBtnApprove from '@/hooks/useBtn/useBtnApprove'
import useBtnBridge from '@/hooks/useBtn/useBtnBridge'
import useBtnChain from '@/hooks/useBtn/useBtnChain'
import { zeroAddress } from '@/utils/addressStatic'
import ScCoin from '@/components/Section/ScCoin'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import FormInput from '@/components/FormInput'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import { ExchangeStyle } from './styled'
import BigNumber from 'bignumber.js'
import Text from '@/components/Text'
import Gas from './Gas'

const Exchange:React.FC<RouterProps> = ({ t }) => {

  const { _getChainProperty } = useCommon() 
  
  const { _inPutFormat } = usePublicEthers()

  const { isPC, account } = useContext(ConfigContext)

  const { bridgeOperateContract } = useContractBridge()

  const bridgeDispatch:any = useContext(BridgeDispatchContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { _getAllowanceOperate, _getDecimals } = usePublicErc20()

  const { formParam, tokenBalance, destPoolInfo, gasParam, exchangeFee, continueStatus } = useContext(BridgeContext)

  const { fromNetwork, fromToken, fromTokenSymbol, toNetwork, bridgeAmount, tokenDecimal } = formParam

  const fromChainSymbol = _getChainProperty(fromNetwork, 'chain_symbol')

  const fromChainName = _getChainProperty(fromNetwork, 'chain_name')

  const toChainSymbol = _getChainProperty(toNetwork, 'chain_symbol')

  const toChainName = _getChainProperty(toNetwork, 'chain_name')

  const { gasToken, gasCost, gasSymbol } = gasParam

   // 1:授權A 2：加載中 3:确认 5: 钱包未链接 6: 链不匹配 7:授权B
  const [ btnStatus, setBtnStatus ] = useState<number>(3)

  const { last_balance } = destPoolInfo

  // 授权操作
  const coinApproveHandle = useBtnApprove(btnStatus === 1 ? fromToken : gasToken, bridgeOperateContract, setBtnStatus, btnStatus)

  // 跨链操作
  const { _transferTokenToChainHandle } = useBusinessBridge(setBtnStatus)

  // 切链操作
  const toggleChainHandle = useBtnChain(fromNetwork, setBtnStatus)

  // 按钮状态控制
  useBtnBridge(bridgeAmount, setBtnStatus)

  useEffect(() => {
    if(continueStatus) _transferTokenToChainHandle(updataApproveHandle)
  }, [continueStatus])

  // 操作 - 展示
  const showHandle = (kind:string) => {
    configDispatch({ type: 'changePop', payload: kind })
  }

  // 操作 - 输入框
  const changeHandle = (val:string) => {
    bridgeDispatch({ type: 'changeForm', payload: { bridgeAmount: val } })
  }

  // 最大可跨数量
  const getMaxAmount = () => {
    if(+tokenBalance >= +last_balance) return last_balance.toString()
    return tokenBalance
  }

  // 跨链数量提示
  const getAmountErrTips = () => {
    let errorStatus = ''
    const errorList:any = {
      'Insufficient': t('Content.DaoExtractErr',{voucher: fromTokenSymbol}),
    }
    const differ3 = new BigNumber(tokenBalance).minus(bridgeAmount).toNumber()
    // 余额不足
    if(differ3 < 0) errorStatus = 'Insufficient'
    return errorStatus ? errorList[errorStatus] : ''
  }

  // 判断 - 按钮状态
  const openHandle = () => {
    let receiveAmount = '0'
    if(bridgeAmount) {
      let amount = new BigNumber(bridgeAmount).minus(exchangeFee).toString()
      if(gasSymbol === fromTokenSymbol) {
        amount = new BigNumber(amount).minus(gasCost).toString()
        amount = +amount > 0 ? amount : '0'
      }
      receiveAmount = amount
    }
    const isPass = +last_balance >= +bridgeAmount &&
                   +bridgeAmount > 0 && 
                   !getAmountErrTips() &&  
                   +receiveAmount > 0 
    if(isPass) return true
    return false
  }

  // 更新授权数量
  const updataApproveHandle = async() => {
    let directionA:any = 0
    let directionB:any = 0
    const approveAmountA = await _getAllowanceOperate(fromToken, bridgeOperateContract)
    directionA = new BigNumber(approveAmountA).minus(_inPutFormat(bridgeAmount, tokenDecimal)).toFixed(0)
    if(gasToken !== zeroAddress) {
      if(gasToken !== fromToken) {
        const decimal = await _getDecimals(gasToken)
        const approveAmountB = await _getAllowanceOperate(gasToken, bridgeOperateContract)
        directionB = new BigNumber(approveAmountB).minus(_inPutFormat(Math.ceil(gasCost).toString(), decimal)).toFixed(0)
      } else {
        directionA = new BigNumber(directionA).minus(_inPutFormat(Math.ceil(gasCost).toString(), tokenDecimal)).toFixed(0)
      }
    }
    if(+directionA >= 0 && +directionB >= 0) {
      setBtnStatus(3)
    } else {
      if(+directionA < 0) {
        setBtnStatus(1)
      } else {
        setBtnStatus(7)
      }
    }
  }

  // 展示 - 按钮信息列表
  const btnInfoList:any = {
    1: { text: `${t("Button.Approve")} ${fromTokenSymbol}`, kind: 'primary', disabled: false },
    2: { text: t("Button.Loading"), kind: 'loading', disabled: true },
    3: { text: t('Button.Confirm'), kind: 'primary', disabled: !openHandle() },
    5: { text: t("Button.Connect"), kind: 'primary', disabled: false },
    6: { text: t('Button.SwitchChain', { chain: fromChainName }), kind: 'primary', disabled: false },
    7: { text: `${t("Button.Approve")} ${gasSymbol}`, kind: 'primary', disabled: false },
  }

  const { text, disabled, kind } =  btnInfoList[btnStatus]

  // 按钮处理
  const btnHandle = () => {
    switch(btnStatus) {
      // 授权
      case 1:
        coinApproveHandle(updataApproveHandle)
        break;
      // 跨链
      case 3:
        const status = sessionStorage.getItem('gasHintState')
        bridgeDispatch({ type: 'continueStatus', payload: false })
        if(status) {
          _transferTokenToChainHandle(updataApproveHandle)
        } else {
          configDispatch({ type: 'changePop', payload: 'gasHintState' })
        }
        break;
      // 链接
      case 5:
        configDispatch({ type: 'changePop', payload: 'selectWallteState' })
        break;
      // 切链
      case 6:
        toggleChainHandle()
        break;
      // 授权B
      case 7:
        coinApproveHandle(updataApproveHandle)
        break;
      default:
    }
  }

  return (
    <ExchangeStyle>
      <div className='coin-bar' onClick={() => showHandle('bridgeCoinState')}>
        <Text size='12' color="gray600">{t('Field.ChooseBridgeAsset')}</Text>
        <div>
          <ScCoin isSpecial={true} kind='13' name={fromTokenSymbol} />
          <DownSvg />
        </div>
      </div>
      <div className='chain-bar'>
        <div className='select-key'>
          <Text size='12' color="gray600">{t('Field.From')}</Text>
          <div className='chain-key'>
            <Text size='12' color='gray500'>{t('Field.Network')}</Text>
            <div onClick={() => !account && showHandle('bridgeFromChainState')}>
              <ScCoin isSpecial={true} kind='13' content={fromChainName} name={fromChainSymbol} imgType='chain' />
              { !account && <DownSvg /> }
            </div>
          </div>
        </div>
        <div className='arrow-key'><Down2Svg /></div>
        <div className='select-key'>
          <Text size='12' color="gray600">{t('Field.To')}</Text>
          <div className='chain-key'>
            <Text size='12' color='gray500'>{t('Field.Network')}</Text>
            <div onClick={() => showHandle('bridgeChainState')}>
              <ScCoin isSpecial={true} kind='13'content={toChainName} name={toChainSymbol} imgType='chain' />
              <DownSvg />
            </div>
          </div>
        </div>
      </div>
      <div className='input-bar'>
        <div>
          <Text size='12' color="gray600">{t('Field.TotalAmount')}</Text>
          <div>
            <Wallet2Svg />
            <Text size='12' color='gray500' type='english'>{_numComma(tokenBalance, 4)} {fromTokenSymbol}</Text>
          </div>
        </div>
        <FormInput size={isPC ? 'large' : 'normal'} callback={changeHandle} placeholder='0.0' maxvalue={getMaxAmount()} init={bridgeAmount} />
        { bridgeAmount && <Text clsName='error-key' size='12' color='error_text'>{getAmountErrTips()}</Text> }
      </div>
      <Gas />
      <FormButton kind={kind} callback={btnHandle} disabled={disabled}>
        <Text type='specialEnglish' size='16' color='white'>{text}</Text>
      </FormButton>
    </ExchangeStyle>
  )
}

export default withRouter(Exchange)
