import React, { useReducer } from 'react'
import { PoolContext, PoolDispatchContext } from '@/utils/contextStatic'
import { poolState, poolReducer } from '@/reducer/pool'
import useInitPool from '@/hooks/useInit/useInitPool'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useInit from '@/hooks/useInit'
import { PoolStyle } from './PoolCss'
import FarmsCard from './FarmsCard'
import FarmsBar from './FarmsBar'


const Pool:React.FC<RouterProps> = () => {

  useInit('Pool')

  const [ poolData, poolDispatch ] = useReducer(poolReducer, poolState)

  useInitPool(poolData, poolDispatch)

  return (
    <PoolStyle>
      <PoolContext.Provider value={poolData}>
        <PoolDispatchContext.Provider value={poolDispatch}>
          <div className='bg-bar'></div>
          <main>
            <FarmsCard />
            <FarmsBar />
          </main>
        </PoolDispatchContext.Provider>
      </PoolContext.Provider>
    </PoolStyle>
  )
}

export default withRouter(Pool)
