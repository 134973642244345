import { rem } from '@/theme'
import styled from 'styled-components'

const ScSearchStyle = styled.div`
	position: relative;
	input { 
		background: ${({ theme }) => theme.text_bg};
		box-shadow: none !important;
	}
	input::-webkit-input-placeholder { 
		font-weight: normal;
	}
	input:hover, input:focus {
		background: ${({ theme }) => theme.white};
	}
	& > svg {
		position: absolute;
		z-index: 2;
	}

	// PC
	@media screen and (min-width: 540px) { 
		input { 
			height: 46px;
			padding: 0 16px 0 45px;
			border-radius: 12px;
		}
		input::-webkit-input-placeholder { 
			font-size: 14px;
		}
		& > svg {
			left: 16px;
			top: 12px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		input { 
			height: ${rem(42)};
			padding: 0 ${rem(16)} 0 ${rem(45)};
			border-radius: ${rem(12)};
		}
		input::-webkit-input-placeholder { 
			font-size: 14px;
		}
		& > svg {
			left: ${rem(16)};
			top: ${rem(10)};
		}
	}
`

export {
  ScSearchStyle
}


