import { useEffect, useContext } from 'react'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import { ConfigContext, BridgeContext } from '@/utils/contextStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { zeroAddress } from '@/utils/addressStatic'
import BigNumber from 'bignumber.js'

/**
 * 设置按钮状态 1:授權 2:加載中 3:确认 5:钱包未链接 6: 链不匹配 7:授权B
 * @param {String} inputVal         // 输入值
 * @param {String} handler          // 方法
 */

export default function useBtnBridge( inputVal: string, handler: any) {

  const { _inPutFormat } = usePublicEthers()
  
  const { bridgeOperateContract } = useContractBridge()

  const { _getApproveAmount, _getDecimals } = usePublicErc20()

  const { formParam, gasParam, exchangeFee } = useContext(BridgeContext)
  
  const { account, chainId, chainSign } = useContext(ConfigContext)

  const { fromToken, tokenDecimal } = formParam
  
  const { gasToken, gasCost } = gasParam

  // 钱包 -> 授权
  useEffect(() => {
    const initFun = async() => {
      if(account) {
        if(+chainId === chainSign) {
          let directionA:any = 0
          let directionB:any = 0
          if(+inputVal > 0) {
            const approveAmountA = await _getApproveAmount(fromToken, bridgeOperateContract)
            directionA = new BigNumber(approveAmountA).minus(_inPutFormat(inputVal, tokenDecimal)).toFixed(0)
            if(gasToken !== zeroAddress) {
              if(gasToken !== fromToken) {
                const decimal = await _getDecimals(gasToken)
                const approveAmountB = await _getApproveAmount(gasToken, bridgeOperateContract)
                directionB = new BigNumber(approveAmountB).minus(_inPutFormat(Math.ceil(gasCost).toString(), decimal)).toFixed(0)
              } else {
                directionA = new BigNumber(directionA).minus(_inPutFormat(Math.ceil(gasCost).toString(), tokenDecimal)).toFixed(0)
              }
            }
          }
          if(+directionA >= 0 && +directionB >= 0) {
            handler(3)
          } else {
            if(+directionA < 0) {
              handler(1)
            } else {
              handler(7)
            }
          }
        } else {
          handler(6)
        }
      } else {
        handler(5)
      }
    }
    initFun()
  },[exchangeFee, fromToken, gasToken]) 
}