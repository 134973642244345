import { rem } from '@/theme'
import styled from 'styled-components'

const InfoStyle = styled.div`

	background: ${({ theme }) => theme.white};
	transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border: 1px solid ${({ theme }) => theme.gray100};
	overflow: hidden;
	div.collapse-bar {
		${({ theme }) => theme.align_between};
		background: ${({ theme }) => theme.text_bg};
		& > div {
			${({ theme }) => theme.align};
		}
		& > div:nth-child(1) {
			div.fresh-key {
				background: ${({ theme }) => theme.gray100};
			}
		}
		& > div:nth-child(2) {
			svg:nth-child(3) {
				transform: rotate(-90deg);
			}
		}
	}
	div.field-bar {
		div.field-item {
			${({ theme }) => theme.align_between};
			& > div {
				${({ theme }) => theme.align};
				div.refund-key {
					border-radius: 9999px;
					background: ${({ theme }) => theme.refund_bg};
				}
			}
		}
	}
	&.active {
		height: fit-content;
		div.collapse-bar {
			& > div:nth-child(2) {
				svg:nth-child(1) {
					transform: rotate(180deg);
				}
			}
		}
	}

	// PC
	@media screen and (min-width: 540px){
		height: 54px;
		margin-top: 24px;
		border-radius: 8px;
		div.collapse-bar {
			padding: 16px;
			border-radius: 8px;
			cursor: pointer;
			& > div:nth-child(1) {
				& > svg:nth-child(1) {
					width: 16px;
					height: 16px;
					margin-right: 6px;
				}
				div.fresh-key {
					margin-left: 6px;
					padding: 3px;
					border-radius: 6px;
					svg {
						width: 14px;
						height: 14px;
					}
				}
			}
			& > div:nth-child(2) {
				/* svg:nth-child(1) {
					width: 16px;
					height: 16px;
					margin-right: 6px;
				} */
				svg:nth-child(1) {
					width: 12px;
					height: 12px;
					margin-left: 6px;
				}
			}
		}
		div.field-bar {
			padding: 15px;
			div.field-item {
				& > div {
					svg {
						width: 16px;
						height: 16px;
						margin-left: 4px;
						cursor: pointer;
					}
					div.refund-key {
						margin-right: 6px;
						padding: 0 8px;
					}
				}
			}
			div.field-item:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		height: ${rem(48)};
		margin-top: ${rem(16)};
		border-radius: ${rem(8)};
		div.collapse-bar {
			height: ${rem(48)};
			padding: 0 ${rem(12)};
			border-radius: ${rem(8)};
			& > div:nth-child(1) {
				& > svg:nth-child(1) {
					width: ${rem(16)};
					height: ${rem(16)};
					margin-right: ${rem(2)};
				}
				div.fresh-key {
					margin-left: ${rem(6)};
					padding: ${rem(3)};
					border-radius: ${rem(6)};
					font-size: 0;
					svg {
						width: ${rem(14)};
						height: ${rem(14)};
					}
				}
			}
			& > div:nth-child(2) {
				/* svg:nth-child(1) {
					width: ${rem(16)};
					height: ${rem(16)};
					margin-right: ${rem(2)};
				} */
				svg:nth-child(1) {
					width: ${rem(12)};
					height: ${rem(12)};
					margin-left: ${rem(2)};
				}
			}
		}
		div.field-bar {
			padding: ${rem(12)};
			div.field-item {
				& > div {
					svg {
						width: ${rem(16)};
						height: ${rem(16)};
						margin-left: ${rem(4)};
					}
					div.refund-key {
						margin-right: ${rem(6)};
						padding: ${rem(1)} ${rem(8)};
					}
				}
			}
			div.field-item:not(:last-child) {
				margin-bottom: ${rem(10)};
			}
		}
	}
`

export {
	InfoStyle
}




