import React, { useReducer, useContext } from 'react'
import { BridgeContext, BridgeDispatchContext, ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import PopBridgeFromChain from '@/components/Pop/PopBridgeFromChain'
import InterimBridge from '@/components/Interim/InterimBridge'
import { bridgeState, bridgeReducer } from '@/reducer/bridge'
import PopBridgeChain from '@/components/Pop/PopBridgeChain'
import PopBridgeCoin from '@/components/Pop/PopBridgeCoin'
import useInitBridge from '@/hooks/useInit/useInitBridge'
import PopGasHint from '@/components/Pop/PopGasHint'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import { BridgeStyle } from './BridgeCss'
import useInit from '@/hooks/useInit'
import Exchange from './Exchange'
import Info from './Info'


const Bridge:React.FC<RouterProps> = ({ params }) => {

  useInit('Bridge')

  const { popShowConfig } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const [ bridgeData, bridgeDispatch ] = useReducer(bridgeReducer, bridgeState)

  const { bridgeFromChainState, bridgeCoinState, bridgeChainState, gasHintState } = popShowConfig

  useInitBridge(bridgeData, bridgeDispatch, params)

  const { loadingStatus } = bridgeData

  // 操作 - 弹框
  const popHandle = (kind:string) => {
    configDispatch({ type: 'changePop', payload: kind })
  }

  return (
    <BridgeStyle>
      <BridgeContext.Provider value={bridgeData}>
        <BridgeDispatchContext.Provider value={bridgeDispatch}>
          <div className='bg-bar'></div>
          <main>
            {
              loadingStatus ? <InterimBridge /> : (
                <>
                  <Exchange /> 
                  <Info /> 
                </>
              )
            }
          </main>
          { gasHintState && <PopGasHint callback={() => popHandle('gasHintState')} status={gasHintState} />}
          { bridgeCoinState && <PopBridgeCoin callback={() => popHandle('bridgeCoinState')} status={bridgeCoinState} />}
          { bridgeChainState && <PopBridgeChain callback={() => popHandle('bridgeChainState')} status={bridgeChainState} />}
          { bridgeFromChainState && <PopBridgeFromChain callback={() => popHandle('bridgeFromChainState')} status={bridgeFromChainState} />}
        </BridgeDispatchContext.Provider>
      </BridgeContext.Provider>
    </BridgeStyle>
  )
}

export default withRouter(Bridge)
