import React, { useState, useEffect, useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { SelectSquareSvg } from '@/utils/icoStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import { CheckBoxStyle } from './styled'
import Text from '@/components/Text'

interface ChildProps extends RouterProps {
  callback: any,
  text: string,
  init?:boolean
}

const FormCheckbox:React.FC<ChildProps> = ({ callback, text, init=false })=> {

  const { isEnglish } = useCommon()

  const { isPC } = useContext(ConfigContext)

  const [ checkStatus, setCheckStatus ] = useState<boolean>(false)

  useEffect(() => {
    setCheckStatus(init)
  }, [init])

  const changeHandle = () => {
    setCheckStatus(!checkStatus)
    if(callback) callback(!checkStatus)
  }

  return (
    <CheckBoxStyle onClick={changeHandle} className='box-module'>
      { checkStatus ? <SelectSquareSvg /> : <div className='square-key'></div> }
      <Text size={isPC ? '14' : (isEnglish ? '14' : '12')} >{text}</Text>
    </CheckBoxStyle>
  )
}

export default withRouter(FormCheckbox)
