import { rem } from '@/theme'
import styled from 'styled-components'

const ScLinkStyle = styled.div`

	${({ theme }) => theme.align};

	// pc
	@media screen and (min-width: 540px){ 
		${({ theme }) => theme.align};
		cursor: pointer;
		svg {
			width: 16px;
			height: 16px;
			margin-left: 4px;
		}
	}

	// H5
	@media screen and (max-width: 540px){
		svg {
			width: ${rem(16)};
			height: ${rem(16)};
			margin-left: ${rem(4)};
		}
	}
`


export {
	ScLinkStyle
}




