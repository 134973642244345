import { useContext } from 'react'
import { contractAddressList } from '@/utils/addressStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'

/**
 * API - BridgeFee
 */
export default function useContractBridgeFee() {
  
  const { chainSign } = useContext(ConfigContext)

  const { _getContract, _getDecimals } = usePublicErc20()

  const { _outPutFormat, _inPutFormat } = usePublicEthers()

  // 费用合约地址
  const bridgeFeeContractAddress = contractAddressList[chainSign]['operate']['bridge_fee']

  /**
   * 获取 - 跨链平台费/LP费
   * @param {String} token            要计算费用的代币地址
   * @param {String} targetChainId    目标链选择器的ID
   * @param {String} amount           计算费用的金额
   */ 
  const _getCombinedFees = async(token:string, targetChainId:string, amount:string) => {
    console.log('合约【获取 - 跨链平台费/LP费(入参):', { token, targetChainId, amount })
    try {
      const decimal = await _getDecimals(token)
      const inputAmount = _inPutFormat(amount, decimal)
      const bridgeFeeContractExample = await _getContract(bridgeFeeContractAddress, 'BRIDGEFEEABI')
      const { platformFeeAmount, lpFeeAmount } = await bridgeFeeContractExample.getCombinedFees(token, targetChainId, inputAmount)
      const feeList:any = {
        platformFeeAmount: _outPutFormat(platformFeeAmount, decimal),
        lpFeeAmount: _outPutFormat(lpFeeAmount, decimal)
      }
      return feeList
    } catch (error) {
      console.error('合约调用异常【获取 - 跨链平台费/LP费】:', error)
    }
  }

  /**
   * 获取 - 查询指定代币的交换费率
   * @param {String} token            要查询费率的代币地址
   */ 
  const _getSwapFee = async(token:string) => {
    console.log('合约【获取 - 查询指定代币的交换费率(入参):', { token })
    try {
      const bridgeFeeContractExample = await _getContract(bridgeFeeContractAddress, 'BRIDGEFEEABI')
      return bridgeFeeContractExample.getSwapFeeRate(token)
    } catch (error) {
      console.error('合约调用异常【获取 - 查询指定代币的交换费率】:', error)
    }
  }


  return {
    _getCombinedFees,
    _getSwapFee
  }
}
