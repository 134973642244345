import { rem } from '@/theme'
import styled from 'styled-components'

const SettingStyle = styled.div`

  & > div:nth-child(2) {
    ${({ theme }) => theme.align_between};
    & > div {
      flex: 1;
      ${({ theme }) => theme.align_center};
      border: 1px solid ${({ theme }) => theme.gray200};
      background: ${({ theme }) => theme.gray50};
    }
    & > div.active {
      border-color: ${({ theme }) => theme.primary500};
    }
  }
  & > div:nth-child(3), div.minutes-bar {
    input {
      border-color: ${({ theme }) => theme.gray200};
    }
  }
  div.minutes-bar {
    position: relative;
    & > div:nth-child(2) {
      position: absolute;
    }
  }
  & > button {
    width: 100%;
  }

  // PC
  @media screen and (min-width: 540px) { 
    margin-top: 24px;
    & > div:nth-child(2) {
      margin-top: 10px;
      & > div {
        height: 56px;
        border-radius: 8px;
      }
      & > div:not(:last-child) {
        margin-right: 10px;
      }
    }
    & > div:nth-child(3) {
      margin-top: 16px;
    }
    & > div:nth-child(4) {
      margin-top: 20px;
    }
    div.minutes-bar {
      margin-top: 10px;
      input {
        padding-right: 90px;
      }
      & > div:nth-child(2) {
        right: 16px;
        top: 16px;
      }
    }
    & > button {
      margin-top: 20px;
    }
  }

  // H5
  @media screen and (max-width: 540px) { 
    margin-top: ${rem(24)};
    & > div:nth-child(2) {
      margin-top: ${rem(10)};
      & > div {
        height: ${rem(48)};
        border-radius: ${rem(8)};
      }
      & > div:not(:last-child) {
        margin-right: ${rem(10)};
      }
    }
    & > div:nth-child(3) {
      margin-top: ${rem(16)};
    }
    & > div:nth-child(4) {
      margin-top: ${rem(20)};
    }
    div.minutes-bar {
      margin-top: ${rem(10)};
      input {
        padding-right: ${rem(80)};
      }
      & > div:nth-child(2) {
        right: ${rem(16)};
        top: ${rem(13)};
      }
    }
    & > button {
      margin-top: ${rem(20)};
    }
  }

`

export {
  SettingStyle
}


