import React, { useReducer, useContext } from 'react'
import { PoolCreateContext, PoolCreateDispatchContext, ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import { poolCreateState, poolCreateReducer } from '@/reducer/poolCreate'
import InterimPoolCreate from '@/components/Interim/InterimPoolCreate'
import useInitPoolCreate from '@/hooks/useInit/useInitPoolCreate'
import PopCreateCoin from '@/components/Pop/PopCreateCoin'
import PopImportHint from '@/components/Pop/PopImportHint'
import { PoolCreateStyle } from './PoolCreateCss'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useInit from '@/hooks/useInit'
import Info from './Info'
import Form from './Form'


const PoolCreate:React.FC<RouterProps> = () => {

  useInit('Pool')

  const { popShowConfig } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const [ poolCreateData, poolCreateDispatch ] = useReducer(poolCreateReducer, poolCreateState)

  const { createCoinState, importHintState } = popShowConfig

  useInitPoolCreate(poolCreateData, poolCreateDispatch)
  
  const { loadingStatus } = poolCreateData

  // 操作 - 弹框
  const showPopHandle = (kind:string) => {
    configDispatch({ type: 'changePop', payload: kind })
  }

  return (
    <PoolCreateStyle>
      <PoolCreateContext.Provider value={poolCreateData}>
        <PoolCreateDispatchContext.Provider value={poolCreateDispatch}>
          <div className='bg-bar'></div>
            <main>
              <Info />
              { loadingStatus ? <InterimPoolCreate /> : <Form /> }
            </main>
          { createCoinState && <PopCreateCoin callback={() => showPopHandle('createCoinState')} status={createCoinState} /> }
          { importHintState && <PopImportHint callback={() => showPopHandle('importHintState')} status={importHintState} /> }
        </PoolCreateDispatchContext.Provider>
      </PoolCreateContext.Provider>
    </PoolCreateStyle>
  )
}

export default withRouter(PoolCreate)
