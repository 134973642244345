import React, { useContext } from 'react'
import { ConfigContext, BridgeContext, BridgeDispatchContext } from '@/utils/contextStatic'
import { CheckOutlined } from '@ant-design/icons'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import { PopBridgeChainStyle } from './styled'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Pop from '../index'

interface ChildProps extends RouterProps {
  callback: any
  status: boolean
}

const PopBridgeChain:React.FC<ChildProps> = ({ t, callback, status })=> {

  const { _getChainProperty } = useCommon()

  const { isPC } = useContext(ConfigContext)

  const bridgeDispatch:any = useContext(BridgeDispatchContext)

  const { bridgeTokens, formParam } = useContext(BridgeContext)

  const { fromTokenSymbol, toNetwork } = formParam

  // 操作 - 选择链
  const selectHandle = (item:any) => {
    const { dest_chainid, dest_token_symbol } = item
    const param = { 
      toTokenSymbol: dest_token_symbol,
      toNetwork: dest_chainid  
    }
    bridgeDispatch({ type: 'changeForm', payload: param })
    callback()
  }

  // 渲染 - 链
  const coinRender = () => {
    const list:any = []
    bridgeTokens[fromTokenSymbol].forEach((item:any, index:number) => {
      const { dest_chainid } = item
      const chainName = _getChainProperty(dest_chainid, 'chain_name')
      const chainSymbol = _getChainProperty(dest_chainid, 'chain_symbol')
      list.push(
        <div key={`bridgeChain_${index}`} onClick={() => selectHandle(item)}>
          <ScCoin kind={isPC ? '14' : '15'} name={chainSymbol} content={chainName} imgType='chain' />
          { toNetwork === dest_chainid && <CheckOutlined /> }
        </div>
      )
    })
    return list
  }

  return (
    <Pop status={status} title={t('Title.Chain')} callback={callback}>
      <PopBridgeChainStyle>
        <div className='chain-bar'>{coinRender()}</div>
      </PopBridgeChainStyle>
    </Pop>
  )
}

export default withRouter(PopBridgeChain)

