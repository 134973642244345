import React, { useContext, useState } from 'react'
import { ConfigDispatchContext, SwapContext, SwapDispatchContext } from '@/utils/contextStatic'
import useBusinessSwapExchange from '@/hooks/useBusiness/useBusinessSwapExchange'
import { Down2Svg, DownSvg, Wallet2Svg, SettingSvg } from '@/utils/icoStatic'
import { contractAddressList, chainCoin } from '@/utils/addressStatic'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import useBtnApprove from '@/hooks/useBtn/useBtnApprove'
import { ConfigContext } from '@/utils/contextStatic'
import useBtnChain from '@/hooks/useBtn/useBtnChain'
import useBtnSwap from '@/hooks/useBtn/useBtnSwap'
import FormButton from '@/components/FormButton'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import FormInput from '@/components/FormInput'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import { ExchangeStyle } from './styled'
import Text from '@/components/Text'
import BigNumber from 'bignumber.js'
import Info from './Info'

const Exchange:React.FC<RouterProps> = ({ t }) => {

  const { _getChainProperty } = useCommon() 

  const { isPC } = useContext(ConfigContext)

  const { _getAllowanceOperate } = usePublicErc20()

  const { bridgeOperateContract } = useContractBridge()
  
  const swapDispatch:any = useContext(SwapDispatchContext)
  
  const configDispatch:any = useContext(ConfigDispatchContext)

  const { swapFormParam, tokenBalance, slippageParam, amountParam, swapDestPoolInfo, inputLoadingStatus, tokenFromList } = useContext(SwapContext)

  const { fromNetwork, fromToken, fromTokenSymbol, toNetwork, toTokenSymbol, swapAmount, fromTokenDecimal, exchangeToken, toTokenDecimal, toToken } = swapFormParam

  const fromChainSymbol = _getChainProperty(fromNetwork, 'chain_symbol')

  const fromChainName = _getChainProperty(fromNetwork, 'chain_name')

  const toChainSymbol = _getChainProperty(toNetwork, 'chain_symbol')

  const toChainName = _getChainProperty(toNetwork, 'chain_name')

  const  { receiveAmount, totalAmountIn } = amountParam

  const { last_balance } = swapDestPoolInfo

  // 1:授權A 2：加載中 3:确认 5: 钱包未链接 6: 链不匹配 7:授权B
  const [ btnStatus, setBtnStatus ] = useState<number>(3)

  // 授权操作
  const coinApproveHandle = useBtnApprove(btnStatus === 1 ? fromToken : exchangeToken, bridgeOperateContract, setBtnStatus, btnStatus)

  // 跨链交易
  const { _swapAndTransferTokenToChainHandle } = useBusinessSwapExchange(setBtnStatus)

  // 切链操作
  const toggleChainHandle = useBtnChain(fromNetwork, setBtnStatus)

  // 按钮状态控制
  useBtnSwap(swapAmount, setBtnStatus)

  // 操作 - 选择
  const selectHandle = (type:string) => {
    configDispatch({ type: 'changePop', payload: type === 'from' ? 'swapFromCoinState' : 'swapToCoinState' })
    if(!isPC) configDispatch({ type: 'changeState', payload: 'h5MaskShow' })
  }

  // 操作 - 输入框
  const changeHandle = (val:string) => {
    const param = { swapAmount: val }
    swapDispatch({ type: 'changeForm', payload: param })
  }

  // 操作 - 弹框
  const popHandle = () => {
    configDispatch({ type: 'changePop', payload: 'settingState' })
  }

  // 操作 - 币种
  const coinHandle = () => {
    if(toNetwork === fromNetwork) {
      const fromOperateToken = chainCoin !== toToken ? toToken : contractAddressList[toNetwork]['symbol']['current']
      const exchangeTokenInfo = tokenFromList.find((item:any) => item.symbol === fromTokenSymbol)
      const param = {
        fromOperateToken,
        fromTokenDecimal: toTokenDecimal,
        fromTokenSymbol: toTokenSymbol,
        fromToken: toToken,
        exchangeToken: exchangeTokenInfo['address'],
        toToken: fromToken,
        toTokenSymbol: fromTokenSymbol,
        toTokenDecimal: fromTokenDecimal,
        swapAmount: '',
      }
      swapDispatch({ type: 'changeForm', payload: param })
    }
  }

  // 判断 - 按钮状态
  const openHandle = () => {
    if(+swapAmount <= +tokenBalance &&
       +totalAmountIn > 0 &&
       !inputLoadingStatus &&
       (fromNetwork !== toNetwork ? +last_balance >= +swapAmount : true) && 
       +swapAmount > 0) return true
    return false
  }

  // 获取 - 确认按钮内容
  const getConfirmBtnText = () => {
    if(fromNetwork !== toNetwork && last_balance < +swapAmount) {
      return t('Warning.SwapLiquidityHint1')
    } else {
      return t('Button.Confirm')
    }
  }

  // 更新授权数量
  const updataApproveHandle = async() => {
    let directionA:any = 0
    const approveAmountA = await _getAllowanceOperate(fromToken, bridgeOperateContract)
    directionA = new BigNumber(approveAmountA).minus(totalAmountIn).toFixed(0)
    if(+directionA >= 0) {
      setBtnStatus(3)
    } else {
      setBtnStatus(1)
    }
  }

  // 展示 - 按钮信息列表
  const btnInfoList:any = {
    1: { text: `${t("Button.Approve")} ${fromTokenSymbol}`, kind: 'primary', disabled: false },
    2: { text: t("Button.Loading"), kind: 'loading', disabled: true },
    3: { text: getConfirmBtnText(), kind: 'primary', disabled: !openHandle() },
    5: { text: t("Button.Connect"), kind: 'primary', disabled: false },
    6: { text: t('Button.SwitchChain', { chain: fromChainName }), kind: 'primary', disabled: false },
    7: { text: `${t("Button.Approve")} ${toTokenSymbol}`, kind: 'primary', disabled: false },
  }

  const { text, disabled, kind } =  btnInfoList[btnStatus]

  // 按钮处理
  const btnHandle = () => {
    switch(btnStatus) {
      // 授权A
      case 1:
        coinApproveHandle(updataApproveHandle)
        break;
      // 交易
      case 3:
        _swapAndTransferTokenToChainHandle(updataApproveHandle)
        break;
      // 链接
      case 5:
        configDispatch({ type: 'changePop', payload: 'selectWallteState' })
        break;
      // 切链
      case 6:
        toggleChainHandle()
        break;
      // 授权B
      case 7:
        coinApproveHandle(updataApproveHandle)
        break;
      default:
    }
  }

  // 渲染 - 选择
  const selectRender = (sign:string) => {
    const selectList:any = {
      fn: { key: 'Network', symbol: fromChainSymbol, name: fromChainName },
      ft: { key: 'Token', symbol: fromTokenSymbol, name: fromTokenSymbol },
      tn: { key: 'Network', symbol: toChainSymbol, name: toChainName },
      tt: { key: 'Token', symbol: toTokenSymbol, name: toTokenSymbol },
    }
    const { key, symbol, name } = selectList[sign]
    const imgType = ['fn', 'tn'].includes(sign) ? 'chain' : 'token'
    return (
      <div className='select-item'>
        <Text size='12' color='gray500'>{t(`Field.${key}`)}</Text>
        <div>
          <ScCoin kind='13' isSpecial={true} content={name} name={symbol} imgType={imgType} />
          <DownSvg />
        </div>
      </div>
    )
  }

  return (
    <ExchangeStyle>
      <div className='exchange-bar'>
        <Text size='12' color="gray600">{t('Field.From')}</Text>
        <div className='select-bar' onClick={() => selectHandle('from')}>
          { selectRender('fn') }
          { selectRender('ft') }
        </div>
        <div className='arrow-bar' onClick={coinHandle}><Down2Svg /></div>
        <Text size='12' color="gray600">{t('Field.To')}</Text>
        <div className='select-bar' onClick={() => selectHandle('to')}>
          { selectRender('tn') }
          { selectRender('tt') }
        </div>
        <div className='tolerance-bar'>
          <div>
            <Text size='12' color="gray500">{t('Field.SlippageTolerance')} : </Text>
            <Text kind='Bold' size='13' type='number' color="primary500">{slippageParam['slippage']}%</Text>
          </div>
          <SettingSvg onClick={popHandle}/>
        </div>
        <div className='input-bar'>
          <div>
            <Text size='12' color="gray600">{t('Field.TotalAmount')}</Text>
            <div>
              <Wallet2Svg />
              <Text size='12' color='gray500' type='english'>{_numComma(tokenBalance, 4)} {fromTokenSymbol}</Text>
            </div>
          </div>
          <FormInput size='large' callback={changeHandle} init={swapAmount} maxvalue={tokenBalance} />
          {
            +swapAmount > +totalAmountIn && btnStatus === 3 && openHandle() && (
              <Text size='12' color='error_text'>
                {t('Warning.SwapLiquidityHint2', { amountA: totalAmountIn, symbolA: fromTokenSymbol, amountB: receiveAmount, symbolB: toTokenSymbol })}
              </Text>
            )
          }
        </div>
        <FormButton kind={kind} callback={btnHandle} disabled={disabled}>
          <Text type='specialEnglish' size='16' color='white'>{text}</Text>
        </FormButton>
        <Info />
      </div>
    </ExchangeStyle>
  )
}

export default withRouter(Exchange)
