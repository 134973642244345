import styled from 'styled-components'
import { rem } from '@/theme'

const ChainStyle = styled.div`
	position: relative;
	z-index: 2;
	ul.con-bar {
		position: absolute;
		width: 100%;
		white-space: nowrap;
		background-color: white;
		transform-origin: center top;
		li {
			${({ theme }) => theme.align};
		}
	}
	// PC
	@media screen and (min-width: 540px) { 
		margin-right: 12px;
		div.show-bar {
			${({ theme }) => theme.align};
			background: ${({ theme }) => theme.white};
			padding: 7px 16px 7px 7px;
			border-radius: 9999px;
			cursor: pointer;
			& > svg {
				width: 12px;
				height: 12px;
				margin-left: 10px;
			}
			div.err-item {
				${({ theme }) => theme.align};
				svg {
					position: relative;
					top: 2px;
					width: 20px;
					height: 20px;
					margin-right: 4px;
				}
			}
		}
		div.err-bar {
			${({ theme }) => theme.align};
		}
		ul.con-bar {
			top: 52px;
			min-width: 160px;
			padding: 10px 0;
			border-radius: 12px;
			box-shadow: 0px 6px 12px 0px rgba(75, 85, 104, 0.05);
			li {
				padding: 8px 12px;
				cursor: pointer;
				img {
					width: 24px;
					height: 24px;
					margin-right: 10px;
				}
			}
			li:hover {
				background: ${({ theme }) => theme.gray50};
			}
		}
	}
	// H5
	@media screen and (max-width: 540px) { 
		margin-right: ${rem(10)};
		div.show-bar {
			font-size: 0;
			& > img {
				width: ${rem(26)};
				height: ${rem(26)};
			}
		}
		ul.con-bar {
			top: ${rem(40)};
			min-width: ${rem(125)};
			border-radius: ${rem(8)};
			box-shadow: 0 ${rem(8)} ${rem(16)} 0 rgba(0,0,0,0.2);
			padding: ${rem(5)} 0;
			li {
				padding: ${rem(8)} ${rem(12)};
				img {
					width: ${rem(16)};
					height: ${rem(16)};
					margin-right: ${rem(6)};
				}
			}
		}
	}
`

export {
	ChainStyle
}




