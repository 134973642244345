import { useEffect, useContext } from 'react'
import useContractSwapPairV2 from '@/hooks/useContract/useContractSwapPairV2'
import { getPoolsLpsInfo, getUserCreatePoolsInfo } from '@/service'
import useContractPool from '@/hooks/useContract/useContractPool'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'

/**
 * 初始化 - SwapManage
 */

export default function useInitSwapManage(swapManageData:any, swapManageDispatch:any, params:any) {

  const { poolInfo } = swapManageData

  const { chain_id, token_contract } = params

  const { _getPoolBalance } = useContractPool()

  const {  _getBalacneOperate } = usePublicErc20()

  const { _getLpTotalAmount } = useContractSwapPairV2()

  const { updateConfig, account, chainId } = useContext(ConfigContext)

  const { UPDATE_POOL_TOKEN } = updateConfig

  // 获取 - 目标链单币池子信息
  const requestPoolLpsInfo = async() => {
    swapManageDispatch({ type: 'loadingStatus', payload: true })
    const lpTotal = +chainId ===  +chain_id ? await _getLpTotalAmount(token_contract) : '0'
    if(window.location.hash.includes('create')) {
      getUserCreatePoolsInfo(chain_id, token_contract).then(async(res:any) => {
        console.log('interface - 目标链双币池子信息(创建)【报文】:', res)
        const { code, data } = res
        if(code === 1) {
          const { pair_address, token0_address, token1_address, token0_symbol, token1_symbol } = data
          const themp:any = {
            chain_id: +chain_id,
            pair: pair_address,
            pair_token0: token0_address,
            pair_token1: token1_address,
            pool_apy: '0',
            pool_symbol: `${token0_symbol}/${token1_symbol}`,
            last_balance: lpTotal,
          }
          swapManageDispatch({ type: 'poolInfo', payload: themp })
          swapManageDispatch({ type: 'loadingStatus', payload: false })
        }
      }).catch(() => {
        console.log('interface - 目标链双币池子信息(创建)【异常】')
      })
    } else {
      getPoolsLpsInfo({ chain_id, pair: token_contract }).then(async(res: any) => {
        console.log('interface - 目标链双币池子信息(白名单)【报文】:', res)
        const cloneData = Object.assign({}, res, { last_balance: lpTotal})
        swapManageDispatch({ type: 'poolInfo', payload: cloneData })
        swapManageDispatch({ type: 'loadingStatus', payload: false })
      }).catch(() => {
        console.log('interface - 目标链双币池子信息(白名单)【异常】')
      })
    }
  }

  // 获取 - 目标链双币池子信息
  useEffect(() => {
    requestPoolLpsInfo()
  }, [UPDATE_POOL_TOKEN, chainId])

  // 获取 - 可质押币种资产/可移除的的流动性
  useEffect(() => {
    const getBalance = async() => {
      const { pair_token0, pair_token1, pair } = poolInfo
      // 可质押币种资产
      const tokenBalanceA:any = await _getBalacneOperate(pair_token0)
      swapManageDispatch({ type: 'tokenBalanceA', payload: tokenBalanceA })
      // 可质押币种资产
      const tokenBalanceB:any = await _getBalacneOperate(pair_token1)
      swapManageDispatch({ type: 'tokenBalanceB', payload: tokenBalanceB })
      // 可移除的的流动性
      const removeBalance:any = await _getPoolBalance(pair)
      swapManageDispatch({ type: 'lpBalance', payload: removeBalance })
    }
    if(account && +chainId === +chain_id && Object.keys(poolInfo).length) {
      getBalance()
    } else {
      swapManageDispatch({ type: 'tokenBalanceA', payload: '0' })
      swapManageDispatch({ type: 'tokenBalanceB', payload: '0' })
      swapManageDispatch({ type: 'lpBalance', payload: '0' })
    }
  }, [account, chainId, poolInfo])
}