import React, { useContext } from 'react'
import { PoolCreateContext, ConfigContext, ConfigDispatchContext, PoolCreateDispatchContext } from '@/utils/contextStatic'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import ScCoin from '@/components/Section/ScCoin'
import ScLink from '@/components/Section/ScLink'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import { PopImportHintStyle } from './styled'
import withRouter from '@/utils/withRouter'
import Text from '@/components/Text'
import Pop from '../index'

interface ChildProps extends RouterProps {
  callback: any
  status: boolean
}

const PopImportHint:React.FC<ChildProps> = ({ t, callback, status })=> {
  
  const { isPC, chainSign } = useContext(ConfigContext)
  
  const configDispatch:any = useContext(ConfigDispatchContext)
  
  const { _getDecimals, _getBalacneOperate } = usePublicErc20()
  
  const PoolCreateDispatch:any = useContext(PoolCreateDispatchContext)

  const { importTokenInfo, tokenList } = useContext(PoolCreateContext)

  const { symbol, address, type } = importTokenInfo

  // 操作 - 继续
  const submitHandle = async() => {
    let listString:any = ''
    let localListArray:any = []
    let currentListArray:any = []
    const decimals = await _getDecimals(address)
    const balance = await _getBalacneOperate(address)
    const localThemp = { symbol, address, decimals: Number(decimals), chainId: chainSign }
    const currentThemp = Object.assign({}, localThemp, {balance})
    const tokenListString:any = localStorage.getItem(`${chainSign}_importTokenList`)
    if(tokenListString) {
      let tokenListArr:any = JSON.parse(tokenListString)
      localListArray.push(localThemp)
      currentListArray.push(currentThemp)
      localListArray = tokenListArr.concat(localListArray)
      currentListArray = tokenListArr.concat(currentListArray)
    } else {
      localListArray = [localThemp]
      currentListArray = [currentThemp]
    }
    listString = JSON.stringify(localListArray)
    localStorage.setItem(`${chainSign}_importTokenList`, listString)
    const param = { 
      [`tokenAddress${type}`]: address,
      [`tokenDecimal${type}`]: decimals,
      [`tokenSymbol${type}`]: symbol
    }
    const combinationList = currentListArray.concat(tokenList)
    PoolCreateDispatch({ type: 'changeForm', payload: param })
    PoolCreateDispatch({ type: 'tokenList', payload: combinationList })
    configDispatch({ type: 'changePop', payload: 'createCoinState' })
    callback()
  }

  return (
    <Pop status={status} title={t('Title.ImportToken')} callback={callback}>
      <PopImportHintStyle>
        <Text size='16'>{t('Content.ImportTokenDesc')}</Text>
        <div className='coin-bar'>
          <ScCoin kind='20' name={symbol} />
          <ScLink address={address} query='token' />
        </div>
        <FormButton size={isPC ? 'large' : 'normal'} callback={submitHandle}>
          <Text type='specialEnglish' size='16' color='white'>{t('Button.Understand')}</Text>
        </FormButton>
      </PopImportHintStyle>
    </Pop>
  )
}

export default withRouter(PopImportHint)

