import React, { useContext } from 'react'
import { ConfigContext, SwapContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import { ListRecordStyle } from './styled'
import Text from '@/components/Text'

interface ChildProps extends RouterProps {
  data: any
}

const ListRecord:React.FC<ChildProps> = ({ data }) => {

  const { isPC } = useContext(ConfigContext)
  
  const { swapFormParam } = useContext(SwapContext)

  const { protocolName, swapPrice, receiveAmount, swapFee, proportion } = data

  const { toTokenSymbol } = swapFormParam

  return (
    <ListRecordStyle>
      <td>
        <Text type='english' size={isPC ? '14' : '12'}>{protocolName}</Text>
        <Text type='number' size='12' color='gray600'>{`${_numComma(proportion)}%`}</Text>
      </td>
      { isPC && <td><Text type='english'>{_numComma(swapPrice, 6)} {toTokenSymbol}</Text></td> }
      <td><Text size={isPC ? '14' : '12'} type='english'>{_numComma(receiveAmount, 4)} {toTokenSymbol}</Text></td>
      { isPC && <td><Text type='number'>$ {_numComma(swapFee, 4)}</Text></td> }
    </ListRecordStyle>
  )
}

export default withRouter(ListRecord)