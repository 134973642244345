// Logo
import { ReactComponent as FooterLogoSvg } from '@/assets/img/Logo/footer_logo.svg'
import { ReactComponent as LogoSvg } from '@/assets/img/Logo/logo.svg'

// Icon
import { ReactComponent as SelectCircleSvg } from '@/assets/img/Icon/select_circle.svg'
import { ReactComponent as SelectSquareSvg } from '@/assets/img/Icon/select_square.svg'
import { ReactComponent as ErrorChainSvg } from '@/assets/img/Icon/error_chain.svg'
import { ReactComponent as LanguageSvg } from '@/assets/img/Icon/language.svg'
import { ReactComponent as Wallet2Svg } from '@/assets/img/Icon/wallet2.svg'
import { ReactComponent as NoDataSvg } from '@/assets/img/Icon/no_data.svg'
import { ReactComponent as WalletSvg } from '@/assets/img/Icon/wallet.svg'
import { ReactComponent as SearchSvg } from '@/assets/img/Icon/search.svg'
import { ReactComponent as TrendSvg } from '@/assets/img/Icon/trend.svg'
import { ReactComponent as CloseSvg } from '@/assets/img/Icon/close.svg'
import { ReactComponent as UserSvg } from '@/assets/img/Icon/user.svg'
import { ReactComponent as NewsSvg } from '@/assets/img/Icon/news.svg'
import { ReactComponent as TipSvg } from '@/assets/img/Icon/tip.svg'
import { ReactComponent as LpSvg } from '@/assets/img/Icon/lp.svg'
import LoadingPng from '@/assets/img/Icon/loading.png'
import FarmImg from '@/assets/img/Icon/farm.svg'

// Tool
import { ReactComponent as SettingSvg } from '@/assets/img/Tool/setting.svg'
import { ReactComponent as HiddenSvg } from '@/assets/img/Tool/hidden.svg'
import { ReactComponent as Link2Svg } from '@/assets/img/Tool/link2.svg'
import { ReactComponent as FreshSvg } from '@/assets/img/Tool/fresh.svg'
import { ReactComponent as ShowSvg } from '@/assets/img/Tool/show.svg'
import { ReactComponent as JumpSvg } from '@/assets/img/Tool/jump.svg'
import { ReactComponent as CopySvg } from '@/assets/img/Tool/copy.svg'
import { ReactComponent as MoreSvg } from '@/assets/img/Tool/more.svg'
import { ReactComponent as LinkSvg } from '@/assets/img/Tool/link.svg'
import { ReactComponent as SortSvg } from '@/assets/img/Tool/sort.svg'
import { ReactComponent as AddSvg } from '@/assets/img/Tool/add.svg'

// Arrow
import { ReactComponent as Right2Svg } from '@/assets/img/Arrow/right2.svg'
import { ReactComponent as RightSvg } from '@/assets/img/Arrow/right.svg'
import { ReactComponent as Down2Svg } from '@/assets/img/Arrow/down2.svg'
import { ReactComponent as Down3Svg } from '@/assets/img/Arrow/down3.svg'
import { ReactComponent as Left2Svg } from '@/assets/img/Arrow/left2.svg'
import { ReactComponent as Down4Svg } from '@/assets/img/Arrow/down4.svg'
import { ReactComponent as Down5Svg } from '@/assets/img/Arrow/down5.svg'
import { ReactComponent as DownSvg } from '@/assets/img/Arrow/down.svg'
import { ReactComponent as LeftSvg } from '@/assets/img/Arrow/left.svg'
import { ReactComponent as Up4Svg } from '@/assets/img/Arrow/up4.svg'

// Contact
import { ReactComponent as DiscordSvg } from '@/assets/img/Contact/discord.svg'
import { ReactComponent as TwitterSvg } from '@/assets/img/Contact/twitter.svg'
import { ReactComponent as GithubSvg } from '@/assets/img/Contact/github.svg'

// State
import { ReactComponent as SendSuccessSvg } from '@/assets/img/State/send_success.svg'
import { ReactComponent as SendWarningSvg } from '@/assets/img/State/send_warning.svg'
import { ReactComponent as SendSubmitSvg } from '@/assets/img/State/send_submit.svg'
import { ReactComponent as SendFailSvg } from '@/assets/img/State/send_fail.svg'

// Wallet
import { ReactComponent as WalletConnectSvg } from '@/assets/img/Wallet/wallet_connect.svg'
import { ReactComponent as TokenPocketSvg } from '@/assets/img/Wallet/token_pocket.svg'
import { ReactComponent as TrustWalletSvg } from '@/assets/img/Wallet/trust_wallet.svg'
import { ReactComponent as HyperpaySvg } from '@/assets/img/Wallet/hyperpay.svg'
import { ReactComponent as MetamaskSvg } from '@/assets/img/Wallet/metamask.svg'
import { ReactComponent as CoinhubSvg } from '@/assets/img/Wallet/coinhub.svg'
import { ReactComponent as BitkeepSvg } from '@/assets/img/Wallet/bitkeep.svg'
import { ReactComponent as OkexSvg } from '@/assets/img/Wallet/okex.svg'

// Footer
import { ReactComponent as BridgeDefaultSvg } from '@/assets/img/Footer/bridge_default.svg'
import { ReactComponent as BridgeActiveSvg } from '@/assets/img/Footer/bridge_active.svg'
import { ReactComponent as PoolDefaultSvg } from '@/assets/img/Footer/pool_default.svg'
import { ReactComponent as SwapDefaultSvg } from '@/assets/img/Footer/swap_default.svg'
import { ReactComponent as PoolActiveSvg } from '@/assets/img/Footer/pool_active.svg'
import { ReactComponent as SwapActiveSvg } from '@/assets/img/Footer/swap_active.svg'

// 获取 - 钱包相关图片
const walletSvgList:any = {
	"MetaMask": MetamaskSvg,
	"OKX Wallet": OkexSvg,
	"Token Pocket": TokenPocketSvg,
	"Coinhub": CoinhubSvg,
	"Trust Wallet": TrustWalletSvg,
	"Hyperpay": HyperpaySvg,
	"WalletConnect": WalletConnectSvg,
	"Bitkeep": BitkeepSvg,
}

// 获取 - H5底部相关图片
const h5FooterSvgList:any = {
	"Bridge": { default: BridgeDefaultSvg, active: BridgeActiveSvg },
	"Pool": { default: PoolDefaultSvg, active: PoolActiveSvg },
	"Swap": { default: SwapDefaultSvg, active: SwapActiveSvg },
}

// 获取 - 状态相关图片
const stateSvgList:any = {
	"wait": "",
	"submit": SendSubmitSvg,
	"success": SendSuccessSvg,
	"fail": SendFailSvg,
	"warning": SendWarningSvg
}

export {
	SelectSquareSvg,
	SelectCircleSvg,
	h5FooterSvgList,
	SendSuccessSvg,
	SendWarningSvg,
	SendSubmitSvg,
	FooterLogoSvg,
	walletSvgList,
	ErrorChainSvg,
	stateSvgList,
	SendFailSvg,
	LanguageSvg,
	SettingSvg,
	DiscordSvg,
	TwitterSvg,
	Wallet2Svg,
	LoadingPng,
	SearchSvg,
	NoDataSvg,
	WalletSvg,
	Right2Svg,
	HiddenSvg,
	GithubSvg,
	Down5Svg,
	Down3Svg,
	TrendSvg,
	RightSvg,
	Left2Svg,
	Down2Svg,
	FreshSvg,
	CloseSvg,
	Down4Svg,
	Link2Svg,
	ShowSvg,
	FarmImg,
	SortSvg,
	NewsSvg,
	MoreSvg,
	UserSvg,
	JumpSvg,
	LinkSvg,
	CopySvg,
	LogoSvg,
	DownSvg,
	LeftSvg,
	AddSvg,
	Up4Svg,
	TipSvg,
	LpSvg
}


