import { Tooltip } from 'antd'
import React, { useContext } from 'react'
import { ConfigContext, BridgeContext } from '@/utils/contextStatic'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import { TipSvg } from '@/utils/icoStatic'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { InfoStyle } from './styled'
import BigNumber from 'bignumber.js'


const Info:React.FC<RouterProps> = ({ t }) => {

  const { isPC, account } = useContext(ConfigContext)

  const { _getChainProperty, _getExchangePrice } = useCommon()

  const { formParam, destPoolInfo, gasParam, exchangeFee } = useContext(BridgeContext)

  const { toNetwork, toTokenSymbol, bridgeAmount, fromTokenSymbol } = formParam

  const toChainName = _getChainProperty(toNetwork, 'chain_name')

  const { gasCost, gasSymbol } = gasParam

  const { last_balance } = destPoolInfo

  // 获取 - 收到金额
  const getReceiveAmount = () => {
    if(bridgeAmount && account) {
      let amount = new BigNumber(bridgeAmount).minus(exchangeFee).toString()
      if(gasSymbol === fromTokenSymbol) {
        amount = new BigNumber(amount).minus(gasCost).toString()
        amount = +amount > 0 ? amount : '0'
      }
      return _numComma(amount, 4)
    } else {
      return '0'
    }
  }

  // 渲染 - 字段
  const fieldRender = () => {
    const list:any = []
    const fieldList = [
      { key: 'OrderRouting', val: 'CCDex' },
    ]
    fieldList.forEach((item:any, index:number) => {
      const { key, val } = item
      list.push(
        <div className='field-item' key={`infoField_${index}`}>
          <Text size='12' color='gray600'>{t(`Field.${key}`)}</Text>
          <Text size='12' color='gray600' type='english'>{val}</Text>
        </div>
      )
    })
    return list
  }

  // 渲染 - Position
  const positionRender = (
    <section className='position-wrap'>
      <Text kind='Bold' size={isPC ? '20' : '16'}>{toChainName} {toTokenSymbol} {t('Title.Position')}</Text>
      <div>
        <Text size='16' kind='Bold'>{t('Field.TotalValue')}</Text>
        <Text size='16' type='specialNumber'>$ {_getExchangePrice(toTokenSymbol, last_balance)}</Text>
      </div>
      <div>
        <div>
          <ScCoin isSpecial={true} kind={isPC ? '5' : '2'} name={toTokenSymbol} />
          <Text kind='Bold' type='specialNumber' size={isPC ? '20' : '16'}>{_numComma(last_balance, 4)}</Text>
        </div>
        <div>
          <Text type='english' size={isPC ? '13' : '12'} color='gray500'>{t('Field.Token')}</Text>
          <Text size={isPC ? '13' : '12'} color='gray500'>{t('Field.Amount')}</Text>
        </div>
      </div>
    </section>
  )

  // 渲染 - Field
  const FieldRender = (
    <section className='field-wrap'>
      <div className='collapse-bar'>
        <Text kind='Bold' color='gray600'>{t('Field.WillReceive')}</Text>
        <ScCoin kind='3' content={`${getReceiveAmount()} ${fromTokenSymbol}`} name={fromTokenSymbol} />
      </div>
      <div className='field-bar'>
        { fieldRender() }
        <div className='field-item'>
          <div>
            <Text size='12' color='gray600'>{t('Field.Fees')}</Text>
            <Tooltip placement="top" title={<Text size='12' color='white'>{t('Tooltip.NetworkFeesTip')}</Text>}>
              <TipSvg />
            </Tooltip>
          </div>
          <Text size='12' color='gray600' type='english'>{_numComma(exchangeFee, 4)} {fromTokenSymbol}</Text>
        </div>
        <div className='field-item'>
          <div>
            <Text size='12' color='gray600'>{t('Field.GasCost')}</Text>
            <Tooltip placement="top" title={<Text size='12' color='white'>{t('Tooltip.NetworkFeesTip')}</Text>}>
              <TipSvg />
            </Tooltip>
          </div>
          <Text type='english' size='12' color='gray600'>{_numComma(gasCost, 4)} {gasSymbol}</Text>
        </div>
      </div>
    </section>
  ) 

  return (
    <InfoStyle>
      { isPC ? <>{positionRender}{FieldRender}</> : <>{FieldRender}{positionRender}</> }
    </InfoStyle>
  )
}

export default withRouter(Info)
