import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { h5FooterSvgList } from '@/utils/icoStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { FooterBar } from './styled'

const H5Footer:React.FC<RouterProps> = ({ t, navigate })=> {

  const { linkList } = useCommon()

  const configData = useContext(ConfigContext)

  // 操作 - 导航
  const navHandle = (item:string) => {
    navigate(linkList[item])
  }

  // 渲染 - 导航
  const footNavRender = () =>  {
    let list:any = []
    const footerNavList = ['Swap', 'Bridge', 'Pool']
    footerNavList.forEach((item, index) => {
      const DefaultSvg = h5FooterSvgList[item]['default']
      const ActiveSvg = h5FooterSvgList[item]['active']
      const isActive = item === configData.routerSign
      list.push( 
        <figure key={`h5FootNavRender${index}`} onClick={() => navHandle(item)}>
          { isActive ? <ActiveSvg className='active'/> : <DefaultSvg /> }
          <Text type='specialEnglish' size='12' color={isActive ? 'primary500' : 'gray600'}>{t(`Link.${item}`)}</Text>
        </figure>
      )
    })
    return list
  }

  return (
    <FooterBar>
      <nav>{footNavRender()}</nav>
    </FooterBar>
  )
}

export default withRouter(H5Footer)
