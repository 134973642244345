import React, { useState, useEffect, useContext } from 'react'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import { BridgeDispatchContext } from '@/utils/contextStatic'
import FormCheckbox from '@/components/FormCheckbox'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import { PopGasHintStyle } from './styled'
import Text from '@/components/Text'
import Pop from '../index'

interface ChildProps extends RouterProps {
  callback: any
  status: boolean
}

const PopGasHint:React.FC<ChildProps> = ({ t, callback, status })=> {

  const { _getGasPrice } = usePublicEthers()

  const bridgeDispatch:any = useContext(BridgeDispatchContext)

  const [ checkStatus, setCheckStatus ] = useState<boolean>(false)  

  const [ gasPrice, setGasPrice ] = useState<string>('-')
  
  useEffect(() => {
    const initHandle = async() => {
      const themp:any = await _getGasPrice()
      setGasPrice(themp)
    }
    initHandle()
  }, [])

  // 操作 - 继续
  const submitHandle = () => {
    callback()
    if(checkStatus) sessionStorage.setItem('gasHintState', status ? '1' : '')
    bridgeDispatch({ type: 'continueStatus', payload: true })
  }

  // 操作 - 关闭
  const cancelHandle = () => {
    callback()
    if(checkStatus) sessionStorage.setItem('gasHintState', status ? '1' : '')
  }

  // 操作 - 勾选
  const checkHandle = (status:boolean) => {
    setCheckStatus(status)
  }

  return (
    <Pop status={status} title={t('Title.GasPrice')} callback={callback}>
      <PopGasHintStyle>
        <div className='gas-bar'>
          <Text kind='Bold' size='54' type='number'>{_numComma(gasPrice, 4)}</Text>
          <Text size='20' color='gray500' type='english'>Gwei</Text>
        </div>
        <Text size='16'>{t('Content.GasPriceDesc')}</Text>
        <FormCheckbox callback={checkHandle} text={t('Content.RemindDesc')} />
        <div className='btn-bar'>
          <FormButton kind='cancel' size='large' callback={cancelHandle}>
            <Text type='specialEnglish' size='16' color='gray500'>{t('Button.Cancel')}</Text>
          </FormButton>
          <FormButton size='large' callback={submitHandle}>
            <Text type='specialEnglish' size='16' color='white'>{t('Button.Continue')}</Text>
          </FormButton>
        </div>
      </PopGasHintStyle>
    </Pop>
  )
}

export default withRouter(PopGasHint)

