// 最大值
const maxUint256 = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"

// 零地址
const zeroAddress = '0x0000000000000000000000000000000000000000'

// 原生代币
const chainCoin = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

// 域名
const hostname = window.location.hostname.replace('www.', '')

// 合约地址列表
const contractAddressList:any = process.env.CONTRACT_ADDRESS_LIST

// 特殊 - 协议ROUTER 
const specialRouterList:any = process.env.SPECIAL_ROUTER_LIST

// 图片地址
const tokenIconsDomain = process.env.TOKEN_ICONS_DOMAIN

// socket - 请求地址
const socketUrl:any = process.env.SOCKET_URL

// info - 请求地址
const infoUrl = process.env.INFO_URL

// 本站 - 请求地址
const baseUrl = process.env.BASE_URL

export {
  contractAddressList,
  specialRouterList,
  tokenIconsDomain,
  zeroAddress,
  maxUint256,
  chainCoin,
  socketUrl,
  hostname,
  baseUrl,
  infoUrl
}