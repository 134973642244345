import React, { useReducer } from 'react'
import { BridgeManageContext, BridgeManageDispatchContext } from '@/utils/contextStatic'
import { bridgeManageState, bridgeManageReducer } from '@/reducer/bridgeManage'
import useInitBridgeManage from '@/hooks/useInit/useInitBridgeManage'
import InterimManage from '@/components/Interim/InterimManage'
import { BridgeManageStyle } from './BridgeManageCss'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useInit from '@/hooks/useInit'
import Reward from './Reward'
import Info from './Info'
import Form from './Form'


const BridgeManage:React.FC<RouterProps> = ({ params }) => {

  useInit('Pool')

  const [ bridgeManageData, bridgeManageDispatch ] = useReducer(bridgeManageReducer, bridgeManageState)

  useInitBridgeManage(bridgeManageData, bridgeManageDispatch, params)
  
  const { loadingStatus } = bridgeManageData

  return (
    <BridgeManageStyle>
      <BridgeManageContext.Provider value={bridgeManageData}>
        <BridgeManageDispatchContext.Provider value={bridgeManageDispatch}>
          <div className='bg-bar'></div>
          <main>
            <Info />
            { loadingStatus ? <InterimManage /> : (
              <div>
                <Form />
                <Reward />
              </div>
            ) }
          </main>
        </BridgeManageDispatchContext.Provider>
      </BridgeManageContext.Provider>
    </BridgeManageStyle>
  )
}

export default withRouter(BridgeManage)
