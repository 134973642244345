import React, { useContext, useReducer } from 'react'
import { SwapContext, SwapDispatchContext, ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import InterimSwapExchange from '@/components/Interim/InterimSwapExchange'
import InterimRecharts from '@/components/Interim/InterimRecharts'
import InterimRouting from '@/components/Interim/InterimRouting'
import PopSwapFromCoin from '@/components/Pop/PopSwapFromCoin'
import PopSwapToCoin from '@/components/Pop/PopSwapToCoin'
import { swapState, swapReducer } from '@/reducer/swap'
import useInitSwap from '@/hooks/useInit/useInitSwap'
import PopSetting from '@/components/Pop/PopSetting'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useInit from '@/hooks/useInit'
import { SwapStyle } from './SwapCss'
import Recharts from './Recharts'
import Exchange from './Exchange'
import Routing from './Routing'
import Record from './Record'


const Swap:React.FC<RouterProps> = ({ params }) => {

  useInit('Swap')

  const { popShowConfig } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const [ swapData, swapDispatch ] = useReducer(swapReducer, swapState)

  const { chartLoadingStatus, inputLoadingStatus, formLoadingStatus } = swapData

  const { settingState } = popShowConfig

  useInitSwap(swapData, swapDispatch, params)

  // 操作 - 弹框
  const popHandle = () => {
    configDispatch({ type: 'changePop', payload: 'settingState' })
  }
    
  return (
    <SwapStyle>
      <SwapContext.Provider value={swapData}>
        <SwapDispatchContext.Provider value={swapDispatch}>
          <div className='bg-bar'></div>
          <main>
            <div>
              { chartLoadingStatus ? <InterimRecharts /> : <Recharts /> }
              { inputLoadingStatus ? <InterimRouting /> : <Routing /> }
              <Record />
            </div>
            { formLoadingStatus ? <InterimSwapExchange /> : <Exchange /> }
          </main>
          <PopSwapFromCoin />
          <PopSwapToCoin />
          {settingState && <PopSetting callback={popHandle} status={settingState} />}
        </SwapDispatchContext.Provider>
      </SwapContext.Provider>
    </SwapStyle>
  )
}

export default withRouter(Swap)
