import React, { useContext } from 'react'
import { ConfigContext, SwapContext, SwapDispatchContext } from '@/utils/contextStatic'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import FormInput from '@/components/FormInput'
import withRouter from '@/utils/withRouter'
import { SettingStyle } from './styled'
import Text from '@/components/Text'
import Pop from '../index'

interface ChildProps extends RouterProps {
  callback: any
  status: boolean
}

const Setting:React.FC<ChildProps> = ({ t, callback, status })=> {

  const { isPC } = useContext(ConfigContext)

  const { slippageParam } = useContext(SwapContext)

  const swapDispatch:any = useContext(SwapDispatchContext)

  const { slippage, deadline } = slippageParam

  // 更新 - 滑点
  const changeHandle = (type:string, val?:string) => {
    const param:any = {
      slippage: { slippage: val },
      deadline: { deadline: val },
      reset: { slippage: '0.5', deadline: '60' }
    }
    swapDispatch({ type: 'changeSlippage', payload: param[type] })
  }

  // 渲染 - 列表
  const slippageRender = () => {
    const list:any = []
    const slippageList = ['0.1', '0.5', '1']
    slippageList.forEach((item:any, index:number) => {
      const isActive = slippage === item
      list.push(
        <Text 
          color={isActive ? 'primary500' : 'gray600'}
          callback={() => changeHandle('slippage', item)}
          clsName={isActive ? 'active' : ''}
          key={`p_slippageList${index}`} 
          size={isPC ? '16' : '14'} 
          kind='Bold' 
          type='number'>
          {item}%
        </Text>
      )
    })
    return list
  }

  return (
    <Pop status={status} title={t('Title.Setting')} callback={callback}>
      <SettingStyle>
        <Text kind='Bold'size={isPC ? '16' : '14'}>{t('Field.Slippage')}</Text>
        <div>{slippageRender()}</div>
        <FormInput placeholder='0.0%' size={isPC ? 'large' : 'normal'} init={slippage} callback={(val:string) => changeHandle('slippage', val)} />
        <Text kind='Bold' size={isPC ? '16' : '14'}>{t('Field.Deadline')}</Text>
        <div className='minutes-bar'>
          <FormInput disabled={true} type='number' placeholder='0' size={isPC ? 'large' : 'normal'} init={deadline} callback={(val:string) => changeHandle('deadline', val)} />
          <Text size={isPC ? '16' : '14'} color='gray500'>{t('Field.Minutes')}</Text>
        </div>
        <FormButton size={isPC ? 'large' : 'normal'} callback={() => changeHandle('reset')}>
          <Text type='specialEnglish' size={isPC ? '16' : '14'} color='white'>{t('Button.ResetDefault')}</Text>
        </FormButton>
      </SettingStyle>
    </Pop>
  )
}

export default withRouter(Setting)

