import { useEffect, useContext } from 'react'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import { ConfigContext, SwapContext } from '@/utils/contextStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import BigNumber from 'bignumber.js'

/**
 * 设置按钮状态 1:授權A 2:加載中 3:确认 5:钱包未链接 6: 链不匹配 7:授权B
 * @param {String} inputVal         // 输入值
 * @param {String} handler          // 方法
 */

export default function useBtnSwap( inputVal: string, handler: any) {

  const { _inPutFormat } = usePublicEthers()

  const { _getApproveAmount } = usePublicErc20()

  const { bridgeOperateContract } = useContractBridge()

  const { account, chainId } = useContext(ConfigContext)

  const { swapFormParam, protocolsRouterInfo } = useContext(SwapContext)

  const { fromToken, fromTokenDecimal, fromNetwork } = swapFormParam
  
  // 钱包 -> 授权
  useEffect(() => {
    const initFun = async() => {
      if(account) {
        if(+chainId === +fromNetwork) {
          let directionA:any = 0
          if(+inputVal > 0) {
            const approveAmountA = await _getApproveAmount(fromToken, bridgeOperateContract)
            directionA = new BigNumber(approveAmountA).minus(_inPutFormat(inputVal, fromTokenDecimal)).toFixed(0)
          }
          if(+directionA >= 0) {
            handler(3)
          } else {
            handler(1)
          }
        } else {
          handler(6)
        }
      } else {
        handler(5)
      }
    }
    initFun()
  },[protocolsRouterInfo, account, fromToken, chainId]) 
}