import React from 'react'
import { _getCoinImgUrl } from '@/utils/urlMethod'
import { LpSvg } from '@/utils/icoStatic'
import { ScCoinStyle } from './styled'
import Text from '@/components/Text'

interface ChildProps {
  isSpecial?: boolean
  imgType?: string
  content?: string
  color?: string
  isLp?: boolean
  svgCon?: any
  name: string
  kind: string
}

const ScCoin:React.FC<ChildProps> = ({ kind, name, svgCon, content=name, isLp=false, imgType='token', color, isSpecial=false })=> {

  const sizeList:any = { 
    '1': { textSize: '32', textKind: 'Bold' },
    '2': { textSize: '16', textKind: 'Light'},
    '3': { textSize: '14', textKind: 'Bold'},
    '4': { textSize: '20', textKind: 'Light'},
    '5': { textSize: '20', textKind: 'Light'},
    '6': { textSize: '14', textKind: 'Bold'},
    '7': { textSize: '20', textKind: 'Bold'},
    '8': { textSize: '14', textKind: 'Bold'},
    '9': { textSize: '14', textKind: 'Light'},
    '10': { textSize: '14', textKind: 'Light'},
    '11': { textSize: '16', textKind: 'Light'},
    '12': { textSize: '16', textKind: 'Bold'},
    '13': { textSize: '16', textKind: 'Light'},
    '14': { textSize: '16', textKind: 'Light'},
    '15': { textSize: '14', textKind: 'Light'},
    '16': { textSize: '20', textKind: 'Light'},
    '17': { textSize: '16', textKind: 'Light'},
    '18': { textSize: '12', textKind: 'Light'},
    '19': { textSize: '12', textKind: 'Bold'},
    '20': { textSize: '16', textKind: 'Bold'},
    '21': { textSize: '14', textKind: 'Light'},
  }

  const { textSize, textKind } = sizeList[kind]

  return (
    <ScCoinStyle kind={kind} content={content} className='sccoin-module'>
      { svgCon ? svgCon : <img src={_getCoinImgUrl(name, imgType)} alt='' /> }
      <Text kind={textKind} type={isSpecial ? 'specialEnglish' : 'english'} size={textSize} color={color} clamp='1'>{content}</Text>
      { isLp && <LpSvg /> }
    </ScCoinStyle>
  )
}

export default ScCoin

