import { rem } from '@/theme'
import styled from 'styled-components'

const ExchangeStyle = styled.div`

	div.exchange-bar {
		background: ${({ theme }) => theme.gray50};
		border: 1px solid ${({ theme }) => theme.gray100};
		div.arrow-bar {
			${({ theme }) => theme.align_center};
			${({ theme }) => theme.x_center};
			background: ${({ theme }) => theme.white};
			border-radius: 9999px;
		}
		div.select-bar {
			${({ theme }) => theme.align_between};
			background: ${({ theme }) => theme.white};
			box-shadow: ${({ theme }) => theme.input_bs};
			margin-top: 1px;
			& > div:nth-child(2) {
				border-left: 1px solid ${({ theme }) => theme.gray200};
			}
			div.select-item {
				& > div:nth-child(2) {
					${({ theme }) => theme.align_between};
					& > div {
						${({ theme }) => theme.align};
					}
				}
			}
		}
		div.tolerance-bar {
			${({ theme }) => theme.align_between};
			& > div {
				${({ theme }) => theme.align};
			}
		}
		div.input-bar {
			& > div:nth-child(1) {
				${({ theme }) => theme.align_between};
				& > div:nth-child(2) {
					${({ theme }) => theme.align};
				}
			}
		}
		& > button {
			width: 100%;
		}
	}

	// PC
	@media screen and (min-width: 540px){
		div.exchange-bar {
			padding: 20px;
			border-radius: 24px;
			div.arrow-bar {
				cursor: pointer;
				width: 24px;
				height: 24px;
				margin-top: 10px;
				svg {
					width: 7px;
					height: 10px;
				}
			}
			div.select-bar {
				padding: 8px 16px;
				border-radius: 8px;
				& > div:nth-child(1) {
					width: 210px;
					padding-right: 16px;
				}
				& > div:nth-child(2) {
					width: 156px;
					padding-left: 16px;
				}
				div.select-item {
					& > div:nth-child(1) {
						margin-bottom: 6px;
					}
					& > div:nth-child(2) {
						cursor: pointer;
						& > svg {
							width: 12px;
							height: 12px;
						}
					}
				}
			}
			div.tolerance-bar {
				margin-top: 16px;
				& > div {
					& > div:nth-child(1) {
						margin-right: 5px;
					}
				}
				& > svg {
					width: 24px;
					height: 24px;
					cursor: pointer;
				}
			}
			div.input-bar {
				margin-top: 10px;
				& > div:nth-child(1) {
					svg {
						width: 18px;
						height: 18px;
						margin-right: 4px;
					}
				}
				div.input-moudle {
					margin-top: 4px;
					input {
						border-radius: 8px;
					}
				}
			}
			& > button {
				margin-top: 16px;
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		padding-top: ${rem(20)};
		div.exchange-bar {
			padding: ${rem(16)}; 
			border-radius: ${rem(16)};
			div.arrow-bar {
				width: ${rem(24)};
				height: ${rem(24)};
				margin-top: ${rem(10)};
				svg {
					width: ${rem(7)};
					height: ${rem(10)};
				}
			}
			div.select-bar {
				padding: ${rem(6)} ${rem(10)};
				border-radius: ${rem(8)};
				& > div:nth-child(1) {
					width: ${rem(160)};
				}
				& > div:nth-child(2) {
					width: ${rem(121)};
					padding-left: ${rem(10)};
				}
				div.select-item {
					& > div:nth-child(1) {
						margin-bottom: ${rem(2)};
					}
					& > div:nth-child(2) {
						& > svg {
							width: ${rem(12)};
							height: ${rem(12)};
						}
					}
				}
			}
			div.tolerance-bar {
				margin-top: ${rem(16)};
				& > div {
					& > div:nth-child(1) {
						margin-right: ${rem(5)};
					}
				}
				& > svg {
					width: ${rem(24)};
					height: ${rem(24)};
				}
			}
			div.input-bar {
				margin-top: ${rem(10)};
				& > div:nth-child(1) {
					svg {
						width: ${rem(18)};
						height: ${rem(18)};
						margin-right: ${rem(4)};
					}
				}
				div.input-moudle {
					margin-top: ${rem(4)};
					input {
						border-radius: ${rem(8)};
					}
				}
			}
			& > button {
				margin-top: ${rem(16)};
			}
		}
  }
`

export {
	ExchangeStyle
}




