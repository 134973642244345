import { rem } from '@/theme'
import styled from 'styled-components'

const PopBridgeCoinStyle = styled.div`

  div.coin-bar {
    overflow: auto;
    div.coin-item {
      ${({ theme }) => theme.align_between};
    }
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  // PC
  @media screen and (min-width: 540px) { 
    & > div:nth-child(1) {
      margin-top: 16px;
    }
    div.coin-bar {
      min-height: 162px;
      max-height: 490px;
      margin-top: 10px;
      div.coin-item {
        padding: 16px 0;
        cursor: pointer;
      }
      div.coin-item:hover {
        background: ${({ theme }) => theme.gray50};
      }
    }
  }

  // H5
  @media screen and (max-width: 540px) { 
    & > div:nth-child(1) {
      margin-top: ${rem(16)};
    }
		div.coin-bar {
      max-height: ${rem(430)};
      margin-top: ${rem(20)};
      div.coin-item {
        padding: ${rem(16)} 0;
      }
    }
  }
`

export {
  PopBridgeCoinStyle
}


