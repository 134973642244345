import { rem } from '@/theme'
import styled from 'styled-components'

const SelectCoinStyle = styled.div<{$state:boolean}>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 998;
  & > div {
    display: flex;
    background: ${({ theme }) => theme.web_bg};
    height: -webkit-fill-available;
    div.chain-bar {
      background: ${({ theme }) => theme.gray100};
      & > i {
        display: block;
        background: ${({ theme }) => theme.gray300};
      }
      div.chain-item {
        position: relative;
        text-align: center;
        & > div.active {
          background: ${({ theme }) => theme.white};
        }
      }
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    div.coin-bar {
      position: relative;
      flex: 1;
      & > div:nth-child(1) {
        text-align: center;
      }
      & > div:nth-child(3) {
        ${({ theme }) => theme.flex_wrap};
        display: flex;
        & > div {
          border: 1px solid ${({ theme }) => theme.gray200};
        }
      }
      & > div:nth-child(4) {
        overflow: auto;
        div.coin-item {
          ${({ theme }) => theme.align_between};
          & > div:nth-child(1) {
            ${({ theme }) => theme.align};
          }
        }
        &::-webkit-scrollbar {
          width: 0;
        }
      }
      & > svg {
        position: absolute;
      }
    }
  }

  // PC
  @media screen and (min-width: 540px) { 
    ${({ theme }) => theme.align_center};
    background-color: rgba(0,0,0,.4);
    & > div {
      width: 464px;
      min-height: 510px;
      max-height: 710px;
      border-radius: 24px;
      div.chain-bar {
        width: 80px;
        padding: 22px 0;
        border-radius: 24px 0 0 24px;
        & > i {
          margin-left: 34px;
          margin-bottom: 20px;
          width: 22px;
          height: 2px;
        }
        div.chain-item {
          margin-left: 10px;
          margin-top: 10px;
          cursor: pointer;
          & > div:nth-child(1) {
            padding: 10px 0;
            border-radius: 12px 0px 0px 12px;
            img {
              width: 36px;
              height: 36px;
            }
          }
          & > div:nth-child(2) {
            position: absolute;
            top: 0;
            right: 0;
            padding: 16px;
            display: none;
            background: ${({ theme }) => theme.white};
            border-radius: 12px 0px 0px 12px;
            border-width: 1px 0 1px 1px;
            border-style: solid;
            border-color: ${({ theme }) => theme.gray200};
            white-space: nowrap;
          }
        }
        div.chain-item:hover {
          & > div:nth-child(2) {
            display: block;
          }
        }
      }
      div.coin-bar {
        padding: 16px 25px 25px;
        & > div:nth-child(2) {
          margin-top: 20px;
        }
        & > div:nth-child(3) {
          margin-top: 16px;
          gap: 6px;
          & > div {
            padding: 7px 10px;
            border-radius: 10px;
            cursor: pointer;
          }
        }
        & > div:nth-child(4) {
          min-height: 270px;
          max-height: 480px;
          margin-top: 10px;
          div.coin-item {
            padding: 16px 0;
            cursor: pointer;
            & > div:nth-child(1) {
              img {
                width: 30px;
                height: 30px;
                margin-right: 12px;
              }
            }
          }
          div.coin-item:hover {
            background: ${({ theme }) => theme.gray50};
          }
        }
        & > svg {
          cursor: pointer;
          top: 22px;
          right: 22px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  // H5
  @media screen and (max-width: 540px) { 
		height: 100vh;
		width: 100%;
		overflow: auto;
		opacity: 1;
		filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
		transform: ${({ $state }) => `translate3d(0px, ${$state ? '100%' : '0px'}, 0px)`}; 
		transition: transform 0.4s cubic-bezier(0.45, 0, 0.55, 1) 0s, opacity 0.4s 0s;
		// 滚动滑块
		&::-webkit-scrollbar-thumb {
			background: ${({ theme }) => theme.alpha300};
			border-radius: 9999px;
		}
		// 滚动条
		&::-webkit-scrollbar {
			width: ${rem(4)};
		}
    div.chain-bar {
      width: ${rem(65)};
      padding: ${rem(22)} 0;
      & > i {
        margin-left: ${rem(27)};
        margin-bottom: ${rem(20)};
        width: ${rem(22)};
        height: ${rem(2)}; 
      }
      div.chain-item {
        margin-left: ${rem(10)};
        margin-top: ${rem(10)};
        font-size: 0;
        & > div:nth-child(1) {
          padding: ${rem(10)} 0;
          border-radius: ${rem(12)} 0 0 ${rem(12)};
          img {
            width: ${rem(32)};
            height: ${rem(32)};
          }
        }
      }
    }
    div.coin-bar {
      padding: ${rem(16)};
      & > div:nth-child(2) {
        margin-top: ${rem(16)};
      }
      & > div:nth-child(3) {
        margin-top: ${rem(16)};
        gap: ${rem(6)};
        & > div {
          padding: ${rem(5)} ${rem(8)};
          border-radius: ${rem(10)};
        }
      }
      & > div:nth-child(4) {
        max-height: ${rem(430)};
        margin-top: ${rem(20)};
        div.coin-item {
          padding-bottom: ${rem(20)};
          & > div:nth-child(1) {
            img {
              width: ${rem(24)};
              height: ${rem(24)};
              margin-right: ${rem(12)};
            }
          }
        }
      }
      & > svg {
        top: ${rem(22)};
        right: ${rem(16)};
      }
    }
  }

`

export {
  SelectCoinStyle
}


