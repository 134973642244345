import React, { useState, useContext, useRef } from 'react'
import { ConfigContext, BridgeManageContext, ConfigDispatchContext } from '@/utils/contextStatic'
import useBusinessBridge from '@/hooks/useBusiness/useBusinessBridge'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import useBtnAddBridgeLiq from '@/hooks/useBtn/useBtnBridgeLiq'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import useBtnApprove from '@/hooks/useBtn/useBtnApprove'
import useBtnChain from '@/hooks/useBtn/useBtnChain'
import ScInput from '@/components/Section/ScInput'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import BigNumber from 'bignumber.js'
import Text from '@/components/Text'

const Form:React.FC<RouterProps> = ({ t }) => {

  let delayModule:any = useRef()

  const { _getChainProperty } = useCommon()

  const { isPC } = useContext(ConfigContext)

  const { _inPutFormat } = usePublicEthers()
  
  const { _getAllowanceOperate } = usePublicErc20()

  const { bridgeOperateContract } = useContractBridge()

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { poolInfo, tokenBalance } = useContext(BridgeManageContext)

  const { pool_symbol, last_balance, staking_token, chain_id, token_decimal } = poolInfo

  const chainName = _getChainProperty(chain_id, 'chain_name')
  
  // 1:授權 2:加載中 3:确认 5:钱包未链接 6:链不匹配
  const [ btnStatus, setBtnStatus ] = useState<number>(3)

  const [ amount, setAmount ] = useState<string>('')

  // 授权操作
  const coinApproveHandle = useBtnApprove(staking_token, bridgeOperateContract, setBtnStatus)

  // 添加流动性操作
  const { _addLiquidityTokenHandle } = useBusinessBridge(setBtnStatus)

  // 按钮状态控制
  useBtnAddBridgeLiq(amount, setBtnStatus, staking_token, 'Add')

  // 切链操作
  const toggleChainHandle = useBtnChain(chain_id, setBtnStatus)

  // 计算 - 池子份额
  const computeSharePoolFun = () => {
    if(amount) {
      const total = new BigNumber(last_balance).plus(amount).toString()
      const sharePool = new BigNumber(amount).div(total).times(100).toString()
      return _numComma(sharePool)
    } else {
      return '0.0'
    }
  }

  // 判断 - 按钮状态
  const openHandle = () => {
    if( (+tokenBalance > 0) && (+amount > 0) && (+amount <= +tokenBalance) ) return true
    return false
  }

  // 更新授权数量
  const updataApproveHandle = async() => {
    const allowance = await _getAllowanceOperate(staking_token, bridgeOperateContract)
    const direction = new BigNumber(allowance).minus(_inPutFormat(amount ? amount : '0', token_decimal)).toFixed(0)
    if(+direction >= 0) {
      setBtnStatus(3)
    } else {
      setBtnStatus(1)
    }
  }

  // 展示 - 按钮信息列表
  const btnInfoList:any = {
    1: { text: t("Button.Approve"), kind: 'primary', disabled: false },
    2: { text: t("Button.Loading"), kind: 'loading', disabled: true },
    3: { text: t('Button.AddLiquidity'), kind: 'primary', disabled: !openHandle() },
    5: { text: t("Button.Connect"), kind: 'primary', disabled: false },
    6: { text: t('Button.SwitchChain', { chain: chainName }), kind: 'primary', disabled: false },
  }

  const { text, disabled, kind } =  btnInfoList[btnStatus]

  // 操作 - input
  const changeHandle = (val:string) => {
    clearTimeout(delayModule.current)
    delayModule.current = setTimeout(async() => {
      setAmount(val)
    }, 500)
  }

  // 按钮处理
  const btnHandle = () => {
    switch(btnStatus) {
      // 授权
      case 1:
        coinApproveHandle(updataApproveHandle)
        break;
      // 添加
      case 3:
        _addLiquidityTokenHandle(staking_token, amount, updataApproveHandle)
        break;
      // 链接
      case 5:
        configDispatch({ type: 'changePop', payload: 'selectWallteState' })
        break;
      // 切链
      case 6:
        toggleChainHandle()
        break;
      default:
    }
  }

  return (
    <>
      <Text kind='Bold' size={isPC ? '18' : '16'}>{t('Title.AddLiquidity')}</Text>
      <Text color='gray500' size={isPC ? '14' : '12'}>{t('Content.AddLiquidityDesc', { lp: `${pool_symbol}-LP` })}</Text>
      <ScInput 
        amount={amount} 
        symbol={pool_symbol} 
        balance={tokenBalance} 
        maxvalue={tokenBalance}
        changeAmount={changeHandle} />
      <div className='receive-bar'>
        <Text kind='Bold' size={isPC ? '15' : '14'} color='gray600'>{t('Field.WillReceive')}</Text>
        <div>
          <Text color='gray600'>{pool_symbol} {t('Field.LiquidityToken')}</Text>
          <Text kind='Bold' type='english' color='gray600'>{`${amount ? _numComma(amount, 4) : '-'} ${pool_symbol}-LP`}</Text>
        </div>
        <div>
          <Text size='12' color='gray600'>{t('Field.SharePool')}</Text>
          <Text size='12' color='gray600'>{computeSharePoolFun()}%</Text>
        </div>
      </div>
      <FormButton size={isPC ? 'large' : 'normal'} kind={kind} callback={btnHandle} disabled={disabled}>
        <Text type='specialEnglish' size='16' color='white'>{text}</Text>
      </FormButton>
    </>
  )
}

export default withRouter(Form)
