import { rem } from '@/theme'
import { Skeleton } from 'antd'
import styled from 'styled-components'

const InterimCardStyle = styled.div`

	background: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray200};
	& > div {
		${({ theme }) => theme.align_between};
	}

	// PC
	@media screen and (min-width: 540px) { 
		padding: 16px;
		border-radius: 16px;
		& > div:nth-child(1), & > div:nth-child(3) {
			margin-bottom: 5px;
		}
		& > div:nth-child(2), & > div:nth-child(4) {
			margin-bottom: 4px;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		min-width: ${rem(213)};
		padding: ${rem(12)};
		border-radius: ${rem(16)};
		& > div:nth-child(1), & > div:nth-child(3) {
			margin-bottom: ${rem(5)};
		}
		& > div:nth-child(2), & > div:nth-child(4) {
			margin-bottom: ${rem(4)};
		}
	}
`

const SkeletonIconStyle = styled(Skeleton)`

	// PC
	@media screen and (min-width: 540px) { 
		.ant-skeleton-avatar {
			width: 24px !important;
			height: 24px !important;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		.ant-skeleton-avatar {
			width: ${rem(23)} !important;
			height: ${rem(23)} !important;
		}
	}
	
`

const SkeletonChainStyle = styled(Skeleton.Button)`

	// PC
	@media screen and (min-width: 540px) { 
		.ant-skeleton-button {
			width: 100px !important;
			height: 26px !important;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		.ant-skeleton-button {
			width: ${rem(80)} !important;
			height: ${rem(23)} !important;
		}
	}

`

const SkeletonLabelStyle = styled(Skeleton.Button)`

	// PC
	@media screen and (min-width: 540px) { 
		.ant-skeleton-button {
			height: 20px !important;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		.ant-skeleton-button {
			width: ${rem(50)} !important;
			min-width: ${rem(50)} !important;
			height: ${rem(20)} !important;
		}
	}

`

const SkeletonValueStyle = styled(Skeleton.Button)`

	// PC
	@media screen and (min-width: 540px) { 
		.ant-skeleton-button {
			width: 100px !important;
			height: 24px !important;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		.ant-skeleton-button {
			width: ${rem(80)} !important;
			height: ${rem(22)} !important;
		}
	}

`

export {
	SkeletonChainStyle,
	SkeletonLabelStyle,
	SkeletonValueStyle,
	SkeletonIconStyle,
  InterimCardStyle
}