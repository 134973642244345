import { rem } from '@/theme'
import styled from 'styled-components'

const RewardStyle = styled.div`

	& > div {
		background: ${({ theme }) => theme.white};
		border: 1px solid  ${({ theme }) => theme.gray100};
	}
	& > div:nth-child(1) {
		div.title-item {
			text-align: center;
		}
		div.position-item {
			& > div {
				${({ theme }) => theme.align_between};
				div.double-key {
					${({ theme }) => theme.align};
				}
			}
		}
	}
	& > div:nth-child(2) {
		& > div:nth-child(1) {
			text-align: center;
		}
		& > div:not(:first-child) {
			${({ theme }) => theme.align_between};
		}
	}

	// PC
	@media screen and (min-width: 540px){
		width: 506px;
		& > div {
			padding: 30px 36px;
			border-radius: 20px;
		}
		& > div:nth-child(1) {
			& > div:not(:last-child) {
				margin-bottom: 16px;
			}
			div.position-item {
				& > div:nth-child(1) {
					margin-bottom: 5px;
				}
			}
		}
		& > div:nth-child(2) {
			margin-top: 20px;
			& > div:not(:first-child) {
				margin-top: 16px;
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		& > div {
			padding: ${rem(16)};
			border-radius: ${rem(16)};
		}
		& > div:nth-child(1) {
			& > div:not(:last-child) {
				margin-bottom: ${rem(16)};
			}
			div.position-item {
				& > div:nth-child(1) {
					margin-bottom: ${rem(2)};
				}
			}
		}
		& > div:nth-child(2) {
			margin-top: ${rem(20)};
			& > div:not(:first-child) {
				margin-top: ${rem(16)};
			}
		}
  }
`

export {
	RewardStyle
}




