/**
 * 获取url中"?"符后的字串
 * @param {String}  url  链接地址
 */
const _getParamByUrl = (url:string) => {
	let theRequest:any = {}
	let index = url.indexOf("?")
	if (index !== -1) {
	    let str = url.substr(index + 1)
	    let strs = str.split("&")
	    for(let i = 0; i < strs.length; i ++) {
		   theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
	    }
	}
	return theRequest
}

/**
 * 每三位隔一逗号
 * @param {String/Number} str        需要格式化得数字
 * @param {Number}        index      保留小数位数
 * @param {Boolean}       isConvert  添加逗号
 */
const _numComma = (num: string|number, index=2, isConvert=true) => {
	const isNegative = Number(num) < 0
	let str = num ? num.toString() : '0'
	str = isNegative ? str.replace('-', '') : str
	let newStr = ""
	let count = 0
	// 当数字是整数
	if (str && str?.indexOf(".") === -1) {
		for (let i = str.length - 1; i >= 0; i--) {
			if (count % 3 === 0 && count !== 0 && isConvert) {
				newStr = str.charAt(i) + "," + newStr
			} else {
				newStr = str.charAt(i) + newStr
			}
			count++
		}
		str = newStr
		return `${isNegative ? '-' : ''}${str}`
	}
	// 当数字带有小数
	else {
		let decimal = str.split('.')[1]
		decimal = decimal.length > index ? decimal.substring(0,index) : decimal
		for (let i = str?.indexOf(".") - 1; i >= 0; i--) {
			if (count % 3 === 0 && count !== 0 && isConvert) {
				newStr = str.charAt(i) + "," + newStr
			} else {
				newStr = str.charAt(i) + newStr
			}
			count++
		}
		var len = decimal.split("0").length - 1
		decimal = len !== decimal.length ? `.${decimal.replace(/(0+)$/g,"")}` : ''
		str = newStr + decimal
		if (index === 0) str = str.split('.')[0]
		return `${isNegative ? '-' : ''}${str}`
	}
}

/**
 * 数组对象排序
 * @param {array} arr          需要排序数组对象
 * @param {String} field       排序字段
 * @param {boolean} direction  true: 降序 , false : 升序  
 */
const _compare = (arr:object[], field:string, direction=true) => {
	const newArr = arr.slice(0)
	const handle = (a:any, b:any) => {
	  let value1 = +a[field]
	  let value2 = +b[field]
	  if (direction) {
			// 降序(desc)
			return value2 - value1
	  } else {
			// 升序(asc)
			return value1 - value2
	  }
	}
	return newArr.sort(handle)
}

/**
 * 地址截取
 * @param {String} str  //地址
 * @param {String} ind  //截断位数
 */
 const _substrAddress = (str:string, ind:number) => {
	return str ? (str.substring(0,ind)+'...'+str.substring(str.length - ind)) : ''
}

/**
 * 创建数组
 * @param {Number}  size   // 数组长度
 */
const _loaderArray = (size:number) => {
	return Array.from({length: size}, (_, index) => index + 1)
}

export {
	_substrAddress,
	_getParamByUrl,
	_loaderArray,
	_numComma,
	_compare
}
