import React, { useState, useContext, useEffect } from 'react'
import { ConfigContext, ConfigDispatchContext, SwapContext, SwapDispatchContext } from '@/utils/contextStatic'
import { contractAddressList, chainCoin } from '@/utils/addressStatic'
import ScSearch from '@/components/Section/ScSearch'
import { _getCoinImgUrl } from '@/utils/urlMethod'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import { CloseSvg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import { SelectCoinStyle } from './styled'
import Text from '@/components/Text'

const PopSwapFromCoin:React.FC<RouterProps> = ({ t })=> {
  
  const swapDispatch:any = useContext(SwapDispatchContext)
  
  const configDispatch:any = useContext(ConfigDispatchContext)

  const { tokenFromList, swapFormParam } = useContext(SwapContext)
  
  const { isPC, chainSign, popShowConfig, supportChainList } = useContext(ConfigContext)
  
  const { toTokenSymbol, toNetwork, fromToken, fromTokenDecimal, fromTokenSymbol } = swapFormParam

  const [ selectChainId, setSelectChainId ] = useState<number>(chainSign)

  const [ showTokenList, setShowTokenList ] = useState<any>([]) 

  const [ inputVal, setInputVal ] = useState<string>('')

  const { swapFromCoinState } = popShowConfig

  useEffect(() => {
    setShowTokenList(tokenFromList)
  }, [tokenFromList])

  const chainHandle = (val:number) => {
    setSelectChainId(val)
  }

  // 操作 - 币种
  const coinHandle = (item:any) => {
    const { symbol, chainId, address, decimals } = item
    const fromOperateToken = chainCoin !== address ? address : contractAddressList[chainSign]['symbol']['current']
    const param:any = {
      fromOperateToken,
      fromTokenDecimal: decimals,
      fromTokenSymbol: symbol,
      fromNetwork: chainId,
      fromToken: address,
      swapAmount: ''
    }
    if(chainId === toNetwork && symbol === toTokenSymbol) {
      const exchangeTokenInfo = tokenFromList.find((item:any) => item.symbol === fromTokenSymbol)
      param['exchangeToken'] = exchangeTokenInfo['address']
      param['toToken'] = fromToken
      param['toTokenSymbol'] = fromTokenSymbol
      param['toTokenDecimal'] = fromTokenDecimal
    }
    swapDispatch({ type: 'changeForm', payload: param })
    swapDispatch({ type: 'period', payload: '1W' })
    closeHandle()
  }

  // 操作 - 关闭弹窗
  const closeHandle = () => {
    configDispatch({ type: 'changePop', payload: 'swapFromCoinState' })
    if(!isPC) configDispatch({ type: 'changeState', payload: 'h5MaskShow' })
  }

  // 更新 - Input值/币种列表
  const updateInput = (val:string) => {
    setInputVal(val)
    const cloneData = tokenFromList.slice(0)
    if(val) {
      const filterData = cloneData.filter((item:any) => item.symbol.includes(val.toUpperCase()))
      setShowTokenList(filterData)
    } else {
      setShowTokenList(cloneData)
    }
  }

  // 渲染 - 链
  const chainRender = () => {
    const list:any = []
    supportChainList.forEach((item:any, index:number) => {
      const { chain_id, chain_symbol } = item
      const isActive = selectChainId === chain_id
      if(isActive) {
        list.push(
          <div key={`sc_chain${index}`} className='chain-item' onClick={() => chainHandle(chain_id)}>
            <div className={ isActive ? 'active' : '' }>
              <img src={_getCoinImgUrl(chain_symbol, 'chain')} alt='' />
            </div>
            { isPC && <div><ScCoin kind='11' name={chain_symbol.toUpperCase()} imgType='chain' /></div> }
          </div>
        )
      }
    })
    return list
  }

  // 渲染 - 币种
  // const coinOneRender = () => {
  //   const list:any = []
  //   const coinList = ['WETH']
  //   coinList.forEach((item:any, index) => {
  //     list.push(<ScCoin key={`sc_oneCoin${index}`} kind='10' name={item} />)
  //   })
  //   return list
  // }

  // 渲染 - 币种
  const coinTwoRender = () => {
    const list:any = []
    showTokenList.forEach((item:any, index:number) => {
      let isDisable = false
      const { symbol, balance, chainId } = item
      if(chainId === toNetwork) isDisable = fromTokenSymbol === symbol
      if(chainId !== toNetwork) isDisable = (toTokenSymbol === symbol) || (fromTokenSymbol === symbol)
      list.push(
        <div key={`sc_twoCoin${index}`} className='coin-item' onClick={() => !isDisable && coinHandle(item)}>
          <div>
            <img src={_getCoinImgUrl(symbol)} alt='' />
            <Text size={isPC ? '16' : '14'} type='english' color={ isDisable ? 'gray500' : 'text_main' }>{symbol}</Text>
          </div>
          <Text size={isPC ? '16' : '14'} type='number'>{balance ? _numComma(balance, 4) : '--'}</Text>
        </div>
      )
    })
    return list
  }

  return (
    <SelectCoinStyle className={isPC ? (swapFromCoinState ? '' : 'dn') : ''} $state={!swapFromCoinState}>
      <div>
        <div className='chain-bar'>
          <i></i>
          {chainRender()}
        </div>
        <div className='coin-bar'>
          <Text kind='Bold' size='20' type='english'>Token</Text>
          <ScSearch placeholder={t('Placeholder.SearchToken1')} inputVal={inputVal} callback={(val:string) => updateInput(val)} />
          {/* <div>{coinOneRender()}</div> */}
          <div></div>
          <div>{coinTwoRender()}</div>
          <CloseSvg onClick={closeHandle} />
        </div>
      </div>
    </SelectCoinStyle>
  )
}

export default withRouter(PopSwapFromCoin)

