import React, { useState, useContext, useRef } from 'react'
import { ConfigContext, ConfigDispatchContext, SwapManageContext } from '@/utils/contextStatic'
import useBusinessSwapLiquidity from '@/hooks/useBusiness/useBusinessSwapLiquidity'
import { chainCoin, contractAddressList } from '@/utils/addressStatic'
import useContractSwapV2 from '@/hooks/useContract/useContractSwapV2'
import useBtnSwapRemoveLiq from '@/hooks/useBtn/useBtnSwapRemoveLiq'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import useBtnApprove from '@/hooks/useBtn/useBtnApprove'
import useBtnChain from '@/hooks/useBtn/useBtnChain'
import ScChain from '@/components/Section/ScChain'
import ScInput from '@/components/Section/ScInput'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import BigNumber from 'bignumber.js'
import Text from '@/components/Text'

const Form:React.FC<RouterProps> = ({ t }) => {

  let delayModule:any = useRef()

  const { _getChainProperty } = useCommon()

  const { _inPutFormat } = usePublicEthers()

  const { _getAllowanceOperate, _getLpInsideTokenAmount } = usePublicErc20()

  const { swapOperateContract } = useContractSwapV2()

  const { isPC, chainSign } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { poolInfo, lpBalance } = useContext(SwapManageContext)

  const { pool_symbol, chain_id, pair_token0, pair_token1, pair, last_balance } = poolInfo

  const currentContract = contractAddressList[chainSign]['symbol']['current']

  const [ receiveAmountA, setReceiveAmountA ] = useState<string>('-')

  const [ receiveAmountB, setReceiveAmountB ] = useState<string>('-')

  const chainName = _getChainProperty(chain_id, 'chain_name')

  // 1:授權 2:加載中 3:确认 5:钱包未链接 6:链不匹配
  const [ btnStatus, setBtnStatus ] = useState<number>(3)
  
  const [ amount, setAmount ] = useState<string>('')

  const coinArr = pool_symbol.split('/')

  // 授权操作
  const coinApproveHandle = useBtnApprove(pair, swapOperateContract, setBtnStatus)

  // 移除流动性操作
  const { _removeLiquidityTokenHandle } = useBusinessSwapLiquidity(setBtnStatus)

  // 切链操作
  const toggleChainHandle = useBtnChain(chain_id, setBtnStatus)

  // 按钮状态控制
  useBtnSwapRemoveLiq(amount, setBtnStatus)

  // 判断 - 按钮状态
  const openHandle = () => {
    if( (+lpBalance > 0) && (+amount > 0) && (+amount <= +lpBalance) ) return true
    return false
  }

  // 更新 - 授权数量
  const updateApproveHandle = async() => {
    const allowance = await _getAllowanceOperate(pair, swapOperateContract)
    const direction = new BigNumber(allowance).minus(_inPutFormat(amount ? amount : '0')).toFixed(0)
    if(+direction >= 0) {
      setBtnStatus(3)
    } else {
      setBtnStatus(1)
    }
  }

  // 展示 - 按钮信息列表
  const btnInfoList:any = {
    1: { text: t("Button.Approve"), kind: 'primary', disabled: false },
    2: { text: t("Button.Loading"), kind: 'loading', disabled: true },
    3: { text: t('Button.RemoveLiquidity'), kind: 'primary', disabled: !openHandle() },
    5: { text: t("Button.Connect"), kind: 'primary', disabled: true },
    6: { text: t('Button.SwitchChain', { chain: chainName }), kind: 'primary', disabled: false },
  }

  const { text, disabled, kind } =  btnInfoList[btnStatus]

  // 更新 - 数量
  const changeHandle = async(val:string) => {
    clearTimeout(delayModule.current)
    delayModule.current = setTimeout(async() => {
      setAmount(val)
      if(+val > 0) {
        const per = new BigNumber(val).div(last_balance).toString()
        const totalAmountA:any = await _getLpInsideTokenAmount(pair, pair_token0 === chainCoin ? currentContract : pair_token0)
        const totalAmountB:any = await _getLpInsideTokenAmount(pair, pair_token1 === chainCoin ? currentContract : pair_token1) 
        const amountA = new BigNumber(per).times(totalAmountA).toFixed(8)
        const amountB = new BigNumber(per).times(totalAmountB).toFixed(8)
        setReceiveAmountA(amountA)
        setReceiveAmountB(amountB)
      }
    }, 1000)
  }

  // 按钮处理
  const btnHandle = () => {
    switch(btnStatus) {
      // 授权
      case 1:
        coinApproveHandle(updateApproveHandle)
        break;
      // 添加
      case 3:
        _removeLiquidityTokenHandle(pair_token0, pair_token1, amount, updateApproveHandle)
        break;
      // 链接
      case 5:
        configDispatch({ type: 'changePop', payload: 'selectWallteState' })
        break;
      // 切链
      case 6:
        toggleChainHandle()
        break;
      default:
    }
  }

  // 渲染 - 接受币种列表
  const receiveRender = () => {
    const list:any = []
    const coinList = [
      { key: coinArr[0], value: receiveAmountA },  
      { key: coinArr[1], value: receiveAmountB }  
    ]
    coinList.forEach((item:any, index:number) => {
      const { key, value } = item
      list.push(
        <div key={`${key}_${index}`} className='receive-item'>
          <div>
            <Text color='gray600'>{key} {t('Field.TokenOn')}</Text>
            <ScChain kind='pop' chainSign={chainSign} />
          </div>
          <Text kind='Bold' type='number' color='gray600'>{value}</Text>
        </div>
      )
    })
    return list
  }

  return (
    <>
      <Text kind='Bold' size={isPC ? '18' : '16'}>{t('Title.RemoveLiquidity')}</Text>
      <Text color='gray500' size={isPC ? '14' : '12'}>{t('Content.RemoveLiquidityDesc')}</Text>
      <ScInput 
        type='AB'
        amount={amount} 
        balance={lpBalance} 
        symbol={pool_symbol} 
        maxvalue={lpBalance}
        changeAmount={changeHandle} />
      <div className='receive-bar'>
        <Text kind='Bold' size={isPC ? '15' : '14'} color='gray600'>{t('Field.WillReceive')}</Text>
        { receiveRender() }
      </div>
      <FormButton size={isPC ? 'large' : 'normal'} kind={kind} callback={btnHandle} disabled={disabled}>
        <Text type='specialEnglish' size='16' color='white'>{text}</Text>
      </FormButton>
    </>
  )
}

export default withRouter(Form)
