import { useContext } from 'react'
import { contractAddressList, chainCoin } from '@/utils/addressStatic'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'

/**
 * API - SwapFactoryV2
 */
export default function useContractSwapFactoryV2() {
  
  const { _getContract } = usePublicErc20()

  const { chainSign } = useContext(ConfigContext)

  // 工厂合约地址
  const swapQuoterFactoryContract = contractAddressList[chainSign]['operate']['swap_factory']

  // 本链合约地址
  const currentContract = contractAddressList[chainSign]['symbol']['current']

  /**
   * 获取 - LP合约地址
   * @param {String} tokenA       代币A
   * @param {String} tokenB       代币B
   */ 
  const _getLpContractAddress = async(tokenA:string, tokenB:string) => {
    console.log('合约【获取 - LP合约地址(入参)】:', { tokenA, tokenB })
    try {
      const tokenAddressA = chainCoin === tokenA ? currentContract : tokenA
      const tokenAddressB = chainCoin === tokenB ? currentContract : tokenB
      const pairContract = await _getContract(swapQuoterFactoryContract, 'SWAPFACTORYABI')
      const address = await pairContract['getPair'](tokenAddressA, tokenAddressB)
      console.log('lpAddress', address)
      return address
    } catch(error) {
      console.error('合约调用异常【获取 - LP合约地址】:', error)
    }
  }

  return {
    _getLpContractAddress
  }
}
