import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import ScChain from '@/components/Section/ScChain'
import { _getCoinImgUrl } from '@/utils/urlMethod'
import { RouterProps } from '@/utils/interface'
import ScImg from '@/components/Section/ScImg'
import { _numComma } from '@/utils/comMethod'
import { ListFarmsCardStyle } from './styled'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import BigNumber from 'bignumber.js'

interface ChildProps extends RouterProps {
  kind: string
  data: any
}

const ListFarmsCard:React.FC<ChildProps> = ({ t, data, kind, navigate })=> {

  const { linkList } = useCommon()

  const { isPC } = useContext(ConfigContext)

  const { chain_id, pool_symbol, pool_apy, pool_tvl, unStake_lp, token_balance, volume, pair, pool_type } = data

  const poolType =  pool_type === 'create' ? 'create' : (pool_symbol.includes('/') ? 'swap' : 'bridge')

  const poolApy = new BigNumber(pool_apy).times(100).toString()

  const coinSign = poolType === 'bridge' ? pool_symbol : pair

  const jumpHandle = () => {
    navigate(`${linkList['Manage']}/${poolType}/${chain_id}/${coinSign}`)
  }

  const filedData:any = {
    'Your': { key: 'PooledToken', value: _numComma(unStake_lp, 4) },
    'Avaliable': { key: 'WalletBalance', value: _numComma(token_balance, 4) },
    'Top': { key: 'Volume', value: _numComma(volume) },
  }

  const { key, value } = filedData[kind]

  return (
    <ListFarmsCardStyle onClick={jumpHandle}>
      <div>
        {
          poolType === 'swap' ? (
            <ScImg kind='4' symbol={pool_symbol} />
          ): (
            <img src={_getCoinImgUrl(pool_symbol)} alt='' />
          )
        }
        <ScChain kind='card' chainSign={chain_id}/>
      </div>
      <div>
        <Text color='gray500' size='12'>{t('Field.Token')}</Text>
        <Text color='gray500' size='12' type='english'>APY</Text>
      </div>
      <div>
        <Text kind='Bold' size={isPC ? '18' : '16'} type='specialEnglish'>{pool_symbol}</Text>
        <Text kind='Bold' size={isPC ? '18' : '16'} type='specialNumber'>{_numComma(poolApy)}%</Text>
      </div>
      <div>
        <Text color='gray500' size='12'>{t(`Field.${key}`)}</Text>
        <Text color='gray500' size='12'>{t('Field.Liquidity')}</Text>
      </div>
      <div>
        <Text size={isPC ? '16' : '14'} type='specialNumber'>{value}</Text>
        <Text size={isPC ? '16' : '14'} type='specialNumber'>$ {_numComma(pool_tvl)}</Text>
      </div>
    </ListFarmsCardStyle>
  )
}

export default withRouter(ListFarmsCard)

