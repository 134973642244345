import React, { useState, useContext } from 'react'
import { ConfigContext, SwapContext, SwapDispatchContext } from '@/utils/contextStatic'
import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts'
import { LinkSvg, HiddenSvg, ShowSvg } from '@/utils/icoStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import { RechartsStyle } from './styled'
import NoData from '@/components/NoData'
import Text from '@/components/Text'
import Copy from '@/components/Copy'

const Recharts:React.FC<RouterProps> = () => {

  const { isPC } = useContext(ConfigContext)

  const swapDispatch:any = useContext(SwapDispatchContext)

  const { period, chartList, swapFormParam } = useContext(SwapContext)

  const [ showChart, setShowChart ] = useState<boolean>(true) 

  const { fromTokenSymbol, toTokenSymbol } = swapFormParam

  // 操作 - 周期
  const periodHandle = (val:string) => {
    if(val !== period) swapDispatch({ type: 'period', payload: val })
  }

  // 获取 - 最新价格
  const getLatestPrice = () => {
    let price = '--'
    const size = chartList.length
    if(size > 0) {
      const latesInfo = chartList[size-1]
      price = latesInfo['uv']
    }
    return price
  }

  // 渲染 - 折线图点提示
  const CustomTooltip = (param:any) => {
    const { active, payload } = param
    if (active && payload && payload.length) {
      return (
        <div className="tip-key">
          <Text size='12' color='gray600'>{payload[0]['value']}</Text>
        </div>
      )
    }
    return null
  }

  // 渲染 - 周期
  const periodRender = () => {
    const list:any = []
    const periodList = ['12H', '1D', '3D', '1W', '1M']
    periodList.forEach((item:string, index:number) => {
      const isActive = item === period
      list.push(
        <Text 
          type='number'
          key={`period_${index}`} 
          size={isPC ? '14' : '12'}
          kind={isActive ? 'Bold' : 'Light'}
          callback={() => periodHandle(item)}
          clsName={isActive ? 'active-key' : ''} 
          color={isActive ? 'primary500' : 'gray500'}>{item}</Text>)
    })
    return list
  }

  // 渲染 - 图表
  const rechartRender = (
    <>
      <ResponsiveContainer width="100%" height={isPC ? '46%' : '56%'}>
        <AreaChart data={chartList}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0.1" stopColor="#45C581" stopOpacity={0.1}/>
              <stop offset="0.95" stopColor="white" stopOpacity={0.1}/>
            </linearGradient>
          </defs>
          <Tooltip content={<CustomTooltip />}/>
          <Area type="monotone" dataKey="uv" stroke="#45C581" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>
      <div className='time-bar'>{periodRender()}</div>
    </>
  )

  return (
    <RechartsStyle $isShow={showChart}>
      <div className='operate-bar'>
        <Text type='english' size={isPC ? '16' : '14'} color='gray600'>{ `${fromTokenSymbol}/${toTokenSymbol}`}</Text>
        <div className='tool-item'>
          <figure onClick={() => setShowChart(!showChart)}> { showChart ? <HiddenSvg /> : <ShowSvg /> }</figure>
          <Copy address={window.location.href}>
            <figure><LinkSvg /></figure>
          </Copy>
        </div>
      </div>
      <div className='val-bar'>
        <Text kind='Bold' size={isPC ? '22' : '16'} type='number'>{getLatestPrice()}</Text>
      </div>
      { chartList.length > 0 ? <>{ showChart && rechartRender }</> : <NoData /> }
    </RechartsStyle>
  )
}

export default withRouter(Recharts)
