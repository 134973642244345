import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import { LoadingPng } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import { RemoveLiqStyle } from './styled'
import Text from '@/components/Text'
import Pop from '../index'

interface ChildProps extends RouterProps {
  callback: any
  status: boolean
}

const RemoveLiq:React.FC<ChildProps> = ({ t, callback, status })=> {

  const { isPC } = useContext(ConfigContext)

  return (
    <Pop status={status} title={t('Title.RemoveLiquidity')} callback={callback}>
      <RemoveLiqStyle>
        <img src={LoadingPng} alt='' />
        <Text size={isPC ? '16' : '14'}>{t('Content.PopRemoveLiquidityDesc')}</Text>
      </RemoveLiqStyle>
    </Pop>
  )
}

export default withRouter(RemoveLiq)

