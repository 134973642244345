const swapState:any = {
  // 表单参数
  swapFormParam: {
    fromNetwork: null,
    fromOperateToken: '',
    fromToken: '',
    fromTokenSymbol: '',
    fromTokenDecimal: 18,
    exchangeToken: '',
    toNetwork: null,
    toToken: '',
    toTokenSymbol: '',
    toTokenDecimal: 18,
    swapAmount: ''
  },
  // 周期
  period: '1W',
  // 输入状态
  inputLoadingStatus: false,
  // 图标状态
  chartLoadingStatus: true,
  // 表单状态
  formLoadingStatus: true,
  // router列表
  routerList: [],
  // chart列表
  chartList: [],
  // 滑点参数
  slippageParam: {
    // 未除以100
    slippage: '0.5',
    deadline: '60'
  },
  // Token - 资产
  tokenBalance: '0',
  // TokenFrom - 列表
  tokenFromList: [],
  // TokenTo - 列表
  tokenToList: {},
  // 协议router - 信息
  protocolsRouterInfo: {
    total_amount_out: '0'
  },
  // 目标链池子信息
  swapDestPoolInfo: { last_balance: 0 },
  // 费用参数
  feeParam: {
    // swap交易费用（实际）
    swapFee: '0',
    // LP(chainLink)费用(实际)
    lpFeeAmount: '0',
    // 平台费用(实际)
    platformFeeAmount: '0',
    // 执行跨链gas费用（实际）
    estimateGas: '0'
  },
  // 数量参数
  amountParam: {
    // 实际交易数量(合约)
    totalAmountIn: '0',
    // 跨链输入数量(合约)
    totalAmountOut: '0',
    // 跨链输出数量（实际）
    receiveAmount: '0'
  }
}

const swapReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    case 'changeForm':
      updateData['swapFormParam'] = { ...updateData['swapFormParam'], ...payload }
      break;
    case 'changeSlippage':
      updateData['slippageParam'] = { ...updateData['slippageParam'], ...payload }
      break;
    case 'changeReset':
      updateData['routerList'] = []
      updateData['protocolsRouterInfo'] = { total_amount_out: '0' }
      updateData['feeParam'] = { swapFee: '0', lpFeeAmount: '0', platformFeeAmount: '0', estimateGas: '0' }
      updateData['amountParam'] = { totalAmountIn: '0', totalAmountOut: '0', receiveAmount: '0' }
      break;
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  swapReducer,
  swapState
}