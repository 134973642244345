import { useEffect, useContext } from 'react'
import useContractPool from '@/hooks/useContract/useContractPool'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'
import { getPoolTokenInfo } from '@/service'

/**
 * 初始化 - BridgeManage
 */

export default function useInitBridgeManage(bridgeManageData:any, bridgeManageDispatch:any, params:any) {

  const { poolInfo } = bridgeManageData

  const { chain_id, token_symbol } = params

  const { _getPoolBalance } = useContractPool()

  const {  _getBalacneOperate } = usePublicErc20()

  const { updateConfig, account, chainId } = useContext(ConfigContext)

  const { UPDATE_POOL_TOKEN } = updateConfig

  // 获取 - 目标链单币池子信息
  const requestPoolTokenInfo = async() => {
    bridgeManageDispatch({ type: 'loadingStatus', payload: true })
    getPoolTokenInfo({ chain_id, token_symbol }).then((res: any) => {
      console.log('interface - 目标链单币池子信息【报文】:', res)
      bridgeManageDispatch({ type: 'poolInfo', payload: res })
      bridgeManageDispatch({ type: 'loadingStatus', payload: false })
    }).catch(() => {
      console.log('interface - 目标链单币池子信息【异常】')
    })
  }

  useEffect(() => {
    // 获取 - 目标链单币池子信息
    requestPoolTokenInfo()
  }, [chain_id, token_symbol, UPDATE_POOL_TOKEN])

  // 获取 - 可质押币种资产/可移除的的流动性
  useEffect(() => {
    const getBalance = async() => {
      const { staking_token, pool_contract } = poolInfo
      // 可质押币种资产
      const tokenBalance:any = await _getBalacneOperate(staking_token)
      bridgeManageDispatch({ type: 'tokenBalance', payload: tokenBalance })
      // 可移除的的流动性
      const removeBalance:any = await _getPoolBalance(pool_contract)
      bridgeManageDispatch({ type: 'lpBalance', payload: removeBalance })
    }
    if(account && +chainId === +chain_id && Object.keys(poolInfo).length) {
      getBalance()
    } else {
      bridgeManageDispatch({ type: 'tokenBalance', payload: '0' })
      bridgeManageDispatch({ type: 'lpBalance', payload: '0' })
    }
  }, [account, chainId, poolInfo])
}
