import React from 'react'
import { InterimFarmsBar, SkeletonLabelStyle } from './styled'


const InterimFarmsCard:React.FC = ()=> {

  return (
		<InterimFarmsBar>
			<td><SkeletonLabelStyle active /></td>
			<td><SkeletonLabelStyle active /></td>
			<td><SkeletonLabelStyle active /></td>
			<td><SkeletonLabelStyle active /></td>
			<td><SkeletonLabelStyle active /></td>
			<td><SkeletonLabelStyle active /></td>
			<td><SkeletonLabelStyle active /></td>
		</InterimFarmsBar>
  )
}

export default InterimFarmsCard
