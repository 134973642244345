import React, { useContext, useEffect, useState } from 'react'
import { ConfigContext, ConfigDispatchContext, PoolCreateContext, PoolCreateDispatchContext } from '@/utils/contextStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import ScSearch from '@/components/Section/ScSearch'
import ScCoin from '@/components/Section/ScCoin'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import { PopCreateCoinStyle } from './styled'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import Text from '@/components/Text'
import Pop from '../index'

interface ChildProps extends RouterProps {
  callback: any
  status: boolean
}

const PopCreateCoin:React.FC<ChildProps> = ({ t, callback, status })=> {

  const { _checkerErc20Fun } = usePublicEthers()

  const { isPC, chainSign } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { _getBalacneOperate, _getContract } = usePublicErc20()

  const poolCreateDispatch:any = useContext(PoolCreateDispatchContext)

  const { tokenList, createFormParam, tokenType, balanceState } = useContext(PoolCreateContext)

  const { tokenSymbolA, tokenSymbolB, tokenDecimalA, tokenDecimalB, tokenAddressA, tokenAddressB, selectNetwork } = createFormParam

  const [ coinData, setCoinData ] = useState<any>(tokenList)

  const [ coinRender, setCoinRender ] = useState<any>(null)

  const [ searchKey, setSearchKey ] = useState<string>('')

  useEffect(() => {
    getCoinList()
  }, [coinData, searchKey])

  useEffect(() => {
    const initHandle = async() => {
      const cloneData = tokenList.slice(0)
      for(const item of cloneData) {
        const tokenBalance:any = await _getBalacneOperate(item['address'])
        item['balance'] = tokenBalance
      }
      setCoinData(cloneData)
      poolCreateDispatch({ type: 'balanceState', payload: false })
      poolCreateDispatch({ type: 'tokenList', payload: cloneData })
    }
    const isMatch = chainSign === selectNetwork
    if(isMatch && balanceState) initHandle()
  }, [balanceState])

  // 操作 - 选择币种
  const selectHandle = (item:any) => {
    const { symbol, decimals, address } = item
    const param:any = { tokenAmountA: '', tokenAmountB: '' }
    if(tokenType === 'A') {
      param['tokenAddressA'] = address
      param['tokenDecimalA'] = decimals
      param['tokenSymbolA'] = symbol
      if(symbol === tokenSymbolB) {
        param['tokenAddressB'] = tokenAddressA
        param['tokenDecimalB'] = tokenDecimalA
        param['tokenSymbolB'] = tokenSymbolA
      }
    } 
    if(tokenType === 'B') {
      param['tokenAddressB'] = address
      param['tokenDecimalB'] = decimals
      param['tokenSymbolB'] = symbol
      if(symbol === tokenSymbolA) {
        param['tokenAddressA'] = tokenAddressB
        param['tokenDecimalA'] = tokenDecimalB
        param['tokenSymbolA'] = tokenSymbolB
      }
    }
    poolCreateDispatch({ type: 'changeForm', payload: param })
    callback()
  }

  // 操作 - 查询币种
  const searchHandle = (sign:string) => {
    setSearchKey(sign)
  }

  // 操作 - 弹框
  const showPopHandle = (item:any) => {
    poolCreateDispatch({ type: 'importTokenInfo', payload: item })
    configDispatch({ type: 'changePop', payload: 'importHintState' })
  }
 
  // 获取 - 币种列表
  const getCoinList = async() => {
    const list:any = []
    coinData.forEach((item:any, index:number) => {
      const { symbol, address, balance } = item
      const isDisable = (tokenType === 'A' ? tokenSymbolA : tokenSymbolB) === symbol
      const color = isDisable ? 'gray500' : 'text_main'
      const checkSymbol = symbol.includes(searchKey.toUpperCase())
      const checkAddress = address.toLowerCase() === searchKey.toLowerCase()
      if(checkAddress || checkSymbol) {
        list.push(
          <div key={`createTokenACoin_${index}`} className='coin-item' onClick={() => !isDisable && selectHandle(item)}>
            <ScCoin kind={isPC ? '14' : '15'} name={symbol} color={color}/>
            <Text type='number' size={isPC ? '16' : '14'}>{_numComma(balance, 4)}</Text>
          </div>
        )
      }
    })
    const isExist = (searchKey && searchKey.length === 42) ? await _checkerErc20Fun(searchKey) : null
    if(list.length === 0 && isExist) {
      const tokenContract = await _getContract(searchKey)
      const importSymbol = await tokenContract.symbol()
      const tokenInfo = { address: searchKey, symbol: importSymbol, type: 'A' }
      list.push(
        <div key={`${chainSign}_${importSymbol}`} className='coin-item'>
          <ScCoin kind={isPC ? '14' : '15'} name={importSymbol} />
          <FormButton size='small' callback={() => showPopHandle(tokenInfo)}>
            <Text type='specialEnglish' color='white'>{t('Button.Import')}</Text>
          </FormButton>
        </div>
      )
    }
    setCoinRender(list)
  }

  return (
    <Pop status={status} title={t('Title.Token')} callback={callback}>
      <PopCreateCoinStyle>
        <ScSearch placeholder={t('Placeholder.SearchToken2')} inputVal={searchKey} callback={(val:string) => searchHandle(val)} />
        <div className='coin-bar'>{coinRender}</div>
      </PopCreateCoinStyle>
    </Pop>
  )
}

export default withRouter(PopCreateCoin)

