import React from 'react'
import ScChain from '@/components/Section/ScChain'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import ScImg from '@/components/Section/ScImg'
import { _numComma } from '@/utils/comMethod'
import { ListFarmsBarStyle } from './styled'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import BigNumber from 'bignumber.js'

interface ChildProps extends RouterProps {
  kind: string
  data: any
}

const ListFarmsBar:React.FC<ChildProps> = ({ navigate, data, kind })=> {

  const { linkList } = useCommon()

  const { pool_symbol, chain_id, pool_tvl, pengding_reward, volume, fees, pool_apy, unStake_lp, stake_lp, user_earn, pair, protocol_type, pool_type } = data

  const poolType = pool_type === 'create' ? 'create' : (pool_symbol.includes('/') ? 'swap' : 'bridge')

  const poolApy = new BigNumber(pool_apy).times(100).toString()

  const coinSign = poolType === 'bridge' ? pool_symbol : pair

  const jumpHandle = () => {
    navigate(`${linkList['Manage']}/${poolType}/${chain_id}/${coinSign}`)
  }

  return (
    <ListFarmsBarStyle onClick={jumpHandle}>
      <td>
        {
          poolType === 'swap' ? (
            <div className='double-bar'>
              <ScImg symbol={pool_symbol} />
              <div>
                <Text kind='Bold' type='english'>{pool_symbol}</Text>
                <div className='version-key'>
                  <Text type='english' size='12' color='primary500'>{protocol_type}</Text>
                </div>
              </div>
            </div>
          ) : (
            <ScCoin kind='6' name={pool_symbol} />
          )
        }
      </td>
      <td><ScChain kind='bar' chainSign={chain_id} /></td>
      <td><Text type='number'>$ {_numComma(pool_tvl)}</Text></td>
      {
        kind === 'All' && (
          <>
            <td>
              <div>
                <Text type='number'>{_numComma(pengding_reward)}</Text>
                <Text type='english' color='gray500'>/ Block</Text>
              </div>
            </td>
            <td><Text type='number'>{_numComma(volume)}</Text></td>
            <td><Text type='number'>$ {_numComma(fees)}</Text></td>
          </>
        )
      }
      {
        kind === 'User' && (
          <>
            <td><Text type='number' color='gray500'>{_numComma(unStake_lp, 4)}</Text></td>
            <td><Text type='number'>{_numComma(stake_lp, 4)}</Text></td>
            <td><Text type='number'>$ {_numComma(user_earn, 4)}</Text></td>
          </>
        )
      }
      <td><Text kind='Bold' type='number' color='primary500'>{_numComma(poolApy)}%</Text></td>
    </ListFarmsBarStyle>
  )
}

export default withRouter(ListFarmsBar)

