import { rem } from '@/theme'
import styled from 'styled-components'

const ExchangeStyle = styled.div`

	background: ${({ theme }) => theme.gray50};
	border: 1px solid ${({ theme }) => theme.gray100};
	div.coin-bar {
		& > div:nth-child(2) {
			${({ theme }) => theme.align_between};
			background: ${({ theme }) => theme.white};
		}
	}
	div.chain-bar {
		${({ theme }) => theme.align_between};
		div.arrow-key { 
			${({ theme }) => theme.align_center};
			background: ${({ theme }) => theme.white};
			border-radius: 9999px;
			svg {
				transform: rotate(-90deg);
			}
		}
		div.select-key {
			& > div:nth-child(2) {
				background: ${({ theme }) => theme.white};
				box-shadow: ${({ theme }) => theme.input_bs};
				& > div:nth-child(2) {
					${({ theme }) => theme.align_between};
				}
			}
		}
	}
	div.input-bar {
		position: relative;
		& > div:nth-child(1) {
			${({ theme }) => theme.align_between};
			& > div:nth-child(2) {
				${({ theme }) => theme.align};
			}
		}
		div.error-key {
			position: absolute;
		}
	}
	& > button {
		width: 100%;
	}

	// PC
	@media screen and (min-width: 540px){
		width: 440px;
		height: fit-content;
		padding: 24px;
		border-radius: 24px;
		div.coin-bar { 
			& > div:nth-child(2) {
				height: 56px;
				padding: 0 12px;
				margin-top: 4px;
				border-radius: 8px;
				cursor: pointer;
				& > svg {
					width: 12px;
					height: 12px;
				}
			}
		}
		div.chain-bar {
			margin-top: 30px;
			div.arrow-key {
				width: 24px;
				height: 24px;
				margin-top: 21px;
				svg {
					width: 7px;
					height: 10px;
				}
			}
			div.select-key {
				width: 172px;
				div.chain-key {
					margin-top: 4px;
					padding: 8px 12px;
					border-radius: 8px;
					& > div:nth-child(2) {
						margin-top: 6px;
						cursor: pointer;
						& > svg {
							width: 12px;
							height: 12px;
						}
					}
				}
			}
		}
		div.input-bar {
			margin-top: 30px;
			& > div:nth-child(1) {
				svg {
					width: 18px;
					height: 18px;
					margin-right: 4px;
				}
			}
			div.input-moudle {
				margin-top: 4px;
				input {
					border-radius: 8px;
				}
			}
			div.error-key {
				bottom: -25px;
			}
		}
		& > button {
			margin-top: 35px;
		}
	}

	// H5
	@media screen and (max-width: 540px){
		padding: ${rem(16)}; 
		border-radius: ${rem(16)};
		div.coin-bar { 
			& > div:nth-child(2) {
				height: ${rem(48)};
				padding: 0 ${rem(10)};
				margin-top: ${rem(4)};
				border-radius: ${rem(8)};
			}
		}
		div.chain-bar {
			margin-top: ${rem(10)};
			div.arrow-key {
				width: ${rem(24)};
				height: ${rem(24)};
				margin-top: ${rem(21)};
				svg {
					width: ${rem(7)};
					height: ${rem(10)};
				}
			}
			div.select-key {
				width: ${rem(135)};
				div.chain-key {
					margin-top: ${rem(4)};
					padding: ${rem(6)} ${rem(10)};
					border-radius: ${rem(8)};
					& > div:nth-child(2) {
						margin-top: ${rem(4)};
						div.text-module {
							width: ${rem(74)};
						}
						& > svg {
							width: ${rem(12)};
							height: ${rem(12)};
						}
					}
				}
			}
		}
		div.input-bar {
			margin-top: ${rem(20)};
			& > div:nth-child(1) {
				svg {
					width: ${rem(18)};
					height: ${rem(18)};
					margin-right: ${rem(4)};
				}
			}
			div.input-moudle {
				margin-top: ${rem(4)};
				input {
					border-radius: ${rem(8)};
				}
			}
			div.error-key {
				bottom: ${rem(-25)};
			}
		}
		& > button {
			margin-top: ${rem(30)};
		}
  }
`

export {
	ExchangeStyle
}




