import { rem } from '@/theme'
import styled from 'styled-components'

const FormStyle = styled.div`

	background: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray100};
	& > div:nth-child(1) {
		text-align: center;
	}
	div.tab-bar {
		${({ theme }) => theme.align};
		background: ${({ theme }) => theme.text_bg};
		border-radius: 9999px;
		& > div {
			${({ theme }) => theme.align_center};
			flex: 1;
			border-radius: 9999px;
		}
		& > div.active {
			background: ${({ theme }) => theme.white};
		}
	}
	div.receive-bar {
		background: ${({ theme }) => theme.white};
		border: 1px solid ${({ theme }) => theme.gray100};
		& > div {
			${({ theme }) => theme.align_between};
		}
		& > div:nth-child(1) {
			background: ${({ theme }) => theme.text_bg};
			border-bottom: 1px solid ${({ theme }) => theme.gray100};
		}
	}
	& > button {
		width: 100%;
	}
	div.extract-key {
		text-align: center;
		cursor: pointer;
	}

	// PC
	@media screen and (min-width: 540px){
		height: fit-content;
		padding: 30px 36px;
		border-radius: 20px;
		div.tab-bar {
			margin-top: 16px;
			padding: 5px;
			& > div {
				height: 50px;
			}
		}
		& > div:nth-child(3) {
			margin-top: 36px;
			margin-bottom: 5px;
		}
		div.receive-bar {
			margin-top: 36px;
			border-radius: 12px;
			& > div:nth-child(1) {
				margin-bottom: 12px;
				padding: 12px 16px;
				border-radius: 12px;
			}
			div.receive-item {
				padding: 0 16px 12px;
				& > div:nth-child(1) {
					${({ theme }) => theme.align};
					div.scchain-module {
						margin-left: 6px;
					}
				}
			}
			div.last-item {
				padding: 0 16px 12px;
			}
		}
		& > button {
			margin-top: 36px;
		}
		div.extract-key {
			margin-top: 20px;
		}
	}

	// H5
	@media screen and (max-width: 540px){
		padding: ${rem(16)};
		border-radius: ${rem(16)};
		margin-bottom: ${rem(20)};
		div.tab-bar {
			margin-top: ${rem(16)};
			padding: ${rem(4)};
			& > div {
				height: ${rem(40)};
			}
		}
		& > div:nth-child(3) {
			margin-top: ${rem(16)};
		}
		div.receive-bar {
			margin-top: ${rem(16)};
			border-radius: ${rem(8)};
			& > div:nth-child(1) {
				margin-bottom: ${rem(12)};
				padding: ${rem(12)} ${rem(16)};
				border-radius: ${rem(8)};
			}
			div.receive-item {
				padding: 0 ${rem(12)} ${rem(10)};
			}
			div.last-item {
				padding: 0 ${rem(12)} ${rem(12)};
			}
		}
		& > button {
			margin-top: ${rem(16)};
		}
		div.extract-key {
			margin-top: ${rem(16)};
		}
  }
`

export {
	FormStyle
}




