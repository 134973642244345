import { rem } from '@/theme'
import styled from 'styled-components'

const InterimPoolCreateStyle = styled.div`

	${({ theme }) => theme.align_center};
	background: ${({ theme }) => theme.gray50};
	border: 1px solid ${({ theme }) => theme.gray100};
	div.text-module {
		position: relative;
	}

	// PC
	@media screen and (min-width: 540px) { 
		width: 540px;
		height: 653px;
		padding: 20px;
		border-radius: 24px;
		div.text-module {
			margin-top: 5px;
			left: -30px;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		height: ${rem(653)};
		padding: ${rem(16)};
		margin-top: ${rem(16)};
		border-radius: ${rem(16)};
		& > div {
			div.text-module {
				margin-top: ${rem(5)};
				left: ${rem(-25)};
			}
		}
	}
`

export {
	InterimPoolCreateStyle,
}