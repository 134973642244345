import { rem } from '@/theme'
import styled from 'styled-components'

const FormStyle = styled.div`

	background: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray100};
	& > div:nth-child(1) {
		text-align: center;
	}
	div.dropdown-module {
		width: 100%;
	}
	div.input-bar {
		position: relative;
		& > svg {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
	div.field-bar {
		${({ theme }) => theme.align_between};
		border: 1px solid ${({ theme }) => theme.gray200};
	}
	& > button {
		width: 100%;
	}
	
	// PC
	@media screen and (min-width: 540px){
		width: 540px;
		height: fit-content;
		padding: 20px 30px;
		border-radius: 24px;
		div.name-key {
			margin-top: 20px;
		}
		div.dropdown-module {
			height: 56px;
			margin-top: 10px;
		}
		div.input-bar {
			& > svg {
				width: 36px;
				height: 36px;
			}
		}
		div.field-bar {
			margin-top: 20px;
			padding: 12px 16px;
    	border-radius: 12px;
			div.text-module {
				padding: 2px 0;
			}
		}
		& > button {
			margin-top: 20px;
		}
	}

	// H5
	@media screen and (max-width: 540px){
		padding: ${rem(16)};
		border-radius: ${rem(16)};
		margin-bottom: ${rem(16)};
		div.name-key {
			margin-top: ${rem(16)};
		}
		div.dropdown-module {
			height: ${rem(48)};
			margin-top: ${rem(10)};
		}
		div.input-bar {
			& > svg {
				width: ${rem(30)};
				height: ${rem(30)};
			}
		}
		div.field-bar {
			margin-top: ${rem(16)};
			padding: ${rem(12)} ${rem(16)};
    	border-radius: ${rem(12)};
			div.text-module {
				padding: ${rem(2)} 0;
			}
		}
		& > button {
			margin-top: ${rem(16)};
		}
  }
`

export {
	FormStyle
}




