import React from 'react'
import { SkeletonChainStyle, InterimCardStyle, SkeletonLabelStyle, SkeletonValueStyle, SkeletonIconStyle } from './styled'


const InterimFarmsCard:React.FC = ()=> {

  return (
		<InterimCardStyle>
			<div>
				<SkeletonIconStyle avatar title={false} paragraph={{ rows: 0 }}/> 
				<SkeletonChainStyle />
			</div>
			<div>
				<SkeletonLabelStyle active />
				<SkeletonLabelStyle active />
			</div>
			<div>
				<SkeletonValueStyle active />
				<SkeletonValueStyle active />
			</div>
			<div>
				<SkeletonLabelStyle active />
				<SkeletonLabelStyle active />
			</div>
			<div>
				<SkeletonValueStyle active />
				<SkeletonValueStyle active />
			</div>
		</InterimCardStyle>
  )
}

export default InterimFarmsCard
