import { Colors } from './styled'

const scChainList:any = {
  11155111: { cl: '#405FD6', bg: '#E6EAFE' },
  43114: { cl: '#E61D34', bg: '#FAECEC' },
  42161: { cl: '#2E384C', bg: '#E6E8EE' },
  80001: { cl: '#405FD6', bg: '#E6EAFE' },
  8453: { cl: '#0052FF', bg: '#E0EAFE' },
  137: { cl: '#8247E5', bg: '#EEE5FD' },
  10: { cl: '#E61D34', bg: '#FBECED' },
  56: { cl: '#D9A607', bg: '#FDF1D5' },
  97: { cl: '#D9A607', bg: '#FDF1D5' },
  1: { cl: '#405FD6', bg: '#E6EAFE' },
}

const toastBgList:any = {
  "wait": "#fff",
	"submit": 'rgba(240, 247, 255, 1)',
	"success": 'rgba(241, 251, 245, 1)',
	"fail": 'rgba(255, 244, 242, 1)',
	"warning": 'rgba(255, 246, 235, 1)'
}

const colors = (isDaskMode: boolean): Colors => {
  return {
    // 主字体
    text_main: isDaskMode ? '#000' : '#000',
    // 页面 - 背景
    web_bg: '#fff',
    // 内容 - 背景
    con_bg: 'rgba(255, 255, 255, 0.90)',
    // 首页 - 背景
    home_bg: 'linear-gradient(180deg, rgba(179, 209, 255, 0.60) 0%, rgba(179, 209, 255, 0.00) 100%)', 
    // 资产 - 背景
    balance_bg: 'linear-gradient(269deg, #0F4ED1 0%, #1B3B7D 49.46%, #0F2040 100%)',
    // 文本 - 背景
    text_bg: 'rgba(235, 238, 245, 0.60)',
    // input-default - 阴影
    input_bs: '0px 6px 12px 0px rgba(75, 85, 104, 0.05)',
    // input-hover - 阴影
    input_hover_bs: '0px 0px 0px 3px rgba(55, 114, 255, 0.20)',
    // input-focus - 阴影
    input_focus_bs: '0px 0px 0px 3px rgba(55, 114, 255, 0.50)',
    // refund - 背景
    refund_bg: 'linear-gradient(268deg, #189BB8 0%, #2B97AE 44.16%, #115261 100%)',
    // pending-背景
    pending_bg: '#06C9F0',
    // button_disabled
    button_ds: 'rgba(119, 126, 144, 0.70)',
    // select - 阴影
    select_bs: '0px 0px 0px 2px rgba(62, 122, 245, 0.40)',
    // pop阴影
    pop_bd: '0px 25px 50px -12px rgba(0, 0, 0, 0.15)',
    // h5-Footer - 阴影
    h5_footer_bs: '0px -1px 2px 0px rgba(230, 234, 242, 0.60)',
    // 链 - 颜色
    sc_chain_hue: scChainList,
    // toast - 背景
    toast_bg: toastBgList,
    // tab - 背景
    tab_bg: '#1D2940',
    // tab - default
    tab_default: 'rgba(119, 126, 144, 0.50)',
    // 钱包 - 背景
    wallet_bg: 'rgba(219, 235, 255, 0.7)',
    // 地址 - 背景
    address_bg: 'rgba(219, 235, 255, 0.30)',
    // max - 背景
    max_bg: '#E3EFFF',
    // 错误提示文本
    error_text: 'rgba(255, 81, 91, 1)',

    gray50: '#F7F9FC',
    
    gray100: '#EBEEF5',

    gray200: '#E2E7F0',

    gray300: '#CED4E0',

    gray400: '#B4BBCA',

    gray500: '#777E90',

    gray600: '#4E5362',

    gray700: '#353945',

    primary100: '#DBEBFF',

    primary200: '#B3D1FF',

    primary500: '#3E7AF5',

    primary700: '#153ab3',

    white: '#fff',

    black: '#000',

    rise: '#45C581'
  }
}

export default colors