import React, { useReducer, useContext } from 'react'
import { SwapManageContext, SwapManageDispatchContext, ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import { swapManageState, swapManageReducer } from '@/reducer/swapManage'
import useInitSwapManage from '@/hooks/useInit/useInitSwapManage'
import InterimManage from '@/components/Interim/InterimManage'
import PopRemoveLiq from '@/components/Pop/PopRemoveLiq'
import { SwapManageStyle } from './SwapManageCss'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useInit from '@/hooks/useInit'
import Reward from './Reward'
import Info from './Info'
import Form from './Form'


const SwapManage:React.FC<RouterProps> = ({ params }) => {

  useInit('Pool')

  const { popShowConfig } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const [ swapManageData, swapManageDispatch ] = useReducer(swapManageReducer, swapManageState)

  useInitSwapManage(swapManageData, swapManageDispatch, params)
  
  const { loadingStatus } = swapManageData

  const { removeLiqState } = popShowConfig

  // 操作 - 弹框
  const showPopHandle = (kind:string) => {
    configDispatch({ type: 'changePop', payload: kind })
  }

  return (
    <SwapManageStyle>
      <SwapManageContext.Provider value={swapManageData}>
        <SwapManageDispatchContext.Provider value={swapManageDispatch}>
          <div className='bg-bar'></div>
            <main>
              <Info />
              { loadingStatus ? <InterimManage /> : (
                <div>
                  <Form />
                  <Reward />
                </div>
              ) }
            </main>
          { removeLiqState && <PopRemoveLiq callback={() => showPopHandle('removeLiqState')} status={removeLiqState} /> }
        </SwapManageDispatchContext.Provider>
      </SwapManageContext.Provider>
    </SwapManageStyle>
  )
}

export default withRouter(SwapManage)
