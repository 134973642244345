import React, { useContext } from 'react'
import { ConfigContext, SwapManageContext } from '@/utils/contextStatic'
import ScCoin from '@/components/Section/ScCoin'
import ScLink from '@/components/Section/ScLink'
import { RouterProps } from '@/utils/interface'
import ScImg from '@/components/Section/ScImg'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import { RewardStyle } from './styled'
import Text from '@/components/Text'

const Reward:React.FC<RouterProps> = ({ t }) => {

  const { isPC } = useContext(ConfigContext)

  const { _getChainProperty, _getExchangePrice } = useCommon()
  
  const { lpBalance, poolInfo, tokenBalanceA, tokenBalanceB } = useContext(SwapManageContext)

  const { pool_symbol, chain_id, pair, pair_token0, pair_token1, pool_apy, last_balance } = poolInfo

  const chainSymbol = _getChainProperty(chain_id, 'chain_symbol')

  const chainName = _getChainProperty(chain_id, 'chain_name')

  const coinArr = pool_symbol.split('/')

  // 渲染 - Position
  const positionRender = () => {
    const list:any = []
    const positionList:any = {
      'PoolPosition': [
        { coinName: pool_symbol, coinAmount: last_balance, explain: 'TotalLiquidity', isLp: true },
      ],
      'MyPosition': [
        { coinName: pool_symbol, coinAmount: lpBalance, explain: 'MyLiquidity', isLp: true },
        { coinName: coinArr[0], coinAmount: tokenBalanceA, explain: 'AddableLiquidity', isLp: false },
        { coinName: coinArr[1], coinAmount: tokenBalanceB, explain: 'AddableLiquidity', isLp: false }
      ],
    }
    Object.keys(positionList).forEach((key:any) => {
      list.push(<Text key={`position_${key}`} clsName='title-item' kind='Bold' size={isPC ? '20' : '16'}>{ t(`Title.${key}`) }</Text>)
      positionList[key].forEach((item:any, index:number) => {
        const { coinName, coinAmount, explain, isLp } = item
        list.push(
          <div key={`position_${explain}_${index}`} className='position-item'>
            <div>
              {
                isLp ? (
                  <div className='double-key'>
                    <ScImg kind='4' symbol={coinName} isLp={true} />
                    <Text size={isPC ? '20' : '16'} type='specialEnglish'>{coinName}</Text>
                  </div>
                ) : (
                  <ScCoin isSpecial={true} kind={isPC ? '16' : '17'} content={coinName} name={coinName} isLp={isLp} />
                )
              }
              <Text kind='Bold' size={isPC ? '20' : '16'} type='specialNumber'>{_numComma(coinAmount, 4)}</Text>
            </div>
            <div>
              <Text size={isPC ? '13' : '12'} type='english' color='gray500'>{t(`Field.${explain}`)}</Text>
              <Text size={isPC ? '13' : '12'} type='number' color='gray500'>~ $ {_getExchangePrice(coinName, coinAmount)}</Text>
            </div>
          </div>
        )
      })
    })
    return list
  }

  return (
    <RewardStyle>
      <div>{positionRender()}</div>
      <div>
        <Text kind='Bold' size={isPC ? '20' : '16'}>{ `${pool_symbol} Pool Info` }</Text>
        <div>
          <Text size={isPC ? '16' : '14'} type='english'>Chain</Text>
          <ScCoin kind={isPC ? '12' : '8'} content={chainName} name={chainSymbol} imgType='chain' />
        </div>
        <div>
          <Text size={isPC ? '16' : '14'} type='english'>APY</Text>
          <Text kind='Bold' size={isPC ? '16' : '14'} type='number'>{_numComma(pool_apy*100)}%</Text>
        </div>
        <div>
          <Text size={isPC ? '16' : '14'} type='english'>{coinArr[0]} {t('Field.Contract')}</Text>
          <ScLink address={pair_token0} query='token' />
        </div>
        <div>
          <Text size={isPC ? '16' : '14'} type='english'>{coinArr[1]} {t('Field.Contract')}</Text>
          <ScLink address={pair_token1} query='token' />
        </div>
        <div>
          <Text size={isPC ? '16' : '14'} type='english'>{pool_symbol} {t('Field.Contract')}</Text>
          <ScLink address={pair} query='token' />
        </div>
      </div>
    </RewardStyle>
  )
}

export default withRouter(Reward)
