import { rem } from '@/theme'
import styled from 'styled-components'

const HeaderStyle = styled.header`

  z-index: 2;
  ${({ theme }) => theme.align_between};
  & > div {
    ${({ theme }) => theme.align};
  }

  // PC
	@media screen and (min-width: 540px){
    position: relative;
    padding: 16px 50px;
    div.left-bar {
      svg {
        margin-right: 50px;
      }
    }
    div.right-bar {
      button {
        margin-right: 12px;
        background: ${({ theme }) => theme.pending_bg};
      }
    }
    // 540 - 1000
    @media screen and (max-width: 1000px){
      min-width: 1000px;
    }
	}

  // H5
	@media screen and (max-width: 540px){
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: ${rem(56)};
    padding: 0 ${rem(16)};
    background: ${({ theme }) => theme.white};
  }
`

export {
  	HeaderStyle
}