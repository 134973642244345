import { Tooltip } from 'antd'
import React, { useContext } from 'react'
import { ConfigContext, SwapContext } from '@/utils/contextStatic'
import InterimRecord from '@/components/Interim/InterimRecord'
import ListRecord from '@/components/Listing/ListRecord'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import { TipSvg } from '@/utils/icoStatic'
import NoData from '@/components/NoData'
import { RecordStyle } from './styled'
import Text from '@/components/Text'


const Record:React.FC<RouterProps> = ({ t }) => {

  const { isPC } = useContext(ConfigContext)

  const { inputLoadingStatus, routerList, swapFormParam } = useContext(SwapContext)

   const { toTokenSymbol, fromTokenSymbol } = swapFormParam

  // 渲染 - 过度
  const interimRender = () => {
    const size = 5
    const list:any = []
    for(let i=0; i < size; i++) {
      list.push(<InterimRecord key={`interim_record_${i}`}/>)
    }
    return list
  }

  // 渲染 - 列表
  const recordListRender = () => {
    const list:any = []
    routerList.forEach((item:any, index:number) => {
      list.push(<ListRecord key={`list_record_${index}`} data={item} />)
    })
    return list
  }

  return (
    <RecordStyle>
      <table>
        <colgroup >
          {
            isPC ? (
              <>
                <col width='32%' />
                <col width='28%' />
                <col width='20%' />
                <col width='20%' />
              </>
            ) : (
              <>
                <col width='70%' />
                <col width='30%' />
              </>
            )
          }
        </colgroup >
        <thead>
          <tr>
            <th><Text size='12' color='gray500'>{t('Field.Exchange')}</Text></th>
            { isPC && <th><Text size='12' color='gray500'>{t('Field.Price')}({fromTokenSymbol}/{toTokenSymbol})</Text></th> }
            <th><Text size='12' color='gray500'>{t('Field.Earn')}</Text></th>
            { isPC && (
              <th>
                <Text size='12' color='gray500'>{t('Field.Fees')}</Text>
                <Tooltip placement="top" title={<Text size='12' color='white'>{t('Tooltip.FeesTip')}</Text>}>
                  <TipSvg />
                </Tooltip>
              </th>
            ) }
          </tr>
        </thead>
        <tbody>
          { 
            inputLoadingStatus ? (
              <>{ interimRender() }</>
            ) : (
              <>{ recordListRender().length <= 0 ? <tr><td colSpan={isPC ? 4 : 2}><NoData /></td></tr> : recordListRender() }</>
            )
          }
        </tbody>
      </table>
    </RecordStyle>
  )
}

export default withRouter(Record)
