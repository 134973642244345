import { rem } from '@/theme'
import styled from 'styled-components'

const InterimManageStyle = styled.div`

	div.form-bar {
		${({ theme }) => theme.align_center};
		background: ${({ theme }) => theme.white};
		border: 1px solid ${({ theme }) => theme.gray100};
	}
	div.reward-bar {
		& > div {
			${({ theme }) => theme.align_center};
			background: ${({ theme }) => theme.white};
			border: 1px solid  ${({ theme }) => theme.gray100};
		}
	}
	div.text-module {
		position: relative;
	}

	// PC
	@media screen and (min-width: 540px) { 
		div.form-bar {
			height: 618px;
			padding: 30px 36px;
			margin-bottom: 100px;
			border-radius: 20px;
		}
		div.reward-bar {
			width: 506px;
			margin-left: 30px;
			& > div {
				padding: 30px 36px;
				border-radius: 20px;
			}
			& > div:nth-child(1) {
				height: 346px;
			}
			& > div:nth-child(2) {
				height: 255px;
				margin-top: 20px;
			}
		}
		div.text-module {
			margin-top: 5px;
			left: -30px;
		}
		// > 1000
		@media screen and (max-width: 1000px){
			div.form-bar {
				width: 518px;
			}
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		div.form-bar {
			height: ${rem(482)};
			padding: ${rem(16)};
			margin-bottom: ${rem(20)};
			border-radius: ${rem(16)};
		}	
		div.reward-bar {
			& > div {	
				padding: ${rem(16)};
				border-radius: ${rem(16)};
			}
			& > div:nth-child(1) {
				height: ${rem(346)};
			}
			& > div:nth-child(2) {
				height: ${rem(255)};
				margin-top: ${rem(20)};
			}
		}
		div.text-module {
			margin-top: ${rem(5)};
			left: ${rem(-25)};
		}
	}
`

export {
  InterimManageStyle
}