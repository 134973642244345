import { rem } from '@/theme'
import styled from 'styled-components'

const FarmsCardStyle = styled.div`

	// PC
	@media screen and (min-width: 540px) { 
		& > div.list-bar {
			position: relative;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
			gap: 20px;
			margin-top: 20px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		padding: ${rem(20)} 0 0  ${rem(16)};
		& > div.list-bar {
			display: flex;
			width: 100%;
			margin-top: ${rem(12)};
			overflow-x: auto;
			& > div {
				margin-right: ${rem(12)}
			}
		}
	}
`

export {
  FarmsCardStyle
}


