import { useEffect, useContext } from 'react'
import { ConfigDispatchContext } from '@/utils/contextStatic'

/**
 * 页组件初始化
 * @param {String} kind      // 类型
 */

export default function useInit(kind:string) {

  const configDispatch:any = useContext(ConfigDispatchContext)

  useEffect(() => {
    window.scrollTo(0, 0)
    configDispatch({ type: 'routerSign', payload: kind })
  }, [])
}
