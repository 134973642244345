import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigDispatchContext } from '@/utils/contextStatic'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'

/**
 * 按钮操作
 * @param {String} contractAddress  // 币种合约地址
 * @param {String} operateContract  // 执行合约地址
 * @param {Void}   handler          // 方法
 * @param {Number} btnStatus        // 状态
 */

export default function useBtnApprove(contractAddress: string, operateContract: string, handler: any, btnStatus?: number) {

  const t:any = useTranslation()['t']

  const { _setCoinApprovedOperate } = usePublicErc20()

  const configDispatch:any = useContext(ConfigDispatchContext)

  // 币种授权
  const coinApproveHandle = (callback:any) => {
    handler(2)
    let text = `${t("Button.Approve")}`
    configDispatch({ type: 'changeToast', payload: {status: true, kind: 'wait', text} })
    _setCoinApprovedOperate(contractAddress, operateContract).then(async res => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'submit', address: res.hash} })
			const approveInfo = await res.wait()
			const txId = approveInfo.transactionHash
			if(approveInfo.status === 1) {
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'success', timing: true, address: txId, text} })
        callback()
			} else {
				handler(btnStatus ? btnStatus : 1)
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text} })
			}
		}).catch((err)=>{
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text } })
      handler(btnStatus ? btnStatus : 1)
      console.log({err})
		})
  }

  return coinApproveHandle

}
