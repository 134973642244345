import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

export default function withRouter(Component:any) {
  
  return (props:any) => {

    const params = useParams()

    const navigate = useNavigate()

    const location = useLocation()

    const { t, i18n }: { t: any; i18n: any } = useTranslation()
    
    return <Component {...props} navigate={navigate} location={location} params={params} t={t} i18n={i18n} />
  
  } 
}