import { rem } from '@/theme'
import styled from 'styled-components'

const ContactStyle = styled.div`
	display: flex;
	// PC
	@media screen and (min-width: 540px){ 
		& > div:not(:last-child) {
			margin-right: 24px;
		}
		div.list-bar {
			width: 20px;
			overflow: hidden;
			cursor: pointer;
			svg {
				width: 20px;
				height: 20px;
				filter: ${({ theme }) => `drop-shadow(20px 0 ${theme.text_main})`}; 
			}
		}
		div.list-bar:hover {
			svg {
        transform: translateX(-20px);
      }
		}
	}

	// H5
	@media screen and (max-width: 540px){
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		bottom: ${rem(50)};
		& > div:not(:last-child) {
			margin-right: ${rem(60)};
		}
		div.list-bar {
			svg {
				width: ${rem(32)};
				height: ${rem(32)};
			}
		}
	}
`


export {
	ContactStyle
}




