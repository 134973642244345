import { rem } from '@/theme'
import styled from 'styled-components'

const RemoveLiqStyle = styled.div`

  text-align: center;
  svg, img {
    animation: circle infinite 0.75s linear;
  }
  @keyframes circle {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  // PC
  @media screen and (min-width: 540px) { 
    svg, img {
      width: 120px;
      height: 120px;
      margin-top: 64px;
      margin-bottom: 50px;
    }
  }

  // H5
  @media screen and (max-width: 540px) { 
    svg, img {
      width: ${rem(120)};
      height: ${rem(120)};
      margin-top: ${rem(45)};
      margin-bottom: ${rem(30)};
    }
  }

`

export {
  RemoveLiqStyle
}


