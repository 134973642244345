import React, { useState, useEffect } from 'react'
import { CloseSvg } from '@/utils/icoStatic'
import Text from '@/components/Text'
import { PopStyle } from './styled'


interface ChildProps {
  status: boolean
  callback?: any
  children: any
  title: string
}


const Pop:React.FC<ChildProps> = ({ callback, children=<></>, status=false, title="" })=> {

  const [ currentStatus, setCurrentStatus ] = useState<boolean>(false)
  
  useEffect(() => {
    setCurrentStatus(status)
  },[status])

  const closeHandle = () => {
    setCurrentStatus(!status)
    if(callback) callback()
  }

  return (
    <PopStyle style={currentStatus ? {} : {display: 'none'}}>
      <div className='pop'>
        <section className='title-bar'>
          <Text kind='Bold' size='18'>{title}</Text>
          <CloseSvg onClick={closeHandle} />
        </section>
        {children}
      </div>
    </PopStyle>
  )
}

export default Pop

