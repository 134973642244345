import React, { useState, useContext, useEffect } from 'react'
import { ConfigContext, ConfigDispatchContext, SwapContext, SwapDispatchContext } from '@/utils/contextStatic'
import { contractAddressList, chainCoin } from '@/utils/addressStatic'
import ScSearch from '@/components/Section/ScSearch'
import { _getCoinImgUrl } from '@/utils/urlMethod'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import { CloseSvg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import { SelectCoinStyle } from './styled'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'

const PopSwapToCoin:React.FC<RouterProps> = ({ t })=> {

  const { _getChainProperty } = useCommon()

  const swapDispatch:any = useContext(SwapDispatchContext)

  const { isPC, popShowConfig } = useContext(ConfigContext)
  
  const configDispatch:any = useContext(ConfigDispatchContext)
  
  const { swapFormParam, tokenToList, tokenFromList } = useContext(SwapContext)

  const { fromTokenSymbol, toNetwork, toTokenSymbol, toTokenDecimal, toToken } = swapFormParam

  const [ selectChainId, setSelectChainId ] = useState<number>(toNetwork)

  const [ showTokenList, setShowTokenList ] = useState<any>([]) 

  const [ inputVal, setInputVal ] = useState<string>('')
  
  const { swapToCoinState } = popShowConfig

  useEffect(() => {
    if(toNetwork) setSelectChainId(toNetwork)
  }, [swapToCoinState])

  useEffect(() => {
    if(Object.keys(tokenToList).length && selectChainId) setShowTokenList(tokenToList[selectChainId])
  }, [tokenToList, selectChainId])

  const chainHandle = (val:string) => {
    setSelectChainId(+val)
    setShowTokenList(tokenToList[val])
  }

  // 操作 - 币种
  const coinHandle = (item:any) => {
    const { symbol, chainId, address, decimals } = item
    const exchangeTokenInfo = tokenFromList.find((item:any) => item.symbol === symbol)
    const param:any = {
      exchangeToken: exchangeTokenInfo['address'],
      toTokenDecimal: decimals,
      toTokenSymbol: symbol,
      toNetwork: chainId,
      toToken: address,
      swapAmount: ''
    }
    if(chainId === toNetwork && symbol === fromTokenSymbol) {
      const fromOperateToken = chainCoin !== toToken ? toToken : contractAddressList[toNetwork]['symbol']['current']
      param['fromToken'] = toToken
      param['fromTokenSymbol'] = toTokenSymbol
      param['fromTokenDecimal'] = toTokenDecimal
      param['fromOperateToken'] = fromOperateToken
    }
    swapDispatch({ type: 'changeForm', payload: param })
    swapDispatch({ type: 'period', payload: '1W' })
    closeHandle()
  }

  // 操作 - 关闭弹窗
  const closeHandle = () => {
    configDispatch({ type: 'changePop', payload: 'swapToCoinState' })
    if(!isPC) configDispatch({ type: 'changeState', payload: 'h5MaskShow' })
  }

  // 更新 - Input值/币种列表
  const updateInput = (val:string) => {
    setInputVal(val)
    const cloneData = tokenToList[selectChainId].slice(0)
    if(val) {
      const filterData = cloneData.filter((item:any) => item.symbol.includes(val.toUpperCase()))
      setShowTokenList(filterData)
    } else {
      setShowTokenList(cloneData)
    }
  }

  // 渲染 - 链
  const chainRender = () => {
    const list:any = []
    Object.keys(tokenToList).forEach((item:string, index:number) => {
      const isActive = +selectChainId === +item
      const chainSymbol = _getChainProperty(item, 'chain_symbol')
      list.push(
        <div key={`sc_chain${index}`} className='chain-item' onClick={() => chainHandle(item)}>
          <div className={ isActive ? 'active' : '' }>
            <img src={_getCoinImgUrl(chainSymbol, 'chain')} alt='' />
          </div>
          { isPC && <div><ScCoin kind='11' name={chainSymbol.toUpperCase()} imgType='chain' /></div> }
        </div>
      )
    })
    return list
  }

  // 渲染 - 币种
  // const coinOneRender = () => {
  //   const list:any = []
  //   const coinList = ['WETH']
  //   coinList.forEach((item:any, index) => {
  //     list.push(<ScCoin key={`sc_oneCoin${index}`} kind='10' name={item} />)
  //   })
  //   return list
  // }

  // 渲染 - 币种
  const coinTwoRender = () => {
    const list:any = []
    showTokenList.forEach((item:any, index:number) => {
      let isDisable = false
      const { symbol, balance, chainId } = item
      if(chainId === toNetwork) isDisable = toTokenSymbol === symbol
      if(chainId !== toNetwork) isDisable = fromTokenSymbol === symbol
      list.push(
        <div key={`sc_twoCoin${index}`} className='coin-item' onClick={() => !isDisable && coinHandle(item)}>
          <div>
            <img src={_getCoinImgUrl(symbol)} alt='' />
            <Text size={isPC ? '16' : '14'} type='english' color={ isDisable ? 'gray500' : 'text_main' }>{symbol}</Text>
          </div>
          <Text size={isPC ? '16' : '14'} type='number'>{balance ? _numComma(balance, 4) : '--'}</Text>
        </div>
      )
    })
    return list
  }

  return (
    <SelectCoinStyle className={isPC ? (swapToCoinState ? '' : 'dn') : ''} $state={!swapToCoinState}>
      <div>
        <div className='chain-bar'>
          <i></i>
          {chainRender()}
        </div>
        <div className='coin-bar'>
          <Text kind='Bold' size='20' type='english'>Token</Text>
          <ScSearch placeholder={t('Placeholder.SearchToken1')} inputVal={inputVal} callback={(val:string) => updateInput(val)} />
          {/* <div>{coinOneRender()}</div> */}
          <div></div>
          <div>{coinTwoRender()}</div>
          <CloseSvg onClick={closeHandle} />
        </div>
      </div>
    </SelectCoinStyle>
  )
}

export default withRouter(PopSwapToCoin)

