import { ethers } from "ethers"
import { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { chainCoin, maxUint256 } from '@/utils/addressStatic'
import SWAPFACTORYABI from '@/contract/swap_factory.json'
import SWAPQUOTERABI from '@/contract/swap_quoter.json'
import BRIDGEFEEABI from '@/contract/bridge_fee.json'
import SWAPPAIRABI from '@/contract/swap_pair.json'
import usePublicEthers from './usePublicEthers'
import BRIDGEABI from '@/contract/bridge.json'
import ERC20ABI from '@/contract/erc20.json'
import POOLABI from '@/contract/pool.json'
import SWAPABI from '@/contract/swap.json'

/**
 * Erc20基础方法
 */
export default function usePublicErc20() {

  const { account, chainName } = useContext(ConfigContext)

  const { _getSigner, _outPutFormat, _getBrowserProvider } = usePublicEthers()
  
  /**
   * 实例化合约
   * @param {String}  contractAddr    合约地址
   * @param {Boolean} type            合约类型 
   * @param {String}  kind            provide类型: 1:rpc, 2:浏览器 
   */ 
  const _getContract = async (contractAddr:string, type='ERC20ABI') => {
    const provider = await _getSigner()
    let contractList:any = {
      SWAPFACTORYABI,
      SWAPQUOTERABI,
      BRIDGEFEEABI,
      SWAPPAIRABI,
      BRIDGEABI,
      ERC20ABI,
      POOLABI,
      SWAPABI
    }
    return new ethers.Contract(contractAddr, contractList[type], provider)
  }

  /**
   * 获取币种精度
   * @param {String} tokenAddress    代币地址
   */ 
  const _getDecimals = async(tokenAddress:string) => {
    // console.log('获取币种精度(入参):', { tokenAddress })
    try {
      const tokenContract = await _getContract(tokenAddress)
      return tokenContract.decimals()
    } catch(error) {
      console.error('合约调用异常(获取币种精度):', error)
    }
  }

  /**
   * 获取币种名称
   * @param {String} tokenAddress    代币地址
   */ 
  const _getCoinName = async(tokenAddress:string) => {
    console.log('获取币种名称(入参):', { tokenAddress })
    try {
      const tokenContract = await _getContract(tokenAddress)
      return tokenContract.symbol()
    } catch(error) {
      console.error('合约调用异常(获取币种名称):', error)
    }
  }

  /**
   * 获取用户单一币种资产
   * @param {String} tokenAddress    代币地址
   */ 
  const _getBalacneOperate = async(tokenAddress=chainCoin) => {
    console.log('获取用户单一币种资产(入参):', { account, tokenAddress })
    try {
      let decimals = 18 
      let balance:any = '0'
      if(tokenAddress !== chainCoin) {
        const tokenContract = await _getContract(tokenAddress)
        balance = await tokenContract['balanceOf'](account)
        decimals = await _getDecimals(tokenAddress)
      } else {
        const provider = await _getBrowserProvider()
        balance = await provider.getBalance(account)
      }
      return _outPutFormat(balance, decimals)
    } catch(error) {
      console.error('合约调用异常(获取用户单一币种资产):', error)
    }
  }

  /**
   * 获取LP里面某一token总量
   * @param {String} lpAddress       池子地址
   * @param {String} tokenAddress    代币地址
   */ 
   const _getLpInsideTokenAmount = async(lpAddress:string, tokenAddress:string) => {
    console.log('合约【获取 - LP里面某一token总量(入参)】:', { lpAddress, tokenAddress })
    try {
      let decimals = await _getDecimals(tokenAddress) 
      const tokenContract = await _getContract(tokenAddress)
      const balance = await tokenContract['balanceOf'](lpAddress)
      return _outPutFormat(balance, decimals)
    } catch(error) {
      console.error('合约调用异常【获取 - SWAP_V2_ERC20_ERC20流动性】:', error)
    }
  }

  /** 
   * 保存授权状态(本地)
   * @param {String} tokenAddress     代币地址
   * @param {String} contractAddr     合约地址
   */
  const _setLocalAllowance = (tokenAddress:string, contractAddr:string, allowance=maxUint256) => { 
    console.log('保存授权状态(本地)参数:', {tokenAddress, contractAddr})
    try {
      sessionStorage.setItem(`Allowanc-${chainName}-${tokenAddress.slice(0, 10)}${account.slice(0, 10)}${contractAddr.slice(0, 10)}`, allowance ? allowance : '998')
    } catch (error) {
      console.log('保存授权状态(本地)异常:', error)
    }
  }

  /** 
   * 查询币种授权状态(合约)
   * @param {String} tokenAddress     代币地址
   * @param {String} contractAddr     合约地址
   * @param {Void}   handle           执行方法
   */
  const _getAllowanceOperate = async(tokenAddress:string, contractAddr:string) => {
    console.log('查询币种授权状态入参:', {tokenAddress, contractAddr})
    try {
      const tokenContract = await _getContract(tokenAddress)
      let allowance = await tokenContract['allowance'](account, contractAddr)
      allowance = allowance.toString()
      _setLocalAllowance(tokenAddress, contractAddr, allowance)
      return allowance
    } catch(error) {
      console.error('查询币种授权状态异常:', error)
    }
  }

  /** 
   * 获取授权状态(本地)
   * @param {String} tokenAddress     代币地址
   * @param {String} contractAddr     合约地址
   */
  const _getLocalAllowance = (tokenAddress:string, contractAddr:string) => { 
    console.log('获取授权状态(本地)参数:', {tokenAddress, contractAddr})
    try {
      return sessionStorage.getItem(`Allowanc-${chainName}-${tokenAddress.slice(0, 10)}${account.slice(0, 10)}${contractAddr.slice(0, 10)}`)
    } catch (error) {
      console.log('获取授权状态(本地)异常:', error)
    }
  }

  /** 
   * 获取 - 授权额度（合集）
   * @param {String} tokenAddress     代币地址
   * @param {String} contractAddr     操作地址
   */
  const _getApproveAmount = async(tokenAddress:string, contractAddr:string) => {
    console.log('合约【获取 - 授权额度（入参）】:', {tokenAddress, contractAddr})
    try {
      const allowance:any = _getLocalAllowance(tokenAddress, contractAddr)
      if(!allowance) return await _getAllowanceOperate(tokenAddress, contractAddr)
      return allowance
    } catch (error) {
      console.log('合约调用异常【获取 - 授权额度】:', error)
    }
  }

  /** 
   * 设置币种授权
   * @param {String} tokenAddress     代币地址
   * @param {String} contractAddr     合约地址
   */
  const _setCoinApprovedOperate = async(tokenAddress:string, contractAddr:string) => {
    console.log('设置币种授权(入参):', { tokenAddress, contractAddr })
    try {
      const tokenContract = await _getContract(tokenAddress)
      return tokenContract['approve'](contractAddr, maxUint256)
    } catch(error) {
      console.error('合约调用异常(设置币种授权):', error)
    }
  }

  return {
    _setCoinApprovedOperate,
    _getLpInsideTokenAmount,
    _getAllowanceOperate,
    _getBalacneOperate,
    _getLocalAllowance,
    _getApproveAmount,
    _getContract,
    _getDecimals,
    _getCoinName
  }
}
