import { rem } from '@/theme'
import styled from 'styled-components'

const NoDataStyle = styled.div`
	${({ theme }) => theme.align_center};
	text-align: center;
	width: 100%;
	@media screen and (min-width: 540px){
		min-height: 300px;
		figure {
			svg {
				width: 120px;
				height: 106px;
			}
			div {
				margin-top: 10px;
			}
		}
	}
	@media screen and (max-width: 540px){
		min-height: ${rem(150)};
		figure {
			font-size: 0;
			svg {
				width: ${rem(120)};
				height: ${rem(106)};
			}
			div {
				margin-top: ${rem(10)};
			}
		}
	}
`

export {
    NoDataStyle
}