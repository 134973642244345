import React, { useContext, useEffect, useState } from 'react'
import { ConfigContext, BridgeContext, BridgeDispatchContext } from '@/utils/contextStatic'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import ScSearch from '@/components/Section/ScSearch'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import { PopBridgeCoinStyle } from './styled'
import withRouter from '@/utils/withRouter'
import Text from '@/components/Text'
import Pop from '../index'

interface ChildProps extends RouterProps {
  callback: any
  status: boolean
}

const PopBridgeCoin:React.FC<ChildProps> = ({ t, callback, status })=> {

  const { isPC, chainId, chainSign, account } = useContext(ConfigContext)

  const {  _getBalacneOperate } = usePublicErc20()

  const { bridgeTokens } = useContext(BridgeContext)

  const bridgeDispatch:any = useContext(BridgeDispatchContext)

  const [ coinData, setCoinData ] = useState<any>(bridgeTokens)

  const [ searchKey, setSearchKey ] = useState<string>('')

  useEffect(() => {
    const initHandle = async() => {
      const cloneData = Object.assign({}, bridgeTokens)
      for(const item of Object.keys(cloneData)) {
        const info = cloneData[item][0]
        const { src_token } = info
        const tokenBalance:any = await _getBalacneOperate(src_token)
        info['balance'] = tokenBalance
      }
      setCoinData(cloneData)
    }
    const isMatch = chainSign === +chainId
    if(isMatch && account) initHandle()
  }, [account])

  // 操作 - 选择币种
  const selectHandle = (item:any) => {
    const { src_token, src_chainid, src_token_symbol, dest_token_symbol, dest_chainid, src_token_decimals } = item
    const param = { 
      fromTokenSymbol: src_token_symbol,
      toTokenSymbol: dest_token_symbol,
      tokenDecimal: src_token_decimals,
      fromNetwork: src_chainid,
      toNetwork: dest_chainid,
      fromToken: src_token,
      bridgeAmount: ''
    }
    bridgeDispatch({ type: 'changeForm', payload: param })
    callback()
  }

  // 操作 - 查询币种
  const searchHandle = (sign:string) => {
    setSearchKey(sign.toUpperCase())
  }

  // 渲染 - 币种
  const coinRender = () => {
    const list:any = []
    Object.keys(coinData).forEach(async(item:any, index) => {
      const { src_token_symbol, balance } = coinData[item][0]
      if(src_token_symbol.includes(searchKey)) {
        list.push(
          <div key={`bridgeCoin_${index}`} className='coin-item' onClick={() => selectHandle(bridgeTokens[item][0])}>
            <ScCoin kind={isPC ? '14' : '15'} name={src_token_symbol} />
            <Text type='number' size={isPC ? '16' : '14'}>{_numComma(balance, 4)}</Text>
          </div>
        )
      }
    })
    return list
  }

  return (
    <Pop status={status} title={t('Title.Token')} callback={callback}>
      <PopBridgeCoinStyle>
        <ScSearch placeholder={t('Placeholder.SearchToken1')} inputVal={searchKey} callback={(val:string) => searchHandle(val)} />
        <div className='coin-bar'>{coinRender()}</div>
      </PopBridgeCoinStyle>
    </Pop>
  )
}

export default withRouter(PopBridgeCoin)

