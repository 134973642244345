import { rem } from '@/theme'
import styled from 'styled-components'

const DropDownStyle = styled.div<{$status:boolean}>`

	${({ theme }) => theme.align};	
	position: relative;
	z-index: 2;
	& > div {
		width: 100%;
		height: 100%;
		${({ theme }) => theme.align_between};
		border: 1px solid ${({ theme, $status }) => $status ? theme.primary500 : theme.gray200};
		background: ${({ theme }) => theme.white};
		${({ $status, theme }) => $status && `box-shadow:${theme.input_focus_bs}`}
	}
	& > ul {
		width: 100%;
		position: absolute;
		background: ${({ theme }) => theme.white};
		box-shadow: ${({ theme }) => theme.input_bs};
	}

	// PC
	@media screen and (min-width: 540px) { 
		width: 200px;
		height: 48px;
		& > div {
			border-radius: 12px;
			padding: 0 16px;
			cursor: pointer;
			svg {
				width: 16px;
				height: 16px;
			}
		}
		& > div:hover {
			border-color: ${({ theme }) => theme.primary500};
			box-shadow: ${({ theme }) => theme.input_hover_bs};
		}
		& > ul {
			top: 60px;
			padding: 10px 0;
			border-radius: 12px;
			li {
				cursor: pointer;
				padding: 10px 16px;
			}
			li:hover {
				background: ${({ theme }) => theme.gray50};
			}
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		height: ${rem(44)};	
		& > div { 
			min-width: ${rem(138)};
			padding: 0 ${rem(16)};
			border-radius: ${rem(10)};
			svg {
				width: ${rem(16)};
				height: ${rem(16)};
				margin-left: ${rem(10)};
			}
		}
		& > ul {
			top: ${rem(56)};
			padding: ${rem(16)};
			border-radius: ${rem(10)};
			li:not(:last-child) {
				margin-bottom: ${rem(12)};
			}
		}
	}
`

export {
  DropDownStyle
}