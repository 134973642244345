import { rem } from '@/theme'
import styled from 'styled-components'

const SwapManageStyle = styled.div`

	div.bg-bar {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		background: ${({ theme }) => theme.home_bg};
	}
	main {
		position: relative;
	}

	// PC
	@media screen and (min-width: 540px){
		div.bg-bar {
			height: 530px;
		}
		main {
			z-index: 1;
			${({ theme }) => theme.mainContent};
			margin-top: 20px;
			margin-bottom: 100px;
		}
		// 540 - 1000
		@media screen and (max-width: 1000px){
			main {
				& > div:nth-child(2) {
					& > div:nth-child(1) {
						margin-bottom: 40px;
					}
				}
				& > div {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		}
		// > 1000
		@media screen and (min-width: 1001px){
			main {
				& > div:nth-child(2) {
					display: flex;
					& > div:nth-child(1) {
						flex: 1;
					}
					& > div:nth-child(2) {
						margin-left: 30px;
					}
				}
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		div.bg-bar {
			height: ${rem(530)};
		}
		main {
			z-index: 1;
			margin-top: ${rem(56)};
			margin-bottom: ${rem(90)};
			padding: 0 ${rem(16)};
		}
  }
`

export {
	SwapManageStyle
}




