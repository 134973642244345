import React, { useState, useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import RemoveLiqForm from './RemoveLiqForm'
import AddLiqForm from './AddLiqForm'
import { FormStyle } from './styled'
import Text from '@/components/Text'


const Form:React.FC<RouterProps> = ({ t }) => {

  const { isPC } = useContext(ConfigContext)

  const [ tabSign, setTabSign ] = useState<string>('Add')

  // 操作 - tab
  const tabHandle = (tabSign:string) => {
    setTabSign(tabSign)
  }

  // tab - 渲染
  const tabRender = () => {
    const list:any = []
    // const tabList = ['Add', 'Remove', 'Stake', 'Unstake']
    const tabList = ['Add', 'Remove']
    tabList.forEach((item:any, index:number) => {
      const isActive = item === tabSign
      list.push(
        <Text 
          color={isActive ? 'text_main' : 'gray400'}
          clsName={isActive ? 'active' : ''}
          callback={() => tabHandle(item)}
          size={isPC ? '16' : '15'}
          key={`MF_TAB${index}`} 
          type='specialEnglish'
          kind='Bold'>
            {t(`Tab.${item}`)}
        </Text>
      )
    })
    return list
  }

  return (
    <FormStyle>
      <Text kind='Bold' size={isPC ? '20' : '16'}>{t('Title.ManagePosition')}</Text>
      <div className='tab-bar'>{ tabRender() }</div>
      { tabSign === 'Add' && <AddLiqForm /> }
      { tabSign === 'Remove' && <RemoveLiqForm /> }
    </FormStyle>
  )
}

export default withRouter(Form)
