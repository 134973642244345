import { Tooltip } from 'antd'
import React, { useState, useContext } from 'react'
import { ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import { TipSvg, DownSvg, FreshSvg } from '@/utils/icoStatic'
import { SwapContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import Text from '@/components/Text'
import { InfoStyle } from './styled'
import BigNumber from 'bignumber.js'


const Info:React.FC<RouterProps> = ({ t }) => {

  const { updateConfig } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { amountParam, swapFormParam, feeParam, protocolsRouterInfo } = useContext(SwapContext)

  const { fromTokenSymbol, toTokenSymbol, fromNetwork, toNetwork } = swapFormParam

  const { lpFeeAmount, platformFeeAmount, estimateGas, swapFee } = feeParam

  const [ dropShow, setDropShow ] = useState<boolean>(true)

  const showPlatformFee = new BigNumber(platformFeeAmount).plus(lpFeeAmount).toString()
  
  const { totalAmountIn, receiveAmount } = amountParam

  const { UPDATE_SWAP_PROTOCOL_ROUTER } = updateConfig

  // 获取 - 价格
  const priceRender = () => {
    let showPrice = '?'
    const  { total_amount_out } = protocolsRouterInfo
    if(+total_amount_out > 0 && +totalAmountIn > 0) {
      const price = new BigNumber(total_amount_out).div(totalAmountIn).toString()
      showPrice = _numComma(price, 6)
    }
    return `1 ${fromTokenSymbol} = ${showPrice} ${toTokenSymbol}`
  }

  // 操作 - 刷新
  const freshHandle = () => {
    configDispatch({ type: 'changeUpdate', payload: { UPDATE_SWAP_PROTOCOL_ROUTER: !UPDATE_SWAP_PROTOCOL_ROUTER } })
  }

  // 操作 - 折叠
  const collapseHandle = () => {
    setDropShow(!dropShow)
  }

  // 渲染 - 字段
  const fieldRender = () => {
    const list:any = []
    const fieldList = [
      { key: 'OrderRouting', val: 'CCDex' },
      // { key: 'PriceImpact', val: '4.41%' },
      { key: 'MinimumReceived', val: `${_numComma(receiveAmount, 4)} ${toTokenSymbol}` },
    ]
    if(fromNetwork !== toNetwork) {
      fieldList.push( 
        { key: 'PlatformFee', val: `${_numComma(showPlatformFee, 4)} ${toTokenSymbol}` },
        { key: 'GasCost', val: `${_numComma(estimateGas, 4)} ${toTokenSymbol}` }
      )
    }
    fieldList.push({ key: 'SwapFee', val: `$ ${_numComma(swapFee, 4)}` })
    fieldList.forEach((item:any, index:number) => {
      const { key, val } = item
      list.push(
        <div className='field-item' key={`infoField_${index}`}>
          <Text size='12' color='gray600'>{t(`Field.${key}`)}</Text>
          <Text size='12' color='gray600' type='number'>{val}</Text>
        </div>
      )
    })
    return list
  }

  return (
    <InfoStyle className={`${dropShow ? 'active' : ''}`}>
      <div className='collapse-bar'>
        <div onClick={freshHandle}>
          <Tooltip placement="top" title={<Text size='12' color='white'>{t('Tooltip.BestPriceTip')}</Text>}>
            <TipSvg />
          </Tooltip>
          <Tooltip placement="top" title={<Text size='12' color='white' type='english'>{priceRender()}</Text>}>
            <Text size='12' color='gray600' type='english'>{priceRender()}</Text>
            <i></i>
          </Tooltip>
          <div className='fresh-key'><FreshSvg /></div>
        </div>
        <div onClick={collapseHandle}>
          {/* <TrendSvg />
          <Text size='12' color='gray600' type='number'>4.41%</Text> */}
          <DownSvg />
        </div>
      </div>
      <div className='field-bar'>{ fieldRender() }</div>
    </InfoStyle>
  )
}

export default withRouter(Info)
