import { hostname, tokenIconsDomain } from "@/utils/addressStatic"

/**
 * 获取地址集合
 * @param {String} lang   // 语言
 */
const _collectLink = (lang:string) => {
  const setlangPram = `?lang=${lang}`
  return {
    // Header - Swap
    'Swap': `/${setlangPram}`,
    // Header - Bridge
    'Bridge': `/bridge${setlangPram}`,
    // Header - Pool
    'Pool': `/pool/list${setlangPram}`,
    // Header- Faucet
    'Faucet': 'https://faucet.ccdex.finance/',
    // Header - Dao
    'Dao': `https://dao.${hostname}/#/${setlangPram}`,
    // More - News
    'News': '',
    // Pool - Manage
    'Manage': `/pool/manage`,
    // Pool - Create
    'Create': '/pool/create',
    // Footer - Cookies
    'Cookies': '',
    // Footer - Documentation
    'Documentation': '',
    // Footer - Service
    'Service': '',
    // Footer - Policy
    'Policy': '',
    // Footer - Faqs
    'Faqs': '',
    // Footer - Github
    'Github': '',
    // Footer - Discord
    'Discord': '',
    // Footer - Twitter
    'Twitter': '',
    // MetaMask钱包下载地址
    "MetaMaskDownLoad": "https://metamask.io/download/",
    // Bitkeep钱包下载地址
    "BitkeepDownLoad": "https://bitkeep.com/en/download",
    // OKX Wallet钱包下载地址
    "OkxWalletDownLoad": "https://www.okx.com/web3"
  } 
}

/**
 * 获取币种图标
 * @param {String}  name   // 币种名称/链名称
 * @param {String}  kind   // 类型 token:币种  chain:链 
 */
const _getCoinImgUrl = (name:string, kind='token') => {
  let url = ''
  if(name) url = `${tokenIconsDomain}/${kind}/${name.toUpperCase()}.png`
  return url
}

export {
  _getCoinImgUrl,
  _collectLink,
}
