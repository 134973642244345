import { useContext } from 'react'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { contractAddressList } from '@/utils/addressStatic'
import { ConfigContext } from '@/utils/contextStatic'
import useCommon from '@/hooks/useCommon'

/**
 * API - SwapV2
 */
export default function useContractSwapV2() {

  const { exchangeDeadline } = useCommon()

  const { _inPutFormat } = usePublicEthers()

  const { _getContract, _getDecimals } = usePublicErc20()

  const { account, chainSign } = useContext(ConfigContext)

  // 执行合约地址
  const swapOperateContract = contractAddressList[chainSign]['operate']['swap']

  /**
   * 添加 - ERC20-ERC20代币流动性
   * @param {String} tokenA       代币A
   * @param {String} tokenB       代币B
   * @param {String} amountA      希望存入的代币A数量
   * @param {String} amountB      希望存入的代币B数量
   * @param {String} minAmountA   最少存入的代币A数量
   * @param {String} minAmountB   最少存入的代币B数量
   */ 
   const _addSwapLiquidityV2 = async(tokenA:string, tokenB:string, amountA:string, amountB:string, minAmountA:string, minAmountB:string) => {
    console.log('合约【添加 - SWAP_V2_ERC20_ERC20流动性(入参):', { tokenA, tokenB, amountA, amountB, minAmountA, minAmountB })
    try {
      const decimalA = await _getDecimals(tokenA)
      const decimalB = await _getDecimals(tokenB)
      const inputAmountA = _inPutFormat(amountA, decimalA)
      const inputAmountB = _inPutFormat(amountB, decimalB)
      const minUseAmountA = _inPutFormat(minAmountA, decimalA)
      const minUseAmountB = _inPutFormat(minAmountB, decimalB)
      const liquidityContract = await _getContract(swapOperateContract, 'SWAPABI')
      // console.log('合约方法(入参):', { tokenA, tokenB, inputAmountA, inputAmountB, minUseAmountA, minUseAmountB, account, exchangeDeadline})
      return liquidityContract.addLiquidity(tokenA, tokenB, inputAmountA, inputAmountB, minUseAmountA, minUseAmountB, account, exchangeDeadline)
    } catch (error) {
      console.error('合约调用异常【添加 - SWAP_V2_ERC20_ERC20流动性】:', error)
    }
  }

  /**
   * 添加 - ERC20-ETH代币流动性
   * @param {String} token            ERC20代币
   * @param {String} amountErc20      希望存入的ERC20代币数量
   * @param {String} amountEth        希望存入Eth代币数量
   * @param {String} minAmountErc20   最少存入的ERC20代币数量
   * @param {String} minAmountEth     最少存入的Eth代币数量
   */ 
   const _addSwapLiquidityEthV2 = async(token:string, amountErc20:string, amountEth:string, minAmountErc20:string, minAmountEth:string) => {
    console.log('合约【添加 - SWAP_V2_ERC20_ETH流动性(入参):', { token, amountErc20, amountEth, minAmountErc20, minAmountEth })
    try {
      const decimal = await _getDecimals(token)
      const inputAmountErc20 = _inPutFormat(amountErc20, decimal)
      const inputAmountEth = _inPutFormat(amountEth)
      const minUseAmountErc20 = _inPutFormat(minAmountErc20, decimal)
      const minUseAmountEth = _inPutFormat(minAmountEth)
      const liquidityContract = await _getContract(swapOperateContract, 'SWAPABI')
      return liquidityContract.addLiquidityETH(token, inputAmountErc20, minUseAmountErc20, minUseAmountEth, account, exchangeDeadline, { value: inputAmountEth })
    } catch (error) {
      console.error('合约调用异常【添加 - SWAP_V2_ERC20_ETH流动性】:', error)
    }
  }

  /**
   * 移除 - ERC20_ERC20_LP流动性
   * @param {String} tokenA       代币A
   * @param {String} tokenB       代币B
   * @param {String} amount       要移除的代币数量
   * @param {String} minAmountA   最低预期代币A数量
   * @param {String} minAmountB   最低预期代币B数量
   */ 
   const _removeSwapLiquidityV2 = async(tokenA:string, tokenB:string, amount:string, minAmountA:string, minAmountB:string) => {
    console.log('合约【移除 - SWAP_V2_ERC20_ERC20_LP流动性(入参):', { tokenA, tokenB, amount, minAmountA, minAmountB })
    try {
      const decimalA = await _getDecimals(tokenA)
      const decimalB = await _getDecimals(tokenB)
      const inputAmount = _inPutFormat(amount)
      const minUseAmountA = _inPutFormat(minAmountA, decimalA)
      const minUseAmountB = _inPutFormat(minAmountB, decimalB)
      const liquidityContract = await _getContract(swapOperateContract, 'SWAPABI')
      console.log('合约方法(入参):', { tokenA, tokenB, inputAmount, minUseAmountA, minUseAmountB, account, exchangeDeadline})
      return liquidityContract.removeLiquidity(tokenA, tokenB, inputAmount, minUseAmountA, minUseAmountB, account, exchangeDeadline)
    } catch (error) {
      console.error('合约调用异常【移除 - SWAP_V2_ERC20_ERC20_LP流动性】:', error)
    }
  }

  /**
   * 移除 - ERC20_Eth_LP流动性
   * @param {String} token            ERC20代币
   * @param {String} amount           要移除的代币数量
   * @param {String} minAmountErc20   最低预期ERC20代币数量
   * @param {String} minAmountEth     最低预期ETH代币数量
   */ 
   const _removeSwapLiquidityEthV2 = async(token:string, amount:string, minAmountErc20:string, minAmountEth:string) => {
    console.log('合约【移除 - SWAP_V2_ERC20_ETH_LP流动性(入参):', { token, amount, minAmountErc20, minAmountEth })
    try {
      const decimal = await _getDecimals(token)
      const inputAmount = _inPutFormat(amount)
      const minUseAmountErc20 = _inPutFormat(minAmountErc20, decimal)
      const minUseAmountEth = _inPutFormat(minAmountEth)
      const liquidityContract = await _getContract(swapOperateContract, 'SWAPABI')
      return liquidityContract.removeLiquidityETH(token, inputAmount, minUseAmountErc20, minUseAmountEth, account, exchangeDeadline)
    } catch (error) {
      console.error('合约调用异常【移除 - SWAP_V2_ERC20_ETH_LP流动性:', error)
    }
  }

  return {
    _removeSwapLiquidityEthV2,
    _addSwapLiquidityEthV2,
    _removeSwapLiquidityV2,
    swapOperateContract,
    _addSwapLiquidityV2
  }
}
