import { rem } from '@/theme'
import { Skeleton } from 'antd'
import styled from 'styled-components'

const InterimRecordStyle = styled.tr`

	td {
		border-top: 1px solid ${({ theme }) => theme.gray100};
	}
	td:nth-child(1) {
		${({ theme }) => theme.align};
		& > div:nth-child(2) {
			background: ${({ theme }) => theme.gray100};
			border-radius: 9999px;
		}
	}
	td:last-child {
		text-align: right;
	}

	// PC
	@media screen and (min-width: 540px){ 
		td {
			height: 58px;
		}
		td:nth-child(1) {
			padding-left: 25px;
			& > div:nth-child(2) {
				padding: 0 8px;
				margin-left: 5px;
			}
		}
		td:last-child {
			padding-right: 25px;
		}
	}

	// H5
	@media screen and (max-width: 540px){
		td {
			height: ${rem(52)};
			font-size: 0;
		}
		td:nth-child(1) {
			padding-left: ${rem(16)};
			& > div:nth-child(2) {
				padding: 0 ${rem(8)};
				margin-left: ${rem(5)};
			}
		}
		td:last-child {
			padding-right: ${rem(16)};
		}
	}
`


const SkeletonLabelStyle = styled(Skeleton.Button)`

	// PC
	@media screen and (min-width: 540px) { 
		.ant-skeleton-button {
			height: 24px !important;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		.ant-skeleton-button {
			height: ${rem(20)} !important;
		}
	}

`

export {
	SkeletonLabelStyle,
	InterimRecordStyle
}




