import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigDispatchContext, ConfigContext, BridgeContext, BridgeManageContext } from '@/utils/contextStatic'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import { chainCoin } from '@/utils/addressStatic'

/**
 * 业务 - Bridge
 */
export default function useBusinessBridge(handle:any) {

  const t:any = useTranslation()['t']

  const { poolInfo } = useContext(BridgeManageContext)
  
  const { formParam, gasParam } = useContext(BridgeContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { updateConfig, account, supportChainList } = useContext(ConfigContext)

  const { _addLiquidityToken, _addLiquidityETH, _removeLiquidityToken, _removeLiquidityETH, _transferTokenToChain } = useContractBridge()
  
  const { fromToken ,toNetwork, bridgeAmount, fromTokenSymbol } = formParam
  
  const chainInfo = supportChainList.find((item:any) => item.chain_id === toNetwork)

  const { UPDATE_BALANCE, UPDATE_POOL_TOKEN } = updateConfig

  const { gasToken, estimateGas } = gasParam

  const { pool_symbol } = poolInfo

  /**
   * 添加 - 流动性
   * @param {String} token       要添加的代币的地址
   * @param {String} amount      要添加的代币数量
   * @param {String} callback    回调函数
   */ 
  const _addLiquidityTokenHandle = async(token:string, amount:string, callback:any) => {
    handle(2)
    let text = `${t("Button.AddLiquidity")} ${amount} ${pool_symbol}`
    configDispatch({ type: 'changeToast', payload: {status: true, kind: 'wait', text} })
    // 执行成功
    const successHandle = async(res:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'submit', address: res.hash} })
      const pledgeInfo = await res.wait()
      if(pledgeInfo.status === 1) {
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'success', timing: true, address: res.hash, text} })
        configDispatch({ type: 'changeUpdate', payload: { UPDATE_POOL_TOKEN: !UPDATE_POOL_TOKEN } })
        callback()
      } else {
        handle(3)
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text} })
      }
    }
    // 执行失败
    const failedHandle = (err:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text } })
      handle(3)
      console.log({err})
    }
    if(chainCoin !== token) {
      // ERC-20
      _addLiquidityToken(token, amount).then(async(res:any) => {
        successHandle(res)
      }).catch((err:any)=>{
        failedHandle(err)
      })
    } else {
      // 原生
      _addLiquidityETH(amount).then(async(res:any) => {
        successHandle(res)
      }).catch((err:any)=>{
        failedHandle(err)
      })
    }
  }

  /**
   * 移除 - 流动性
   * @param {String} token       要移除的代币的地址
   * @param {String} amount      要添加的代币数量
   * @param {String} callback    回调函数
   */
  const _removeLiquidityTokenHandle = async(token:string, amount:string, callback:any) => {
    handle(2)
    let text = `${t("Button.RemoveLiquidity")} ${amount} ${pool_symbol}`
    configDispatch({ type: 'changeToast', payload: {status: true, kind: 'wait', text} })
    // 执行成功
    const successHandle = async(res:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'submit', address: res.hash} })
      const pledgeInfo = await res.wait()
      if(pledgeInfo.status === 1) {
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'success', timing: true, address: res.hash, text} })
        configDispatch({ type: 'changeUpdate', payload: { UPDATE_POOL_TOKEN: !UPDATE_POOL_TOKEN } })
        callback()
      } else {
        handle(3)
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text} })
      }
    }
    // 执行失败
    const failedHandle = (err:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text } })
      handle(3)
      console.log({err})
    }
    if(chainCoin !== token) {
      // ERC-20
      _removeLiquidityToken(token, amount).then(async(res:any) => {
        successHandle(res)
      }).catch((err:any)=>{
        failedHandle(err)
      })
    } else {
      // 原生
      _removeLiquidityETH(amount).then(async(res:any) => {
        successHandle(res)
      }).catch((err:any)=>{
        failedHandle(err)
      })
    }
  } 

  /**
   * 跨链
   * @param {String} callback    回调函数
   * @param {String} handle      更新状态
   */ 
  const _transferTokenToChainHandle = async(callback:any) => {
    handle(2)
    let text = `${t("Button.Transferred")} ${bridgeAmount} ${fromTokenSymbol}`
    configDispatch({ type: 'changeToast', payload: {status: true, kind: 'wait', text} })
    _transferTokenToChain(chainInfo['selector'], fromToken, account, bridgeAmount, gasToken, estimateGas).then(async(res:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'submit', address: res.hash} })
      const pledgeInfo = await res.wait()
      if(pledgeInfo.status === 1) {
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'success', timing: true, address: res.hash, text} })
        configDispatch({ type: 'changeUpdate', payload: { UPDATE_POOL_TOKEN: !UPDATE_POOL_TOKEN } })
        configDispatch({ type: 'changeUpdate', payload: { UPDATE_BALANCE: !UPDATE_BALANCE } })
        callback()
      } else {
        handle(3)
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text} })
      }
    }).catch((err:any)=>{
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text } })
      handle(3)
      console.log({err})
    })
  }

  return {
    _removeLiquidityTokenHandle,
    _transferTokenToChainHandle,
    _addLiquidityTokenHandle,
  }
}
