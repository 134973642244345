import { useEffect, useContext } from 'react'
import { ConfigDispatchContext, ConfigContext } from '@/utils/contextStatic'
import usePublicMonitor from '@/hooks/usePublic/usePublicMonitor' 
import usePublicWallet from '@/hooks/usePublic/usePublicWallet' 
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { getChainList, getChainTokenPrice } from '@/service'
import useWebSocket from '@/hooks/useWebSocket'

/**
 * 初始化 - APP
 */

export default function useInitApp() {

  const configData = useContext(ConfigContext)

  const { _connectMetaMask } = usePublicWallet()

  const { _getBalacneOperate } = usePublicErc20()

  const { _webSocketSwapMonitor } = useWebSocket()

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { _metamaskInit, _metaMaskMonitor } = usePublicMonitor()

  const { chainSign, chainId, account, updateConfig } = configData

  const { UPDATA_BALANCE } = updateConfig

  // 获取 - 支持的链列表列表
  const requestChainList = () => {
    getChainList().then((res: any) => {
      console.log('interface - 支持的链列表【报文】:', res)
      configDispatch({ type: 'changeChainList', payload: res })
    }).catch(() => {
      console.log('interface - 支持的链列表【异常】')
    })
  }

  // 获取 - 链统一代币行情价格 
  const requestChainTokenPrice = () => {
    getChainTokenPrice().then((res: any) => {
      console.log('interface - 链统一代币行情价格【报文】:', res)
      configDispatch({ type: 'tokenPriceList', payload: res })
    }).catch(() => {
      console.log('interface - 链统一代币行情价格【异常】')
    })
  }

  useEffect(() => {
    // 获取 - 支持的链列表列表
    requestChainList()
    // 获取 - 支持的链列表列表
    requestChainTokenPrice()
    // 初始化 - MetaMask系列
    _metamaskInit()
    // 监听 - MetaMask系列
    _metaMaskMonitor()
    // 监听 - 跨链交易
    _webSocketSwapMonitor()
    // 弹出 - MetaMask系列钱包
    _connectMetaMask().catch((err) => console.log('用户拒绝链接钱包', err))
  }, [])

  // 更新 - 原生资产
  useEffect(() => {
    const isMatch = chainSign === +chainId
    const getNativeBalance = async() => {
      let balance:any = '0'
      if(account) balance = await _getBalacneOperate()
      configDispatch({ type: 'nativeBalance', payload: balance })
    }
    if(isMatch) getNativeBalance()
  }, [account, chainId, UPDATA_BALANCE])

}
