import { Spin } from 'antd'
import React, { useContext } from 'react'
import { InterimBridgeExchangeStyle, InterimBridgeInfoStyle } from './styled'
import { ConfigContext } from '@/utils/contextStatic'
import Text from '@/components/Text'


const InterimBridge:React.FC = ()=> {

	const { isPC }:any = useContext(ConfigContext)

	// 加载中
  const loaderRender = () => {
    return (
			<Spin size={isPC ? 'large' : 'default'} tip={<Text type='english' size={isPC ? '16' : '14'} color='gray600'>Loading...</Text>} >
				<i></i>
			</Spin>
		)
  }

  return (
		<>
			<InterimBridgeExchangeStyle>{loaderRender()}</InterimBridgeExchangeStyle>
			<InterimBridgeInfoStyle>
				<div>{loaderRender()}</div>
				<div>{loaderRender()}</div>
			</InterimBridgeInfoStyle>
		</>
  )
}

export default InterimBridge
