import React from 'react'
import { Tooltip } from 'antd'
import { DiscordSvg, TwitterSvg, GithubSvg } from '@/utils/icoStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import { ContactStyle } from './styled'

const Contact:React.FC<RouterProps> = ({ i18n })=> {

  const { linkList } = useCommon()

  const jumpHandle = (url:string) => window.open(url)

  // 联系方式渲染
  const contactRender = () => {
    let list:any = []
    const contactList = [
      { ico: <GithubSvg />, url: 'Github' },
      { ico: <DiscordSvg />, url: 'Discord' },
      { ico: <TwitterSvg />, url: 'Twitter' },
    ] 
    contactList.forEach((item, index) => {
      const { url, ico } = item
      list.push(
        <Tooltip title={url} key={`contactRender${index}`}>
          <div className="list-bar" onClick={() => jumpHandle(linkList[url])}>{ico}</div>
        </Tooltip>
      )
    })
    return list
  }


  return (
    <ContactStyle className='contact-module'>
      { contactRender() }
    </ContactStyle>
  )
}

export default withRouter(Contact)
