import { rem } from '@/theme'
import styled from 'styled-components'

const FarmsBarStyle = styled.div`

	div.table-bar {
		& > div {
			background: ${({ theme }) => theme.white};
			border: 1px solid ${({ theme }) => theme.gray100};
			box-shadow: 0px 2px 5px 0px rgba(38, 51, 77, 0.03);
			table {
				th {
					font-weight: normal;
					& > div {
						${({ theme }) => theme.align};
					}
				}
				th:not(:last-child) {
					text-align: left;
				}
				th:last-child {
					text-align: right;
					& > div {
						justify-content: end;
					}
				}
			}
		}
	}

	// PC
	@media screen and (min-width: 540px) { 
		margin-top: 50px;
		margin-bottom: 20px;
		@media screen and (max-width: 1050px) { 
			div.filter-bar {
				& > div:nth-child(2) {
					& > div:nth-child(2) {
						margin-top: 20px;
					}
				}
			}
		}
		@media screen and (min-width: 1051px) { 
			div.filter-bar {
				& > div:nth-child(2) {
					${({ theme }) => theme.align_between};
				}
			}
		}
		div.filter-bar {
			& > div:nth-child(1) {
				${({ theme }) => theme.align};
				height: 48px;
				div.tvl-key {
					${({ theme }) => theme.align};
					margin-left: 40px;
					& > div:nth-child(1) {
						margin-right: 5px;
					}
				}
			} 
			& > div:nth-child(2) {
				margin-top: 20px;
				& > div:nth-child(1) {
					${({ theme }) => theme.align};
					div.scSearch-module {
						margin-right: 12px;
						input {
							height: 41px;
						}
						svg {
							top: 10px;
						}
					}
				}
				& > div:nth-child(2) {
					${({ theme }) => theme.align};
					& > button {
						border-radius: 12px;
						height: 41px;
					}
					div.dropdown-module {
						height: 41px;
						margin-left: 12px;
					}
				}
			}
		}
		div.table-bar {
			margin-top: 20px;
			& > div {
				border-radius: 24px;
				table {
					width: 100%;
					th {
						height: 56px;
						& > div {
							svg {
								width: 16px;
								height: 16px;
								margin-left: 4px;
							}
						}
					}
					th:first-child {
						padding-left: 25px;
					}
					th:last-child {
						padding-right: 25px;
					}
				}
			}
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		margin-top: ${rem(20)};
		div.filter-bar {
			margin: 0 ${rem(16)};
			& > div:nth-child(1) {
				div.tvl-key {
					margin-top: ${rem(12)};
					${({ theme }) => theme.align};
					& > div:nth-child(1) {
						margin-right: ${rem(5)};
					}
				}	
			}
			& > div:nth-child(2) {
				& > div {
					margin-top: ${rem(12)};
				}
				& > div:nth-child(1) {
					${({ theme }) => theme.align_between};
					button {
						width: 48%;
						height: ${rem(40)};
						border-radius: 10px;
					}
					div.dropdown-module {
						width: 48%;
					}
				}
			}
		}
		div.table-bar { 
			margin-top:${rem(12)};
			overflow-x: auto; 
			& > div {
				border-radius: ${rem(16)};
				width: ${rem(940)};
				margin: 0 ${rem(16)};
				table {
					width: 100%;
					th {
						height: ${rem(56)};
						font-size: 0;
						& > div {
							svg {
								width: ${rem(16)};
								height: ${rem(16)};
								margin-left: ${rem(4)};
							}
						}
					}
					th:first-child {
						padding-left: ${rem(16)};
					}
					th:last-child {
						padding-right: ${rem(16)};
					}
				}
			}
		}
	}
`

export {
  FarmsBarStyle
}


