import { rem } from '@/theme'
import styled from 'styled-components'

const ListFarmsBarStyle = styled.tr`

	td {
		border-top: 1px solid ${({ theme }) => theme.gray100};
	}
	td:nth-child(1) {
		div.double-bar {
			& > div:nth-child(2) {
				div.version-key {
					width: fit-content;
					& > div:nth-child(1) {
						border-radius: 9999px;
						background: ${({ theme }) => theme.primary100};
					}
				}
			}
		}
	}
	td:nth-child(1), td:nth-child(4) {
		& > div {
			${({ theme }) => theme.align};
		}
	}
	td:nth-child(7) {
		text-align: right;
	}
	
	// PC
	@media screen and (min-width: 540px) { 
		cursor: pointer;
		&:hover {
			background: ${({ theme }) => theme.gray100};
		}
		td {
			height: 72px;
		}
		td:nth-child(1) {
			padding-left: 25px;
			div.double-bar {
				& > div:nth-child(2) {
					div.version-key {
						& > div:nth-child(1) {
							padding: 0 8px;
						}
					}
				}
			}
		}
		td:nth-child(7) {
			padding-right: 25px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		td {
			height: ${rem(62)};
		}
		td:nth-child(1) {
			padding-left: ${rem(16)};
			div.double-bar {
				& > div:nth-child(2) {
					div.version-key {
						& > div:nth-child(1) {
							padding: 0 ${rem(6)};
						}
					}
				}
			}
		}
		td:nth-child(7) {
			padding-right: ${rem(16)};
		}
	}
`

export {
  ListFarmsBarStyle
}


