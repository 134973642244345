import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { NavStyle } from './styled'

interface NavList {
  text: string
  isRouter: boolean
  urlSign: string
}

const Nav:React.FC<RouterProps> = ({ t, navigate })=> {

  const { linkList } = useCommon()

  const configData = useContext(ConfigContext)

  const navList: Array<NavList> = [
    // Swap
    {text: 'Swap', urlSign: 'Swap', isRouter: true},
    // Bridge
    {text: 'Bridge', urlSign: 'Bridge', isRouter: true},
    // Pool
    {text: 'Pool', urlSign: 'Pool', isRouter: true},
    // Pool
    {text: 'Faucet', urlSign: 'Faucet', isRouter: false},
    // Dao
    // {text: 'Dao', urlSign: 'Dao', isRouter: false}
  ]

  // 字段展示
  const navListRender = () => {
    const list:any = []
    navList.forEach((item, index) => {
      const { text } = item
      const isCurrent = text === configData.routerSign
      list.push(
        <li key={`navList${index}`}>
          <Text kind={isCurrent ? 'Bold' : 'Light'} type='specialEnglish' size='16' hovercolor='text_main' callback={() => jumpHandle(item)}>{t(`Link.${text}`)}</Text>
        </li>
      )
    })
    return list
  }

  // 跳转
  const jumpHandle = (item:NavList) => {
    const url = linkList[item.urlSign]
    if(item.isRouter) {
      navigate(url)
    } else {
      window.open(url)
    }
  }

  return (
    <NavStyle>
      <ul>{navListRender()}</ul>
    </NavStyle>
  )
}

export default withRouter(Nav)
