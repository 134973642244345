const bridgeState:any = {
  // 支持跨链代币列表
  bridgeTokens: [],
  // 目标链池子信息
  destPoolInfo: {},
  // 表单参数
  formParam: {
    fromNetwork: null,
    fromToken: '',
    fromTokenSymbol: '',
    toNetwork: null,
    toTokenSymbol: '',
    tokenDecimal: 18,
    bridgeAmount: ''
  },
  // 币种资产
  tokenBalance: '0',
  // Link币种资产
  linkBalance: '0',
  // 加载状态
  loadingStatus: true,
  // GAS参数
  gasParam: {
    estimateGas: '0',
    gasSymbol: '',
    gasToken: '',
    gasCost: ''
  },
  // 手续费
  exchangeFee: '0',
  // 确认按钮状态
  continueStatus: false 
}

const bridgeReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    case 'changeForm':
      updateData['formParam'] = { ...updateData['formParam'], ...payload }
      break;
    case 'changeGas':
      updateData['gasParam'] = { ...updateData['gasParam'], ...payload }
      break;
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  bridgeReducer,
  bridgeState
}