import { rem } from '@/theme'
import styled from 'styled-components'

const ScTabStyle = styled.div`

	${({ theme }) => theme.align}
	// 类型one
	div.one-normal {
		border-bottom-style: solid;
		border-bottom-color: transparent;
		border-bottom-width: 2px;
	}
	div.one-active {
		border-bottom-color: ${({ theme }) => theme.primary500};
	}
	// 类型two
	div.two-normal {
		${({ theme }) => theme.align};
		border-width: 1px;
		border-style: solid;
		border-color:  ${({ theme }) => theme.gray200};
	}
	div.two-active {
		border-color: ${({ theme }) => theme.primary500};
	}

	// PC
	@media screen and (min-width: 540px) { 
		// 类型one
		div.one-normal {
			padding: 8px 0 6px;
		}
		div.one-normal:not(:last-child) {
			margin-right: 40px;
		} 
		// 类型two
		div.two-normal {
			padding: 7px 20px;
			border-radius: 10px;
			img {
				top: 4px;
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}
		}
		div.two-normal:not(:last-child) {
			margin-right: 12px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		// 类型one
		div.one-normal {
			padding: ${rem(8)} 0 ${rem(6)};
		}
		div.one-normal:not(:last-child) {
			margin-right: ${rem(30)};
		} 
		// 类型two
		div.two-normal {
			padding: ${rem(8)} ${rem(20)};
			border-radius: ${rem(10)};
			img {
				width: ${rem(20)};
				height: ${rem(20)};
				margin-right: ${rem(10)};
			}
		}
		div.two-normal:not(:last-child) {
			margin-right: ${rem(12)};
		}
	}
`

export {
  ScTabStyle
}


