import { createContext } from 'react'
import { bridgeManageState } from '@/reducer/bridgeManage'
import { swapManageState } from '@/reducer/swapManage'
import { poolCreateState } from '@/reducer/poolCreate'
import { configState } from '@/reducer/config'
import { bridgeState } from '@/reducer/bridge'
import { swapState } from '@/reducer/swap'
import { poolState } from '@/reducer/pool'

// 全局配置 - 数据
const ConfigContext = createContext(configState)

// 全局配置 - 方法
const ConfigDispatchContext:any = createContext(null)

// Swap页面 - 数据
const SwapContext = createContext(swapState)

// Swap页面 - 方法
const SwapDispatchContext:any = createContext(null)

// Bridge页面 - 数据
const BridgeContext = createContext(bridgeState)

// Bridge页面 - 方法
const BridgeDispatchContext:any = createContext(null)

// POOL页面 - 数据
const PoolContext = createContext(poolState)

// POOL页面 - 方法
const PoolDispatchContext:any = createContext(null)

// PoolCreate页面 - 数据
const PoolCreateContext = createContext(poolCreateState)

// PoolCreate页面 - 方法
const PoolCreateDispatchContext:any = createContext(null)

// BridgeManage页面 - 数据
const BridgeManageContext = createContext(bridgeManageState)

// BridgeManage页面 - 方法
const BridgeManageDispatchContext:any = createContext(null)

// SwapManage页面 - 数据
const SwapManageContext = createContext(swapManageState)

// SwapManage页面 - 方法
const SwapManageDispatchContext:any = createContext(null)

export {
  BridgeManageDispatchContext,
  PoolCreateDispatchContext,
  SwapManageDispatchContext,
  ConfigDispatchContext,
  BridgeDispatchContext,
  SwapDispatchContext,
  PoolDispatchContext,
  BridgeManageContext,
  PoolCreateContext,
  SwapManageContext,
  BridgeContext,
  ConfigContext,
  SwapContext,
  PoolContext
}
