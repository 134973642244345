import { useContext } from 'react'
import { Interface } from "ethers"
import { contractAddressList, specialRouterList } from '@/utils/addressStatic'
import SWAPESPECIALNCODEABI from '@/contract/swap_special_encode.json'
import SWAPENCODEABI from '@/contract/swap_encode.json'
import { ConfigContext } from '@/utils/contextStatic'
import useCommon from '@/hooks/useCommon'

/**
 * API - SwapEncode
 */
export default function useContractSwapEncode() {

  const { exchangeDeadline } = useCommon()

  const { chainSign } = useContext(ConfigContext)

  // 跨链交易执行合约地址
  const bridgeContractAddress = contractAddressList[chainSign]['operate']['bridge']

  /**
   * 获取 - swap订单信息ERC20-ERC20(V2)
   * @param {String} amountIn      卖出数量
   * @param {String} amountOutMin  兑换数量
   * @param {String} path          路由
   */ 
  const _swapExactTokensForTokens = (amountIn:string, amountOutMin:string, path:any) => {
    console.log('合约【获取 - swap订单信息ERC20-ERC20(V2)】(入参):', { amountIn, amountOutMin, path })
    try {
      const swapEncodeInterface = new Interface(SWAPENCODEABI)
      const tupleParam = [ amountIn, amountOutMin, path, bridgeContractAddress, exchangeDeadline ]
      console.log('V2-encode参数:', { tupleParam }) 
      const encode = swapEncodeInterface.encodeFunctionData('swapExactTokensForTokens', tupleParam)
      return encode
    } catch (error) {
      console.error('合约调用异常【获取 - swap订单信息ERC20-ERC20】:', error)
    }
  }

  /**
   * 获取 - swap订单信息ETH-ERC20(V2)
   * @param {String} amountOutMin  兑换数量
   * @param {String} path          路由
   */ 
  const _swapExactETHForTokens = (amountOutMin:string, path:any) => {
    console.log('合约【获取 - swap订单信息ETH-ERC20(V2)】(入参):', { amountOutMin, path })
    try {
      const swapEncodeInterface = new Interface(SWAPENCODEABI)
      const tupleParam = [ amountOutMin, path, bridgeContractAddress, exchangeDeadline ]
      console.log('V2-encode参数:', { tupleParam }) 
      const encode = swapEncodeInterface.encodeFunctionData('swapExactETHForTokens', tupleParam)
      return encode
    } catch (error) {
      console.error('合约调用异常【获取 - swap订单信息ETH-ERC20(V2)】:', error)
    }
  }

  /**
   * 获取 - swap订单信息ETH-ERC20/ERC20-ERC20(V3)
   * @param {String} tokenIn            卖出代币地址
   * @param {String} tokenOut           兑换代币地址
   * @param {String} feeRate            手续费比率
   * @param {String} amountIn           交易数量
   * @param {String} amountOutMinimum   最少兑换数量
   * @param {String} router             协议路由
   */ 
  const _exactInputSingle = (tokenIn:string, tokenOut:string, feeRate:any, amountIn:any, amountOutMinimum:any, router:string) => {
    console.log('合约【获取 - 订单信息ETH-ERC20/ERC20-ERC20(V3)(入参):', { tokenIn, tokenOut, feeRate, amountIn, amountOutMinimum, router })
    try {
      const specialList = specialRouterList[chainSign]
      const isSpecial = specialList ? specialList.includes(router) : false
      const swapEncodeInterface = new Interface(isSpecial ? SWAPESPECIALNCODEABI : SWAPENCODEABI)
      const tupleParam:any = { 
        tokenIn, 
        tokenOut, 
        fee: feeRate, 
        recipient: bridgeContractAddress, 
        amountIn, 
        amountOutMinimum, 
        sqrtPriceLimitX96: '0' 
      }
      if(!isSpecial) tupleParam['deadline'] = exchangeDeadline
      console.log('V3-encode参数:', { tupleParam }) 
      const encode = swapEncodeInterface.encodeFunctionData('exactInputSingle', [tupleParam])
      return encode
    } catch (error) {
      console.error('合约调用异常【获取 - 订单信息ETH-ERC20/ERC20-ERC20(V3)】:', error)
    }
  }

  return {
    _swapExactTokensForTokens,
    _swapExactETHForTokens,
    _exactInputSingle
  }
}
