import { rem } from '@/theme'
import styled from 'styled-components'

const InfoStyle = styled.div`

	section {
		border: 1px solid ${({ theme }) => theme.gray100};
		background: ${({ theme }) => theme.white};
	}
	section.position-wrap {
		& > div:nth-child(1) {
			text-align: center;
		}
		& > div:nth-child(2) {
			${({ theme }) => theme.align};	
		}
		& > div:nth-child(3), & > div:nth-child(4) {
			& > div {
				${({ theme }) => theme.align_between};
			}
		}
	}
	section.field-wrap {
		div.collapse-bar {
			${({ theme }) => theme.align_between};
			background: ${({ theme }) => theme.text_bg};
			border-bottom: 1px solid ${({ theme }) => theme.gray100};
		}
		div.field-bar {
			div.field-item {
				${({ theme }) => theme.align_between};
				& > div {
					${({ theme }) => theme.align};
				}
			}
		}
	}

	// PC
	@media screen and (min-width: 540px){
		width: 400px;	
		margin-left: 24px;
		section.position-wrap {
			padding: 20px;
			border-radius: 20px;
			& > div:nth-child(2) {
				margin-top: 24px;
				& > div:nth-child(1) {
					margin-right: 10px;
				}
			}
			& > div:nth-child(3), & > div:nth-child(4) {
				margin-top: 24px;
				& > div:nth-child(1) {
					margin-bottom: 5px;
				}
			}
		}
		section.field-wrap {
			height: 168px;
			margin-top: 24px;
			border-radius: 20px;
			div.collapse-bar {
				height: 54px;
				padding: 16px;
				cursor: pointer;
			}
			div.field-bar {
				padding: 15px;
				div.field-item {
					& > div {
						svg {
							width: 16px;
							height: 16px;
							margin-left: 4px;
							cursor: pointer;
						}
					}
				}
				div.field-item:not(:last-child) {
					margin-bottom: 10px;
				}
			}
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		section.position-wrap {
			margin-top: ${rem(20)};
			padding: ${rem(16)};
			border-radius: ${rem(12)};
			& > div:nth-child(2) {
				margin-top: ${rem(16)};
				& > div:nth-child(1) {
					margin-right: 10px;
				}
			}
			& > div:nth-child(3), & > div:nth-child(4) {
				margin-top: ${rem(16)};
				& > div:nth-child(1) {
					margin-bottom: ${rem(2)};
				}
			}
		}
		section.field-wrap {
			margin-top: ${rem(16)};
			border-radius: ${rem(12)};
			div.collapse-bar {
				height: ${rem(48)};
				padding: 0 ${rem(12)};
				border-radius: ${rem(8)};
			}
			div.field-bar {
				padding: ${rem(12)};
				div.field-item {
					& > div {
						svg {
							width: ${rem(16)};
							height: ${rem(16)};
							margin-left: ${rem(4)};
						}
					}
				}
				div.field-item:not(:last-child) {
					margin-bottom: ${rem(10)};
				}
			}
		}
	}
`

export {
	InfoStyle
}




