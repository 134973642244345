import { useEffect, useContext, useRef } from 'react'
import { getPoolsList, getPoolsLpsList, getUserCreatePoolsList } from '@/service'
import useContractPool from '@/hooks/useContract/useContractPool'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'
import { _compare } from '@/utils/comMethod'
import useCommon from '@/hooks/useCommon'
import BigNumber from 'bignumber.js'

/**
 * 初始化 - POOL
 * @param {Any}   poolData
 * @param {Void}  poolDispatch 
 */

export default function useInitPool(poolData:any, poolDispatch:any) {

  let delayModule:any = useRef()

  const { _getExchangePrice } = useCommon()

  const { _getPoolBalance } = useContractPool()
  
  const { _getBalacneOperate } = usePublicErc20()

  const { supportChainList, account, chainSign, chainId } = useContext(ConfigContext)

  const { poolsList, allPoolsList, yourPoolsBarList, filterParam, cardLoadingStatus } = poolData

  // 获取 - 白名单池子列表
  const requestPools= () => {
    Promise.all([getPoolsList(), getPoolsLpsList()]).then((res:any) => {
      console.log('interface - 单币池/双币池列表【报文】:', res)
      const concatData = res[0].concat(res[1])
      // 获取 - ALL/TOP池子数据
      const cloneData = concatData.slice(0)
      for(let item of cloneData) {
        const { pool_tvl, pool_symbol } = item
        item['pool_tvl'] = _getExchangePrice(pool_symbol, pool_tvl, false)
      }
      // 池子展示列表 - TOP 
      const topData = _compare(cloneData, 'pool_apy')
      poolDispatch({ type: 'changePoolsBase', payload: { allData: concatData, topData } })
    }).catch(() => {
      console.log('interface - 单币池/双币池列表【异常】')
    })
  }

  // 获取 - 用户创建池子列表
  const requestUserCreatePoolsList = () => {
    getUserCreatePoolsList(chainSign, account).then(async(res:any) => {
      console.log('interface - 用户创建池子列表【报文】:', res)
      const { code, data } = res
      if(code === 1) {
        const list = []
        for(const item of data) {
          const { pair_address, token0_address, token1_address, token0_symbol, token1_symbol } = item
          // 添加 - 流动性
          const poolToken:any = await _getPoolBalance(pair_address) 
          const themp = {
            chain_id: chainSign,
            pair: pair_address,
            pair_token0: token0_address,
            pair_token1: token1_address,
            pool_apy: '0',
            pool_symbol: `${token0_symbol}/${token1_symbol}`,
            protocol_type: 'v2',
            pool_tvl: '0',
            token_balance: poolToken,
            unStake_lp: poolToken,
            stake_lp: '0',
            user_earn: '0', 
            pool_type: 'create'
          }
          list.push(themp)
        }
        poolDispatch({ type: 'userCreatePoolsList', payload: list })
      }
    }).catch(() => {
      console.log('interface - 用户创建池子列表【异常】')
    })
  }
  
  // 初始化 - 单币池列表/筛选币种列表
  useEffect(() => {
    requestPools()
  }, [])

  // 初始化 - 筛选链列表
  useEffect(() => {
    const list = [{ text: 'All chains', key: -1 }]
    for(const item of supportChainList) {
      const { chain_id, chain_name, chain_symbol } = item
      const themp = { text: chain_name, key: chain_id, sign: chain_symbol }
      list.push(themp)
    }
    poolDispatch({ type: 'filterChainList', payload: list })
  }, [])

  // 更新 - 用户创建池子
  useEffect(() => {
    if(account && +chainId === chainSign) requestUserCreatePoolsList()
  }, [account, chainId])

  // 更新 - 池子合约相关数据
  useEffect(() => {
    const getConstructList = async() => {
      poolDispatch({ type: 'cardLoadingStatus', payload: true })
      const cloneData = poolsList.slice(0)
      // 池子展示列表 - YOU 
      let yourData = cloneData.filter((item:any) => item.chain_id === +chainId)
      for(let item of yourData) {
        const { pool_contract, pair } = item
        // 添加 - 流动性
        const poolToken:any = await _getPoolBalance(pool_contract ? pool_contract : pair) 
        if(pair) item['pair'] = pair.toLowerCase()
        item['unStake_lp'] = poolToken
        item['stake_lp'] = 0
        item['user_earn'] = 0
      }
      yourData = yourData.filter((item:any) => +item.unStake_lp > 0)
      // 池子展示列表 - AVALIABLE
      let avaliableData = cloneData.filter((item:any) => item.chain_id === +chainId)
      for(let item of avaliableData) {
        const { staking_token, pair } = item
        // 用户 - 资产
        const tokenBalance:any = await _getBalacneOperate(staking_token ? staking_token : pair)
        item['token_balance'] = tokenBalance
      }
      avaliableData = avaliableData.filter((item:any) => +item.token_balance > 0)
      // 更新 - 数据
      poolDispatch({ type: 'changePoolsUser', payload: { yourData, avaliableData } })
      poolDispatch({ type: 'cardLoadingStatus', payload: false }) 
    }
    if(!account && poolsList.length) poolDispatch({ type: 'cardLoadingStatus', payload: false }) 
    if(account && poolsList.length) getConstructList()
  }, [account, chainId, poolsList])

  // 更新 - 展示池子数据
  useEffect(() => {
    let list = []
    clearTimeout(delayModule.current)
    poolDispatch({ type: 'barLoadingStatus', payload: true }) 
    if(!cardLoadingStatus) {
      const { tabOneKey, tabTwoKey, searchKey, chainKey, sortInfo } = filterParam
      const sourceList:any = { "All": allPoolsList, "User": yourPoolsBarList }
      const cloneSourceData = sourceList[tabOneKey].slice(0)
      const { sortKey, sortBy } = sortInfo
      list = cloneSourceData.filter((item:any) => {
        const { chain_id, pool_symbol, pool_apy } = item
        const isSwap = pool_symbol.includes('/') 
        // 筛选 - 池子类型
        const checkTypeList:any = { 'Swap': isSwap, 'Bridge': !isSwap, 'Farm': +pool_apy > 0 }
        const checkType = checkTypeList[tabTwoKey]
        // 筛选 - 链
        const checkChainId = chainKey === -1 ? true : chain_id === chainKey
        // 筛选 - 池子名称
        const checkSymbol = pool_symbol.includes(searchKey.toUpperCase())
        // 筛选 - 组合
        const isPass = tabOneKey === 'All' ? ( checkType && checkChainId && checkSymbol ) : checkType
        return isPass
      })
      // 筛选 - 排序
      if( sortKey && sortBy ) {
        list = _compare(list, sortKey, sortBy === 'desc' ? true : false)
      }
      poolDispatch({ type: 'showPoolsList', payload: list }) 
      delayModule.current = setTimeout(() => {
        poolDispatch({ type: 'barLoadingStatus', payload: false }) 
      }, 500)
    }
  }, [filterParam, cardLoadingStatus])

  // 更新 - 当前池子列表TVL数据
  useEffect(() => {
    const currentTvl = allPoolsList.reduce((pre:any, current:any) => {
      return new BigNumber(pre).plus(current['pool_tvl']).toString()
    },0)
    poolDispatch({ type: 'currentTvl', payload: currentTvl })
  }, [allPoolsList])
}