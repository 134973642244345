import { useEffect, useContext, useRef } from 'react'
import useContractBridgeFee from '@/hooks/useContract/useContractBridgeFee'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import { getBridgeTokensList, getPoolTokenInfo } from '@/service'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'
import { zeroAddress } from '@/utils/addressStatic'
import useCommon from '@/hooks/useCommon'
import BigNumber from 'bignumber.js'

/**
 * 初始化 - Bridge
 */

export default function useInitBridge(bridgeData:any, bridgeDispatch:any, params:any) {

  let delayModule:any = useRef()

  let delayModule2:any = useRef()

  const { formParam, gasParam } = bridgeData

  const { chain_id, token_contract } = params
  
  const { _getBalacneOperate } = usePublicErc20()
  
  const { _getCombinedFees } = useContractBridgeFee()

  const { _getChainProperty, linkAddress } = useCommon()
  
  const { _estimateExecutionFees } = useContractBridge()

  const { chainSign, account, updateConfig, chainId } = useContext(ConfigContext)
  
  const { fromToken, toNetwork, toTokenSymbol, bridgeAmount, fromNetwork } = formParam

  const { UPDATE_POOL_TOKEN, UPDATE_BRIDGE } = updateConfig

  const { gasToken, estimateGas } = gasParam

  // 获取 - 支持的跨链代币列表
  const requestBridgeTokensList = () => {
    bridgeDispatch({ type: 'loadingStatus', payload: true })
    const param:any = { src_chainid: chain_id ? chain_id : (account ? chainSign : fromNetwork) }
    console.log('interface - 支持的跨链代币列表【入参】:', param)
    getBridgeTokensList(param).then((res: any) => {
      console.log('interface - 支持的跨链代币列表【报文】:', res)
      let initCoin = ''
      const list:any = {}
      // 重组列表数据
      if(res.length > 0) {
        for(const item of res) {
          const { src_token_symbol, src_token } = item
          if(token_contract && token_contract.toLowerCase() === src_token.toLowerCase()) initCoin = src_token_symbol
          if(!list[src_token_symbol]) list[src_token_symbol] = []
          list[src_token_symbol].push(item)
        }
        initCoin = initCoin ? initCoin : Object.keys(list)[0]
        const { src_token, src_chainid, src_token_symbol, dest_chainid, dest_token_symbol, src_token_decimals } = list[initCoin][0]
        const param = { 
          fromTokenSymbol: src_token_symbol,
          toTokenSymbol: dest_token_symbol,
          tokenDecimal: src_token_decimals,
          fromNetwork: src_chainid,
          toNetwork: dest_chainid,
          fromToken: src_token,
          bridgeAmount: ''
        }
        bridgeDispatch({ type: 'bridgeTokens', payload: list })
        bridgeDispatch({ type: 'changeForm', payload: param })
        bridgeDispatch({ type: 'loadingStatus', payload: false })
      }
    }).catch(() => {
      console.log('interface - 支持的跨链代币列表【异常】')
    })
  }

  // 获取 - 目标链单币池子信息
  const requestPoolTokenInfo = () => {
    const param:any = { chain_id: toNetwork, token_symbol: toTokenSymbol }
    console.log('interface - 目标链单币池子信息【入参】:', param)
    getPoolTokenInfo(param).then((res: any) => {
      console.log('interface - 目标链单币池子信息【报文】:', res)
      bridgeDispatch({ type: 'destPoolInfo', payload: res })
    }).catch(() => {
      console.log('interface - 目标链单币池子信息【异常】')
    })
  }

  // 更新 - 支持的跨链代币列表
  useEffect(() => {
    requestBridgeTokensList()
  }, [account, chainSign, UPDATE_BRIDGE])

  // 更新 - 目标链单币池子信息
  useEffect(() => {
    if(fromToken) {
      requestPoolTokenInfo()
      const currentCoin = _getChainProperty(chainSign, 'currency')
      bridgeDispatch({ type: 'changeForm', payload: { bridgeAmount: '' } })
      bridgeDispatch({ type: 'changeGas', payload: { gasSymbol: currentCoin, gasToken: zeroAddress, gasCost: '0', estimateGas: '0' }})
    }
  }, [fromToken, toNetwork, UPDATE_POOL_TOKEN])

  // 更新 - 币种资产
  useEffect(() => {
    if(!account) {
      bridgeDispatch({ type: 'tokenBalance', payload: '0' })
      bridgeDispatch({ type: 'linkBalance', payload: '0' })
    }
  }, [account])

  // 更新 - 币种资产
  useEffect(() => {
    const getTokenBalance = async() => {
      let linkBalance:any = '0'
      let tokenBalance:any = '0'
      const balanceChainId = chain_id ? +chain_id : chainSign
      if(account && fromToken && (balanceChainId === +chainId)) {
        tokenBalance = await _getBalacneOperate(fromToken)
        linkBalance = await _getBalacneOperate(linkAddress)
      }
      bridgeDispatch({ type: 'tokenBalance', payload: tokenBalance })
      bridgeDispatch({ type: 'linkBalance', payload: linkBalance })
    }
    getTokenBalance()
  }, [fromToken, UPDATE_POOL_TOKEN])

  // 更新 - 费用(LP费用,平台费用, 跨链GAS费用)
  useEffect(() => {
    clearTimeout(delayModule.current)
    if(+bridgeAmount > 0 && account) {
      delayModule.current = setTimeout(async() => {
        // 获取 - 选择器
        const selector = _getChainProperty(toNetwork, 'selector')
        // 获取 - 平台费用/LP费用
        const { platformFeeAmount, lpFeeAmount } = await _getCombinedFees(fromToken, selector, bridgeAmount)
        const fee = new BigNumber(platformFeeAmount).plus(lpFeeAmount).toString()
        // 更新 - 费用（平台费用+LP费用）
        bridgeDispatch({ type: 'exchangeFee', payload: fee })
        if(+estimateGas === 0) {
          // 获取 - 跨链GAS费用
          const { crossChainFee, feeInToken } = await _estimateExecutionFees(selector, fromToken, account, bridgeAmount, gasToken)
          // 更新 - 跨链GAS费用
          bridgeDispatch({ type: 'changeGas', payload: { estimateGas: crossChainFee, gasCost: feeInToken } })
        }
      }, 500)
    } else {
      bridgeDispatch({ type: 'exchangeFee', payload: '0' })
      bridgeDispatch({ type: 'changeGas', payload: { estimateGas: '0', gasCost: '0' } })
    }
  }, [bridgeAmount])

  // 更新 - 跨链GAS费用
  useEffect(() => {
    clearTimeout(delayModule2.current)
    if(+bridgeAmount > 0 && account) {
      delayModule2.current = setTimeout(async() => {
        // 获取 - 选择器
        const selector = _getChainProperty(toNetwork, 'selector')
        // 获取 - 跨链GAS费用
        const { crossChainFee, feeInToken } = await _estimateExecutionFees(selector, fromToken, account, bridgeAmount, gasToken)
        // 更新 - 跨链GAS费用
        bridgeDispatch({ type: 'changeGas', payload: { estimateGas: crossChainFee, gasCost: feeInToken } })
      }, 500)
    }
  }, [gasToken])

}
