import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import BigNumber from 'bignumber.js'

/**
 * API - SwapPairV2
 */
export default function useContractSwapPairV2() {
  
  const { _getContract } = usePublicErc20()

  const { _outPutFormat } = usePublicEthers()

  /**
   * 获取 - LP_TOKEN总量
   * @param {String} lpAddress       池子地址
   */ 
  const _getLpTotalAmount = async(lpAddress:string) => {
    console.log('合约【获取 - LP_TOKEN总量(入参)】:', { lpAddress })
    try {
      const pairContract = await _getContract(lpAddress, 'SWAPPAIRABI')
      const total = await pairContract['totalSupply']()
      return _outPutFormat(total)
    } catch(error) {
      console.error('合约调用异常【获取 - LP_TOKEN总量】:', error)
    }
  }

  /**
   * 获取 - LP池子中A和B的总量(地址大的在后面)
   * @param {String} lpAddress       池子地址
   */ 
   const _getTokenArrayAmount= async(lpAddress:string) => {
    console.log('合约【获取 - LP池子中A和B的总量(入参)】:', { lpAddress })
    try {
      const pairContract = await _getContract(lpAddress, 'SWAPPAIRABI')
      return await pairContract['getReserves']()
    } catch(error) {
      console.error('合约调用异常【获取 - LP池子中A和B的总量】:', error)
    }
  }

  /**
   * 获取 - 输入兑换LP数量
   * @param {String} tokenA          代币A地址
   * @param {String} tokenB          代币B地址
   * @param {String} lpAddress       池子地址
   * @param {String} amountA         tokenA数量
   * @param {String} amountB         tokenB数量
   */
  const _getExchangeLpAmount = async(tokenA:string, tokenB:string, lpAddress:string, amountA:string, amountB:string) => {
    console.log('合约【获取 - 输入兑换LP数量(入参)】:', { lpAddress, amountA, amountB })
    try {
      let liquidity = 0
      const pairContract = await _getContract(lpAddress, 'SWAPPAIRABI')
      const total = await pairContract['totalSupply']()
      const { _reserve0, _reserve1 } = await pairContract['getReserves']()
      if(total === 0) {
        liquidity = Math.sqrt(new BigNumber(amountA).times(amountB).minus(1000).toNumber())
      } else {
        const totalA = +tokenA < +tokenB ? _reserve0 : _reserve1
        const totalB = +tokenB < +tokenA ? _reserve0 : _reserve1
        const numberA = new BigNumber(amountA).times(total).div(totalA).toNumber()
        const numberB = new BigNumber(amountB).times(total).div(totalB).toNumber()
        liquidity = Math.min(numberA, numberB)
      }
      return liquidity
    } catch(error) {
      console.error('合约调用异常【获取 - 输入兑换LP数量】:', error)
    }
  }

  return {
    _getTokenArrayAmount,
    _getExchangeLpAmount,
    _getLpTotalAmount
  }
}
