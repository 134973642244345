import { useContext } from 'react'
import useContractSwapPairV2 from '@/hooks/useContract/useContractSwapPairV2'
import { contractAddressList, chainCoin } from '@/utils/addressStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'

/**
 * API - SwapQuoterV2
 */
export default function useContractSwapQuoterV2() {

  const { chainSign } = useContext(ConfigContext)

  const { _getContract, _getDecimals } = usePublicErc20()

  const { _getTokenArrayAmount } = useContractSwapPairV2()

  const { _outPutFormat, _inPutFormat } = usePublicEthers()

  // 执行合约地址
  const swapQuoterOperateContract = contractAddressList[chainSign]['operate']['swap_quoter']

  /**
   * 获取 - A计算B数量(原生代币地址用如：WETH地址代替[每条链都不一样])
   * @param {String} pair         池子地址
   * @param {String} tokenA       代币A地址
   * @param {String} tokenB       代币B地址
   * @param {String} amount       需要计算数量
   * @param {String} type         A: in B:out
   */ 
  const _getAmounts = async(pair:string, tokenA:string, tokenB:string, amount:string, type:string) => {
    console.log('合约【获取 - A计算B数量(入参)】:', { tokenA, tokenB, amount })
    try {
      const isChainCoinA = chainCoin === tokenA
      const isChainCoinB = chainCoin === tokenB
      const decimalA = isChainCoinA ? 18 : await _getDecimals(tokenA)
      const decimalB = isChainCoinB ? 18 : await _getDecimals(tokenB)
      const inputAmountA = _inPutFormat(amount, decimalA)
      const { _reserve0, _reserve1 } = await _getTokenArrayAmount(pair)
      const totalA = +tokenA < +tokenB ? _reserve0 : _reserve1
      const totalB = +tokenB < +tokenA ? _reserve0 : _reserve1
      const quoterContract = await _getContract(swapQuoterOperateContract, 'SWAPQUOTERABI')
      if(type === 'A') {
        const amountB = await quoterContract['quote'](inputAmountA, totalA, totalB)
        return _outPutFormat(amountB, decimalB)
      } else {
        const amountA = await quoterContract['quote'](inputAmountA, totalB, totalA)
        return _outPutFormat(amountA, decimalA)
      }
    } catch(error) {
      console.error('合约调用异常【获取 - A计算B数量】:', error)
    }
  }

  return {
    _getAmounts
  }
}
