import { rem } from '@/theme'
import styled from 'styled-components'

const RecordStyle = styled.div`

	background: ${({ theme }) => theme.con_bg};
	border: 1px solid ${({ theme }) => theme.gray100};
	table {
		width: 100%;
		th {
			font-weight: normal;
		}
		th:not(:last-child) {
			text-align: left;
		}
		th:last-child {
			${({ theme }) => theme.align_end};
		}
	}

	// PC
	@media screen and (min-width: 540px){
		min-height: 300px;
		margin: 20px 0;
		border-radius: 24px;
		table {
			th {
				height: 56px;
			}
			th:nth-child(1) {
				padding-left: 25px;
			}
			th:last-child {
				padding-right: 25px;
				svg {
					cursor: pointer;
					margin-left: 6px;
					width: 16px;
					height: 16px;
				}
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		height: fit-content;
		margin-top: ${rem(20)};
		border-radius: ${rem(16)};
		table {
			th {
				height: ${rem(52)};
			}
			th:nth-child(1) {
				padding-left: ${rem(16)};
			}
			th:last-child {
				padding-right: ${rem(16)};
			}
			td {
				div.nodata-module {
					margin-bottom: ${rem(16)};
				}
			}
		}
	}
	
`

export {
	RecordStyle
}




