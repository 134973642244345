import React from 'react'
import { RouterProps } from '@/utils/interface'
import { LeftSvg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { InfoStyle } from './styled'


const Info:React.FC<RouterProps> = ({ t, navigate }) => {

  const { linkList } = useCommon()

  const jumpHandle = () => {
    navigate(linkList['Pool'])
  }

  return (
    <InfoStyle>
      <div className='crumbs-bar' onClick={jumpHandle}>
        <LeftSvg />
        <Text color='primary500'>{t('Link.Pool')}</Text>
      </div>
    </InfoStyle>
  )
}

export default withRouter(Info)
