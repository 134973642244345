import { rem } from '@/theme'
import styled from 'styled-components'

const PopGasHintStyle = styled.div`
  div.gas-bar {
    ${({ theme }) => theme.align_center};
    & > div:nth-child(2) {
      position: relative;
    }
  }
  div.btn-bar {
    ${({ theme }) => theme.align_between};
    button {
      width: 48%;
    }
  }
  // PC
  @media screen and (min-width: 540px) { 
    margin-top: 46px;
    div.gas-bar {
      margin-bottom: 40px;
      & > div:nth-child(2) {
        top: 12px;
        left: 10px;
      }
    }
    div.box-module {
      margin-top: 20px;
    }
    div.btn-bar {
      margin-top: 20px;
      button {
        border-radius: 16px;
      }
    }
  }

  // H5
  @media screen and (max-width: 540px) { 
		margin-top: ${rem(36)};
    div.gas-bar {
      margin-bottom: ${rem(30)};
      & > div:nth-child(2) {
        top: ${rem(12)};
        left: ${rem(10)};
      }
    }
    div.box-module {
      margin-top: ${rem(20)};
    }
    div.btn-bar {
      margin-top: ${rem(20)};
      button {
        border-radius: ${rem(16)};
      }
    }
  }
`

export {
  PopGasHintStyle
}


