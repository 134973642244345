import { rem } from '@/theme'
import styled from 'styled-components'

const BridgeStyle = styled.div`

	div.bg-bar {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		background: ${({ theme }) => theme.home_bg};
	}
	main {
		position: relative;
	}

	// PC
	@media screen and (min-width: 540px){
		div.bg-bar {
			height: 530px;
		}
		main {
			z-index: 1;
			${({ theme }) => theme.mainContent};
			margin-top: 60px;
			margin-bottom: 20px;
		}
		// 540 - 870
		@media screen and (max-width: 870px){
			main {
				display: flex;
				flex-direction: column;
				align-items: center;
				& > div:nth-child(1) {
					margin-bottom: 30px;
				}
			}
		}
		// > 870
		@media screen and (min-width: 871px){
			main {
				${({ theme }) => theme.horizontal_center};
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		div.bg-bar {
			height: ${rem(530)};
		}
		main {
			z-index: 1;
			margin-top: ${rem(56)};
			margin-bottom: ${rem(70)};
			padding: ${rem(20)} ${rem(16)};
		}
  }
`

export {
	BridgeStyle
}




