import React, { useContext } from 'react'
import { ConfigContext, SwapContext } from '@/utils/contextStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import { _getCoinImgUrl } from '@/utils/urlMethod'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import { RightSvg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import NoData from '@/components/NoData'
import { RoutingStyle } from './styled'
import Text from '@/components/Text'


const Routing:React.FC<RouterProps> = ({ t }) => {

  const { isPC } = useContext(ConfigContext)

  const { _outPutFormat } = usePublicEthers()

  const { routerList, swapFormParam, amountParam } = useContext(SwapContext)

  const { fromTokenSymbol, toTokenSymbol, toTokenDecimal } = swapFormParam

  const { totalAmountOut, totalAmountIn } = amountParam

  // 渲染 - router
  const routerRender = () => {
    const list:any = []
    routerList.forEach((item:any, index:number) => {
      const { protocolName, buyTokenSymbol, sellTokenSymbol, proportion } = item
      list.push(
        <div key={`router_${index}`}>
          <div className='plat-key'>
            <Text color='gray500' size='12' type='english'>{protocolName}</Text>
            <ScCoin kind='19' name={sellTokenSymbol} content={isPC ? sellTokenSymbol : ''} />
          </div>
          <div className='arrow-key'><RightSvg /></div> 
          <div className='rate-key'>
            <ScCoin kind='19' name={buyTokenSymbol} content={isPC ? buyTokenSymbol : ''} />
            <Text color='gray500' size='12' type='number'>{`${_numComma(proportion)}%`}</Text>
          </div>
        </div>
      )
    })
    return list
  }

  // 渲染 - content
  const contentRender = () => {
    const amountIn = _outPutFormat(totalAmountIn, toTokenDecimal)
    const amountOut = _outPutFormat(totalAmountOut, toTokenDecimal)
    return (
      <>
        <Text kind='Bold' clsName='title-bar' size={isPC ? '16' : '14'} color='gray600'>{t('Title.OrderRouting')}</Text>
        <div className='start-bar'>
          <div>
            <div className='coin-item'>
              <img src={_getCoinImgUrl(fromTokenSymbol)} alt='' /> 
              <i></i>
            </div>
            <Text kind='Bold' size={isPC ? '14' : '12'} type='english' color='text-main'>{_numComma(amountIn, 4)} {fromTokenSymbol}</Text>
          </div>
          <div>
            <Text kind='Bold' size={isPC ? '14' : '12'} type='english' color='text-main'>{_numComma(amountOut, 4)} {toTokenSymbol}</Text>
            <div className='coin-item'>
              <img src={_getCoinImgUrl(toTokenSymbol)} alt='' /> 
              <i></i>
            </div>
          </div>
        </div>
        <div className='router-bar'>{routerRender()}</div>
      </>
    )
  }

  return (
    <RoutingStyle>
      { routerList.length > 0 ? contentRender() : <NoData /> }
    </RoutingStyle>
  )
}

export default withRouter(Routing)
