import React, { useContext } from 'react'
import { ConfigContext, SwapManageContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import ScImg from '@/components/Section/ScImg'
import { LeftSvg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { InfoStyle } from './styled'


const Info:React.FC<RouterProps> = ({ t, navigate }) => {

  const { linkList } = useCommon()
  
  const { isPC } = useContext(ConfigContext)

  const { poolInfo } = useContext(SwapManageContext)

  const { pool_symbol } = poolInfo

  const jumpHandle = () => {
    navigate(linkList['Pool'])
  }

  // 渲染 - 面包屑
  const crumbsRender = () => {
    const pcCon = (
      <>
        <div className='crumbs-bar' onClick={jumpHandle}>
          <LeftSvg />
          <Text color='primary500'>{t('Link.Pool')}</Text>
        </div>
        <div className='double-key'>
          { pool_symbol && <ScImg kind='2' symbol={pool_symbol} /> }
          <Text kind='Bold' size='32'>{pool_symbol}</Text>
        </div>
      </>
    )
    const h5Con = (
      <div className='crumbs-bar' onClick={jumpHandle}>
        <LeftSvg />
        <div className='double-key'>
          { pool_symbol && <ScImg kind='3' symbol={pool_symbol} /> }
          <Text kind='Bold' size='20'>{pool_symbol}</Text>
        </div>
      </div>
    )
    return isPC ? pcCon : h5Con
  }

  return (
    <InfoStyle>{ crumbsRender() }</InfoStyle>
  )
}

export default withRouter(Info)
