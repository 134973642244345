import { rem } from '@/theme'
import styled from 'styled-components'

const InterimBridgeExchangeStyle = styled.div`

	${({ theme }) => theme.align_center};
	background: ${({ theme }) => theme.gray50};
	border: 1px solid ${({ theme }) => theme.gray100};
	div.text-module {
		position: relative;
	}

	// PC
	@media screen and (min-width: 540px) { 
		width: 440px;
		height: 445px;
		padding: 24px;
		border-radius: 24px;
		div.text-module {
			margin-top: 5px;
			left: -30px;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		height: ${rem(367)};
		padding: ${rem(16)}; 
		border-radius: ${rem(16)};
		div.text-module {
			margin-top: ${rem(5)};
			left: ${rem(-25)};
		}
	}
`

const InterimBridgeInfoStyle = styled.div`
	& > div {
		${({ theme }) => theme.align_center};
		border: 1px solid ${({ theme }) => theme.gray100};
		background: ${({ theme }) => theme.white};
		div.text-module {
			position: relative;
		}
	}
	// PC
	@media screen and (min-width: 540px) { 
		width: 400px;	
		margin-left: 24px;
		& > div:nth-child(1) {
			height: 198px;
			padding: 20px;
			border-radius: 20px;
		}
		& > div:nth-child(2) {
			height: 168px;
			margin-top: 24px;
			border-radius: 20px;
		}
		& > div {
			div.text-module {
				margin-top: 5px;
				left: -30px;
			}
		}
	}
	// H5
	@media screen and (max-width: 540px) {
		margin-top: ${rem(20)};
		& > div:nth-child(1) {
			height: ${rem(218)};
			padding: ${rem(16)};
			border-radius: ${rem(12)};
		}
		& > div:nth-child(2) {
			height: ${rem(164)};
			margin-top: ${rem(20)};
			border-radius: ${rem(12)};
		}
		& > div {
			div.text-module {
				margin-top: ${rem(5)};
				left: ${rem(-25)};
			}
		}
	}
`


export {
	InterimBridgeExchangeStyle,
  InterimBridgeInfoStyle
}