import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigDispatchContext, ConfigContext, SwapManageContext, SwapContext } from '@/utils/contextStatic'
import { chainCoin, contractAddressList } from '@/utils/addressStatic'
import useContractSwapV2 from '@/hooks/useContract/useContractSwapV2'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import BigNumber from 'bignumber.js'

/**
 * 业务 - Swap流动性管理
 */
export default function useBusinessSwapLiquidity(handle:any) {

  const t:any = useTranslation()['t']
  
  const { slippageParam } = useContext(SwapContext)

  const { poolInfo } = useContext(SwapManageContext)
  
  const { _getLpInsideTokenAmount } = usePublicErc20()
  
  const configDispatch:any = useContext(ConfigDispatchContext)

  const { chainSign, updateConfig } = useContext(ConfigContext)

  const { _addSwapLiquidityV2, _addSwapLiquidityEthV2, _removeSwapLiquidityV2, _removeSwapLiquidityEthV2 } = useContractSwapV2()

  const currentContract = contractAddressList[chainSign]['symbol']['current']

  const { pool_symbol, last_balance, pair } = poolInfo

  const { UPDATE_POOL_TOKEN } = updateConfig

  const { slippage } = slippageParam

  /**
   * 添加 - 流动性
   * @param {String} tokenA       代币A
   * @param {String} tokenB       代币B
   * @param {String} amountA      希望存入的代币A数量
   * @param {String} amountB      希望存入的代币B数量
   * @param {String} callback     回调函数
   */ 
  const _addLiquidityTokenHandle = async(tokenA:string, tokenB:string, amountA:string, amountB:string, callback:any, poolName=pool_symbol) => {
    handle(2)
    let text = `${t("Button.AddLiquidity")} - ${poolName}`
    configDispatch({ type: 'changeToast', payload: {status: true, kind: 'wait', text} })
    // 执行成功
    const successHandle = async(res:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'submit', address: res.hash} })
      const pledgeInfo = await res.wait()
      if(pledgeInfo.status === 1) {
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'success', timing: true, address: res.hash, text} })
        configDispatch({ type: 'changeUpdate', payload: { UPDATE_POOL_TOKEN: !UPDATE_POOL_TOKEN } })
        callback()
      } else {
        handle(3)
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text} })
      }
    }
    // 执行失败
    const failedHandle = (err:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text } })
      handle(3)
      console.log({err})
    }
    const isChainCoinA = chainCoin === tokenA
    const isChainCoinB = chainCoin === tokenB
    const minAmountA = new BigNumber(1-(slippage/100)).times(amountA).toFixed(18)
    const minAmountB = new BigNumber(1-(slippage/100)).times(amountB).toFixed(18)
    if(!isChainCoinA && !isChainCoinB) {
      // ERC-20
      _addSwapLiquidityV2(tokenA, tokenB, amountA, amountB, minAmountA, minAmountB).then(async(res:any) => {
        successHandle(res)
      }).catch((err:any)=>{
        failedHandle(err)
      })
    } else {
      // 原生
      const token = isChainCoinA ? tokenB : tokenA
      const amountErc20 = isChainCoinA ? amountB : amountA
      const amountEth = isChainCoinA ? amountA : amountB
      const minAmountErc20 = isChainCoinA ? minAmountB : minAmountA
      const minAmountEth = isChainCoinA ? minAmountA : minAmountB
      _addSwapLiquidityEthV2(token, amountErc20, amountEth, minAmountErc20, minAmountEth).then(async(res:any) => {
        successHandle(res)
      }).catch((err:any)=>{
        failedHandle(err)
      })
    }
  }

  /**
   * 移除 - 流动性
   * @param {String} tokenA       代币A
   * @param {String} tokenB       代币B
   * @param {String} amount       要移除的代币数量
   * @param {String} callback     回调函数
   */
   const _removeLiquidityTokenHandle = async(tokenA:string, tokenB:string, amount:string, callback:any) => {
    handle(2)
    let text = `${t("Button.RemoveLiquidity")} ${amount} ${pool_symbol}`
    configDispatch({ type: 'changeToast', payload: {status: true, kind: 'wait', text} })
    // 执行成功
    const successHandle = async(res:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'submit', address: res.hash} })
      const pledgeInfo = await res.wait()
      if(pledgeInfo.status === 1) {
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'success', timing: true, address: res.hash, text} })
        configDispatch({ type: 'changeUpdate', payload: { UPDATE_POOL_TOKEN: !UPDATE_POOL_TOKEN } })
        callback()
      } else {
        handle(3)
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text} })
      }
    }
    // 执行失败
    const failedHandle = (err:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text } })
      handle(3)
      console.log({err})
    }
    const isChainCoinA = chainCoin === tokenA
    const isChainCoinB = chainCoin === tokenB
    const token = isChainCoinA ? tokenB : tokenA
    const per = new BigNumber(amount).div(last_balance).toString()
    const totalAmountA:any = await _getLpInsideTokenAmount(pair, tokenA === chainCoin ? currentContract : tokenA)
    const totalAmountB:any = await _getLpInsideTokenAmount(pair, tokenB === chainCoin ? currentContract : tokenB)
    const amountA = new BigNumber(per).times(totalAmountA).toString()
    const amountB = new BigNumber(per).times(totalAmountB).toString()
    const minAmountA = new BigNumber(1-(slippage/100)).times(amountA).toFixed(18)
    const minAmountB = new BigNumber(1-(slippage/100)).times(amountB).toFixed(18)
    if(!isChainCoinA && !isChainCoinB) {
      // ERC-20
      _removeSwapLiquidityV2(tokenA, tokenB, amount, minAmountA, minAmountB).then(async(res:any) => {
        successHandle(res)
      }).catch((err:any)=>{
        failedHandle(err)
      })
    } else {
      // 原生
      _removeSwapLiquidityEthV2(token, amount, minAmountA, minAmountB).then(async(res:any) => {
        successHandle(res)
      }).catch((err:any)=>{
        failedHandle(err)
      })
    }
  } 

  return {
    _removeLiquidityTokenHandle,
    _addLiquidityTokenHandle,
  }
}
