import { rem } from '@/theme'
import styled from 'styled-components'

const RoutingStyle = styled.div`

	background: ${({ theme }) => theme.con_bg};
	border: 1px solid ${({ theme }) => theme.gray100};
	div.title-bar {
		text-align: center;
	}
	div.start-bar {
		${({ theme }) => theme.align_between};
		& > div {
			${({ theme }) => theme.start};
			div.coin-item {
				i {
					${({ theme }) => theme.x_center};
					display: block;
					background: ${({ theme }) => theme.primary500};
					border-radius: 50%;
				}
			}
		}
	}
	div.router-bar {
		& > div {
			${({ theme }) => theme.align_around};
			position: relative;
			border-style: dashed;
			border-color: ${({ theme }) => theme.primary200};
			border-width: 0 1px 1px 1px;
			& > div {
				position: relative;
				${({ theme }) => theme.align};
			}
			& > svg {
				position: relative;
			}
			div.plat-key {
				justify-content: right;
			}
			div.arrow-key {
				justify-content: center;
			}
			div.sccoin-module{
				background: ${({ theme }) => theme.text_bg};
			}
		}
	}

	// PC
	@media screen and (min-width: 540px){
		min-height: 300px;
		margin-top: 20px;
		padding: 25px 20px 5px 20px;
		border-radius: 24px;
		div.title-bar {
			margin-bottom: 12px;
		}
		div.start-bar {
			& > div {
				div.coin-item {
					img {
						width: 20px;
						height: 20px;
						margin-bottom: 5px;
					}
					i {
						width: 8px;
						height:8px;
					}
				}
				div.coin-item:nth-child(1) {
					margin-right: 10px;
				}
				div.coin-item:nth-child(2) {
					margin-left: 10px;
				}
			}
		}
		div.router-bar {
			margin: 0 9px;
			& > div {
				border-radius: 0 0 12px 12px;
				& > svg {
					width: 10px;
					height: 10px;
					top: 34px;
				}
				div.plat-key {
					width: 190px;
					div.sccoin-module {
						margin-left: 20px;
					}
				}
				div.rate-key {
					width: 130px;
					div.sccoin-module {
						margin-right: 20px;
					}
				}
				div.arrow-key {
					width: 110px;
				}
				div.sccoin-module {
					padding: 4px 8px;
					border-radius: 20px;
				}
			}
			& > div:not(:first-child) {
				height: 70px;
				& > div, & > svg {
					top: 34px;
				}
			}
			& > div:nth-child(1) {
				height: 62px;
				& > div, & > svg {
					top: 30px;
				}
			}
			& > div:nth-child(2) {
				top: -8px;
			} 
			& > div:nth-child(3) {
				top: -16px;
			} 
			& > div:nth-child(4) {
				top: -24px;
			} 
			& > div:nth-child(5) {
				top: -30px;
			} 
		}
	}

	// H5
	@media screen and (max-width: 540px){
		height: fit-content;
		margin-top: ${rem(16)};
		padding: ${rem(16)} ${rem(5)};
		border-radius: ${rem(16)};
		div.title-bar {
			margin-bottom: ${rem(12)};
		}
		div.start-bar {
			& > div {
				div.coin-item {
					font-size: 0;
					img {
						width: ${rem(16)};
						height: ${rem(16)};
						margin-bottom: ${rem(5)};
					}
					i {
						width: ${rem(5)};
						height:${rem(5)};
					}
				}
				div.coin-item:nth-child(1) {
					margin-right: ${rem(10)};
				}
				div.coin-item:nth-child(2) {
					margin-left: ${rem(10)};
				}
			}
		}
		div.router-bar {
			margin: 0 ${rem(7)};
			& > div {
				border-radius: 0 0 ${rem(6)} ${rem(6)};
				& > svg {
					width: ${rem(10)};
					height: ${rem(10)};
					top: ${rem(24)};
				}
				div.plat-key {
					width: ${rem(120)};
					div.sccoin-module {
						margin-left: ${rem(6)};
					}
				}
				div.rate-key {
					width: ${rem(70)};
					div.sccoin-module {
						margin-right: ${rem(6)};
					}
				}
				div.arrow-key {
					width: ${rem(32)};
				}
				div.sccoin-module {
					padding: ${rem(2)};
					border-radius: ${rem(20)};
				}
			}
			& > div:not(:first-child) {
				height: ${rem(60)};
				& > div, & > svg {
					top: ${rem(29)};
				}
			}
			& > div:nth-child(1) {
				height: ${rem(52)};
				& > div, & > svg {
					top: ${rem(25)};
				}
			}
			& > div:nth-child(2) {
				top: ${rem(-8)};
			} 
			& > div:nth-child(3) {
				top: ${rem(-16)};
			} 
			& > div:nth-child(4) {
				top: ${rem(-24)};
			} 
			& > div:nth-child(5) {
				top: ${rem(-30)};
			} 
		}
	}
`

export {
	RoutingStyle
}




