import React, { useContext } from 'react'
import { ConfigContext, PoolDispatchContext, PoolContext } from '@/utils/contextStatic'
import { TipSvg, SortSvg, Down4Svg, Up4Svg } from '@/utils/icoStatic'
import InterimFarmsBar from '@/components/Interim/InterimFarmsBar'
import ListFarmsBar from '@/components/Listing/ListFarmsBar'
import ScSearch from '@/components/Section/ScSearch'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import ScTab from '@/components/Section/ScTab'
import { _numComma } from '@/utils/comMethod'
import DropDown from '@/components/DropDown'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import NoData from '@/components/NoData'
import { FarmsBarStyle } from './styled'
import Text from '@/components/Text'

const FarmsBar:React.FC<RouterProps> = ({ t, navigate })=> {

  const { linkList } = useCommon()

  const { isPC, account } = useContext(ConfigContext)

  const poolDispatch:any = useContext(PoolDispatchContext)

  const { showPoolsList, filterChainList, currentTvl, barLoadingStatus, filterParam } = useContext(PoolContext)

  const { sortInfo, searchKey, tabOneKey, tabTwoKey, chainKey } = filterParam
  
  const chainIndex = filterChainList.findIndex((item:any) => item['key'] === chainKey)

  const tabOneList = account ? [{key: 'All', text: 'AllFarms'}, {key: 'User', text: 'YourFarms'}] : [{key: 'All', text: 'AllFarms'}]

  // const tabTwoList = [{key: 'Swap', text: 'Swap'}, {key: 'Bridge', text: 'Bridge'},{key: 'Farm', text: 'FarmOnly', imgUrl: FarmImg }]
  const tabTwoList = [{key: 'Swap', text: 'Swap'}, {key: 'Bridge', text: 'Bridge'}]

  const { sortKey, sortBy } = sortInfo

  // 操作 - tab
  const tabHandle = (key:string, type:string) => {
    poolDispatch({ type: 'changeFilterParam', payload: { [type === '1' ? 'tabOneKey' : 'tabTwoKey']: key } })
  }

  // 操作 - 搜索
  const searchHandle = (key:string) => {
    poolDispatch({ type: 'changeFilterParam', payload: { searchKey: key } })
  }

  // 操作 - 链
  const chainHandle = (key:string) => {
    poolDispatch({ type: 'changeFilterParam', payload: { chainKey: key } })
  }

  // 操作 - 排序
  const sortHandle = (key:string) => {
    const sortParam = { sortKey: key, sortBy: '' }
    const byList:any = { asc: 'desc', desc: 'asc'}
    if(key === sortKey) {
      sortParam['sortBy'] = byList[sortBy]
    } else {
      sortParam['sortBy'] = 'desc'
    }
    poolDispatch({ type: 'changeFilterParam', payload: { sortInfo: sortParam } })
  }

  // 渲染 - 过度
  const interimRender = () => {
    const size = 6
    const list:any = []
    for(let i=0; i < size; i++) {
      list.push(<InterimFarmsBar key={`farmsBar_${i}`}/>)
    }
    return list
  }

  // 渲染 - 列表
  const farmsBarListRender = () => {
    const list:any = []
    showPoolsList.forEach((item:any, index:number) => {
      const content = <ListFarmsBar key={`farmsBar_${index}`} data={item} kind={tabOneKey} />
      if(tabOneKey === 'All') list.push(content)
      if(tabOneKey === 'User') {
        const { unStake_lp } = item
        if(+unStake_lp > 0) list.push(content)
      }
    })
    return list
  }

  // 渲染 - THEAD 
  const theadRender = () => {
    const list:any = []
    const theadList:any = {
      'All': [
        { key: '', text: 'Token' },
        { key: '', text: 'Chain' },
        { key: 'pool_tvl', text: 'Liquidity' },
        { key: 'pengding_reward', text: 'PengdingReward' },
        { key: 'volume', text: 'Volume' },
        { key: 'fees', text: 'Fees' },
        { key: 'pool_apy', text: 'APY' },
      ],
      'User': [
        { key: '', text: 'Token' },
        { key: '', text: 'Chain' },
        { key: 'pool_tvl', text: 'Liquidity' },
        { key: 'unStake_lp', text: 'PooledToken' },
        { key: 'stake_lp', text: 'StakedLP' },
        { key: 'user_earn', text: 'MyEarning' },
        { key: 'pool_apy', text: 'APY' },
      ]
    }
    const svgList:any = { 'asc': <Up4Svg />, 'desc': <Down4Svg />, 'init': <SortSvg /> }
    theadList[tabOneKey].forEach((item:any, index:number)=> {
      const { key, text } = item
      const sortType = (sortKey === key) ? sortBy : 'init' 
      list.push(
        <th key={`FarmBarThead_${index}`}>
          {
            key !== 'pool_apy' ? (
              <div className={key ? 'cp' : ''} onClick={() => key && sortHandle(key)}>
                <Text size={isPC ? '14' : '12'} color='gray500'>
                  {t(`Field.${text}`)}{tabOneKey === 'All' && ['volume', 'fees'].includes(key) && `(7D)`}
                </Text>
                { key && svgList[sortType] }
              </div>
            ) : (
              <div className='cp' onClick={() => sortHandle('pool_apy')}>
                <Text size={isPC ? '14' : '12'} color='gray500'>APY</Text>
                <TipSvg />
                { svgList[sortType] }
              </div>
            )
          }
        </th>)
    })
    return list
  }

  // 渲染 - 筛选条件 
  const filterRender = () => {
    const pcContent = (
      <div>
        <div>
          { tabOneKey === 'All' && <ScSearch type='none' placeholder={t('Placeholder.SearchPools')} inputVal={searchKey} callback={(val:string) => searchHandle(val)} /> }
          <ScTab kind='2' data={tabTwoList} currentKey={tabTwoKey} callback={(key:string) => tabHandle(key, '2')} />
        </div>
        <div>
          <FormButton kind='primary' callback={() => navigate(linkList['Create'])}>
            <Text type='specialEnglish' size='16' color='white'>{t('Button.CreatePosition')}</Text>
          </FormButton>
          { 
            tabOneKey === 'All' && filterChainList.length > 0 && (
              <DropDown imgKind='2' imgType='chain' init={chainIndex} dropList={filterChainList} callback={(key:string) => chainHandle(key)} /> 
            )
          }
        </div>
      </div>
    )
    const h5Content = (
      <div>
        <div>
          <FormButton kind='primary' callback={() => navigate(linkList['Create'])}>
            <Text type='specialEnglish' color='white'>{t('Button.CreatePosition')}</Text>
          </FormButton>
          { 
            tabOneKey === 'All' && filterChainList.length > 0 && (
              <DropDown imgKind='3' imgType='chain' init={chainIndex} dropList={filterChainList} callback={(key:string) => chainHandle(key)} /> 
            )
          }
        </div>
        <ScTab kind='2' data={tabTwoList} currentKey={tabTwoKey} callback={(key:string) => tabHandle(key, '2')} />
        { tabOneKey === 'All' && <ScSearch placeholder={t('Placeholder.SearchPools')} inputVal={searchKey} callback={(val:string) => searchHandle(val)} /> }
      </div>
    )
    return isPC ? pcContent : h5Content
  }

  return (
    <FarmsBarStyle>
      <div className='filter-bar'>
        <div>
          <ScTab data={tabOneList} currentKey={tabOneKey} callback={(key:string) => tabHandle(key, '1')} />
          {
            tabOneKey === 'All' && (
              <div className='tvl-key'>
                <Text size={isPC ? '18' : '14'} color='gray600' type='specialEnglish'>{t('Field.Tvl')} :</Text>
                <Text kind='Bold' size={isPC ? '18' : '14'} color='gray600' type='specialNumber'>$ {_numComma(currentTvl)}</Text>
              </div>
            )
          }
        </div>
        { filterRender() }
      </div>
      <div className='table-bar'>
        <div>
          <table>
            <colgroup>
              <col width='20%' />
              <col width='17%' />
              <col width='14%' />
              <col width='14%' />  
              <col width='14%' />  
              <col width='11%' />  
              <col width='10%' />  
            </colgroup>
            <thead>
              <tr>{ theadRender() }</tr>
            </thead>
            <tbody>
              { 
                barLoadingStatus ? (
                  <>{ interimRender() }</>
                ) : (
                  <>{ farmsBarListRender().length <= 0 ? <tr><td colSpan={7}><NoData /></td></tr> : farmsBarListRender() }</>
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </FarmsBarStyle>
  )
}

export default withRouter(FarmsBar)

