// 多语言列表
const langList:any = {
  'en':{name : 'English', sign: 'EN', text: 'en-us'},
  'zh-CN':{name : '简体中文', sign: 'CN', text: 'zh-cn'},
  'zh-HK': {name : '繁体中文', sign: 'HK', text: 'zh-hk'},
}

export {
  langList
}
