import { rem } from '@/theme'
import styled from 'styled-components'

const PoolCreateStyle = styled.div`

	div.bg-bar {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		background: ${({ theme }) => theme.home_bg};
	}
	main {
		position: relative;
	}

	// PC
	@media screen and (min-width: 540px){
		div.bg-bar {
			height: 530px;
		}
		main {
			z-index: 1;
			${({ theme }) => theme.mainContent};
			margin-top: 30px;
			margin-bottom: 100px;
			& > div:nth-child(2) {
				${({ theme }) => theme.x_center};
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		div.bg-bar {
			height: ${rem(530)};
		}
		main {
			z-index: 1;
			margin-top: ${rem(56)};
			margin-bottom: ${rem(90)};
			padding: 0 ${rem(16)};
		}
  }
`

export {
	PoolCreateStyle
}




