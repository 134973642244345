import React, { useContext } from 'react'
import { InterimRecordStyle, SkeletonLabelStyle } from './styled'
import { ConfigContext } from '@/utils/contextStatic'


const InterimRecord:React.FC = () => {

  const { isPC } = useContext(ConfigContext)

  return (
    <InterimRecordStyle>
      <td><SkeletonLabelStyle active /></td>
      { isPC && <td><SkeletonLabelStyle active /></td> }
      <td><SkeletonLabelStyle active /></td>
      { isPC && <td><SkeletonLabelStyle active /></td> }
    </InterimRecordStyle>
  )
}

export default InterimRecord