import { rem } from '@/theme'
import styled from 'styled-components'

const GasStyle = styled.div`

	background: ${({ theme }) => theme.white};
	transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border: 1px solid ${({ theme }) => theme.gray100};
	overflow: hidden;
	div.collapse-bar {
		${({ theme }) => theme.align_between};
		background: ${({ theme }) => theme.text_bg};
		& > div {
			${({ theme }) => theme.align};
		}
		& > div:nth-child(1) {
			& > span {
				${({ theme }) => theme.align};
			}
		}
		& > div:nth-child(2) {
			& > svg {
				transform: rotate(-90deg);
			}
		}
	}
	div.field-bar {
		& > div:nth-child(2) {
			${({ theme }) => theme.align};
			div.field-item {
				background: ${({ theme }) => theme.text_bg};
				border-width: 1px;
				border-style: solid;
				border-color: ${({ theme }) => theme.text_bg};
			}
			div.active {
				border-color: ${({ theme }) => theme.primary500}
			}
		}
		& > div:nth-child(3) {
			${({ theme }) => theme.align};
		}
	}
	&.active {
		div.collapse-bar {
			& > div:nth-child(2) {
				& > svg {
					transform: rotate(180deg);
				}
			}
		}
	}

	// PC
	@media screen and (min-width: 540px){
		height: 54px;
		margin-top: 24px;
		border-radius: 8px;
		div.collapse-bar {
			padding: 16px;
			border-radius: 8px;
			cursor: pointer;
			& > div:nth-child(1) {
				svg {
					width: 16px;
					height: 16px;
					margin-left: 6px;
				}
			}
			& > div:nth-child(2) {
				& > svg {
					width: 12px;
					height: 12px;
					margin-left: 6px;
				}
			}
		}
		div.field-bar {
			padding: 15px;
			& > div:nth-child(2) {
				margin-top: 6px;
				div.field-item {
					padding: 4px 8px;
					cursor: pointer;
					border-radius: 6px;
				}
				div.field-item:not(:last-child) {
					margin-right: 16px;
				}
			}
			& > div:nth-child(3) {
				margin-top: 6px;
				& > div:nth-child(1) {
					margin-right: 3px;
				}
			}
		}
		&.active {
			height: 169px;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		height: ${rem(48)};
		margin-top: ${rem(16)};
		border-radius: ${rem(8)};
		div.collapse-bar {
			height: ${rem(48)};
			padding: 0 ${rem(12)};
			border-radius: ${rem(8)};
			& > div:nth-child(1) {
				svg {
					width: ${rem(16)};
					height: ${rem(16)};
					margin-left: ${rem(2)};
				}
			}
			& > div:nth-child(2) {
				& > svg {
					width: ${rem(12)};
					height: ${rem(12)};
					margin-left: ${rem(2)};
				}
			}
		}
		div.field-bar {
			padding: ${rem(12)};
			& > div:nth-child(2) {
				margin-top: ${rem(6)};
				div.field-item {
					padding: ${rem(4)} ${rem(8)};
					cursor: pointer;
					border-radius: ${rem(6)};
				}
				div.field-item:not(:last-child) {
					margin-right:  ${rem(16)};
				}
			}
			& > div:nth-child(3) {
				margin-top: ${rem(6)};
				& > div:nth-child(1) {
					margin-right: ${rem(3)};
				}
			}
		}
		&.active {
			height: ${rem(157)};
		}
	}
`

export {
	GasStyle
}




