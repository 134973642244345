import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import Connect from '@/components/Header/Connect'
import Chain from '@/components/Header/Chain'
import Logo from '@/components/Header/Logo'
import More from '@/components/Header/More'
import Nav from '@/components/Header/Nav'
import FormButton from '../FormButton'
import { HeaderStyle } from './styled'
import Text from '@/components/Text'

const Header:React.FC = ()=> {

  const { eventAmount, isPC, account } = useContext(ConfigContext)

  return (
    <HeaderStyle>
      <div className='left-bar'>
        <Logo />
        { isPC && <Nav /> }
      </div>
      <div className='right-bar'>
        { account && <Chain /> }
        { eventAmount > 0 && (
          <FormButton size='small' kind='loading'>
            <Text type='specialEnglish' color='white'>{ eventAmount } Pending</Text>
          </FormButton>
        )} 
        <Connect />
        <More />
      </div>
    </HeaderStyle>
  )
}

export default Header
