import React, { useState, useRef, useEffect } from 'react'
import useOnClickOutside from '@/hooks/useOutsideClick'
import ScCoin from '@/components/Section/ScCoin'
import { _check_en } from '@/utils/checkMethod'
import { RouterProps } from '@/utils/interface'
import { Down3Svg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import useToggle from '@/hooks/useToggle'
import { DropDownStyle } from './styled'
import Text from '@/components/Text'

interface ChildProps extends RouterProps {
  isSpecial?: boolean
  imgType?: string
  imgKind?: string
  dropList: any
  callback: any
  init: number
}

const DropDown:React.FC<ChildProps> = ({dropList, init=0, callback, imgType='', imgKind='1', isSpecial=false})=> {

  const dropRef:any = useRef()

  const [dropInd, setDropInd] = useState<number>(init)

  const [ status, setStatus ] = useToggle(false)

  const { text, sign } = dropList[dropInd]

  const kindList:any = {
    '1': { showKind: '11', optionKind: '4', showSize: '20', optionSize: '16' },
    '2': { showKind: '11', optionKind: '11', showSize: '16', optionSize: '16' },
    '3': { showKind: '15', optionKind: '15', showSize: '14', optionSize: '14' }
  }

  const { showKind, optionKind, showSize, optionSize } = kindList[imgKind]

  useOnClickOutside(dropRef, status ? setStatus : undefined)

  useEffect(() => setDropInd(init), [init])

  // 下拉列表渲染
  const dropListRender = () => {
    const list:any = []
    dropList.forEach((item:any, index:number) => {
      const { text, sign } = item
      const isActive = dropInd === index
      const color = isActive ? 'text_main' : 'gray500'
      list.push(
        <li className={isActive  ? 'active' : ''} key={`dropList${index}`} onClick={() => selectHandle(index)}>
          {
            imgType ? (
              <>
                {
                  sign ? (
                    <ScCoin kind={showKind} name={sign ? sign : text} content={text} imgType={imgType} color={color} /> 
                  ) : (
                    <Text size={showSize} color={color} kind={isActive ? 'Bold' : 'Light'}>{text}</Text>
                  )
                }
              </>
              
            ) : (
              <Text size={showSize} color={color} kind={isActive ? 'Bold' : 'Light'}>{text}</Text>
            )
          }
        </li>
      )
    })
    return list
  }

  // 下拉选择
  const selectHandle = (index:number) => {
    setDropInd(index)
    callback(dropList[index]['key'])
  }

  return (
    <DropDownStyle className='dropdown-module' $status={status}>
      <div onClick={setStatus} ref={dropRef}>
        { 
          imgType ? (
            <>
              {
                sign ? (
                  <ScCoin isSpecial={isSpecial} kind={optionKind} name={sign} content={text} imgType={imgType}/> 
                ) : (
                  <Text size={optionSize} en={_check_en(text) ? '1' : ''}>{text}</Text>
                )
              }
            </>
          ) : (
            <Text size={optionSize} en={_check_en(text) ? '1' : ''}>{text}</Text>
          )
        }
        <Down3Svg />
      </div>
      {status && <ul>{dropListRender()}</ul>}
    </DropDownStyle>
  )
}

export default withRouter(DropDown)
