import React, { useRef, useContext } from 'react'
import usePublicWallet from '@/hooks/usePublic/usePublicWallet'
import { Down5Svg, ErrorChainSvg } from '@/utils/icoStatic'
import useOnClickOutside from '@/hooks/useOutsideClick'
import { ConfigContext } from '@/utils/contextStatic'
import { _getCoinImgUrl } from '@/utils/urlMethod'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import useToggle from '@/hooks/useToggle'
import useCommon from '@/hooks/useCommon'
import { ChainStyle } from './styled'
import Text from '@/components/Text'

const Chain:React.FC<RouterProps> = ({ t })=> {

  const dropRef:any = useRef()

  const { _getChainProperty } = useCommon()

  const { _switchChainOperate } = usePublicWallet()

  const { isPC, chainSign, chainName, supportChainList, chainId } = useContext(ConfigContext)

  const chainSymbol = _getChainProperty(chainSign, 'chain_symbol')

  const [ status, setStatus ] = useToggle(false)

  useOnClickOutside(dropRef, status ? setStatus : undefined)

  // 链列表渲染
  const chainRenderHandle = () => {
    const list:any[] = []
    supportChainList.forEach((item:any, index:number) => {
      const { chain_name, chain_symbol } = item
      const isActive = chainName === chain_name 
      list.push(
        <li key={`chainRender${index}`} onClick={() => _switchChainOperate(item, setStatus)}>
          <img src={_getCoinImgUrl(chain_symbol, 'chain')} alt='' />
          <Text
            color={isActive ? 'text_main' : 'gray500'} 
            kind={isActive ? 'Bold' : 'Light'} 
            size={isPC ? '14' : '12'} 
            hovercolor='text_main'>
              {chain_name}
          </Text>
        </li>
      )
    })
    return list
  }

  return (
    <ChainStyle ref={dropRef}>
      <div className="show-bar" onClick={ () => (chainSign === +chainId) && setStatus()}>
        {
          chainSign === +chainId ? (
            <>
              {
                !isPC ? (
                  <img src={_getCoinImgUrl(chainSymbol, 'chain')} alt='' />
                ): (
                  <>
                    <ScCoin kind='21' name={chainSymbol} content={chainName} imgType='chain'/> 
                    <Down5Svg />
                  </>
                )
              }
            </>
          ) : (
            <>
              { 
                isPC && (
                  <div className='err-item'>
                    <ErrorChainSvg />
                    <Text size={isPC ? '16' : '14'}>{t('State.NetWorkErr')}</Text>
                  </div>
                )
              }
            </>
          )
        }
      </div>
      <ul className={`con-bar ${!status ? 'dn' : ''}`}>{chainRenderHandle()}</ul>
    </ChainStyle>
  )
}

export default withRouter(Chain)
  