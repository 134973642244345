import React from 'react'
import Text from '@/components/Text'
import { _getCoinImgUrl } from '@/utils/urlMethod'
import useCommon from '@/hooks/useCommon'
import { ScChainStyle } from './styled'

interface ChildProps {
  chainSign: number
  kind: string
}

const ScChain:React.FC<ChildProps> = ({ kind, chainSign })=> {

  const { _getChainProperty } = useCommon()

  const chainSymbol = _getChainProperty(chainSign, 'chain_symbol')

  const chainName = _getChainProperty(chainSign, 'chain_name')

  const sizeList:any = { 'bar': 14, 'card': 14, 'pop': 12 }

  return (
    <ScChainStyle className='scchain-module' kind={kind} $sign={chainSign}>
      <img src={_getCoinImgUrl(chainSymbol, 'chain')} alt='' />
      <Text size={sizeList[kind]} type='english'>{chainName}</Text>
    </ScChainStyle>
  )
}

export default ScChain

