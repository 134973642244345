import { rem } from '@/theme'
import styled from 'styled-components'

const sizeList:any = {
	'bar': { pt: 4, pl: 6, wh: 20 },
	'card': { pt: 2, pl: 5, wh: 20 },
	'pop': { pt: 0, pl: 6, wh: 14 }
}

const ScChainStyle = styled.div<{kind: string, $sign:number}>`

	${({ theme }) => theme.align};
	width: fit-content;
	background: ${({ theme, $sign }) => theme.sc_chain_hue[$sign]['bg']};
	span {
		color: ${({ theme, $sign }) => theme.sc_chain_hue[$sign]['cl']};
	}

	// PC
	@media screen and (min-width: 540px) { 
		padding: ${({ kind }) => `${sizeList[kind]['pt']}px ${sizeList[kind]['pl']}px` };
		border-radius: 6px;
		img {
			width: ${({ kind }) => `${sizeList[kind]['wh']}px` };
			height: ${({ kind }) => `${sizeList[kind]['wh']}px` };
			margin-right: 5px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		padding: ${({ kind }) => `${rem(sizeList[kind]['pt'])} ${rem(sizeList[kind]['pl'])}` };
		border-radius: ${rem(6)};
		img {
			width: ${({ kind }) => `${rem(sizeList[kind]['wh'])}` };
			height: ${({ kind }) => `${rem(sizeList[kind]['wh'])}` };
			margin-right: ${rem(5)};
		}
	}
`

export {
  ScChainStyle
}


