import React from 'react'
import { SearchSvg } from '@/utils/icoStatic'
import FormInput from '@/components/FormInput'
import { ScSearchStyle } from './styled'

interface ChildProps {
  placeholder:string
  inputVal: string
  callback: any
  type?: string
}

const ScSearch:React.FC<ChildProps> = ({ placeholder, inputVal, type='normal', callback })=> {

  return (
    <ScSearchStyle className='scSearch-module'>
      <SearchSvg />
      <FormInput type={type} placeholder={placeholder} init={inputVal} callback={callback}/>
    </ScSearchStyle>
  )
}

export default ScSearch

