import axios from '@/utils/request'
import { 
  GET_USER_CREATE_POOLS_INFO,
  GET_USER_CREATE_POOLS_LIST,
  GET_POOLS_LIQUIDITY_LIST,
  GET_POOLS_LIQUIDITY_INFO,
  GET_BRIDGE_TOKENS_LIST,
  GET_PROTOCOLS_ROUTER,
  GET_TOKEN_PRICE_LIST,
  GET_POOL_TOKEN_INFO,
  GET_TRADE_HISTORY,
  GET_CHAIN_LIST,
  GET_TOKEN_LIST,
  GET_POOLS_LIST } from './api'

// 获取 - 目标链双币池子信息 
const getPoolsLpsInfo = (body: any) => {
  return axios.get(GET_POOLS_LIQUIDITY_INFO, body)
}

// 获取 - 跨链桥支持代币列表
const getBridgeTokensList = (body: any) => {
  return axios.get(GET_BRIDGE_TOKENS_LIST, body)
}

// 获取 - 指定链的交易协议路由
const getProtocolsRouter = (body: any) => {
  return axios.get(GET_PROTOCOLS_ROUTER, body)
}

// 获取 - 目标链单币池子信息 
const getPoolTokenInfo = (body: any) => {
  return axios.get(GET_POOL_TOKEN_INFO, body)
}

// 获取 - 双币池列表 
const getPoolsLpsList = () => {
  return axios.get(GET_POOLS_LIQUIDITY_LIST)
}

// 获取 - 链统一代币行情价格 
const getChainTokenPrice = () => {
  return axios.get(GET_TOKEN_PRICE_LIST)
}

// 获取 - 单币池列表 
const getPoolsList = () => {
  return axios.get(GET_POOLS_LIST)
}

// 获取 - 支持的链列表 
const getChainList = () => {
  return axios.get(GET_CHAIN_LIST)
}

// 获取 - 用户创建池子列表
const getUserCreatePoolsList = (chainId: number, useAdr:string) => {
  return axios.get(GET_USER_CREATE_POOLS_LIST(chainId, useAdr))
}

// 获取 - 用户创建的池子信息
const getUserCreatePoolsInfo = (chainId: number, poolAdr:string) => {
  return axios.get(GET_USER_CREATE_POOLS_INFO(chainId, poolAdr))
}

// 获取 - swap币种列表
const getTokenList = (chainId: number) => {
  return axios.get(GET_TOKEN_LIST(chainId))
}

// 获取 - 交易记录
const getTradeHistory = (chainId: number, body:any) => {
  return axios.get(GET_TRADE_HISTORY(chainId), body)
}

export {
  getUserCreatePoolsInfo,
  getUserCreatePoolsList,
  getBridgeTokensList,
  getChainTokenPrice,
  getProtocolsRouter,
  getPoolTokenInfo,
  getPoolsLpsInfo,
  getTradeHistory,
  getPoolsLpsList,
  getTokenList,
  getChainList,
  getPoolsList,
}
