import { rem } from '@/theme'
import styled from 'styled-components'

const sizeList:any = {
	'1': { dlf: -2, wlf: -8, mr: 2, bw: 2, wh: 30 },
	'2': { dlf: -2, wlf: -12, mr: 0, bw: 2, wh: 44 },
	'3': { dlf: -2, wlf: -12, mr: 2, bw: 2, wh: 34 },
	'4': { dlf: -2, wlf: -8, mr: 0, bw: 2, wh: 28 },
}

const ScImgStyle = styled.div<{kind:string}>`
	position: relative;
	font-size: 0;
	img {
		border-radius: 50%;
		border-style: solid;
		border-color: ${({ theme }) => theme.white};
	}
	img:nth-child(2) {
		position: relative;
	}
	svg:nth-child(3) {
		position: absolute;
	}
	// PC
	@media screen and (min-width: 540px) { 
		left: ${({ kind }) => `${sizeList[kind]['dlf']}px` };
		margin-right: ${({ kind }) => `${sizeList[kind]['mr']}px` };
		img {
			width: ${({ kind }) => `${sizeList[kind]['wh']}px` };
			height: ${({ kind }) => `${sizeList[kind]['wh']}px` };
			border-width: ${({ kind }) => `${sizeList[kind]['bw']}px` };
		}
		img:nth-child(2) {
			left: ${({ kind }) => `${sizeList[kind]['wlf']}px` };
		}
		svg:nth-child(3) {
			left: 38px;
			bottom: 2px;
			width: 14px;
			height: 14px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		left: ${({ kind }) => `${rem(sizeList[kind]['dlf'])}` };
		margin-right: ${({ kind }) => `${rem(sizeList[kind]['mr'])}` };
		img {
			width: ${({ kind }) => `${rem(sizeList[kind]['wh'])}` };
			height: ${({ kind }) => `${rem(sizeList[kind]['wh'])}` };
			border-width: ${({ kind }) => `${rem(sizeList[kind]['bw'])}` };
		}
		img:nth-child(2) {
			left: ${({ kind }) => `${rem(sizeList[kind]['wlf'])}` };
		}
		svg:nth-child(3) {
			left: ${rem(38)};
			bottom: ${rem(2)};
			width: ${rem(14)};
			height: ${rem(14)};
		}
	}
`

export {
  ScImgStyle
}


