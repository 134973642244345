import { rem } from '@/theme'
import styled from 'styled-components'

const PoolStyle = styled.div`

	div.bg-bar {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		background: ${({ theme }) => theme.home_bg};
	}
	main {
		position: relative;
	}

	// PC
	@media screen and (min-width: 540px){
		div.bg-bar {
			height: 530px;
		}
		main {
			z-index: 1;
			${({ theme }) => theme.mainContent};
			padding-top: 30px;
			& > div {
				min-width: 1000px;
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		div.bg-bar {
			height: ${rem(530)};
		}
		main {
			z-index: 1;
			margin-top: ${rem(56)};
			margin-bottom: ${rem(85)};
		}
  }
`

export {
	PoolStyle
}




