import styled from 'styled-components'
import { Suspense, useEffect, useContext, useState, useRef } from 'react'
import { ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'
import PopSelectWallet from '@/components/Pop/PopSelectWallet'
import { Route, Routes, HashRouter } from 'react-router-dom'
import BridgeManage from './BridgeManage/BridgeManage'
import useInitApp from '@/hooks/useInit/useInitApp'
import SwapManage from './SwapManage/SwapManage'
import PoolCreate from './PoolCreate/PoolCreate'
import H5Footer from '@/components/H5Footer'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Toast from '@/components/Toast'
import Bridge from './Bridge/Bridge'
import Swap from './Swap/Swap'
import Pool from './Pool/Pool'

const AppWrapper = styled.div<{$status:boolean}>`
  position: ${({$status}) => $status ? 'fixed' : 'relative'} ;
  width: 100%;
  min-height: 100vh;
  background: ${({theme}) => theme.web_bg};
  overflow: hidden;
	@media screen and (min-width: 540px){
    footer {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
    padding-bottom: 78px;
  }
`

const App = () => {

  useInitApp()

  const timer:any = useRef()

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { isPC, popShowConfig, h5MaskShow } = useContext(ConfigContext)

  const [ showStatus, setShowStatus ] = useState<boolean>(false)

  const { selectWallteState } = popShowConfig

  useEffect(() => {
    timer.current = setTimeout(() => setShowStatus(true), 500)
    return () => clearTimeout(timer.current)
  }, [])

  // 操作 - 弹窗
  const popHandle = (kind:string) => {
    configDispatch({ type: 'changePop', payload: kind })
  }

  // 路由设置
  const _routeSet = () => {
    return (
      <AppWrapper $status={h5MaskShow}>
        <HashRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Swap />} />
            <Route path="swap/:chain_id/:from_contract/:to_contract" element={<Swap />} />
            <Route path="bridge" element={<Bridge />} />
            <Route path="bridge/:chain_id/:token_contract" element={<Bridge />} />
            <Route path="pool">
              <Route path="list" element={<Pool />} />
              <Route path="create" element={<PoolCreate />} />
              <Route path="manage/bridge/:chain_id/:token_symbol" element={<BridgeManage />} />
              <Route path="manage/create/:chain_id/:token_contract" element={<SwapManage />} />
              <Route path="manage/swap/:chain_id/:token_contract" element={<SwapManage />} />
            </Route>
          </Routes>
          {isPC ? <Footer /> : <H5Footer />}
          <Toast />
        </HashRouter>
        {selectWallteState && <PopSelectWallet callback={() => popHandle('selectWallteState')} status={selectWallteState} />}
      </AppWrapper>
    )
  } 

  return (
    <Suspense fallback={null}>
      {showStatus &&  _routeSet()}
    </Suspense>
  )
}

export default App
