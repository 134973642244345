import { useEffect, useContext } from 'react'
import useContractSwapFactoryV2 from '@/hooks/useContract/useContractSwapFactoryV2'
import useContractSwapPairV2 from '@/hooks/useContract/useContractSwapPairV2'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'
import { zeroAddress } from '@/utils/addressStatic'
import { getTokenList } from '@/service'
import BigNumber from 'bignumber.js'

/**
 * 初始化 - PoolCreate
 */

export default function useInitPoolCreate(poolCreateData:any, poolCreateDispatch:any) {

  const { _getLpTotalAmount } = useContractSwapPairV2()
  
  const { _getLpContractAddress } = useContractSwapFactoryV2()

  const { supportChainList, chainSign, account, updateConfig } = useContext(ConfigContext)

  const { createFormParam, liquidityUser, lpAddress } = poolCreateData

  const { tokenAddressA, tokenAddressB, selectNetwork } = createFormParam

  const { _getBalacneOperate } = usePublicErc20()

  const { UPDATE_POOL_TOKEN } = updateConfig

  // 获取 - 支持的代币列表
  const requestTokenList = () => {
    poolCreateDispatch({ type: 'loadingStatus', payload: true })
    getTokenList(selectNetwork).then((res:any) => {
      console.log('interface - bridge支持币种列表【报文】:', res)
      poolCreateDispatch({ type: 'changeForm', payload: { 
        tokenDecimalA: res[0]['decimals'],
        tokenDecimalB: res[1]['decimals'],
        tokenAddressA: res[0]['address'],
        tokenAddressB: res[1]['address'],
        tokenSymbolA: res[0]['symbol'],
        tokenSymbolB: res[1]['symbol'],
        tokenAmountA: '',
        tokenAmountB: ''
      } })
      let list = res
      const importTokenListString = localStorage.getItem(`${selectNetwork}_importTokenList`)
      if(importTokenListString) {
        const importTokenListArr = JSON.parse(importTokenListString)
        list = importTokenListArr.concat(list)
      }
      poolCreateDispatch({ type: 'tokenList', payload: list })
      poolCreateDispatch({ type: 'loadingStatus', payload: false })
    }).catch(() => {
      console.log('interface - bridge支持币种列表【异常】')
    })
  }

  // 获取 - 代币资产
  const getTokenBalance = async(address:string, type:string) => {
    let balance:any = '0'
    if(chainSign === selectNetwork) balance = await _getBalacneOperate(address)
    poolCreateDispatch({ type: type === 'A' ? 'tokenBalanceA' : 'tokenBalanceB', payload: balance })
  }

  // 计算 - 池子份额
  const computeSharePoolHandle = async() => {
    let share:any = '0'
    if(+liquidityUser > 0) {
      share = '100'
      if(lpAddress !== zeroAddress) {
        const lastBalance:any = await _getLpTotalAmount(lpAddress)
        const total = new BigNumber(lastBalance).plus(liquidityUser).toString()
        share = new BigNumber(liquidityUser).div(total).times(100).toString() 
      }
    }
    poolCreateDispatch({ type: 'sharePool', payload: share })
  }

  // 初始化 - 链列表
  useEffect(() => {
    const list = supportChainList.map((item:any) => { 
      const { chain_id, chain_symbol, chain_name } = item
      return { key: chain_id, sign: chain_symbol, text: chain_name }
    })
    poolCreateDispatch({ type: 'chainList', payload: list })
    poolCreateDispatch({ type: 'changeForm', payload: { selectNetwork: chainSign } })
  }, [])

  // 更新 - 池子份额
  useEffect(() => {
    if(lpAddress) computeSharePoolHandle()
  }, [lpAddress, liquidityUser])

  // 更新 - 支持的代币列表
  useEffect(() => {
    if(selectNetwork) {
      requestTokenList()
      poolCreateDispatch({ type: 'changeReset' })
    }
  }, [selectNetwork, account, UPDATE_POOL_TOKEN])

  // 更新 - 代币A资产
  useEffect(() => {
    if(tokenAddressA && account) getTokenBalance(tokenAddressA, 'A')
  }, [tokenAddressA, account])

  // 更新 - 代币B资产
  useEffect(() => {
    if(tokenAddressB && account) getTokenBalance(tokenAddressB, 'B')
  }, [tokenAddressB, account])

  // 更新 - 池子合约地址
  useEffect(() => {
    const getLpAddress = async() => {
      const lpAddress = await _getLpContractAddress(tokenAddressA, tokenAddressB) 
      poolCreateDispatch({ type: 'lpAddress', payload: lpAddress })
    }
    if(tokenAddressA && tokenAddressB && account) getLpAddress()
  }, [tokenAddressA, tokenAddressB, account])

}