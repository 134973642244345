import { rem } from '@/theme'
import styled from 'styled-components'

const PopImportHintStyle = styled.div`
  div.coin-bar {
    ${({ theme }) => theme.align_between};
    border: 1px solid ${({ theme }) => theme.gray200};
  }
  button {
    width: 100%;
  }

  // PC
  @media screen and (min-width: 540px) { 
    margin-top: 25px;
    div.coin-bar {
      margin-top: 20px;
      padding: 16px;
      border-radius: 12px;
    }
    button {
      margin-top: 30px;
    }
  }

  // H5
  @media screen and (max-width: 540px) { 
		margin-top: ${rem(25)};
    div.coin-bar {
      margin-top: ${rem(20)};
      padding: ${rem(16)};
      border-radius: ${rem(12)};
    }
    button {
      margin-top: ${rem(30)};
    }
  }
`

export {
  PopImportHintStyle
}


