import { rem } from '@/theme'
import styled from 'styled-components'

const ListFarmsCardStyle = styled.div`

	background: ${({ theme }) => theme.white};
	border: 1px solid ${({ theme }) => theme.gray200};
	& > div {
		${({ theme }) => theme.align_between};
	}
	
	@media screen and (min-width: 540px) { 
		padding: 16px;
		border-radius: 16px;
		cursor: pointer;
		& > div:nth-child(1) {
			height: 28px;
			margin-bottom: 4px;
			& > img {
				width: 24px;
				height: 24px;
			}
		}
		& > div:nth-child(3) {
			margin-bottom: 4px;
		}
	}

	@media screen and (max-width: 540px) { 
		min-width: ${rem(213)};
		padding: ${rem(12)};
		border-radius: ${rem(16)};
		& > div:nth-child(1) {
			height: ${rem(28)};
			margin-bottom: ${rem(4)};
			& > img {
				width: ${rem(24)};
				height: ${rem(24)};
			}
		}
		& > div:nth-child(3) {
			margin-bottom: ${rem(4)};
		}
	}
`

export {
  ListFarmsCardStyle
}


