import React, { useState, useContext } from 'react'
import InterimFarmsCard from '@/components/Interim/InterimFarmsCard'
import { ConfigContext, PoolContext } from '@/utils/contextStatic'
import ListFarmsCard from '@/components/Listing/ListFarmsCard'
import ScTab from '@/components/Section/ScTab'
import { FarmsCardStyle } from './styled'
import NoData from '@/components/NoData'

const FarmsCard:React.FC = ()=> {

  const { account } = useContext(ConfigContext)

  const tabList = account ? (
    [{key: 'Your', text: 'YourFarms'}, {key: 'Avaliable', text: 'Avaliable'}, {key: 'Top', text: 'TopFarms'}]
  ) : [{key: 'Top', text: 'TopFarms'}]

  const [ tabKey, setTabKey ] = useState<string>(tabList[0]['key'])

  const { yourPoolsCardList, topPoolsList, avaliablePoolsList, cardLoadingStatus } = useContext(PoolContext)

  // tab - 操作
  const tabHandle = (key:string) => {
    setTabKey(key)
  }

  // 渲染 - 过度
  const interimRender = () => {
    const size = 8
    const list:any = []
    for(let i=0; i < size; i++) {
      list.push(<InterimFarmsCard key={`farmsCard_${i}`}/>)
    }
    return list
  }

  // 渲染 - 卡片
  const farmsCardListRender = () => {
    const list:any = []
    const data:any = { 
      "Your": yourPoolsCardList, 
      "Avaliable": avaliablePoolsList, 
      "Top":  topPoolsList
    }
    data[tabKey].forEach((item:any, index:number) => {
      list.push(<ListFarmsCard key={`farmsCard_${index}`} data={item} kind={tabKey} />)
    })
    return list
  }

  return (
    <FarmsCardStyle>
      <ScTab data={tabList} currentKey={tabKey} callback={tabHandle} />
      { 
        cardLoadingStatus ? (
          <div className='list-bar'>{interimRender()}</div>
        ) : (
          <>{farmsCardListRender().length <= 0 ? <NoData /> : <div className='list-bar'>{farmsCardListRender()}</div> }</>
        )
      }
    </FarmsCardStyle>
  )
}

export default FarmsCard

