import { useContext, useRef } from 'react'
import { socketUrl } from '@/utils/addressStatic'
import { ConfigContext, ConfigDispatchContext } from '@/utils/contextStatic'

/**
 * WebSocket
 */

export default function useWebSocket() {

  const timer:any = useRef()

  const socket = new WebSocket(socketUrl)

  const { updateConfig } = useContext(ConfigContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { UPDATE_SWAP_PROTOCOL_ROUTER } = updateConfig

  const updateHandle = () => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      configDispatch({ type: 'changeUpdate', payload: { UPDATE_SWAP_PROTOCOL_ROUTER: !UPDATE_SWAP_PROTOCOL_ROUTER } })
    }, 30000)
  }

  // webSocket - 监听事件
  const _webSocketSwapMonitor = async() => {

    // 链接 - 服务器
    socket.onopen = function (event) {
      console.log('已连接到服务器');
    }

    // 监听 - 事件
    socket.onmessage = (message) => {
      const res = JSON.parse(message.data)
      if(res.event == 'SwapAndTransferPerformed'){
        console.log('原链交易:', res.data)
        updateHandle()
      }
      if(res.event == 'MessageSent'){
        console.log('跨链交易:', res.data)
        updateHandle()
      }
    }

    // 链接 - 关闭
    socket.onclose = function (event) {
      console.log('连接已关闭')
    }

    // 链接 - 错误
    socket.onerror = function (error) {
      console.error('发生错误:', error);
    }
  }

  return {
    _webSocketSwapMonitor
  }
}

