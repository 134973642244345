import { useContext } from 'react'
import { contractAddressList, zeroAddress } from '@/utils/addressStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'
import useCommon from '@/hooks/useCommon'
import BigNumber from 'bignumber.js'

/**
 * API - Bridge
 */
export default function useContractBridge() {

  const { exchangeDeadline } = useCommon()

  const { _getContract, _getDecimals } = usePublicErc20()

  const { account, chainSign } = useContext(ConfigContext)

  const { _inPutFormat, _outPutFormat } = usePublicEthers()

  // 执行合约地址
  const bridgeOperateContract = contractAddressList[chainSign]['operate']['bridge']

  /**
   * 添加 - ERC20代币流动性
   * @param {String} token       要添加的代币的地址
   * @param {String} amount      要添加的代币数量
   */ 
  const _addLiquidityToken = async(token:string, amount:string) => {
    console.log('合约【添加 - ERC20代币流动性(入参):', { token, amount })
    try {
      const decimal = await _getDecimals(token)
      const inputAmount = _inPutFormat(amount, decimal)
      const liquidityContract = await _getContract(bridgeOperateContract, 'BRIDGEABI')
      return liquidityContract.addTokenLiquidity(token, inputAmount, account, exchangeDeadline)
    } catch (error) {
      console.error('合约调用异常【添加 - ERC20代币流动性】:', error)
    }
  }
  
  /**
   * 添加 - 原生代币流动性
   * @param {String} amount      要添加的代币数量
   */ 
  const _addLiquidityETH = async(amount:string) => {
    console.log('合约【添加 - 原生代币流动性】(入参):', { amount })
    try {
      const inputAmount = _inPutFormat(amount)
      const liquidityContract = await _getContract(bridgeOperateContract, 'BRIDGEABI')
      return liquidityContract.addEthLiquidity(inputAmount, account, exchangeDeadline, { value: inputAmount })
    } catch (error) {
      console.error('合约调用异常【添加 - 原生代币流动性】:', error)
    }
  }

  /**
   * 移除 - ERC20代币流动性
   * @param {String} token       要移除的代币的地址
   * @param {String} amount      要移除的代币数量
   */ 
  const _removeLiquidityToken = async(token:string, amount:string) => {
    console.log('合约【移除 - ERC20代币流动性(入参):', { token, amount })
    try {
      const decimal = await _getDecimals(token)
      const inputAmount = _inPutFormat(amount, decimal)
      const liquidityContract = await _getContract(bridgeOperateContract, 'BRIDGEABI')
      return liquidityContract.removeTokenLiquidity(token, inputAmount, account, exchangeDeadline)
    } catch (error) {
      console.error('合约调用异常【移除 - ERC20代币流动性:', error)
    }
  }

  /**
   * 移除 - 原生代币流动性
   * @param {String} amount      要移除的代币数量
   */ 
  const _removeLiquidityETH = async(amount:string) => {
    console.log('合约【移除 - 原生代币流动性】(入参):', { amount })
    try {
      const inputAmount = _inPutFormat(amount)
      const liquidityContract = await _getContract(bridgeOperateContract, 'BRIDGEABI')
      return liquidityContract.removeEthLiquidity(inputAmount, account, exchangeDeadline, { value: inputAmount })
    } catch (error) {
      console.error('合约调用异常【移除 - 原生代币流动性】:', error)
    }
  }

  /**
   * 跨链
   * @param {String} selector              要跨的链选择器
   * @param {String} tokenAddress          要转移代币合约地址
   * @param {String} recipientAddress      在目标链上接收代币的地址
   * @param {String} transferAmount        跨链的金额
   * @param {String} feeToken              用于支付跨链操作费用的代币地址(BaseToken、WrappedBaseToken、Link、TokenAddress)
   * @param {String} estimateGas           源链GAS费用
   */ 
  const _transferTokenToChain = async(selector:string, tokenAddress:string, recipientAddress:string, transferAmount:string, feeToken:string, estimateGas:string) => {
    console.log('合约【跨链】(入参):', { selector, tokenAddress, recipientAddress, transferAmount, feeToken, estimateGas })
    try {
      const decimal = await _getDecimals(tokenAddress)
      const inputAmount = _inPutFormat(transferAmount, decimal)
      const bridgeContract = await _getContract(bridgeOperateContract, 'BRIDGEABI')
      const transferDetails = { 
        targetChainId: selector, 
        tokenAddress, 
        recipientAddress, 
        transferAmount: inputAmount, 
        feeToken, 
        transactionDeadline: exchangeDeadline 
      }
      if(zeroAddress === feeToken) {
        return bridgeContract.transferTokenToChain(transferDetails, { value: _inPutFormat(estimateGas, 18) })
      } else {
        return bridgeContract.transferTokenToChain(transferDetails)
      }
    } catch (error) {
      console.error('合约调用异常【跨链】:', error)
    }
  }

  /**
   * 预估 - 跨链GAS费用
   * @param {String} selector              要跨的链选择器
   * @param {String} tokenAddress          要转移代币合约地址
   * @param {String} recipientAddress      在目标链上接收代币的地址
   * @param {String} transferAmount        跨链的金额
   * @param {String} feeToken              用于支付跨链操作费用的代币地址(BaseToken、WrappedBaseToken、Link、TokenAddress)
   */ 
  const _estimateExecutionFees = async(selector:string, tokenAddress:string, recipientAddress:string, transferAmount:string, feeToken:string) => {
    console.log(`合约【跨链GAS费用】(入参):`, { selector, tokenAddress, recipientAddress, transferAmount, feeToken })
    try {
      const tokenDecimal = await _getDecimals(tokenAddress)
      const inputAmount = _inPutFormat(transferAmount, tokenDecimal)
      const bridgeContract = await _getContract(bridgeOperateContract, 'BRIDGEABI')
      const transferDetails = { 
        targetChainId: selector, 
        tokenAddress, 
        recipientAddress, 
        transferAmount: inputAmount, 
        feeToken, 
        transactionDeadline: exchangeDeadline 
      }
      const { crossChainFee, feeInToken } = await bridgeContract.estimateExecutionFees(transferDetails)
      const gasList:any = {
        crossChainFee: _outPutFormat(crossChainFee, 18),
        feeInToken: _outPutFormat(feeInToken, tokenDecimal)
      }
      return gasList
    } catch (error) {
      console.error(`合约调用异常【跨链GAS费用】`, error)
    }
  }

  /**
   * 跨链交易
   * @param {Array}   swapRoutes            一个或多个交换路由详情，用户可以通过不同的交换路径将代币转换为目标代币
   * @param {Object}  transferDetails       跨链转账的详细信息，定义了最终要发送到目标链上的代币和数量等信息
   * @param {Object}  inputTokenAddress     卖出代币的合约地址
   * @param {String}  totalAmountIn         用户实际成交数量
   * @param {Boolean} isCrossChain          是否要进行跨链操作
   */ 
  const _swapAndTransferTokenToChain = async(swapRoutes:any, transferDetails:any, inputTokenAddress:string, totalAmountIn:string, isCrossChain:boolean) => {
    console.log('合约【跨链交易】(入参):', { swapRoutes, transferDetails, inputTokenAddress, totalAmountIn, isCrossChain })
    try {
      const swapDetails = { swapRoutes, transferDetails, inputTokenAddress, totalAmountIn, isCrossChain }
      const bridgeContract = await _getContract(bridgeOperateContract, 'BRIDGEABI')
      const gasLimit = (await bridgeContract.swapAndTransferTokenToChain.estimateGas(swapDetails)).toString()
      const customizeGasLimit = new BigNumber(gasLimit).times(1.2).toFixed(0)
      console.log('跨链交易Gas费用:', { gasLimit, customizeGasLimit })
      return bridgeContract.swapAndTransferTokenToChain(swapDetails, { gasLimit: customizeGasLimit })
    } catch (error) {
      console.error('合约调用异常【跨链交易】:', error)
    }
  }

  return {
    _swapAndTransferTokenToChain,
    _estimateExecutionFees,
    bridgeOperateContract,
    _removeLiquidityToken,
    _transferTokenToChain,
    _removeLiquidityETH,
    _addLiquidityToken,
    _addLiquidityETH,
  }
}
