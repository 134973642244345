import { rem } from '@/theme'
import styled from 'styled-components'

const sizeList:any = {
	'1': { mr: 16, wh: 40 },
	'2': { mr: 4, wh: 24 },
	'3': { mr: 6, wh: 16 },
	'4': { mr: 8, wh: 24 },
	'5': { mr: 4, wh: 24 },
	'6': { mr: 10, wh: 26 },
	'7': { mr: 6, wh: 30 },
	'8': { mr: 4, wh: 20 },
	'9': { mr: 4, wh: 20 },
	'10': { mr: 6, wh: 20 },
	'11': { mr: 12, wh: 24 },
	'12': { mr: 4, wh: 20 },
	'13': { mr: 8, wh: 22 },
	'14': { mr: 12, wh: 30 },
	'15': { mr: 12, wh: 24 },
	'16': { mr: 10, wh: 24 },
	'17': { mr: 10, wh: 24 },
	'18': { mr: 6, wh: 16 },
	'19': { mr: 6, wh: 15 },
	'20': { mr: 12, wh: 30 },
	'21': { mr: 10, wh: 26 },
}

const ScCoinStyle = styled.div<{kind: string, content: string}>`

	position: relative;
	${({ theme }) => theme.align};
	& > svg:nth-child(3) {
		position: absolute;
		bottom: 0;
	}

	// PC
	@media screen and (min-width: 540px) { 
		& > svg:nth-child(1), img {
			width: ${({ kind }) => `${sizeList[kind]['wh']}px` };
			height: ${({ kind }) => `${sizeList[kind]['wh']}px` };
			margin-right: ${({ kind, content }) => content ? `${sizeList[kind]['mr']}px` : 0 };
		}
		& > svg:nth-child(3) {
			left: 16px;
			width: 14px;
			height: 14px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		& > svg:nth-child(1), img {
			width: ${({ kind }) => `${rem(sizeList[kind]['wh'])}` };
			height: ${({ kind }) => `${rem(sizeList[kind]['wh'])}` };
			margin-right: ${({ kind, content }) => content ? `${rem(sizeList[kind]['mr'])}` : 0 };
		}
		& > svg:nth-child(3) {
			left: ${rem(16)};
			width: ${rem(14)};
			height: ${rem(14)};
		}
	}
`

export {
  ScCoinStyle
}


