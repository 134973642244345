import React, { useContext } from 'react'
import { Wallet2Svg, Down3Svg } from '@/utils/icoStatic'
import { ConfigContext } from '@/utils/contextStatic'
import ScCoin from '@/components/Section/ScCoin'
import FormInput from '@/components/FormInput'
import ScImg from '@/components/Section/ScImg'
import { _numComma } from '@/utils/comMethod'
import useCommon from '@/hooks/useCommon'
import { ScInputStyle } from './styled'
import Text from '@/components/Text'

interface ChildProps {
  isSelect?: boolean
  changeAmount: any
  maxvalue?: string
  balance: string
  symbol: string
  amount: string
  isLp?: boolean
  showFun?: any  // isSelect为true - 必填
  type?: string  // isSelect为true - 必填
}

const ScInput:React.FC<ChildProps> = ({ balance, symbol, amount, changeAmount, showFun, type='A', maxvalue='0', isSelect=false, isLp=false })=> {

  const { _getExchangePrice } = useCommon()

  const { isPC } = useContext(ConfigContext)

  return (
    <ScInputStyle className='scinput-module' $isSelect={isSelect} type={type}>
      <FormInput size='large' init={amount} placeholder='0.0' callback={(val:string) => changeAmount(val, type)} maxvalue={_numComma(maxvalue, 4, false)} />
      <div onClick={() => isSelect && showFun(type)}>
        {
          type === 'AB' ? (
            <div className='double-key'>
              <ScImg kind='4' symbol={symbol} isLp={true} />
              <Text size={isPC ? '20' : '16'} type='specialEnglish'>{symbol}</Text>
            </div>
          ) : (
            <ScCoin isSpecial={true} kind='4' name={symbol} isLp={isLp} />
          )
        }
        { isSelect && <Down3Svg /> }
      </div>
      <div>
        <Wallet2Svg />
        <Text type='number' size='12' color='gray500'>{ _numComma(balance, 4) } {symbol}</Text>
      </div>
      <Text type='number' size='12' color='gray500'>$ {_getExchangePrice(symbol, balance)}</Text>
    </ScInputStyle>
  )
}

export default ScInput

