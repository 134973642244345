import { useEffect, useContext, useRef } from 'react'
import { getTokenList, getBridgeTokensList, getProtocolsRouter, getPoolTokenInfo, getTradeHistory } from '@/service'
import useContractBridgeFee from '@/hooks/useContract/useContractBridgeFee'
import { contractAddressList, chainCoin } from '@/utils/addressStatic'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'
import useCommon from '@/hooks/useCommon'
import BigNumber from 'bignumber.js'

/**
 * 初始化 - Swap
 */

export default function useInitSwap(swapData:any, swapDispatch:any, params:any) {

  let delayModule:any = useRef()

  const { _getBalacneOperate } = usePublicErc20()
  
  const { _estimateExecutionFees } = useContractBridge()

  const { chain_id, from_contract, to_contract } = params
  
  const { _getChainProperty, _getExchangePrice } = useCommon()
  
  const { _getCombinedFees, _getSwapFee } = useContractBridgeFee()

  const { _outPutFormat, _inPutFormat, _isAddress } = usePublicEthers()

  const { chainSign, account, chainId, updateConfig, supportChainList } = useContext(ConfigContext)

  const { swapFormParam, period, tokenFromList, tokenToList, formLoadingStatus, slippageParam } = swapData

  const { fromToken, fromOperateToken, toToken, swapAmount, fromNetwork, toTokenSymbol, fromTokenSymbol, toTokenDecimal, fromTokenDecimal, toNetwork, exchangeToken } = swapFormParam

  const isSupport = supportChainList.find((item:any) => item['chain_id'] === +chain_id)

  const { UPDATE_SWAP_EXCHANGE_END, UPDATE_SWAP_PROTOCOL_ROUTER } = updateConfig

  const requestChainId = isSupport ? chain_id : chainSign


  // 获取 - 目标链单币池子信息
  const requestPoolTokenInfo = () => {
    const param:any = { chain_id: toNetwork, token_symbol: toTokenSymbol }
    console.log('interface - 目标链单币池子信息【入参】:', param)
    getPoolTokenInfo(param).then((res: any) => {
      console.log('interface - 目标链单币池子信息【报文】:', res)
      swapDispatch({ type: 'swapDestPoolInfo', payload: res })
    }).catch(() => {
      console.log('interface - 目标链单币池子信息【异常】')
    })
  }

  // 获取 - 支持币种列表
  const requestTokenList = () => {
    swapDispatch({ type: 'formLoadingStatus', payload: true })
    Promise.all([getTokenList(requestChainId), getBridgeTokensList({ src_chainid: requestChainId })]).then((res:any) => {
      console.log('interface - swap支持币种列表【报文】:', res)
      const list:any = {[requestChainId]:res[0]}
      for(const item of res[1]) {
        const { dest_chainid, dest_token, dest_token_symbol, dest_token_decimals } = item
        if(!list[dest_chainid]) list[dest_chainid] = []
        list[dest_chainid].push({ 
          symbol: dest_token_symbol, 
          address: dest_token, 
          chainId: dest_chainid,
          decimals: dest_token_decimals
        })
      }
      const fromContract = _isAddress(from_contract) ? from_contract : res[0][0]['address']
      const toContract = _isAddress(to_contract) ? to_contract : res[0][1]['address']
      const exchangeTokenInfo = res[0].find((item:any) => item.symbol === res[0][1]['symbol'])
      const fromTokenInfo = from_contract ? res[0].find((item:any) => item.address.toLowerCase() === from_contract.toLowerCase()) : null
      const toTokenInfo = to_contract ? res[0].find((item:any) => item.address.toLowerCase() === to_contract.toLowerCase()) : null
      const fromOperateToken = chainCoin !== fromContract ? fromContract : contractAddressList[requestChainId]['symbol']['current']
      swapDispatch({ type: 'changeForm', payload: { 
        fromTokenDecimal: fromTokenInfo ? fromTokenInfo['decimals'] : res[0][0]['decimals'],
        fromTokenSymbol: fromTokenInfo ? fromTokenInfo['symbol'] : res[0][0]['symbol'],
        fromNetwork: +requestChainId,
        fromToken: fromContract,
        fromOperateToken,
        exchangeToken: _isAddress(to_contract) ? to_contract : exchangeTokenInfo['address'],
        toTokenDecimal: toTokenInfo ? toTokenInfo['decimals'] : res[0][1]['decimals'],
        toTokenSymbol: toTokenInfo ? toTokenInfo['symbol'] : res[0][1]['symbol'],
        toNetwork: +requestChainId,
        toToken: toContract,
        swapAmount: ''
      } })
      swapDispatch({ type: 'tokenFromList', payload: res[0] })
      swapDispatch({ type: 'tokenToList', payload: list })
      swapDispatch({ type: 'formLoadingStatus', payload: false })
    }).catch(() => {
      console.log('interface - swap支持币种列表【异常】')
    })
  }

  // 获取 - 指定链的交易协议路由
  const requestProtocolsRouter = async() => {
    swapDispatch({ type: 'inputLoadingStatus', payload: true })
    const param = {
      token_in: fromOperateToken,
      token_out : exchangeToken,
      chain_id: fromNetwork,
      amount: _inPutFormat(swapAmount, fromTokenDecimal),
      impact: slippageParam['slippage']/100   
    }
    getProtocolsRouter(param).then(async(res:any)=> {
      console.log('interface - 指定链的交易协议路由【报文】:', res)
      const { protocol_swap_data, total_amount_out, total_swap_fee } = res
      // 初始化 - 路由列表
      const routerList:any = []
      // 初始化 - 可交易数量
      let totalAmountIn = '0'
      // 初始化 - 非CCDEX平台过路费
      let unCcdexTolls = '0'
      // 初始化 - SWAP交易后兑换的数量
      let afterExchangeAmount = total_amount_out
      // 获取 - 非CCDEX平台过路费百分比
      let swapFeeRate = await _getSwapFee(exchangeToken)
      swapFeeRate = new BigNumber(swapFeeRate).div(10000).toString()
      if(protocol_swap_data.length) {
        for(const item of protocol_swap_data) {
          const { swap_fee, protocol_name, amountOutMin, price, amountIn } = item
          // 初始化 - 单一router交易后兑换的数量
          let singleAfterExchangeAmount = amountOutMin
          // 初始化 - 单一route交易费用(平台手续费+过路费)
          let singleExchangeFeeU = _outPutFormat(swap_fee, fromTokenDecimal)
          singleExchangeFeeU = _getExchangePrice(fromTokenSymbol, singleExchangeFeeU, false)
          // 累加 - 可交易数量
          totalAmountIn = new BigNumber(amountIn).plus(totalAmountIn).toFixed()
          totalAmountIn = totalAmountIn.split('.')[0]
          if(!protocol_name.includes('CCDex')) {
            // 累加 - 非CCDEX平台过路费
            const singleUnCcdexTolls = new BigNumber(amountOutMin).times(swapFeeRate).toFixed(0)
            unCcdexTolls = new BigNumber(unCcdexTolls).plus(singleUnCcdexTolls).toFixed(0)
            // 累减 - 非CCDEX平台过路费 - 获得 - SWAP交易后兑换的数量
            afterExchangeAmount = new BigNumber(afterExchangeAmount).minus(singleUnCcdexTolls).toFixed()
            afterExchangeAmount = afterExchangeAmount.split('.')[0]
            // 获取 - 单一router交易后兑换的数量
            singleAfterExchangeAmount = new BigNumber(singleAfterExchangeAmount).minus(singleUnCcdexTolls).toFixed()
            singleAfterExchangeAmount = singleAfterExchangeAmount.split('.')[0]
            singleAfterExchangeAmount = _outPutFormat(singleAfterExchangeAmount, toTokenDecimal)
            // 获取 - 单一route交易费用(平台手续费+过路费)
            let singleUnCcdexTollsU = _outPutFormat(singleUnCcdexTolls, toTokenDecimal)
            singleUnCcdexTollsU =  _getExchangePrice(toTokenSymbol, singleUnCcdexTollsU, false)
            singleExchangeFeeU = new BigNumber(singleExchangeFeeU).plus(singleUnCcdexTollsU).toString()
          } else {
            // 获取 - 单一router交易后兑换的数量
            singleAfterExchangeAmount = singleAfterExchangeAmount.split('.')[0]
            singleAfterExchangeAmount = _outPutFormat(singleAfterExchangeAmount, toTokenDecimal)
          }
          // 获取 - 每个DEX交易占比
          const proportion = new BigNumber(amountOutMin).div(total_amount_out).times(100).toString()
          // 组装 - router列表元素
          const routerElement = {
            buyTokenSymbol: toTokenSymbol,
            sellTokenSymbol: fromTokenSymbol,
            protocolName: protocol_name,
            receiveAmount: singleAfterExchangeAmount,
            swapFee: singleExchangeFeeU,
            swapPrice: price,
            proportion, 
          }
          // 添加 - router列表元素
          routerList.push(routerElement)
        }
        // 更新 - 路由列表
        swapDispatch({ type: 'routerList', payload: routerList })
      }
      // 更新 - 协议router配置
      swapDispatch({ type: 'protocolsRouterInfo', payload: res })
      // 更新 - 输入加载状态
      swapDispatch({ type: 'inputLoadingStatus', payload: false })
      if(routerList.length > 0) {
        // 初始化 - 跨链GAS费用
        let estimateGas:any = '0'
        // 初始化 - 实际收到数量
        let receiveAmount = _outPutFormat(afterExchangeAmount, toTokenDecimal).toString()
        // 获取 - route交易费用(平台手续费+过路费)
        const outPutExchangeFee = _outPutFormat(total_swap_fee, fromTokenDecimal)
        const outPutUnCcdexTolls = _outPutFormat(unCcdexTolls, toTokenDecimal)
        const exchangeFeeU = _getExchangePrice(fromTokenSymbol, outPutExchangeFee, false)
        const unCcdexTollsU = _getExchangePrice(toTokenSymbol, outPutUnCcdexTolls, false)
        const routerExchangeFee = new BigNumber(exchangeFeeU).plus(unCcdexTollsU).toString()
        // 获取 - 选择器
        const selector = _getChainProperty(toNetwork, 'selector')
        // 获取 - 平台费用/LP费用
        const { platformFeeAmount, lpFeeAmount } = await _getCombinedFees(fromOperateToken, selector, swapAmount)
        if(fromNetwork !== toNetwork) {
          // 获取 - 跨链GAS费用
          const { feeInToken } = await _estimateExecutionFees(selector, exchangeToken, account, afterExchangeAmount, exchangeToken)
          estimateGas = feeInToken
          // 获取 - 跨链总费用
          const bridgeFee = new BigNumber(platformFeeAmount).plus(lpFeeAmount).plus(estimateGas).toString()
          receiveAmount = new BigNumber(receiveAmount).minus(bridgeFee).toString()
        }
        // 更新 - 费用参数
        swapDispatch({ type: 'feeParam', payload: { 
          swapFee: routerExchangeFee, 
          platformFeeAmount, 
          lpFeeAmount, 
          estimateGas 
        } })
        // 更新 - 数量参数
        swapDispatch({ type: 'amountParam', payload: { 
          totalAmountOut: afterExchangeAmount, 
          totalAmountIn, 
          receiveAmount 
        } })
      }
    }).catch(() => {
      console.log('interface - 指定链的交易协议路由【异常】')
    })
  }
  
  // 获取 - rechart列表
  const requestChart = () => {
    const chartList:any = []
    swapDispatch({ type: 'chartLoadingStatus', payload: true })
    const param:any = { time_range: period, tokenA_address: fromOperateToken, tokenB_address: exchangeToken }
    console.log('interface - 交易记录【入参】:', param)
    getTradeHistory(fromNetwork, param).then((res: any) => {
      console.log('interface - 交易记录【报文】:', res)
      const { data } = res
      for(const item of data) {
        const { amount_in, amount_out } = item
        const proportion = new BigNumber(amount_in).div(amount_out).toFixed(6)
        chartList.push({ uv: proportion })
      }
      swapDispatch({ type: 'chartList', payload: chartList })
      swapDispatch({ type: 'chartLoadingStatus', payload: false })
    }).catch(() => {
      console.log('interface - 交易记录【异常】')
    })
  }

  // 更新 - rechart列表
  useEffect(() => {
    if(fromToken) requestChart()
  }, [period, fromToken, exchangeToken])

  // 更新 - router列表
  useEffect(() => {
    clearTimeout(delayModule.current)
    if(+swapAmount > 0 && account) {
      delayModule.current = setTimeout(requestProtocolsRouter, 1000)
    } else {
      swapDispatch({ type: 'changeReset' })
    }
  }, [swapAmount, account, UPDATE_SWAP_PROTOCOL_ROUTER])

  useEffect(() => {
    // 更新 - 支持的swap代币列表
    requestTokenList()
  }, [chainSign])

  // 更新 - 币种资产
  useEffect(() => {
    if(tokenFromList.length) {
      const tokenInfo = tokenFromList.find((item:any) => item.address === fromToken)
      swapDispatch({ type: 'tokenBalance', payload: tokenInfo['balance']})
    }
  }, [fromToken, tokenFromList])

  // 更新 - 目标链池子信息
  useEffect(() => {
    if(Object.keys(tokenToList).length && fromNetwork !== toNetwork) requestPoolTokenInfo()
  }, [toToken, tokenToList])

  // 更新 - 币种列表资产
  useEffect(() => {
    const getTokenListBalance = async() => {
      const cloneTokenFromList = tokenFromList.slice(0)
      const cloneTokenToList = Object.assign({}, tokenToList)
      for(const item of cloneTokenFromList) {
        const balance = await _getBalacneOperate(item.address)
        item['balance'] = balance
      }
      cloneTokenToList[requestChainId] = cloneTokenFromList
      swapDispatch({ type: 'tokenFromList', payload: cloneTokenFromList })
      swapDispatch({ type: 'tokenToList', payload: cloneTokenToList })
    }
    const isMatch = +requestChainId === +chainId
    if(account && isMatch && !formLoadingStatus) getTokenListBalance()
  }, [account, formLoadingStatus])

  // 更新 - 交易后数据状态
  useEffect(() => {
    if(fromToken) {
      const updateBalance = async() => {
        const balance = await _getBalacneOperate(fromToken)
        const cloneTokenFromList = tokenFromList.slice(0)
        for(const item of cloneTokenFromList) {
          if(item.address === fromToken) item['balance'] = balance
        }
        swapDispatch({ type: 'tokenBalance', payload: balance})
        swapDispatch({ type: 'tokenFromList', payload: cloneTokenFromList })
      }
      if(tokenFromList.length) updateBalance()
      swapDispatch({ type: 'changeForm', payload: { swapAmount: '' }})
    }
  }, [UPDATE_SWAP_EXCHANGE_END])
}
