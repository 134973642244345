import { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'

/**
 * MetaMask基础方法
 */
export default function useWallet() {

  const { account, walletType } = useContext(ConfigContext)

  /**
   * 获取插件对象
   * @param {String}  type         钱包类型
   */
  const _getEthereum = (type=walletType) => {
    let ethereum:any = null
    if(type === 'MetaMask' || !['Bitkeep', 'OKX Wallet'].includes(type)) ethereum = window.ethereum
    if(type === 'Bitkeep') ethereum = window.bitkeep && window.bitkeep.ethereum
    if(type === 'OKX Wallet') ethereum = window.okxwallet
    return ethereum
  }

  /**
   * 连接 - 小狐狸获取钱包地址
   */
  const _connectMetaMask = async () => {
    try {
      const ethereum:any = _getEthereum()
      return ethereum.request({ method: 'eth_requestAccounts' })
    } catch (error) {
      console.error('连接小狐狸获取钱包地址异常:', error)
    }
  }

  /**
   * 切换 - 网络
   * @param {String} chainId          链ID 0x1/0x4/0x80/0x38
   */
  const _changeChain = async (chainId: string) => {
    const ethereum: any = _getEthereum()
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }]
    })
  }

  /**
   * 添加 - 网络
   * @param {String} chainId -> chain Id
   * @param {String} chainName -> chain Name
   * @param {String} name -> chain stort name
   * @param {String} symbol -> chain base symbol
   * @param {String} rpcUrls -> chain rpc url
   * @param {String} blockExplorerUrls -> chain block Explorer
   */
  const _addChain = async (config: any) => {
    const ethereum: any = _getEthereum()
    const { chainId, chainName, name, symbol, rpcUrls, blockExplorerUrls, decimals = 18 } = config
    return ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId,
          chainName,
          nativeCurrency: {
            name,
            symbol,
            decimals
          },
          rpcUrls,
          blockExplorerUrls
        }
      ]
    })
  }

  /**
   * 切换 - 网络
   * @param {Object}  chainInfo        链信息
   * @param {Void}    callback        回调函数
   */ 
  const _switchChainOperate = async(chainInfo:any, callback:any) => {
    if (account) {
      const { chain_id, chain_name, chain_symbol, currency, rpc_node } = chainInfo
      const chainId = `0x${chain_id.toString(16)}`
      _changeChain(chainId).then(() => {
        callback()
      }).catch((changeError) => {
        if(changeError.code === 4902) {
          const chainParam = {
            chainId, 
            chainName: chain_name, 
            name: chain_symbol, 
            symbol: currency, 
            rpcUrls: [rpc_node], 
          }
          _addChain(chainParam).then(() => {           
          }).catch((addError:any) => {
            console.log('添加链异常:', addError)
          })
        } else {
          console.log('切换链异常:', changeError)
        }
      })
    }
  } 

  return {
    _switchChainOperate,
    _connectMetaMask,
    _getEthereum,
    _changeChain,
    _addChain
  }
}
