const wallet =  window.localStorage.getItem('wallet')

const configState:any = {
  // PC端
  isPC: !navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i),
  // 主题 true: 黑夜模式  false: 白昼模式
  isDaskMode: false,
  // 网络名称 
  chainName: 'Sepolia',
  // 链标识 1:Eth  128:Heco  56:Bsc
  chainSign: 11155111, 
  // spaceID 绑定主域名
  spaceName: '',
  // 账户
  account: '',
  // 链ID  0x1:Eth  0x80:Heco  0x38:Bsc
  chainId: '',
  // 事件数
  eventAmount: 0,
  // H5遮罩
  h5MaskShow: false,
  // 钱包类型
  walletType: wallet ? wallet : 'MetaMask',
  // 弹框展示配置
  popShowConfig: {
    // 选择跨链From链
    bridgeFromChainState: false,
    // 选择swapFrom币种
    swapFromCoinState: false,
    // 选择钱包
    selectWallteState: false,
    // 选择跨链链
    bridgeChainState: false,
    // 选择poolCreate币种
    createCoinState: false,
    // 选择swapTo币种
    swapToCoinState: false,
    // 选择跨链币种
    bridgeCoinState: false,
    // 提取流动性
    extractLiqState: false,
    // 导入提示
    importHintState: false,
    // 移除流动性
    removeLiqState: false,
    // 设置滑点
    settingState: false,
    // GAS提示
    gasHintState: false,
  },
  // 支持链列表
  supportChainList: [],
  // 代币价格列表
  tokenPriceList: [],
  // Toast配置
  toastConfig: {
    status: false,
    address: '',
    kind: 'wait',
    text: '',
    timing: false
  },
  // 状态更新
  updateConfig: {
    // 更新 - 目标链单币池子信息
    UPDATE_POOL_TOKEN: true, 
    // 更新 - 原生代币
    UPDATE_BALANCE: true,
    // 更新 - bridge - FromChain
    UPDATE_BRIDGE: true,
    // 更新 - swap兑换之后
    UPDATE_SWAP_EXCHANGE_END: true,
    // 更新 - swap协议路由
    UPDATE_SWAP_PROTOCOL_ROUTER: true
  },
  // 原生资产
  nativeBalance: '0',
  // 路由表示
  routerSign: ''
}

const configReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    case 'changeChainList':
      updateData['supportChainList'] = payload
      const chainInfo = payload.find((item:any) => Number(updateData['chainId']) === item['chain_id'])
      if(chainInfo) {
        updateData['chainName'] = chainInfo['chain_name']
        updateData['chainSign'] = chainInfo['chain_id']
      }
      break
    case 'chainChain':
      updateData['chainId'] = payload
      if(updateData['supportChainList'].length > 0) {
        const chainInfo = updateData['supportChainList'].find((item:any) => Number(payload) === item['chain_id'])
        if(chainInfo) {
          updateData['chainName'] = chainInfo['chain_name']
          updateData['chainSign'] = chainInfo['chain_id']
        }
      }
      break;
    case 'changePop':
      updateData['popShowConfig'] = { 
        ...updateData['popShowConfig'],
        [payload]: !updateData['popShowConfig'][payload]
      }
      break;
    case 'changeToast':
      updateData['toastConfig'] = Object.assign(updateData['toastConfig'], payload)
      break;
    case 'changeWallet':
      updateData['walletType'] = payload
      localStorage.setItem('wallet', payload)
      break;
    case 'changeState':
      updateData[payload] = !updateData[payload]
      break;
    case 'changeUpdate': 
      updateData['updateConfig'] = { ...updateData['updateConfig'], ...payload }
      break;
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  configReducer,
  configState
}