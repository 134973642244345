import { rem } from '@/theme'
import styled from 'styled-components'

const RechartsStyle = styled.div<{$isShow: boolean}>`

	background: ${({ theme }) => theme.con_bg};
	border: 1px solid ${({ theme }) => theme.gray100};
	div.operate-bar {
		${({ theme }) => theme.align_between};
		div.tool-item {
			${({ theme }) => theme.align};
			figure {
				background: ${({ theme }) => theme.text_bg};
			}
		}
	}
	div.val-bar {
		${({ theme }) => theme.align};
	}
	div.time-bar {
		${({ theme }) => theme.align_end};
		div {
			${({ theme }) => theme.align_center};
		}
		div.active-key {
			background: ${({ theme }) => theme.text_bg};
		}
	}
	div.tip-key {
		background: ${({ theme }) => theme.gray100};
	}
	div.nodata-module {
		position: relative;
		min-height: fit-content;
	}
	// PC
	@media screen and (min-width: 540px){
		height: ${({ $isShow }) => $isShow ? '269px' : 'auto'};
		padding: 20px;
		border-radius: 24px;
		div.operate-bar {
			margin-bottom: 4px;
			div.tool-item {
				figure {
					padding: 8px;
					border-radius: 6px;
					cursor: pointer;
					svg {
						width: 16px;
						height: 16px;
					}
				}
				figure:not(:last-child) {
					margin-right: 8px;
				}
			}
		}
		div.val-bar {
			margin-bottom: 10px;
		}
		div.time-bar {
			margin-top: 10px;
			div {
				padding: 3px 7px;
				border-radius: 4px;
			}
			div:not(:last-child) {
				margin-right: 15px;
			}
		}
		div.tip-key {
			padding: 10px;
			border-radius: 12px;
		}
		div.nodata-module {
			top: -10px;
		}
	}

	// H5
	@media screen and (max-width: 540px){
		height: ${({ $isShow }) => $isShow ? `${rem(220)}` : 'auto'};
		padding: ${rem(16)};
		margin-top: ${rem(16)};
		border-radius: ${rem(16)};
		div.operate-bar {
			margin-bottom: ${rem(2)};
			div.tool-item {
				figure {
					font-size: 0;
					padding: ${rem(4)};
					border-radius: ${rem(6)};
					svg {
						width: ${rem(16)};
						height: ${rem(16)};
					}
				}
				figure:not(:last-child) {
					margin-right: ${rem(8)};
				}
			}
		}
		div.val-bar {
			margin-bottom: ${rem(5)};
		}
		div.time-bar {
			margin-top: ${rem(5)};
			div {
				padding: ${rem(2)} ${rem(6)};
				border-radius: ${rem(4)};
			}
			div:not(:last-child) {
				margin-right: ${rem(12)};
			}
		}
		div.tip-key {
			padding: ${rem(10)};
			border-radius: ${rem(12)};
		}
		div.nodata-module {
			top: ${rem(-10)};
		}
  }
	
`

export {
	RechartsStyle
}




