import React, { useContext } from 'react'
import { ConfigContext, BridgeContext, BridgeDispatchContext, ConfigDispatchContext } from '@/utils/contextStatic'
import { PopBridgeFromChainStyle } from './styled'
import { CheckOutlined } from '@ant-design/icons'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import withRouter from '@/utils/withRouter'
import Pop from '../index'

interface ChildProps extends RouterProps {
  callback: any
  status: boolean
}

const PopBridgeFromChain:React.FC<ChildProps> = ({ t, callback, status })=> {

  const { formParam } = useContext(BridgeContext)

  const bridgeDispatch:any = useContext(BridgeDispatchContext)

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { isPC, supportChainList, updateConfig } = useContext(ConfigContext)

  const { UPDATE_BRIDGE } = updateConfig

  const { fromNetwork } = formParam

  // 操作 - 选择链
  const selectHandle = (item:any) => {
    const { chain_id } = item
    const param = { fromNetwork: chain_id, bridgeAmount: '' }
    bridgeDispatch({ type: 'changeForm', payload: param })
    configDispatch({ type: 'changeUpdate', payload: {UPDATE_BRIDGE: !UPDATE_BRIDGE} })
    callback()
  }

  // 渲染 - 链
  const coinRender = () => {
    const list:any = []
    supportChainList.forEach((item:any, index:number) => {
      const { chain_name, chain_id, chain_symbol } = item
      list.push(
        <div key={`bridgeChain_${index}`} onClick={() => selectHandle(item)}>
          <ScCoin kind={isPC ? '14' : '15'} name={chain_symbol} content={chain_name} imgType='chain' />
          { fromNetwork === chain_id && <CheckOutlined /> }
        </div>
      )
    })
    return list
  }

  return (
    <Pop status={status} title={t('Title.Chain')} callback={callback}>
      <PopBridgeFromChainStyle>
        <div className='chain-bar'>{coinRender()}</div>
      </PopBridgeFromChainStyle>
    </Pop>
  )
}

export default withRouter(PopBridgeFromChain)

