import { useContext } from 'react'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import { ConfigContext } from '@/utils/contextStatic'

/**
 * API - Pool
 */
export default function useContractPool() {

  const { _getContract } = usePublicErc20()

  const { _outPutFormat } = usePublicEthers()

  const { account } = useContext(ConfigContext)

  /**
   * 获取 - 用户在池子中分配的LP
   * @param {String} poolAddress  池子合约地址
   */ 
  const _getPoolBalance = async(poolAddress:string) => {
    console.log('合约【获取 - 用户在池子中分配的LP(入参):', { poolAddress })
    try {
      const poolContract = await _getContract(poolAddress, 'POOLABI')
      const num = await poolContract.balanceOf(account)
      return _outPutFormat(num.toString())
    } catch (error) {
      console.error('合约调用异常【获取 - 用户在池子中分配的LP】:', error)
    }
  }

  return {
    _getPoolBalance
  }
}
