import { rem } from '@/theme'
import styled from 'styled-components'

const pcSizeList:any = { A: '140', B: '140', AB: '200' }
const h5SizeList:any = { A: '130', B: '130', AB: '155' }

const ScInputStyle = styled.div<{$isSelect:boolean, type:string}>`
	position: relative;
	input {
		border-color: ${({ theme }) => theme.gray100};
		text-align: right;
		box-shadow: none;
	}
	input::-webkit-input-placeholder { 
		font-size: 24px;
	}
	& > div:nth-child(1) {
		& > div {
			top: 32%;
		}
	}
	& > div:nth-child(2) {
		position: absolute;
		${({ theme }) => theme.align};
		& > div.double-key {
			${({ theme }) => theme.align};
		}
	}
	& > div:nth-child(3) {
		${({ theme }) => theme.align};
		position: absolute;
	}
	& > div:nth-child(4) {
		position: absolute;
	}
	// PC
	@media screen and (min-width: 540px) { 
		margin-top: 16px;
		input {
			height: 83px;
			padding: 4px 74px 35px ${({ type }) => `${pcSizeList[type]}px` };
			border-radius: 12px;
			font-size: 24px;
		}
		& > div:nth-child(2) {
			top: 12px;
			left: 16px;
			${({ $isSelect }) => $isSelect && 'cursor:pointer'};
			& > svg {
				width: 16px;
				height: 16px;
				margin-left: 8px;
			}
		}
		& > div:nth-child(3) {
			bottom: 10px;
			left: 16px;
			svg {
				width: 20px;
				height: 20px;
				margin-right: 4px;
			}
		}
		& > div:nth-child(4) {
			bottom: 10px;
			right: 20px;
		}
	}

	// H5
	@media screen and (max-width: 540px) { 
		margin-top: ${rem(6)};
		input {
			height: ${rem(83)};
			padding: ${rem(4)} ${rem(70)} ${rem(35)} ${({ type }) => `${rem(h5SizeList[type])}` };
			border-radius: ${rem(8)};
			font-size: ${rem(24)};
		}
		& > div:nth-child(2) {
			top: ${rem(12)};
			left: ${rem(16)};
			& > svg {
				width: ${rem(16)};
				height: ${rem(16)};
				margin-left: ${rem(6)};
			}
		}
		& > div:nth-child(3) {
			bottom: ${rem(10)};
			left: ${rem(16)};
			svg {
				width: ${rem(18)};
				height: ${rem(18)};
				margin-right: ${rem(4)};
			}
		}
		& > div:nth-child(4) {
			bottom: ${rem(10)};
			right: ${rem(20)};
		}
	}
`

export {
  ScInputStyle
}


