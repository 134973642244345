import { Tooltip } from 'antd'
import React, { useState, useContext } from 'react'
import { BridgeContext, BridgeDispatchContext, ConfigContext } from '@/utils/contextStatic'
import { TipSvg, DownSvg } from '@/utils/icoStatic'
import { zeroAddress } from '@/utils/addressStatic'
import ScCoin from '@/components/Section/ScCoin'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import { GasStyle } from './styled'


const Gas:React.FC<RouterProps> = ({ t }) => {

  const { _getChainProperty, linkAddress } = useCommon()

  const { nativeBalance, account } = useContext(ConfigContext)

  const bridgeDispatch:any = useContext(BridgeDispatchContext)

  const { formParam, gasParam, tokenBalance, linkBalance } = useContext(BridgeContext)
  
  const { fromNetwork, fromToken, fromTokenSymbol, bridgeAmount } = formParam
  
  const currentCoin = _getChainProperty(fromNetwork, 'currency')
  
  const [ show, setShow ] = useState<boolean>(false)
  
  const { gasSymbol, gasCost } = gasParam

  // 操作 - 折叠
  const collapseHandle = () => setShow(!show)

  // 获取 - token资产
  const getBalance = () => {
    const balanceList = {
      [currentCoin]: nativeBalance,
      [fromTokenSymbol]: tokenBalance,
      'LINK': linkBalance
    }
    return balanceList[gasSymbol]
  }

  // 操作 - GasParam
  const gasParamHandle = (item:any) => {
    const { symbol, token } = item
    const param = { gasSymbol: symbol, gasToken: token, gasCost: '' }
    bridgeDispatch({ type: 'changeGas', payload: param })
  }

  // 判断 - 资产 true:满足
  const decideBalance = (symbol:string, cost:string) => {
    if(symbol === currentCoin) return +nativeBalance > 0 && +nativeBalance >= +cost
    if(symbol === fromTokenSymbol) return +tokenBalance > 0 && +tokenBalance >= +bridgeAmount
    if(symbol === linkAddress) return +linkBalance > 0 && +linkBalance >= +cost
    return false
  }

  // 渲染 - 字段
  const fieldRender = () => {
    const list:any = []
    const fieldList = [
      { symbol: currentCoin, token: zeroAddress },
      { symbol: fromTokenSymbol, token: fromToken },
      { symbol: 'LINK', token: linkAddress}
    ]
    fieldList.forEach((item:any, index:number) => {
      const { symbol } = item
      list.push(
        <div className={`field-item ${gasSymbol === symbol ? 'active' : ''}`} key={`gas_field_${index}`} onClick={() => gasParamHandle(item)}>
          <ScCoin kind='18' color='gray600' name={symbol} />
        </div>
      )
    })
    return list
  }

  return (
    <GasStyle className={`${show ? 'active' : ''}`}>
      <div className='collapse-bar' onClick={collapseHandle}>
        <div>
          <Tooltip placement="top" title={<Text size='12' color='white'>{t('Tooltip.GasTypeTip')}</Text>}>
            <Text kind='Bold' color='gray600'>{t('Field.GasType')}</Text>
            <TipSvg />
          </Tooltip>
        </div>
        <div>
          <ScCoin kind='3' color='gray600' content={`${+gasCost > 0 ? _numComma(gasCost, 4) : '--'} ${gasSymbol}`} name={gasSymbol} />
          <DownSvg />
        </div>
      </div>
      <div className='field-bar'>
        <Text size='12' color='gray600'>{t('Content.SelectPayGas')}</Text>
        <div>{ fieldRender() }</div>
        { 
          !decideBalance(gasSymbol, gasCost) ? (
            <>{ account && <Text size='12' color='gray500'>{t('Content.Insufficient')}</Text>}</>
          ) : (
            <div>
              <Text size='12' color='primary500'>{t('Field.Available')}</Text>
              <Text size='12' color='gray600'>{_numComma(getBalance(), 4)}</Text>
            </div> 
          )
        }
      </div>
    </GasStyle>
  )
}

export default withRouter(Gas)
