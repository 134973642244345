import React, { useState, useContext, useRef } from 'react'
import { ConfigContext, SwapManageContext, ConfigDispatchContext } from '@/utils/contextStatic'
import useBusinessSwapLiquidity from '@/hooks/useBusiness/useBusinessSwapLiquidity'
import useContractSwapQuoterV2 from '@/hooks/useContract/useContractSwapQuoterV2'
import useContractSwapPairV2 from '@/hooks/useContract/useContractSwapPairV2'
import useContractSwapV2 from '@/hooks/useContract/useContractSwapV2'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import useBtnSwapAddLiq from '@/hooks/useBtn/useBtnSwapAddLiq'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import useBtnApprove from '@/hooks/useBtn/useBtnApprove'
import useBtnChain from '@/hooks/useBtn/useBtnChain'
import ScInput from '@/components/Section/ScInput'
import { chainCoin } from '@/utils/addressStatic'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import withRouter from '@/utils/withRouter'
import useCommon from '@/hooks/useCommon'
import BigNumber from 'bignumber.js'
import Text from '@/components/Text'

const Form:React.FC<RouterProps> = ({ t }) => {

  let delayModule:any = useRef()

  const { _getChainProperty } = useCommon()

  const { isPC } = useContext(ConfigContext)

  const { _inPutFormat } = usePublicEthers()
  
  const { _getAmounts } = useContractSwapQuoterV2()

  const { swapOperateContract } = useContractSwapV2()
  
  const { _getExchangeLpAmount } = useContractSwapPairV2()

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { _getAllowanceOperate, _getDecimals } = usePublicErc20()

  const { poolInfo, tokenBalanceA, tokenBalanceB } = useContext(SwapManageContext)

  const { pool_symbol, pair_token0, chain_id, pair_token1, pair, last_balance } = poolInfo

  const chainName = _getChainProperty(chain_id, 'chain_name')
  
  const [ liquidity, setLiquidity ] = useState<string>('-')

  // 1:授權A 2:加載中 3:确认 5:钱包未链接 6:链不匹配 7：授权B
  const [ btnStatus, setBtnStatus ] = useState<number>(3)

  const [ amountA, setAmountA ] = useState<string>('')

  const [ amountB, setAmountB ] = useState<string>('')

  const coinArr = pool_symbol.split('/')

  // 授权操作
  const coinApproveHandle = useBtnApprove(btnStatus === 1 ? pair_token0 : pair_token1, swapOperateContract, setBtnStatus)

  // 添加流动性操作
  const { _addLiquidityTokenHandle } = useBusinessSwapLiquidity(setBtnStatus)

  // 切链操作
  const toggleChainHandle = useBtnChain(chain_id, setBtnStatus)

  // 按钮状态控制
  useBtnSwapAddLiq(amountA, amountB, setBtnStatus)

  // 计算 - 池子份额
  const computeSharePoolFun = () => {
    if(+liquidity > 0) {
      const total = new BigNumber(last_balance).plus(liquidity).toString()
      const per = new BigNumber(liquidity).div(total).times(100).toString()
      return _numComma(per, 6)
    } else {
      return '0.0'
    }
  }

  // 判断 - 按钮状态
  const openHandle = () => {
    const isPass = +amountA <= +tokenBalanceA && 
                   +amountB <= +tokenBalanceB &&  
                   +tokenBalanceA > 0 &&
                   +tokenBalanceB > 0 &&
                   +amountA > 0 &&
                   +amountB > 0           
    if(isPass) return true
    return false
  }

  // 更新 - 授权数量
  const updataApproveHandle = async() => {
    const approveAmountA = await _getAllowanceOperate(pair_token0, swapOperateContract)
    const approveAmountB = await _getAllowanceOperate(pair_token1, swapOperateContract)
    const decimalA = pair_token0 === chainCoin ? 18 : await _getDecimals(pair_token0)
    const decimalB = pair_token0 === chainCoin ? 18 : await _getDecimals(pair_token1)
    const directionA = new BigNumber(approveAmountA).minus(_inPutFormat(amountA ? amountA : '0', decimalA)).toFixed(0)
    const directionB = new BigNumber(approveAmountB).minus(_inPutFormat(amountA ? amountA : '0', decimalB)).toFixed(0)
    if(+directionA >= 0 && +directionB >= 0) {
      setBtnStatus(3)
    } else {
      if(+directionA < 0) {
        setBtnStatus(1)
      } else {
        if(+directionB < 0) setBtnStatus(7)
      }
    }
  }

  // 展示 - 按钮信息列表
  const btnInfoList:any = {
    1: { text: `${t("Button.Approve")} ${coinArr[0]}`, kind: 'primary', disabled: false },
    2: { text: t("Button.Loading"), kind: 'loading', disabled: true },
    3: { text: t('Button.AddLiquidity'), kind: 'primary', disabled: !openHandle() },
    5: { text: t("Button.Connect"), kind: 'primary', disabled: false },
    6: { text: t('Button.SwitchChain', { chain: chainName }), kind: 'primary', disabled: false },
    7: { text: `${t("Button.Approve")} ${coinArr[1]}`, kind: 'primary', disabled: false },
  }

  const { text, disabled, kind } =  btnInfoList[btnStatus]

  // 按钮处理
  const btnHandle = () => {
    switch(btnStatus) {
      // 授权A
      case 1:
        coinApproveHandle(updataApproveHandle)
        break;
      // 添加
      case 3:
        _addLiquidityTokenHandle(pair_token0, pair_token1, amountA, amountB, updataApproveHandle)
        break;
      // 链接
      case 5:
        configDispatch({ type: 'changePop', payload: 'selectWallteState' })
        break;
      // 切链
      case 6:
        toggleChainHandle()
        break;
      // 授权B
      case 7:
        coinApproveHandle(updataApproveHandle)
        break;
      default:
    }
  }

  // 更新 - 数量
  const changeAmount = async(val:string, type:string) => {
    clearTimeout(delayModule.current)
    delayModule.current = setTimeout(async() => {
      if(type === 'A') setAmountA(val)
      if(type === 'B') setAmountB(val)
      if(+val > 0) {
        const exchangeAmount:any = await _getAmounts(pair, pair_token0, pair_token1, val, type)
        if(type === 'A') setAmountB(exchangeAmount)
        if(type === 'B') setAmountA(exchangeAmount)
        const token0_amount = type === 'A' ? val : exchangeAmount
        const token1_amount = type === 'B' ? val : exchangeAmount
        const liquidity:any = await _getExchangeLpAmount(pair_token0, pair_token1, pair, token0_amount, token1_amount)
        setLiquidity(liquidity)
      }
    }, 1000)
  }

  // 渲染 - input
  const inputRender = () => {
    const list:any = []
    const inputList = [
      { type: 'A', symbol: coinArr[0], balance: tokenBalanceA, amount: amountA },
      { type: 'B', symbol: coinArr[1], balance: tokenBalanceB, amount: amountB }
    ]
    inputList.forEach((item:any, index:number) => {
      const { symbol, balance, amount, type } = item
      list.push(
        <ScInput 
          type={type} 
          symbol={symbol} 
          amount={amount} 
          balance={balance} 
          maxvalue={balance}
          key={`input_${index}`}
          changeAmount={changeAmount} />
      )
    })
    return list
  }

  return (
    <>
      <Text kind='Bold' size={isPC ? '18' : '16'}>{t('Title.AddLiquidity')}</Text>
      <Text color='gray500' size={isPC ? '14' : '12'}>{t('Content.AddLiquidityDesc', { lp: `${pool_symbol}` })}</Text>
      { inputRender() }
      <div className='receive-bar'>
        <Text kind='Bold' size={isPC ? '15' : '14'} color='gray600'>{t('Field.WillReceive')}</Text>
        <div className='receive-item'>
          <Text color='gray600'>{pool_symbol} {t('Field.LiquidityToken')}</Text>
          <Text kind='Bold' type='number' color='gray600'>{`${_numComma(liquidity, 4)}`}</Text>
        </div>
        <div className='last-item'>
          <Text size='12' color='gray600'>{t('Field.SharePool')}</Text>
          <Text size='12' color='gray600'>{computeSharePoolFun()}%</Text>
        </div>
      </div>
      <FormButton size={isPC ? 'large' : 'normal'} kind={kind} callback={btnHandle} disabled={disabled}>
        <Text type='specialEnglish' size='16' color='white'>{text}</Text>
      </FormButton>
    </>
  )
}

export default withRouter(Form)
