const poolCreateState:any = {
  // 表单参数
  createFormParam: {
    selectNetwork: null,
    tokenAddressA: '',
    tokenAddressB: '',
    tokenDecimalA: 18,
    tokenDecimalB: 18,
    tokenSymbolA: '',
    tokenSymbolB: '',
    tokenAmountA: '',
    tokenAmountB: ''
  },
  // 币种列表
  tokenList: [],
  // 链列表
  chainList: [],
  // 加载状态
  loadingStatus: true,
  // TokenA - 资产
  tokenBalanceA: '0',
  // TokenB - 资产
  tokenBalanceB: '0',
  // 池子合约地址
  lpAddress: '',
  // 预估 - 用户添加的流动性
  liquidityUser: '0',
  // 预估 - 用户的池子份额
  sharePool: '0',
  // 导入 - token
  importTokenInfo: {},
  // 选择 - token弹窗类型
  tokenType: 'A',
  // 资产 - 标识 true:需要 false:不需要
  balanceState: false
}

const poolCreateReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    case 'changeForm':
      updateData['createFormParam'] = { ...updateData['createFormParam'], ...payload }
      break;
    case 'changeReset':
      updateData['balanceState'] = true
      updateData['liquidityUser'] = '0'
      updateData['sharePool'] = '0'
      break;
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  poolCreateReducer,
  poolCreateState
}