import { rem } from '@/theme'
import { Skeleton } from 'antd'
import styled from 'styled-components'

const InterimFarmsBar = styled.tr`

	td {
		border-top: 1px solid ${({ theme }) => theme.gray100};
	}
	td:nth-child(7) {
		text-align: right;
	}

	// PC
	@media screen and (min-width: 540px) { 
		td {
			height: 72px;
		}
		td:nth-child(1) {
			padding-left: 25px;
		}
		td:nth-child(7) {
			padding-right: 25px;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		td {
			height: ${rem(62)};
			font-size: 0;
		}
		td:nth-child(1) {
			padding-left: ${rem(16)};
		}
		td:nth-child(7) {
			padding-right: ${rem(16)};
		}
	}
`

const SkeletonLabelStyle = styled(Skeleton.Button)`

	// PC
	@media screen and (min-width: 540px) { 
		.ant-skeleton-button {
			height: 24px !important;
		}
	}

	// H5
	@media screen and (max-width: 540px) {
		.ant-skeleton-button {
			height: ${rem(20)} !important;
		}
	}

`




export {
	SkeletonLabelStyle,
  InterimFarmsBar
}