import styled, { keyframes } from 'styled-components'
import { rem } from '@/theme'

const rotate = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

const FooterBar = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  padding: ${rem(10)} ${rem(30)} ${rem(12)};
  z-index: 2;
  width: 100%;
  background: ${({ theme }) => theme.white};
  box-shadow: ${({ theme }) => theme.h5_footer_bs};
  & nav {
    display: flex;
    figure {
      text-align: center;
      width: 33.33%;
      font-size: 0;
      svg {
        height: ${rem(24)};
        width: ${rem(24)};
        margin-bottom: ${rem(3)};
      }
      svg.active {
        animation: ${rotate} 800ms linear;
      }
    }
  }
`

export {
  FooterBar
}