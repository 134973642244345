import React, { useContext } from 'react'
import { ConfigDispatchContext, ConfigContext } from '@/utils/contextStatic'
import { PopSelectWalletStyle, H5SelectWalletStyle } from './styled'
import usePublicWallet from '@/hooks/usePublic/usePublicWallet'
import { walletSvgList } from '@/utils/icoStatic'
import { useTranslation } from 'react-i18next'
import useCommon from '@/hooks/useCommon'
import Text from '@/components/Text'
import Pop from '../index'

interface ChildProps {
  callback: any
  status: boolean
}

const PopSelectWallet:React.FC<ChildProps> = ({ callback, status })=> {

  const { t } = useTranslation()

  const { linkList } = useCommon()

  const { account, isPC } = useContext(ConfigContext)

  const { _connectMetaMask, _getEthereum } = usePublicWallet()

  const configDispatch:any = useContext(ConfigDispatchContext)

  // MetaMask系列链接方式 - bitkeep/metamask/okx
  const metaMaskHandle = (kind:string) => {
    let ethereum:any = _getEthereum(kind)
    if(!account) {
      // 插件已安装 - 未安装
      if(ethereum) {
        _connectMetaMask().then(() => {
          callback()
        }).catch((err) => {
          console.log('用户拒绝链接钱包', err)
        }) 
      } else {
        if(kind === 'Bitkeep') window.open(linkList['BitkeepDownLoad'])
        if(kind === 'MetaMask') window.open(linkList['MetaMaskDownLoad'])
        if(kind === 'OKX Wallet') window.open(linkList['OkxWalletDownLoad'])
      }
    }
  }

  // 手动链接钱包
  const connectWalletHandle = async(selectType: string) => { 
    switch(selectType) {
      case 'MetaMask':
        metaMaskHandle(selectType)
        break;
      case 'OKX Wallet':
        metaMaskHandle(selectType)
        break;
      case 'Token Pocket':
        metaMaskHandle(selectType)
        break;
      case 'Coinhub':
        metaMaskHandle(selectType)
        break;
      case 'Trust Wallet':
        // WalletConnect链接方式
        break;
      case 'Hyperpay':
        metaMaskHandle(selectType)
        break;
      case 'WalletConnect':
        // WalletConnect链接方式
        break;
      case 'Bitkeep':
        metaMaskHandle(selectType)
        break;
      default:
    }
    configDispatch({ type: 'changeWallet', payload: selectType })
  }

  // 钱包列表
  const walletListRender = () => {
    const list:any = []
    Object.keys(walletSvgList).forEach((text:any, index:number) => {
      const WalletSvg = walletSvgList[text]
      list.push(
        <li key={`walletListRender${index}`} onClick={() => connectWalletHandle(text)}>
          <Text type='english' size='16'>{text}</Text>
          <WalletSvg />
        </li>
      )
    })
    return list
  }

  return (
    <React.Fragment>
      {
        isPC ? (
          <Pop status={status} title={t('Title.ConnectWallet')} callback={callback}>
            <PopSelectWalletStyle>
              <ul>{walletListRender()}</ul>
            </PopSelectWalletStyle>
          </Pop>
        ) : <H5SelectWalletStyle>{walletListRender()}</H5SelectWalletStyle>
      }
    </React.Fragment>
  )
}

export default PopSelectWallet

