import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigDispatchContext, ConfigContext, SwapContext } from '@/utils/contextStatic'
import useContractSwapEncode from '@/hooks/useContract/useContractSwapEncode'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import { chainCoin } from '@/utils/addressStatic'
import useCommon from '@/hooks/useCommon'

/**
 * 业务 - Swap跨链交易
 */
export default function useBusinessSwapExchange(handle:any) {

  const t:any = useTranslation()['t']

  const { exchangeDeadline } = useCommon()
  
  const { _outPutFormat } = usePublicEthers()

  const configDispatch:any = useContext(ConfigDispatchContext)

  const { _swapAndTransferTokenToChain } = useContractBridge()
  
  const { updateConfig, account, supportChainList } = useContext(ConfigContext)
  
  const { swapFormParam, protocolsRouterInfo, amountParam } = useContext(SwapContext)
  
  const { _swapExactTokensForTokens, _swapExactETHForTokens, _exactInputSingle } = useContractSwapEncode()

  const { fromToken, fromOperateToken, fromTokenSymbol, toNetwork, exchangeToken, fromNetwork, fromTokenDecimal } = swapFormParam
  
  const { totalAmountIn, totalAmountOut } = amountParam

  const { protocol_swap_data } = protocolsRouterInfo

  const { UPDATE_SWAP_EXCHANGE_END } = updateConfig

  // 获取 - route信息
  const getSwapRoutes = () => {
    const list:any = []
    for(const item of protocol_swap_data) {
      const { router, protocol_type, path, amountIn, amountOutMin, fee_rate } = item
      const element:any = { router, data: '', value: '0', amountIn, amountOutMin }
      if(protocol_type === 'v2') {
        if(fromToken === chainCoin) {
          element['value'] = amountIn
          element['data'] = _swapExactETHForTokens(amountOutMin, path)
        }
        if(fromToken !== chainCoin) element['data'] = _swapExactTokensForTokens(amountIn, amountOutMin, path)
      }
      if(protocol_type === 'v3') {
        if(fromToken === chainCoin) element['value'] = amountIn
        element['data'] = _exactInputSingle(fromOperateToken, exchangeToken, fee_rate, amountIn, amountOutMin, router.toLowerCase())
      }
      list.push(element)
    }
    return { swapRoutes: list }
  }

  // 获取 - 跨链信息
  const getBridgeInfo = () => {
    const chainInfo = supportChainList.find((item:any) => item.chain_id === toNetwork)
    const transferDetails = {
      targetChainId: chainInfo['selector'],
      tokenAddress: exchangeToken,
      recipientAddress: account,
      transferAmount: totalAmountOut,
      feeToken: exchangeToken,
      transactionDeadline: exchangeDeadline
    }
    return transferDetails
  }

   /**
   * 跨链交易
   * @param {String} callback    回调函数
   * @param {String} handle      更新状态
   */ 
  const _swapAndTransferTokenToChainHandle = async(callback:any) => {
    handle(2)
    const showTotalAmountIn = _outPutFormat(totalAmountIn, fromTokenDecimal)
    let text = `${t("Button.Transferred")} ${showTotalAmountIn} ${fromTokenSymbol}`
    const { swapRoutes } = getSwapRoutes()
    const transferDetails = await getBridgeInfo()
    const isCrossChain = fromNetwork !== toNetwork
    configDispatch({ type: 'changeToast', payload: {status: true, kind: 'wait', text} })
    _swapAndTransferTokenToChain(swapRoutes, transferDetails, fromToken, totalAmountIn, isCrossChain).then(async(res:any) => {
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'submit', address: res.hash} })
      const pledgeInfo = await res.wait()
      if(pledgeInfo.status === 1) {
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'success', timing: true, address: res.hash, text} })
        configDispatch({ type: 'changeUpdate', payload: { UPDATE_SWAP_EXCHANGE_END: !UPDATE_SWAP_EXCHANGE_END } })
        callback()
      } else {
        handle(3)
        configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text} })
      }
    }).catch((err:any)=>{
      configDispatch({ type: 'changeToast', payload: {status: true, kind: 'fail', timing: true, text } })
      handle(3)
      console.log({err})
    })
  }

  return {
    _swapAndTransferTokenToChainHandle
  }
}
