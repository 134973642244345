import React, { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { _substrAddress } from '@/utils/comMethod'
import { JumpSvg } from '@/utils/icoStatic'
import useCommon from '@/hooks/useCommon'
import { ScLinkStyle } from './styled'
import Text from '@/components/Text'

interface ChildProps {
  address: string
  query: string
}

const ScLink:React.FC<ChildProps> = ({ query, address }) => {

  const { isPC } = useContext(ConfigContext)

  const { _getHashQueryAddress } = useCommon()

  const jumpHandle = () => {
    const url = _getHashQueryAddress(address, query)
    window.open(url)
  }

  return (
    <ScLinkStyle onClick={jumpHandle}>
      <Text kind='Bold' type='english' size={isPC ? '16' : '14'} color='primary500'>{_substrAddress(address, 5)}</Text>
      <JumpSvg />
    </ScLinkStyle>
  )
}

export default ScLink