import React from 'react'
import { _getCoinImgUrl } from '@/utils/urlMethod'
import { LpSvg } from '@/utils/icoStatic'
import { ScImgStyle } from './styled'

interface ChildProps {
  symbol: string
  isLp?: boolean
  kind?: string
}

const ScImg:React.FC<ChildProps> = ({ symbol, kind='1', isLp=false})=> {

  const poolSymbol = symbol.split('/')

  return (
    <ScImgStyle className='scimg-module' kind={kind}>
      <img src={_getCoinImgUrl(poolSymbol[0])} alt='' />
      <img src={_getCoinImgUrl(poolSymbol[1])} alt='' />
      { isLp && <LpSvg /> }
    </ScImgStyle>
  )
}

export default ScImg

