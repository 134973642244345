import React from 'react'
import { useContext } from 'react'
import { ConfigContext } from '@/utils/contextStatic'
import { RouterProps } from '@/utils/interface'
import { NoDataSvg } from '@/utils/icoStatic'
import withRouter from '@/utils/withRouter'
import { NoDataStyle } from './styled'
import Text from '@/components/Text'

const NoData:React.FC<RouterProps> = ({ t }) => {

  const { isPC } = useContext(ConfigContext)

  return (
    <NoDataStyle className="nodata-module">
      <figure>
        <NoDataSvg />
        <Text size={isPC ? '16' : '14'}>{t('Content.NoData')}</Text>
      </figure>
    </NoDataStyle>
  )
}
export default withRouter(NoData)
