import React, { useContext, useState, useRef } from 'react'
import { ConfigContext, ConfigDispatchContext, PoolCreateContext, PoolCreateDispatchContext } from '@/utils/contextStatic'
import useBusinessSwapLiquidity from '@/hooks/useBusiness/useBusinessSwapLiquidity'
import useContractSwapQuoterV2 from '@/hooks/useContract/useContractSwapQuoterV2'
import useContractSwapPairV2 from '@/hooks/useContract/useContractSwapPairV2'
import useContractSwapV2 from '@/hooks/useContract/useContractSwapV2'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import useBtnPoolCreate from '@/hooks/useBtn/useBtnPoolCreate'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import useBtnApprove from '@/hooks/useBtn/useBtnApprove'
import useBtnChain from '@/hooks/useBtn/useBtnChain'
import { zeroAddress } from '@/utils/addressStatic'
import ScInput from '@/components/Section/ScInput'
import FormButton from '@/components/FormButton'
import { RouterProps } from '@/utils/interface'
import { _numComma } from '@/utils/comMethod'
import DropDown from '@/components/DropDown'
import withRouter from '@/utils/withRouter'
import { AddSvg } from '@/utils/icoStatic'
import useCommon from '@/hooks/useCommon'
import BigNumber from 'bignumber.js'
import { FormStyle } from './styled'
import Text from '@/components/Text'

const Form:React.FC<RouterProps> = ({ t }) => {

  let delayModule:any = useRef()

  const { _getChainProperty } = useCommon()

  const { isPC } = useContext(ConfigContext)

  const { _inPutFormat } = usePublicEthers()

  const { _getAllowanceOperate } = usePublicErc20()

  const { _getAmounts } = useContractSwapQuoterV2()

  const { swapOperateContract } = useContractSwapV2()
  
  const { _getExchangeLpAmount } = useContractSwapPairV2()

  const configDispatch:any = useContext(ConfigDispatchContext)
  
  const poolCreateDispatch:any = useContext(PoolCreateDispatchContext)

  const { createFormParam, tokenBalanceA, tokenBalanceB, chainList, lpAddress, sharePool } = useContext(PoolCreateContext)

  const { tokenAddressA, tokenAddressB, tokenSymbolA, tokenSymbolB, tokenAmountA, tokenAmountB, tokenDecimalA, tokenDecimalB, selectNetwork } = createFormParam

  const chainIndex = chainList.findIndex((item:any) => item['key'] === selectNetwork)

  const chainName = _getChainProperty(selectNetwork, 'chain_name')

  // 1:授權A 2:加載中 3:确认 5:钱包未链接 6:链不匹配 7：授权B
  const [ btnStatus, setBtnStatus ] = useState<number>(3)

  // 授权操作
  const coinApproveHandle = useBtnApprove(btnStatus === 1 ? tokenAddressA : tokenAddressB, swapOperateContract, setBtnStatus)

  // 添加流动性操作
  const { _addLiquidityTokenHandle } = useBusinessSwapLiquidity(setBtnStatus)

  // 切链操作
  const toggleChainHandle = useBtnChain(selectNetwork, setBtnStatus)

  // 按钮状态控制
  useBtnPoolCreate(tokenAmountA, tokenAmountB, setBtnStatus)

  // 更新 - 授权数量
  const updataApproveHandle = async() => {
    const approveAmountA = await _getAllowanceOperate(tokenAddressA, swapOperateContract)
    const approveAmountB = await _getAllowanceOperate(tokenAddressB, swapOperateContract)
    const directionA = new BigNumber(approveAmountA).minus(_inPutFormat(tokenAmountA ? tokenAmountA : '0', tokenDecimalA)).toNumber()
    const directionB = new BigNumber(approveAmountB).minus(_inPutFormat(tokenAmountB ? tokenAmountB : '0', tokenDecimalB)).toNumber()
    if(directionA >= 0 && directionB >= 0) {
      setBtnStatus(3)
    } else {
      if(directionA < 0) {
        setBtnStatus(1)
      } else {
        if(directionB < 0) setBtnStatus(7)
      }
    }
  }

  // 更新 - 链
  const changeChain =(value:string) => {
    poolCreateDispatch({ type: 'changeForm', payload: { selectNetwork: value } })
  }

  // 更新 - 数量
  const changeAmount = async(val:string, type:string) => {
    clearTimeout(delayModule.current)
    delayModule.current = setTimeout(async() => {
      if(+val > 0) {
        const typeKeyOne = type === 'A' ? 'tokenAmountA' : 'tokenAmountB'
        poolCreateDispatch({ type: 'changeForm', payload: { [typeKeyOne]: val } })
        if(zeroAddress !== lpAddress) {
          const exchangeAmount:any = await _getAmounts(lpAddress, tokenAddressA, tokenAddressB, val, type)
          const typeKeyTwo = type === 'B' ? 'tokenAmountA' : 'tokenAmountB'
          poolCreateDispatch({ type: 'changeForm', payload: { [typeKeyTwo]: exchangeAmount } })
          const token0_amount = type === 'A' ? val : exchangeAmount
          const token1_amount = type === 'B' ? val : exchangeAmount
          const liquidityAmount:any = await _getExchangeLpAmount(tokenAddressA, tokenAddressB, lpAddress, token0_amount, token1_amount)
          poolCreateDispatch({ type: 'liquidityUser', payload: liquidityAmount })
        }
      } else {
        if(zeroAddress !== lpAddress) poolCreateDispatch({ type: 'changeForm', payload: { tokenAmountA: '', tokenAmountB: '' } })
        poolCreateDispatch({ type: 'changeReset' })
      }
    }, 1000)
  }

  // 判断 - 按钮状态
  const openHandle = () => {
    const isPass = +tokenAmountA <= +tokenBalanceA && 
                    +tokenAmountB <= +tokenBalanceB &&  
                    +tokenBalanceA > 0 &&
                    +tokenBalanceB > 0 &&
                    +tokenAmountA > 0 &&
                    +tokenAmountB > 0           
    if(isPass) return true
    return false
  }

  // 展示 - 选择币种弹窗
  const showHandle = (type:string) => {
    poolCreateDispatch({ type: 'tokenType', payload: type })
    configDispatch({ type: 'changePop', payload: 'createCoinState' })
  }

  // 展示 - 按钮信息列表
  const btnInfoList:any = {
    1: { text: `${t("Button.Approve")} ${tokenSymbolA}`, kind: 'primary', disabled: false },
    2: { text: t("Button.Loading"), kind: 'loading', disabled: true },
    3: { text: t(`Button.${zeroAddress !== lpAddress ? 'AddLiquidity' : 'CreatePool'}`), kind: 'primary', disabled: !openHandle() },
    5: { text: t("Button.Connect"), kind: 'primary', disabled: false },
    6: { text: t('Button.SwitchChain', { chain: chainName }), kind: 'primary', disabled: false },
    7: { text: `${t("Button.Approve")} ${tokenSymbolB}`, kind: 'primary', disabled: false },
  }

  const { text, disabled, kind } =  btnInfoList[btnStatus]

  // 按钮处理
  const btnHandle = () => {
    switch(btnStatus) {
      // 授权A
      case 1:
        coinApproveHandle(updataApproveHandle)
        break;
      // 添加
      case 3:
        _addLiquidityTokenHandle(tokenAddressA, tokenAddressB, tokenAmountA, tokenAmountB, updataApproveHandle, `${tokenSymbolA}/${tokenSymbolB}`)
        break;
      // 链接
      case 5:
        configDispatch({ type: 'changePop', payload: 'selectWallteState' })
        break;
      // 切链
      case 6:
        toggleChainHandle()
        break;
      // 授权B
      case 7:
        coinApproveHandle(updataApproveHandle)
        break;
      default:
    }
  }

  // 渲染 - input
  const inputRender = () => {
    const list:any = []
    const inputList = [
      { type: 'A', symbol: tokenSymbolA, balance: tokenBalanceA, amount: tokenAmountA },
      { type: 'B', symbol: tokenSymbolB, balance: tokenBalanceB, amount: tokenAmountB }
    ]
    inputList.forEach((item:any, index:number) => {
      const { symbol, balance, amount, type } = item
      list.push(
        <ScInput 
          type={type} 
          isSelect={true}
          symbol={symbol} 
          amount={amount} 
          balance={balance} 
          maxvalue={balance}
          showFun={showHandle}
          key={`input_${index}`}
          changeAmount={changeAmount} />
      )
    })
    return list
  }

  // 渲染 - 字段列表
  const fieldRender = () => {
    const list:any = []
    let perAB = '0'
    let perBA = '0'
    if(tokenAmountA && tokenAmountB) {
      perAB = new BigNumber(tokenAmountA).div(tokenAmountB).toString()
      perBA = new BigNumber(tokenAmountB).div(tokenAmountA).toString()
    }
    const fieldList = [
      { name: `${tokenSymbolA} per ${tokenSymbolB}`, value: _numComma(perAB,6) },
      { name: `${tokenSymbolB} per ${tokenSymbolA}`, value: _numComma(perBA,6) },
      { name: `${t('Field.SharePool')}`, value: `${_numComma(sharePool, 6)}%` },
    ]
    fieldList.forEach((item:any, index:number) => {
      const { name, value } = item
      list.push(
        <div key={`poolCreateField_${index}`}>
          <Text type='number' size='12'>{value}</Text>
          <Text type='english' size='12' color='gray500'>{name}</Text>
        </div>
      )
    })
    return list
  }

  return (
    <FormStyle>
      <Text kind='Bold' size={isPC ? '20' : '18'}>{t('Title.CreatePosition', {version: 'V2'})}</Text>
      <Text clsName='name-key' kind='Bold' size={isPC ? '18' : '16'}>{t('Field.Network')}</Text>
      <Text color='gray600'>{t('Content.CreateNetworkDesc')}</Text>
      { chainList.length > 0 && <DropDown dropList={chainList} isSpecial={true} callback={(key:string) => changeChain(key)} init={chainIndex} imgType='chain' /> }
      <Text clsName='name-key' kind='Bold' size={isPC ? '18' : '16'}>{t('Field.Deposit')}</Text>
      <Text color='gray600'>{t('Content.CreateDepositDesc')}</Text>
      <div className='input-bar'>
        { inputRender() }
        <AddSvg />
      </div>
      <div className='field-bar'>{fieldRender()}</div>
      <FormButton size={isPC ? 'large' : 'normal'} kind={kind} callback={btnHandle} disabled={disabled}>
        <Text type='specialEnglish' size='16' color='white'>{text}</Text>
      </FormButton>
    </FormStyle>
  )
}

export default withRouter(Form)
