import styled from 'styled-components'

const FooterStyle = styled.footer`
  ${({ theme }) => theme.mainContent};
  ${({ theme }) => theme.align_between};
  padding: 28px 0;
  white-space: nowrap;
  & > svg {
    width: 63px;
    height: 22px;
  }
  & > div {
    ${({ theme }) => theme.align};
    div.link-bar {
      ${({ theme }) => theme.align};
      div.text-module {
        margin-right: 24px;
      }
    }
  }
`

export {
  FooterStyle
}