const poolState:any = {
  // 池子列表 - 源(bridge+swap)
  poolsList: [],
  // 池子列表 - ALL
  allPoolsList: [],
  // 池子列表 - CARD-YOUR
  yourPoolsCardList: [],
  // 池子列表 - BAR-YOUR
  yourPoolsBarList: [],
  // 池子列表 - AVALIABLE
  avaliablePoolsList: [],
  // 池子列表 - TOP
  topPoolsList: [],
  // 池子列表 - 展示
  showPoolsList: [],
  // 池子列表 - 用户创建
  userCreatePoolsList: [],
  // 筛选链列表
  filterChainList: [],
  // 当前列表Tvl
  currentTvl: '0',
  // 加载状态 - Card
  cardLoadingStatus: true,
  // 加载状态 - Bar
  barLoadingStatus: true,
  // 筛选参数
  filterParam: {
    // 父类tab标识
    tabOneKey: 'All',
    // 子类tab标识
    tabTwoKey: 'Swap',
    // 查询标识
    searchKey: '',
    // 链标识 
    chainKey: -1,
    // 排序信息
    sortInfo: { sortKey: '', sortBy: '' }
  }
}

const poolReducer = (state:any, action:any) => {
  const updateData = { ...state }
  const { type, payload } = action
  switch (type) {
    case 'changePoolsBase':
      const { allData, topData } = payload
      updateData['poolsList'] = allData
      updateData['allPoolsList'] = allData
      updateData['topPoolsList'] = topData.slice(0, 8)
      break;
    case 'changePoolsUser':
      const { yourData, avaliableData } = payload
      let concatUserPoolList = yourData
      // 合并 - 用户创建/参与流动性 - Begin
      if(yourData.length && updateData['userCreatePoolsList'].length) {
        const userPoolContractList = yourData.map((item:any) => item.pair)
        const removeDuplicatesList = updateData['userCreatePoolsList'].filter((item:any) => !userPoolContractList.includes(item.pair.toLowerCase()))
        concatUserPoolList = yourData.concat(removeDuplicatesList)
      }
      // 合并 - 用户创建/参与流动性 - End
      updateData['yourPoolsCardList'] = concatUserPoolList.slice(0, 8)
      updateData['avaliablePoolsList'] = avaliableData.slice(0, 8)
      updateData['yourPoolsBarList'] = concatUserPoolList
      break;
    case 'changeFilterParam':
      updateData['filterParam'] = { ...updateData['filterParam'], ...payload }
      break;
    default: 
      updateData[type] = payload
  }
  return updateData
}

export {
  poolReducer,
  poolState
}