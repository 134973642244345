import { baseUrl, infoUrl } from '@/utils/addressStatic'

// 获取 - 双币池列表
const GET_POOLS_LIQUIDITY_LIST = `${baseUrl}/pools/liquidity/list`

// 获取 - 目标链双币池子信息
const GET_POOLS_LIQUIDITY_INFO = `${baseUrl}/pools/liquidity/info`

// 获取 - 跨链桥支持代币列表
const GET_BRIDGE_TOKENS_LIST = `${baseUrl}/bridge/chain/tokens`

// 获取 - 链统一代币行情价格
const GET_TOKEN_PRICE_LIST = `${baseUrl}/chains/token/price`

// 获取 - 目标链单币池子信息
const GET_POOL_TOKEN_INFO = `${baseUrl}/pools/token/check`

// 获取 - 指定链的交易协议路由
const GET_PROTOCOLS_ROUTER= `${baseUrl}/protocols/swaps`

// 获取 - 支持的链列表
const GET_CHAIN_LIST = `${baseUrl}/chains/list`

// 获取 - 单币池列表
const GET_POOLS_LIST = `${baseUrl}/pools/list`

// 获取 - swap交易支持币种
const GET_TOKEN_LIST = (chainId: number) => { 
  return `data/${chainId}/tokenlist.json`
}

// 获取 - 用户创建池子列表
const GET_USER_CREATE_POOLS_LIST = (chainId:number, useAdr:string) => {
  return `${infoUrl}/swap/${chainId}/user/${useAdr}/pools`
}

// 获取 - 用户创建的池子信息
const GET_USER_CREATE_POOLS_INFO = (chainId:number, useAdr:string) => {
  return `${infoUrl}/swap/${chainId}/pool/${useAdr}`
}

// 获取 - 交易记录
const GET_TRADE_HISTORY = (chainId:number) => {
  return `${infoUrl}/swap/${chainId}/pool-swap`
}

export {
  GET_USER_CREATE_POOLS_INFO,
  GET_USER_CREATE_POOLS_LIST,
  GET_POOLS_LIQUIDITY_INFO,
  GET_POOLS_LIQUIDITY_LIST,
  GET_BRIDGE_TOKENS_LIST,
  GET_PROTOCOLS_ROUTER,
  GET_TOKEN_PRICE_LIST,
  GET_POOL_TOKEN_INFO,
  GET_TRADE_HISTORY,
  GET_TOKEN_LIST,
  GET_CHAIN_LIST,
  GET_POOLS_LIST,
}