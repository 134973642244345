import { rem } from '@/theme'
import styled from 'styled-components'

const SwapStyle = styled.div`

	div.bg-bar {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		background: ${({ theme }) => theme.home_bg};
	}
	main {
		position: relative;
	}

	// PC
	@media screen and (min-width: 540px){
		div.bg-bar {
			height: 530px;
		}
		main {
			display: flex;
			z-index: 1;
			${({ theme }) => theme.mainContent};
			margin-top: 20px;
			& > div:nth-child(2) {
				max-width: 440px;
			}
		}
		// 540 - 1200
		@media screen and (max-width: 1200px){
			main {
				flex-direction: column-reverse;
				align-items: center;
				& > div:nth-child(1) {
					transition: width 3s;
					margin-top: 30px;
					flex-grow: 0;
					width: 90%;
				}
			}
		}
		// > 1200
		@media screen and (min-width: 1201px){
			main {
				& > div:nth-child(1) {
					transition: flex-grow 3s;
					flex-grow: 1;
					width: 60%;
					margin-right: 20px;
				}
			}
		}
	}

	// H5
	@media screen and (max-width: 540px){
		div.bg-bar {
			height: ${rem(530)};
		}
		main {
			display: flex;
			flex-direction: column-reverse;
			z-index: 1;
			margin-top: ${rem(56)};
			margin-bottom: ${rem(90)};
			padding: 0 ${rem(16)};
		}
  }
`

export {
	SwapStyle
}




