import { rem } from '@/theme'
import styled from 'styled-components'

const CheckBoxStyle = styled.div`

	${({ theme }) => theme.align};
	div.square-key {
		border-width: 1px;
		border-style: solid;
		border-color: ${({ theme }) => theme.gray300};
	}

	/* PC */
	@media screen and (min-width: 540px) { 
		cursor: pointer;
		svg {
			width: 24px;
			height: 24px;
			margin-right: 6px;
		}
		div.square-key {
			width: 24px;
			height: 24px;
			margin-right: 6px;
			border-radius: 6px;
		}
		&:hover {
			div.square-key {
				border-color: ${({ theme }) => theme.primary500};
				box-shadow: ${({ theme }) => theme.select_bs};
			}
		}
	}

	/* H5 */
	@media screen and (max-width: 540px) { 
		svg {
			width: ${rem(20)};
			height: ${rem(20)};
			margin-right: ${rem(6)};
		}
		div.square-key {
			width: ${rem(20)};
			height: ${rem(20)};
			margin-right: ${rem(6)};
			border-radius: ${rem(4)};
		}
	}
`
export {
	CheckBoxStyle
}




