import { useEffect, useContext } from 'react'
import useContractBridge from '@/hooks/useContract/useContractBridge'
import { ConfigContext, BridgeManageContext } from '@/utils/contextStatic'
import usePublicEthers from '@/hooks/usePublic/usePublicEthers'
import usePublicErc20 from '@/hooks/usePublic/usePublicErc20'
import BigNumber from 'bignumber.js'

/**
 * 设置按钮状态 1:授權 2:加載中 3:确认 5:钱包未链接 6: 链不匹配
 * @param {String} inputVal         // 输入值
 * @param {String} handler          // 方法
 * @param {String} tokenContract    // 币种地址
 * @param {String} kind             // 类型
 */

export default function useBtnLiq( inputVal: string, handler: any, tokenContract:string, kind:string) {

  const { _inPutFormat } = usePublicEthers()

  const { poolInfo } = useContext(BridgeManageContext)

  const { bridgeOperateContract } = useContractBridge()

  const { account, chainId } = useContext(ConfigContext)

  const { _getAllowanceOperate, _getLocalAllowance } = usePublicErc20()

  const { chain_id, token_decimal } = poolInfo

  const decimal = kind === 'Add' ? token_decimal : 18

  // 查看授权
  const validationApprove = async() => {
    const allowance:any = _getLocalAllowance(tokenContract, bridgeOperateContract)
    if(!allowance) return await _getAllowanceOperate(tokenContract, bridgeOperateContract)
    return allowance
  }
  
  // 钱包 -> 授权
  useEffect(() => {
    const initFun = async() => {
      if(account) {
        if(+chainId === chain_id) {
          const approveState = await validationApprove()
          const direction = new BigNumber(approveState).minus(_inPutFormat(inputVal ? inputVal : '0', decimal)).toFixed(0)
          if(+direction >= 0) {
            handler(3)
          } else {
            handler(1)
          }
        } else {
          handler(6)
        }
      } else {
        handler(5)
      }
    }
    initFun()
  },[inputVal, poolInfo, account, chainId]) 
}