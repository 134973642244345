import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigContext, SwapContext } from '@/utils/contextStatic'
import { contractAddressList } from '@/utils/addressStatic'
import { _collectLink } from '@/utils/urlMethod'
import { _numComma } from '@/utils/comMethod'
import BigNumber from 'bignumber.js'

/**
 * 公共方法
 */
export default function useCommon() {

  const { i18n } = useTranslation()

  const { slippageParam } = useContext(SwapContext)

  const { chainSign, supportChainList, tokenPriceList, chainId } = useContext(ConfigContext)

  // 是否为支持链
  const isSupportChain = supportChainList.find((item:any) => item['chain_id'] === +chainId)

  // 交易期限(s)
  const exchangeDeadline = Math.floor(Date.now() / 1000) + 60 * +slippageParam['deadline']

  // 是否为英文
  const isEnglish: boolean = !['zh-CN', 'zh-HK'].includes(i18n.language)

  // link币种地址
  const linkAddress = contractAddressList[chainSign]['symbol']['link']

  // 链接集合
  const linkList:any = _collectLink(i18n.language) 

  /**
   * 获取 - 链属性
   * @param {Number|String} sign      // 链标识
   * @param {String} field            // 字段名
   */
  const _getChainProperty = (sign:number|string, field:string) => {
    const chainInfo = supportChainList.find((item:any) => item['chain_id'] === +sign)
    return chainInfo ? chainInfo[field] : ''
  }

  /**
   * 获取 - 币种兑换US价格
   * @param {String} name     // 币种名称
   * @param {String} val      // 币种数量
   */
  const _getExchangePrice = (name:string, val='0', isConvert=true) => {
    const temp = tokenPriceList.find((item:any) => item.symbol === name)
    const rate = temp ? temp['price'] : '1'
    const usPrice = new BigNumber(val).times(rate).toString()
    return isConvert ? _numComma(usPrice) : usPrice
  }

  /**
   * 获取 - hash查詢地址
   * @param {String} address     // 地址
   * @param {String} chainName   // HECO/BSC/ETH
   * @param {String} state       // 查询类型 tk、token、address
   */
  const _getHashQueryAddress= (address:string, state='tx') => {
    const chainInfo = supportChainList.find((item:any) => item['chain_id'] === chainSign)
    return `${chainInfo['block_explorer']}/${state}/${address}`
  }

  return {
    _getHashQueryAddress,
    _getExchangePrice,
    _getChainProperty,
    exchangeDeadline,
    isSupportChain,
    linkAddress,
    isEnglish,
    linkList
  }
}
